import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { breakpoints } from "../../../../Helpers/breakpoints";
import { token_api_interceptor } from "../../../../Store/Interceptor/Interceptor";
import { QuizResultUrl } from "../../../../Store/Interceptor/Url";
import { UserQuizRowComponent } from "./UserQuizRowComponent";

export const UserQuizDialog = ({ state, changeState, id }) => {
  const [loading, setloading] = useState(true);
  const [data, setdata] = useState([]);

  useEffect(() => {
    if (state) {
      setloading(true);
      token_api_interceptor
        .get(`${QuizResultUrl}running/${id}`)
        .then((res) => {
          if (res.data.status) {
            setdata(res.data.data);
          } else {
            alert(res.data.message);
          }
        })
        .catch((err) => {
          alert("Something went wrong");
        })
        .finally(() => setloading(false));
    }

    return () => { };
  }, [state, id]);

  const renderFooter = () => {
    return (
      <div>
        <Button
          loading={loading}
          label="Ok"
          onClick={hideDialog}
          className="p-button-text"
        />
      </div>
    );
  };

  const hideDialog = () => {
    changeState(false);
  };

  return (
    <div>
      <Dialog
        draggable={false}
        header="Users"
        visible={state}
        onHide={hideDialog}
        style={{ minWidth: "90vw", margin: 10 }}
        breakpoints={breakpoints}
        footer={renderFooter()}
      >

        <div className="flex flex-col items-center w-full mx-auto p-2">
          {loading ? (
            <p>Loading...</p>
          ) : data.length ? (
            <div className="w-full overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table className="min-w-full text-left text-sm font-light">
                    <thead className="border-b font-medium dark:border-neutral-500">
                      <tr className="flex-6">
                        <th scope="col" className="px-2 py-4 flex-1">
                          #
                        </th>
                        <th scope="col" className="px-2 py-4 flex-1">
                          Name
                        </th>
                        <th scope="col" className="px-2 py-4 flex-1">
                          Mobile
                        </th>
                        <th scope="col" className="px-2 py-4">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => {
                        return (
                          <tr
                            key={index}
                            className="border-b border-neutral-300"
                          >
                            <UserQuizRowComponent
                              item={item}
                              data={data}
                              setdata={setdata}
                              idx={index}
                              quizID={id}
                            />
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <p>No Data Found</p>
          )}
        </div>
      </Dialog>
    </div>
  );
};
