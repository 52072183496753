import { token_api_interceptor } from "../Interceptor/Interceptor";
import { Social } from "../Interceptor/Url";

// VIEW
export const SOCIAL = "SOCIAL";
export const SOCIALSTATE = "SOCIAL_STATE";

// UPDATE
export const UPDATESOCIAL = "UPDATE_SOCIAL";
export const UPDATESOCIALSTATE = "UPDATE_SOCIAL_STATE";


export const SocialStateAction = ({ loading, error }) => {
    return {
        type: SOCIALSTATE,
        loading, error,
        message: null
    }
}

export const UpdateSocialStateAction = ({ loading, error }) => {
    return {
        type: UPDATESOCIALSTATE,
        loading, error,
        message: null
    }
}

export const SocialAction = () => {
    return async (dispatch) => {
        await token_api_interceptor.post(Social)
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: SOCIAL,
                        data: resp.data.data
                    });
                } else {
                    dispatch({
                        type: SOCIALSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: SOCIALSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const UpdateSocialAction = ({ id, count }) => {

    return async (dispatch) => {
        await token_api_interceptor.post(Social + 'update', { id, count })
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: UPDATESOCIAL,
                        id, count
                    });
                } else {
                    dispatch({
                        type: UPDATESOCIALSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: UPDATESOCIALSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};