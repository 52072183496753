import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { breakpoints } from "../../../../Helpers/breakpoints";
import { token_api_interceptor } from "../../../../Store/Interceptor/Interceptor";
import { QuestionUrl } from "../../../../Store/Interceptor/Url";
import { ViewQuestionRowComponent } from "./ViewQuestionRowComponent";
import { QuestionDialog } from "../../../Dialog/QuestionDialog";

import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";

export const ViewQuestionsDialog = ({ state, changeState, id }) => {
  const [loading, setloading] = useState(true);
  const [data, setdata] = useState([]);

  const [modalState, setmodalState] = useState(false);

  useEffect(() => {
    if (state) {
      setloading(true);
      token_api_interceptor
        .post(QuestionUrl, { quizId: id })
        .then((res) => {
          if (res.data.status) {
            const _data = res.data.data.map((item, index) => ({ ...item, index: index + 1 }))
            setdata(_data);
          } else {
            alert(res.data.message);
          }
        })
        .catch((err) => {
          alert("Something went wrong");
        })
        .finally(() => setloading(false));
    }

    return () => { };
  }, [state, id]);

  const renderFooter = () => {
    return (
      <div>
        <Button
          loading={loading}
          label="Ok"
          onClick={hideDialog}
          className="p-button-text"
        />
      </div>
    );
  };

  const hideDialog = () => {
    changeState(false);
  };


  const _export = React.useRef(null);

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };


  return (
    <div>
      <Dialog
        draggable={false}
        header="Questions"
        visible={state}
        onHide={hideDialog}
        style={{ minWidth: "90vw", margin: 10 }}
        breakpoints={breakpoints}
        footer={renderFooter()}
      >
        <div className="flex justify-end space-x-3">
          <button
            onClick={() => setmodalState(true)}
            className=" bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-all duration-300"
          >
            + Add Manual
          </button>
            <ExcelExport data={data} ref={_export}>
              <ExcelExportColumn field="index" title="Sr. No." />
              <ExcelExportColumn field="Question" title="Question" />
              <ExcelExportColumn field="Option1" title="Option1" />
              <ExcelExportColumn field="Option2" title="Option2" />
              <ExcelExportColumn field="Option3" title="Option3" />
              <ExcelExportColumn field="Option4" title="Option4" />
              <ExcelExportColumn field="Answer" title="Answer" />
            </ExcelExport>
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-all duration-300" onClick={excelExport}>
              Export
            </button>
          </div>

        <QuestionDialog
          _quizId={id}
          _data={data}
          _setdata={setdata}
          state={modalState}
          changeState={setmodalState}
        />
        <div className="flex flex-col items-center w-full mx-auto p-2">
          {loading ? (
            <p>Loading...</p>
          ) : data.length ? (
            <div className="w-full overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table className="min-w-full text-left text-sm font-light">
                    <thead className="border-b font-medium dark:border-neutral-500">
                      <tr className="flex-6">
                        <th scope="col" className="px-2 py-4 flex-1">
                          #
                        </th>
                        <th scope="col" className="px-2 py-4 flex-1">
                          Question
                        </th>
                        <th scope="col" className="px-2 py-4 flex-1">
                          Option1
                        </th>
                        <th scope="col" className="px-2 py-4">
                          Option2
                        </th>
                        <th scope="col" className="px-2 py-4">
                          Option3
                        </th>
                        <th scope="col" className="px-2 py-4">
                          Option4
                        </th>
                        <th scope="col" className="px-2 py-4">
                          Answer
                        </th>
                        <th scope="col" className="px-2 py-4">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => {
                        return (
                          <tr
                            key={index}
                            className="border-b border-neutral-300"
                          >
                            <ViewQuestionRowComponent
                              item={item}
                              data={data}
                              setdata={setdata}
                              idx={index}
                            />
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <p>No Data Found</p>
          )}
        </div>
      </Dialog>
    </div>
  );
};
