import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { QueryAction, QueryStateAction } from '../../../Store/Action/Query';
import { QueryTable } from '../../../Components/Common/Table/Query/QueryTable';

export const QueryScreen = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(QueryStateAction({ loading: true }))
        dispatch(QueryAction())
    }, [dispatch])


    return (
        <div className='table-padding'>
            <div className='mainHeader'>
                <p className='mainHeading'>Manage Queries</p>
            </div>
            <QueryTable />
        </div>
    );
}
