import { LOGOUT } from "../Action/AuthAction";
import { ADDSEMINAR, UPDATESEMINAR, ADDUPDATESEMINARSTATE, SEMINAR, SEMINARSTATE, DELETESEMINARSTATE, DELETESEMINAR, } from "../Action/SeminarAction";

const initialState = {
    data: [],
    loading: true,
    error: false,
    message: null,

    // ADD-UPDATE
    addUpdateLoading: false,
    addUpdateError: false,
    addUpdateMessage: null,
    addUpdateDone: false,

    // DELETE
    deleteLoading: false,
    deleteError: false,
    deleteMessage: null,
    deleteDone: false,
};

export const SeminarReducer = (state = initialState, action) => {
    switch (action.type) {

        // VIEW SEMINAR
        case SEMINAR: {
            return {
                ...state,
                loading: false,
                error: false,
                data: action.data,
                message: null
            };
        }

        case SEMINARSTATE: {
            return {
                ...state,
                loading: action.loading,
                error: action.error,
                message: action.message
            };
        }

        // ADD SEMINAR
        case ADDSEMINAR: {
            const { id, image, status, title, eventDate, slug } = action
            let data = [...state.data];
            data.push({ ID: id, Image: image, Status: status, Slug: slug, Title: title, EventDate: eventDate })
            return {
                ...state,
                addUpdateLoading: false,
                addUpdateError: false,
                data: data,
                addUpdateMessage: "Seminar Added",
                addUpdateDone: true,
            };
        }

        case ADDUPDATESEMINARSTATE: {
            return {
                ...state,
                addUpdateLoading: action.loading,
                addUpdateError: action.error,
                addUpdateMessage: action.message,
                addUpdateDone: false,

            };
        }

        // UPDATE SEMINAR
        case UPDATESEMINAR: {
            let data = [...state.data];
            const { id, image, status, title, eventDate, slug } = action
            let index = data.findIndex(item =>
                item.ID === id
            )
            data[index] = { ID: id, Image: image, Slug: slug, Status: status, Title: title, EventDate: eventDate }
            return {
                ...state,
                addUpdateLoading: false,
                addUpdateError: false,
                data: data,
                addUpdateMessage: "Seminar Updated",
                addUpdateDone: true,
            };
        }

        // DELETE SEMINAR STATE
        case DELETESEMINARSTATE: {
            const { loading, error, message } = action
            return {
                ...state,
                deleteLoading: loading,
                deleteError: error,
                deleteMessage: message,
                deleteDone: false,

            };
        }

        // DELETE SEMINAR
        case DELETESEMINAR: {
            let data = [...state.data];
            const { id } = action
            const filteredData = data.filter(item => item.ID !== id)
            return {
                ...state,
                deleteLoading: false,
                deleteError: false,
                data: filteredData,
                deleteMessage: "SEMINAR Deleted",
                deleteDone: true,
            };
        }

        // LOGOUT
        case LOGOUT:
            return initialState;

        default:
            return { ...state };
    }
};