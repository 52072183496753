import { token_api_interceptor } from "../Interceptor/Interceptor";
import { CurrentAffairs } from "../Interceptor/Url";

// VIEW
export const CURRENTAFFAIRSFOLDER = "CURRENTAFFAIRSFOLDER";
export const CURRENTAFFAIRSFOLDERSTATE = "CURRENTAFFAIRSFOLDERSTATE";

// ADD
export const ADDCURRENTAFFAIRSFOLDER = "ADDCURRENTAFFAIRSFOLDER";
export const ADDCURRENTAFFAIRSFOLDERSTATE = "ADDCURRENTAFFAIRSFOLDERSTATE";

// VIEW
export const DELETECURRENTAFFAIRSFOLDER = "DELETECURRENTAFFAIRSFOLDER";
export const DELETECURRENTAFFAIRSFOLDERSTATE = "DELETECURRENTAFFAIRSFOLDERSTATE";

export const CurrentAffairsFolderStateAction = ({ loading, error }) => {
    return {
        type: CURRENTAFFAIRSFOLDERSTATE,
        loading, error,
        message: null
    }
}

// DELETE
export const AddCurrentAffairsFolderStateAction = ({ loading, error }) => {
    return {
        type: ADDCURRENTAFFAIRSFOLDERSTATE,
        loading, error,
        message: null
    }
}

export const CurrentAffairsFolderAction = () => {
    return async (dispatch) => {
        await token_api_interceptor.get(CurrentAffairs + 'folder')
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: CURRENTAFFAIRSFOLDER,
                        data: resp.data.data
                    });
                } else {
                    dispatch({
                        type: CURRENTAFFAIRSFOLDERSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: CURRENTAFFAIRSFOLDERSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const AddCurrentAffairsFolderAction = ({ name, date, fullDate }) => {

    return async (dispatch) => {
        await token_api_interceptor.post(CurrentAffairs + 'folder', { name, date })
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: ADDCURRENTAFFAIRSFOLDER,
                        id: resp.data.id,
                        name, date, fullDate
                    });
                } else {
                    dispatch({
                        type: ADDCURRENTAFFAIRSFOLDERSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: ADDCURRENTAFFAIRSFOLDERSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const DeleteCurrentAffairsFolderStateAction = ({ loading, error }) => {
    return {
        type: DELETECURRENTAFFAIRSFOLDERSTATE,
        loading, error
    }
}

export const DeleteCurrentAffairsFolderAction = ({ id }) => {
    return async (dispatch) => {
        await token_api_interceptor.post(CurrentAffairs + 'folder/delete', { id })
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: DELETECURRENTAFFAIRSFOLDER,
                        id
                    });
                } else {
                    dispatch({
                        type: DELETECURRENTAFFAIRSFOLDERSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: DELETECURRENTAFFAIRSFOLDERSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};