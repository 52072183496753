import React, { useState } from "react";
import moment from "moment";
import { shortString } from "../../../../Helpers/ShortString";
import { TransactionPdfDialog } from "../../../Dialog/TransactionPdfDialog";

export const TransactionRowComponent = ({ dt, tableIndex, index }) => {
  const [dialogState, setdialogState] = useState(false);

  return (
    <tr>
      <td className="px-2 py-4 whitespace-nowrap">
        <TransactionPdfDialog
          changeState={setdialogState}
          data={dt}
          state={dialogState}
        />
        <div className="text-md font-medium text-gray-600 text-center">
          {tableIndex * 10 - (9 - index)}
        </div>
      </td>
      <td className="px-2 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-900 text-center">
          {shortString({
            str: dt?.Name || "Guest",
            len: 13,
          })}
        </div>
      </td>
      <td className="px-2 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-900 text-center">{dt.Mobile}</div>
      </td>
      <td className="px-2 py-4 w-60">
        <div className="text-sm text-gray-900 text-center">{dt.Course}</div>
      </td>
      <td className="px-2 py-4 w-60">
        <div className="text-xs text-gray-900 text-center">{dt.Batch}</div>
      </td>
      <td className="px-2 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-900 text-center">Rs. {dt.Amount}</div>
      </td>
      <td className="px-2 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-900 text-center">{dt.Type}</div>
      </td>
      <td className="px-2 py-4 whitespace-nowrap text-center">
        <div
          className={"px-2 text-xs leading-5 font-semibold rounded-full m-auto ".concat(
            dt.Status === "Payment Done"
              ? "bg-green-100 text-green-800"
              : "bg-red-100 text-red-800"
          )}
        >
          {dt.Status}
        </div>
      </td>
      <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
        <p>{moment(dt.CurrDate).format("DD/MM/YYYY LT")}</p>
      </td>
      <td className="px-2">
        <button
          onClick={() => setdialogState(true)}
          className="bg-yellow-400 hover:bg-yellow-600 duration-300 transition-all px-3 py-[7px] rounded-md"
        >
          View
        </button>
      </td>
    </tr>
  );
};
