import React, { useState } from 'react'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputComponent } from '../Common/Inputs/InputComponent';
import { DropdownComponent } from '../Common/Inputs/DropdownComponent';
import { useDispatch, useSelector } from 'react-redux'
import { statuses } from '../../Constants/Statuses';
import { AddInstructorAction, AddUpdateInstructorStateAction, UpdateInstructorAction } from '../../Store/Action/InstructorAction';
import { ImageInputComponent } from '../Common/Inputs/ImageInputContainer';
import { breakpoints } from '../../Helpers/breakpoints';

export const InstructorDialog = ({ state, changeState, oldname, status, id, oldImage }) => {

    const {
        addUpdateLoading,
        addUpdateError,
        addUpdateDone,
        addUpdateMessage, } = useSelector(state => state.instructor)

    const dispatch = useDispatch()
    const [name, setname] = useState(oldname)
    const currStatus = statuses.find(item => item.key === Number(status))
    const [batchStatus, setbatchStatus] = useState(currStatus)
    const [image, setimage] = useState('')

    React.useEffect(() => {
        if (state) {
            dispatch(AddUpdateInstructorStateAction({ loading: false, error: false }))
            if (!id) {
                setname('')
                setimage('')
            }
        }
        // eslint-disable-next-line
    }, [state])


    React.useEffect(() => {
        if (id) {
        } else {
            setname('')
            setimage('')
            setbatchStatus(statuses[0])
        }
        // eslint-disable-next-line
    }, [addUpdateDone, id])


    const renderFooter = () => {
        return (
            <div>
                <Button loading={addUpdateLoading} disabled={addUpdateLoading} label="No" icon="pi pi-times" onClick={hideDialog} className="p-button-text" />
                <Button loading={addUpdateLoading} disabled={addUpdateLoading} label="Yes" icon="pi pi-check" onClick={onClick} autoFocus />
            </div>
        );
    }

    const hideDialog = () => {
        changeState(false)
    }

    const onClick = () => {
        if (name.length) {
            dispatch(AddUpdateInstructorStateAction({ loading: true, error: false }))
            if (id) {
                dispatch(UpdateInstructorAction({ id, image: image || oldImage, name, status: batchStatus.key }))
            } else {
                if (image) {
                    dispatch(AddInstructorAction({ name, image, status: batchStatus.key }))
                } else {
                    alert('Invalid Image')
                }
            }
        } else {
            alert('Invalid Data')
        }
    }

    return (
        <Dialog draggable={false} header="Instructor" visible={state} onHide={hideDialog} style={{ minWidth: '50vw', margin: 10 }} breakpoints={breakpoints} footer={renderFooter()}>

            {addUpdateError && <div className="alert alert-danger text-sm" role="alert">
                <span className='font-bold'>Error! </span>
                <span>{addUpdateMessage}</span>
            </div>}
            {addUpdateDone && <div className="alert alert-success text-sm" role="alert">
                <span className='font-bold'>Success! </span>
                <span>{addUpdateMessage}</span>
            </div>}
            <div className='py-2'>
                <InputComponent name="Name" value={name} changeValue={setname} width={80} />
                <ImageInputComponent key={1} index={1} name="Image" value={image} changeValue={setimage} sizeTo={1500} pixels="500px * 500px" width={80} />
                <DropdownComponent title="Status" selectedItem={batchStatus} changeSelectedItem={setbatchStatus} items={statuses} selectBy="title" width={80} />
            </div>
        </Dialog>
    )
}