import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CourseAction,
  CourseStateAction,
} from "../../../Store/Action/CourseAction";

import { CourseWithBatchAction, CourseWithBatchStateAction } from '../../../Store/Action/CourseWithBatchAction';

import { useLocation } from "react-router-dom";

// CONSTANT
import { statuses } from "../../../Constants/Statuses";
import { QuizUrl } from "../../../Store/Interceptor/Url";

// INTERCEPTOR
import { token_api_interceptor } from "../../../Store/Interceptor/Interceptor";

// COMPONENTS
import { ProgressSpinner } from "primereact/progressspinner";
import { InputComponent } from "../../../Components/Common/Inputs/InputComponent";
import { DropdownComponent } from "../../../Components/Common/Inputs/DropdownComponent";
import { DateInputComponent } from "../../../Components/Common/Inputs/DateInputComponent";
import { TextEditorComponent } from "../../../Components/Common/Inputs/TextEditorComponent";
import { MultiSelectDropDown } from "../../../Components/Common/Inputs/MultiSelectDropDown";

import moment from "moment";

export const AddUpdateQuizScreen = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const leftInfoWidth = 140;

  const courseWithBatchdata = useSelector(state => state.courseWithBatch.data)


  // DATA AVAILABLE
  const quizType = ["Mandatory", "Optional"];

  const userType = useMemo(() => ["Enrolled", "All"], []);

  // DATA VARIABLES
  const [title, settitle] = useState("");
  const [passingMarks, setpassingMarks] = useState(0);
  const [negativeMarks, setnegativeMarks] = useState(0);
  const [defaultMarks, setdefaultMarks] = useState(0);
  const [selectedCourse, setselectedCourse] = useState([]);

  const [availableBatches, setavailableBatches] = useState([])

  const [selectedBatch, setselectedBatch] = useState([])

  const [selectedQuizType, setselectedQuizType] = useState(quizType[0]);
  const [selectedUserType, setselectedUserType] = useState();

  const [startTime, setstartTime] = useState(new Date());
  const [endTime, setendTime] = useState(new Date());
  const [duration, setduration] = useState("");
  const [status, setstatus] = useState(statuses[0]);
  const [instructions, setinstructions] = useState("");

  // UPDATE SCREEN
  const [update, setupdate] = useState(false);
  const [id, setid] = useState(0);
  const [loading, setloading] = useState(false);
  const [error, seterror] = useState(false);

  const [addUpdateLoading, setaddUpdateLoading] = useState(false);

  useEffect(() => {
    if (location.pathname === "/quiz/paid") {
      setselectedUserType(userType[0]);

      dispatch(CourseWithBatchStateAction({ loading: true, error: false }))
      dispatch(CourseWithBatchAction())

    } else {
      setselectedUserType(userType[1]);
    }
    return () => { };
  }, [userType, location.pathname, dispatch]);

  useEffect(() => {
    dispatch(CourseStateAction({ loading: true, error: false }));
    dispatch(CourseAction());
  }, [dispatch]);

  const emptyData = () => {
    setupdate(false);
    setselectedCourse([]);
    settitle("");
    setpassingMarks(0);
    setnegativeMarks(0);
    setdefaultMarks(0);
    setselectedQuizType(quizType[0]);
    setstartTime(new Date());
    setendTime(new Date());
    setduration("");
    setstatus(statuses[0]);
    setinstructions("");
  };

  useEffect(() => {
    if (courseWithBatchdata.length) {
      if (location.search.includes("key")) {
        const key = location.search.slice(5);
        setloading(true);
        setupdate(true);
        setid(key);

        token_api_interceptor
          .post(QuizUrl + "edit", { id: key })
          .then((res) => {
            if (res.data.status) {
              const {
                CourseID,
                BatchID,
                DefaultMarks,
                Instructions,
                NegativeMarks,
                PassingMarks,
                QuizType,
                Status,
                Title,
                StartTime,
                EndTime,
                Duration,
              } = res.data.data;
              const _course = courseWithBatchdata.find((dt) => Number(dt.ID) === Number(CourseID))

              if (_course) {
                const findBatch = _course.batch.find((dt) => Number(dt.ID) === Number(BatchID))
                setselectedBatch(findBatch)
              }
              setstatus(
                statuses.find((dt) => Number(dt.key) === Number(Status))
              );
              setdefaultMarks(DefaultMarks);
              setnegativeMarks(NegativeMarks);
              setpassingMarks(PassingMarks);
              setinstructions(Instructions);
              setselectedQuizType(QuizType);
              settitle(Title);
              setstartTime(new Date(StartTime));
              setendTime(new Date(EndTime));
              setduration(Duration);
              seterror(false);
            } else {
              seterror(true);
            }
          })
          .catch((err) => {
            seterror(true);
          })
          .finally(() => setloading(false));
      } else {
        emptyData();
      }
    }

    return () => { };
    // eslint-disable-next-line
  }, [location.search, courseWithBatchdata.length]);

  const onSubmit = () => {
    if (
      (selectedBatch.length && selectedUserType === "Enrolled") ||
      selectedUserType === "All" || location.search.includes('key')
    ) {
      if (
        startTime &&
        passingMarks &&
        defaultMarks &&
        duration &&
        instructions &&
        title
      ) {

        setaddUpdateLoading(true);

        token_api_interceptor
          .post(QuizUrl.concat(update ? "update" : "add"), {
            title,
            id,
            batch: JSON.stringify(selectedBatch),
            startTime: moment(startTime).format("YYYY-MM-DD"),
            endTime: moment(endTime).format("YYYY-MM-DD"),
            passingMarks,
            defaultMarks,
            negativeMarks,
            duration,
            instructions,
            quizType: selectedQuizType,
            userType: selectedUserType,
            status: status.key,
          })
          .then((res) => {
            if (res.data.status) {
              if (update) {
                alert("Quiz Updated");
              } else {
                alert("Quiz Created");
                emptyData();
              }
            } else {
              alert(res.data.message);
            }
          })
          .catch(() => alert("Something went wrong"))
          .finally(() => setaddUpdateLoading(false));
      } else {
        alert("Insert Required data");
      }
    } else {
      alert("Course is not selected");
    }
  };

  useEffect(() => {
    if (startTime > endTime) {
      setendTime(startTime);
    }

    return () => { };
  }, [startTime, endTime]);

  useEffect(() => {
    if (!location.search.includes('key')) {

      const batches = []
      selectedCourse.forEach(item => {
        item.batch.forEach(dt => {
          const _newData = {
            batch: item.Title.concat(' - (' + dt.Batch + ')'),
            courseID: dt.CourseID,
            batchID: dt.ID
          }
          batches.push(_newData)
        })
      })

      setavailableBatches(batches)
      setselectedBatch([])
    }
    return () => {

    };
  }, [selectedCourse, location.search])

  return (
    <>
      {loading ? (
        <div className="p-5 flex justify-center">
          <ProgressSpinner style={{ height: 40, width: 40 }} />
        </div>
      ) : error ? (
        <div className="p-5">
          <p>Something went wrong</p>
        </div>
      ) : (
        <div className="p-3 pt-5 sm:p-5 main-container">
          <p className="mainHeading mb-3">{update ? "Update" : "Add"} Quiz</p>
          <InputComponent
            width={leftInfoWidth}
            value={title}
            name="Title"
            changeValue={settitle}
            mandatory={true}
          />
          <InputComponent
            width={leftInfoWidth}
            value={passingMarks}
            name="Passing Marks"
            changeValue={setpassingMarks}
            number={true}
            mandatory={true}
          />
          <InputComponent
            width={leftInfoWidth}
            value={defaultMarks}
            name="Correct Marks"
            changeValue={setdefaultMarks}
            number={true}
            mandatory={true}
          />
          <InputComponent
            width={leftInfoWidth}
            value={negativeMarks}
            name="Negative Marks"
            changeValue={setnegativeMarks}
            number={true}
            mandatory={true}
          />
          {/* <MultiSelectDropDown showItem={1} mandatory={true} width={leftInfoWidth} changeSelectedItem={setselectedCourse} items={courseWithBatchdata} label="Title" value={selectedCourse} name="Course" /> */}

          {selectedUserType === "All" || location.search.includes('key') ? (
            <></>
          ) : (
            <>
              <MultiSelectDropDown
                items={courseWithBatchdata}
                width={leftInfoWidth}
                value={selectedCourse}
                changeValue={setselectedCourse}
                name={"Course"}
                showItem={1}
                mandatory={true}
                label="Title"
              />
              <MultiSelectDropDown
                items={availableBatches}
                width={leftInfoWidth}
                value={selectedBatch}
                changeValue={setselectedBatch}
                name={"Batch"}
                showItem={1}
                mandatory={true}
                label="batch"
              />
            </>
          )}
          <DateInputComponent
            width={leftInfoWidth}
            name="Start Time"
            value={startTime}
            mandatory={true}
            changeValue={setstartTime}
          />
          <DateInputComponent
            width={leftInfoWidth}
            name="End Time"
            value={endTime}
            mandatory={true}
            changeValue={setendTime}
            minDate={startTime}
          />
          <InputComponent
            width={leftInfoWidth}
            name="Duration"
            value={duration}
            mandatory={true}
            changeValue={setduration}
            number={true}
            suffix="Minutes"
            suffWidth={70}
          />

          <DropdownComponent
            width={leftInfoWidth}
            items={statuses}
            title="Status"
            selectBy="title"
            mandatory={true}
            selectedItem={status}
            changeSelectedItem={setstatus}
          />
          <DropdownComponent
            width={leftInfoWidth}
            items={quizType}
            title="Quiz Type"
            mandatory={true}
            selectBy=""
            selectedItem={selectedQuizType}
            changeSelectedItem={setselectedQuizType}
          />

          <TextEditorComponent
            width={leftInfoWidth}
            name="Instructions"
            value={instructions}
            changeValue={setinstructions}
          />

          <button
            disabled={addUpdateLoading}
            className="submitButton my-2 cursor-pointer"
            onClick={onSubmit}
          >
            {addUpdateLoading ? "Submitting" : "Submit"}
          </button>
        </div>
      )}
    </>
  );
};
