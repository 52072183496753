import { token_api_interceptor } from "../Interceptor/Interceptor";
import { Slider } from "../Interceptor/Url";

// VIEW
export const SLIDER = "SLIDER";
export const SLIDERSTATE = "SLIDERSTATE";

// ADD
export const ADDSLIDER = "ADDSLIDER";
export const ADDUPDATESLIDERSTATE = "ADDUPDATESLIDERSTATE";

// UPDATE
export const UPDATESLIDER = "UPDATESLIDER";
export const UPDATESLIDERSTATE = "UPDATESLIDERSTATE";

// DELETE
export const DELETESLIDER = "DELETESLIDER";
export const DELETESLIDERSTATE = "DELETESLIDERSTATE";


export const SliderStateAction = ({ loading, error }) => {
    return {
        type: SLIDERSTATE,
        loading, error,
        message: null
    }
}

export const AddUpdateSliderStateAction = ({ loading, error }) => {
    return {
        type: ADDUPDATESLIDERSTATE,
        loading, error,
        message: null
    }
}

export const SliderAction = () => {
    return async (dispatch) => {
        await token_api_interceptor.post(Slider)
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: SLIDER,
                        data: resp.data.data
                    });
                } else {
                    dispatch({
                        type: SLIDERSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: SLIDERSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const AddSliderAction = ({ image, status }) => {
    const formData = new FormData()
    formData.append('image', image)
    formData.append('status', status)
    return async (dispatch) => {
        await token_api_interceptor.post(Slider + 'add', formData)
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: ADDSLIDER,
                        id: resp.data.id,
                        image: resp.data.image, status
                    });
                } else {
                    dispatch({
                        type: ADDUPDATESLIDERSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: ADDUPDATESLIDERSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const UpdateSliderAction = ({ id, image, status }) => {

    const formData = new FormData()
    formData.append('id', id)
    formData.append('image', image)
    formData.append('status', status)

    return async (dispatch) => {
        await token_api_interceptor.post(Slider + 'update', formData)
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: UPDATESLIDER,
                        id, image: resp.data.image, status
                    });
                } else {
                    dispatch({
                        type: ADDUPDATESLIDERSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: ADDUPDATESLIDERSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const DeleteSliderStateAction = ({ loading, error }) => {
    return {
        type: DELETESLIDERSTATE,
        loading, error
    }
}

export const DeleteSliderAction = ({ id }) => {

    return async (dispatch) => {
        await token_api_interceptor.post(Slider + 'delete', { id })
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: DELETESLIDER,
                        id
                    });
                } else {
                    dispatch({
                        type: DELETESLIDERSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: DELETESLIDERSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};