import React, { useState, useEffect } from 'react'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { breakpoints } from '../../Helpers/breakpoints';
import { useDispatch, useSelector } from 'react-redux'
import { MonthInputComponent } from '../Common/Inputs/MonthInputComponent';
import moment from 'moment';
import { AddCurrentAffairsFolderAction, AddCurrentAffairsFolderStateAction } from '../../Store/Action/CurrentAffairsFolder';

export const CurrentAffairFolderDialog = ({ state, changeState }) => {

    const [date, setdate] = useState(new Date())

    const dispatch = useDispatch()

    const {
        addFolderLoading,
        addFolderError,
        addFolderDone,
        addFolderMessage } = useSelector(state => state.currentAffairs)

    const submitData = () => {

        const folderDate = moment(date).format('YYYY-MM-DD')
        const folderName = moment(date).format('MMM-YY')

        dispatch(AddCurrentAffairsFolderStateAction({ loading: true, error: false }))
        dispatch(AddCurrentAffairsFolderAction({ name: folderName, date: folderDate, fullDate: date }))
    }

    useEffect(() => {

        if (addFolderDone || addFolderError) {
            if (addFolderDone) {
                setdate(new Date())
            }
            setTimeout(() => {
                dispatch(AddCurrentAffairsFolderStateAction({ loading: false }))
            }, 2000);

        }

        return () => { }
    }, [addFolderDone, addFolderError, dispatch])

    const renderFooter = () => {
        return (
            <div>
                <Button loading={addFolderLoading} disabled={addFolderLoading} label="No" icon="pi pi-times" onClick={hideDialog} className="p-button-text" />
                <Button loading={addFolderLoading} disabled={addFolderLoading} label="Yes" icon="pi pi-check" onClick={submitData} />
            </div>
        );
    }

    const hideDialog = () => {
        changeState(false)
    }

    return (
        <Dialog draggable={false} header="Current Affair Folder" visible={state} onHide={hideDialog} style={{ minWidth: '50vw', margin: 10 }} breakpoints={breakpoints} footer={renderFooter()}>

            {addFolderError && <div className="alert alert-danger text-sm" role="alert">
                <span className='font-bold'>Error! </span>
                <span>{addFolderMessage}</span>
            </div>}
            {addFolderDone && <div className="alert alert-success text-sm" role="alert">
                <span className='font-bold'>Success! </span>
                <span>{addFolderMessage}</span>
            </div>}
            <div className='py-2'>
                <MonthInputComponent name="Folder" value={date} changeValue={setdate} width={80} />
            </div>
        </Dialog>
    )
}