import React from 'react'
import { LoginScreen } from './Screens/Auth/LoginScreen'
import { Routes, Route, Navigate } from 'react-router-dom'
import { routes } from './Constants/routes';
import { useSelector } from 'react-redux';

export const AuthRoutes = () => {
    return <Routes>
        <Route path='/' element={<LoginScreen />} />
        <Route path='*' element={<Navigate to='/' />} />
    </Routes>
}

export const MainRoutes = () => {
    const pages = useSelector(state => state.auth.pages)
    
    return <Routes> {routes.map((item, index) => <Route key={index} path={item.url} element={pages.find(dt => dt.Name.toUpperCase() === item.name.toUpperCase()) ? item.component : <Navigate to='/' />} />)}</Routes>
}