import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SocialTable } from '../../../Components/Common/Table/Social/SocialTable';
import { SocialAction, SocialStateAction } from '../../../Store/Action/SocialAction';

export const SocialScreen = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(SocialStateAction({ loading: true }))
        dispatch(SocialAction())
    }, [dispatch])


    return (
        <div className='table-padding'>
            <div className='mainHeader'>
                <p className='mainHeading'>Manage Social</p>
            </div>
            <SocialTable />
        </div>
    );
}
