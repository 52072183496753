import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TransactionAction,
  TransactionStateAction,
} from "../../../../Store/Action/TransactionAction";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import { MultiSelect } from "primereact/multiselect";
import { InputComponent } from "../../Inputs/InputComponent";
import { TransactionRowComponent } from "./TransactionRowComponent";

export function TransactionTable() {
  const [transactionData, settransactionData] = useState([]);
  const [showtransactionData, setshowtransactionData] = useState([]);
  const [numbers, setnumbers] = useState([1]);
  const [indexValue, setindexValue] = useState(1);
  const [tableIndex, settableIndex] = useState(0);
  const [selectedCourse, setselectedCourse] = useState([]);
  const [availableData, setavailableData] = useState([]);
  const [mobile, setmobile] = useState("");
  const [selectedResponse, setselectedResponse] = useState([]);
  const [selectedType, setselectedType] = useState([]);

  const Transaction = useSelector((state) => state.transaction);

  function range(start, end) {
    return Array(end - start + 1)
      .fill()
      .map((_, idx) => start + idx);
  }

  const dispatch = useDispatch();
  useEffect(() => {
    if (mobile || selectedResponse.length || selectedType.length) {
      const filteredData = Transaction.data.filter((item) => {
        return (
          item.Mobile.includes(mobile) &&
          selectedResponse.find((dt) => dt.Name === item.Status) &&
          selectedType.find((dt) => {
            return dt.Type === item.Type;
          })
        );
      });
      setavailableData(filteredData);
    } else {
      setavailableData(Transaction.data);
      setselectedResponse(Transaction.paymentResponse);
      setselectedType(Transaction.paymentType);
    }
    return () => {};
  }, [Transaction, selectedResponse, selectedType, mobile]);

  useEffect(() => {
    dispatch(TransactionStateAction({ loading: true, error: false }));
    dispatch(TransactionAction());
  }, [dispatch]);

  useEffect(() => {
    setshowtransactionData(
      transactionData.slice((tableIndex - 1) * 10, tableIndex * 10)
    );
  }, [transactionData, tableIndex]);

  useEffect(() => {
    if (Math.ceil(transactionData.length / 10) > indexValue * 5 - 5) {
      setnumbers(
        range(
          indexValue * 5 - 4,
          Math.ceil(
            transactionData.length > indexValue * 5 * 10
              ? indexValue * 5
              : transactionData.length / 10
          )
        )
      );
      settableIndex(indexValue * 5 - 4);
    }
  }, [indexValue, transactionData]);

  useEffect(() => {
    if (tableIndex === 0) {
      setselectedCourse(Transaction.courses);
      settransactionData(availableData);
      setshowtransactionData(availableData.slice(0, 10));
      setnumbers(
        range(
          1,
          Math.ceil(availableData.length > 50 ? 5 : availableData.length / 10)
        )
      );
      if (selectedCourse.length) {
        settableIndex(1);
      }
    } else {
      const data = availableData.filter((item) =>
        selectedCourse.find((data) => data.ID === item.CourseID)
      );
      settransactionData(data);
      setshowtransactionData(data.slice(0, 10));
      setnumbers(range(1, Math.ceil(data.length > 50 ? 5 : data.length / 10)));
      settableIndex(1);
    }
    // eslint-disable-next-line
  }, [selectedCourse, Transaction, availableData]);

  const _export = React.useRef(null);

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  return (
    <div
      className="flex flex-col p-2"
      style={{
        width: "100%",
        overflow: "hidden",
      }}
    >
      <p className="text-2xl font-semibold mt-1 mb-3">Transactions</p>
      {Transaction.loading ? (
        <p>Loading</p>
      ) : Transaction.error ? (
        <p>Something went wrong</p>
      ) : (
        <>
          <div className="mt-1">
            <MultiSelect
              className="w-full"
              value={selectedCourse}
              options={Transaction.courses}
              onChange={(e) => {
                setselectedCourse(e.value);
              }}
              optionLabel="Title"
              placeholder="Select course"
              maxSelectedLabels={1}
            />
          </div>
          <div className="flex flex-col sm:flex-row my-2">
            <MultiSelect
              className="w-full"
              value={selectedType}
              options={Transaction.paymentType}
              onChange={(e) => {
                setselectedType(e.value);
              }}
              optionLabel="Type"
              placeholder="Select Type"
              maxSelectedLabels={1}
            />
            <div style={{ width: 10, height: 8 }} />
            <MultiSelect
              className="w-full"
              value={selectedResponse}
              options={Transaction.paymentResponse}
              onChange={(e) => {
                setselectedResponse(e.value);
              }}
              optionLabel="Name"
              placeholder="Select Response"
              maxSelectedLabels={1}
            />
          </div>
          <div className="mb-3">
            <InputComponent
              width={80}
              name="Mobile"
              changeValue={setmobile}
              value={mobile}
            />
          </div>
          <div className="flex justify-end mb-3">
            <ExcelExport data={transactionData} ref={_export}>
              <ExcelExportColumn field="index" title="Sr. No." />
              <ExcelExportColumn field="Name" title="Name" />
              <ExcelExportColumn field="Mobile" title="Contact" />
              <ExcelExportColumn field="Course" title="Course" />
              <ExcelExportColumn field="Batch" title="Batch" />
              <ExcelExportColumn field="Type" title="Type" />
              <ExcelExportColumn field="Amount" title="Transaction Amount" />
              <ExcelExportColumn field="Status" title="PaymentStatus" />
            </ExcelExport>
            <button className="btn btn-secondary px-4" onClick={excelExport}>
              Export
            </button>
          </div>
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="pl-5 pr-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Sr.
                      </th>
                      <th
                        scope="col"
                        className="pl-5 pr-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Mobile
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Course
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Batch
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3  text-xs font-medium text-gray-500 uppercase  text-center"
                      >
                        Amount
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Type
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        DateTime
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        View
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {showtransactionData.map((dt, index) => {
                      return (
                        <TransactionRowComponent
                          key={index}
                          index={index}
                          dt={dt}
                          tableIndex={tableIndex}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
            <div className="flex-1 flex justify-between sm:hidden">
              <p
                onClick={() => {}}
                style={{ marginBottom: 0 }}
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                Previous
              </p>
              <p
                style={{ marginBottom: 0 }}
                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                Next
              </p>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing{" "}
                  <span className="font-medium">
                    {showtransactionData.length ? tableIndex * 10 - 9 : 0}
                  </span>{" "}
                  to{" "}
                  <span className="font-medium">
                    {tableIndex * 10 > transactionData.length
                      ? transactionData.length
                      : tableIndex * 10}
                  </span>{" "}
                  of
                  <span className="font-medium">
                    {" "}
                    {transactionData.length}
                  </span>{" "}
                  results
                </p>
              </div>
              <div>
                <nav
                  className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                  aria-label="Pagination"
                >
                  <p
                    style={{ marginBottom: 0, cursor: "pointer" }}
                    className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    <span className="sr-only">Previous</span>
                    <ChevronLeftIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                      onClick={() => {
                        if (indexValue !== 1) {
                          setindexValue(indexValue - 1);
                        }
                      }}
                    />
                  </p>
                  {numbers.map((data) => {
                    return (
                      <p
                        onClick={() => {
                          settableIndex(data);
                        }}
                        key={data}
                        style={{ marginBottom: 0, cursor: "pointer" }}
                        className={
                          tableIndex === data
                            ? "z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                            : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                        }
                      >
                        {data}
                      </p>
                    );
                  })}
                  <p
                    style={{ marginBottom: 0, cursor: "pointer" }}
                    className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                      onClick={() => {
                        // changeIndex('increment')
                        setindexValue(indexValue + 1);
                      }}
                    />
                  </p>
                </nav>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
