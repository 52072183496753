import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { EnrolledUserTable } from '../../../Components/Common/Table/Enrollment/EnrollmentTable'
import { EnrolledUsersAction, EnrolledUsersStateAction } from '../../../Store/Action/EnrollmentAction'

export const EnrolledUsersScreen = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(EnrolledUsersStateAction({ loading: true }))
        dispatch(EnrolledUsersAction())

        return () => {

        }
    }, [dispatch])


    return (
        <div className='table-padding'>
            <div className='mainHeader'>
                <p className='mainHeading'>Enrolled Users</p>
            </div>
            <EnrolledUserTable />
        </div>
    )
}