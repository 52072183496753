import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { QuizTable } from "../../../Components/Common/Table/Quiz/QuizTable";
import { QuizAction, QuizStateAction } from "../../../Store/Action/Quiz";
import { DropdownComponent } from "../../../Components/Common/Inputs/DropdownComponent";
import { UploadQuestionDialog } from "../../../Components/Common/Table/Quiz/UploadQuestionDialog";

export const QuizScreen = () => {
  const dispatch = useDispatch();
  const types = ["All", "Enrolled"];

  const [modalState, setmodalState] = useState(false);

  const [selectedUserType, setselectedUserType] = useState(types[0]);

  const [selectedQuizes, setselectedQuizes] = useState([]);

  useEffect(() => {
    dispatch(QuizStateAction({ loading: true }));
    dispatch(QuizAction());
  }, [dispatch]);

  useEffect(() => {
    setselectedQuizes([]);
  }, [selectedUserType]);

  return (
    <div className="table-padding">
      <div className="mainHeader">
        <p className="mainHeading">Manage Quizzes</p>
        <div className="flex space-x-3">
          <button
            onClick={() => setmodalState(true)}
            disabled={!selectedQuizes.length}
            className={" transition-all duration-300 px-4 py-2 rounded-md text-white ".concat(
              selectedQuizes.length
                ? "bg-sky-500 hover:bg-sky-700"
                : "bg-gray-500 "
            )}
          >
            + Multiple Quizzes
          </button>
          <Link to={"/quiz/free"} className="custButton hover:text-white">
            Free Quizzes
          </Link>
          <Link to={"/quiz/paid"} className="custButton hover:text-white">
            Paid Quizzes
          </Link>
        </div>
      </div>
      <UploadQuestionDialog
        id={selectedQuizes}
        state={modalState}
        changeState={setmodalState}
        removeId={() => setselectedQuizes([])}
      />
      <DropdownComponent
        changeSelectedItem={setselectedUserType}
        items={types}
        selectBy={""}
        selectedItem={selectedUserType}
        title={"User Type"}
        width={130}
      />
      <div className="h-3"></div>

      <QuizTable
        selectedUser={selectedUserType}
        selectedQuizes={selectedQuizes}
        setselectedQuizes={setselectedQuizes}
      />
    </div>
  );
};
