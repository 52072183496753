import React, { useEffect, useCallback } from 'react'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useDispatch } from 'react-redux'
import { FiAlertTriangle } from 'react-icons/fi'

export const DeleteAlertComponent = ({ state, changeState, id, data, deleteNow, deleteState }) => {

    const dispatch = useDispatch()
    const { deleteLoading, deleteError, deleteMessage, deleteDone } = data

    const renderFooter = () => {
        return (
            <div>
                <Button loading={deleteLoading} disabled={deleteLoading} label="No" icon="pi pi-times" onClick={hideDialog} className="p-button-text" />
                <Button loading={deleteLoading} disabled={deleteLoading} label="Yes" icon="pi pi-check" onClick={onClick} />
            </div>
        );
    }

    const hideDialog = useCallback(
        () => {
            dispatch(deleteState({ loading: false, error: false }))
            changeState(false)
        },
        [changeState, dispatch, deleteState],
    )

    useEffect(() => {
        if (deleteDone) {
            hideDialog()
        }

        return () => { }
    }, [deleteDone, hideDialog])

    useEffect(() => {
        if (state) {
            dispatch(deleteState({ loading: false, error: false }))
        }

        return () => { }
    }, [state, dispatch, deleteState])


    const onClick = () => {
        dispatch(deleteState({ loading: true }))
        dispatch(deleteNow({ id }))
    }

    return (
        <Dialog draggable={false} header="Delete Confirmation
        " visible={state} onHide={hideDialog} breakpoints={{ '960px': '50vw' }} style={{ width: '30vw' }} footer={renderFooter()}>
            {
                deleteError && <div className="alert alert-danger text-sm" role="alert">
                    <span className='font-bold'>Error! </span>
                    <span>{deleteMessage}</span>
                </div>
            }
            <div className='flex items-center py-1'>
                <FiAlertTriangle size={35} /> <p className='ml-3'>Do you want to delete this record?</p>
            </div>
        </Dialog>
    )
}