import React, { useState, useEffect } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { useSelector } from 'react-redux';
import { QueryRowComponent } from './QueryRowComponent';

export function QueryTable() {
    const [showData, setshowData] = useState([]);
    const [numbers, setnumbers] = useState([1]);
    const [indexValue, setindexValue] = useState(1);
    const [tableIndex, settableIndex] = useState(0)

    const { loading, error, message, data } = useSelector(state => state.query)

    const column = ['Sr.', 'Name', 'Mobile', 'Message', 'Action']

    function range(start, end) {
        return Array(end - start + 1)
            .fill()
            .map((_, idx) => start + idx);
    }

    useEffect(() => {
        setshowData(data.slice((tableIndex - 1) * 10, tableIndex * 10));
        return () => {
            setshowData([])
        }
    }, [data, tableIndex]);

    useEffect(() => {
        if (data.length && Math.ceil(data.length / 10) > indexValue * 5 - 5) {
            setnumbers(
                range(
                    indexValue * 5 - 4,
                    Math.ceil(
                        data.length > indexValue * 5 * 10
                            ? indexValue * 5
                            : data.length / 10
                    )
                )
            );
            settableIndex(indexValue * 5 - 4)
        }
        return () => { }
    }, [indexValue, data.length]);

    useEffect(() => {
        setshowData(data.slice(0, 10));
        setnumbers(
            range(
                1,
                Math.ceil(data.length > 50 ? 5 : data.length / 10)
            )
        );
        return () => { }
    }, [data]);


    return (
        <div
            className="flex flex-col"
            style={{
                width: "100%",
            }}
        >
            {loading ? (
                <p>Loading</p>
            ) : error ? (
                <p>{message}</p>
            ) : (
                <>
                    <div className="overflow-x-auto sm:-mx-4 lg:-mx-6">
                        <div className="align-middle inline-block min-w-full sm:px-4 lg:px-6">
                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-200" >
                                    <thead className="bg-gray-50">
                                        <tr>
                                            {
                                                column.map((item, index) => {
                                                    return <th key={index}
                                                        scope="col"
                                                        className="flex-1 py-3 px-6 text-center text-base font-semibold text-gray-500 tracking-wider mx-2">
                                                        {item}
                                                    </th>
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {showData.map((dt, index) => {
                                            return <QueryRowComponent _i={tableIndex * 10 - (9 - index)} key={dt.ID} dt={dt} />
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                        <div className="flex-1 flex justify-between sm:hidden">
                            <p
                                onClick={() => { }}
                                style={{ marginBottom: 0 }}
                                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                            >
                                Previous
                            </p>
                            <p
                                style={{ marginBottom: 0 }}
                                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                            >
                                Next
                            </p>
                        </div>
                        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                            <div>
                                <p className="text-sm text-gray-700">
                                    Showing
                                    <span className="font-medium mx-1">
                                        {data.length ? tableIndex * 10 - 9 : 0}
                                    </span>
                                    to
                                    <span className="font-medium mx-1">
                                        {tableIndex * 10 > data.length
                                            ? data.length
                                            : tableIndex * 10}
                                    </span>
                                    of
                                    <span className="font-medium mx-1"> {data.length}</span>
                                    results
                                </p>
                            </div>
                            <div>
                                <nav
                                    className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                                    aria-label="Pagination"
                                >
                                    <p
                                        style={{ marginBottom: 0, cursor: "pointer" }}
                                        className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                    >
                                        <span className="sr-only">Previous</span>
                                        <ChevronLeftIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                            onClick={() => {
                                                if (indexValue !== 1) {
                                                    setindexValue(indexValue - 1);
                                                }
                                            }}
                                        />
                                    </p>
                                    {numbers.map((data) => {
                                        return (
                                            <p
                                                onClick={() => {
                                                    settableIndex(data)
                                                }}
                                                key={data}
                                                style={{ marginBottom: 0, cursor: "pointer" }}
                                                className={
                                                    tableIndex === data
                                                        ? "z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                                                        : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                                                }
                                            >
                                                {data}
                                            </p>
                                        );
                                    })}
                                    <p
                                        style={{ marginBottom: 0, cursor: "pointer" }}
                                        className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                    >
                                        <span className="sr-only">Next</span>
                                        <ChevronRightIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                            onClick={() => {
                                                // changeIndex('increment')
                                                setindexValue(indexValue + 1);
                                            }}
                                        />
                                    </p>
                                </nav>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
