import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { InstructorTable } from '../../../Components/Common/Table/Instructor/InstructorTable';
import { InstructorDialog } from '../../../Components/Dialog/InstructorDialog';
import { InstructorAction, InstructorStateAction } from '../../../Store/Action/InstructorAction';

export const InstructorScreen = () => {
    const [instructordialogState, setinstructordialogState] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(InstructorStateAction({ loading: true }))
        dispatch(InstructorAction())
    }, [dispatch])


    return (
        <div className='table-padding'>
            <InstructorDialog state={instructordialogState} changeState={setinstructordialogState} status={1} oldname="" />
            <div className='mainHeader'>
                <p className='mainHeading'>Manage Instructor</p>
                <button className='custButton' onClick={() => setinstructordialogState(true)}>Add New</button>
            </div>
            <InstructorTable />
        </div>
    );
}
