import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { LectureAction, LectureStateAction } from '../../../Store/Action/LectureAction';
import { LectureTable } from '../../../Components/Common/Table/Lecture/LectureTable';

export const LecturesScreen = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(LectureStateAction({ loading: true }))
        dispatch(LectureAction())
    }, [dispatch])

    const navigate = useNavigate()
    return (
        <div className='table-padding'>
            <div className='mainHeader'>
                <p className='mainHeading'>Manage Lectures</p>
                <div className='flex'>
                    <button className='custButton mr-4' onClick={() => navigate('/manual-lecture')}>Manual Add New</button>
                    <button className='custButton' onClick={() => navigate('/lecture')}>Add New</button>
                </div>
            </div>
            <LectureTable />
        </div>
    );
}