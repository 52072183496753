
import React, { useState, useEffect } from 'react';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import { GrFormAdd } from 'react-icons/gr'
import { CouponDialog } from '../../Dialog/CouponDialog';
// import './MultiSelectDemo.css';

export const MultiSelectDropDown = ({ name, items, value, changeValue, showItem, label = 'title', mandatory = false, width = 130, disabled = false }) => {

    const [dialogState, setdialogState] = useState(false)
    const [addMoreState, setaddMoreState] = useState(false)

    useEffect(() => {
        switch (name) {
            case 'Coupon':
                setaddMoreState(true)
                break;

            default:
                setaddMoreState(false)
                break;
        }

        return () => { }
    }, [name])


    const AddNewContent = () => {
        switch (name) {
            case 'Coupon':
                return <CouponDialog state={dialogState} changeState={setdialogState} amt={0} oldcoupon="" status={1} disc={0} type={1} />

            default:
                break;
        }
    }

    return (
        <div className="p-fluid grid mb-2 text-sm z-0">
            <div className="field col-12 md:col-4">
                {AddNewContent()}
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon" style={{ width }}>
                        <span>{name} <span className='text-red-800'>{mandatory && '*'}</span></span>
                    </span>
                    {items?.length ? <MultiSelect disabled={disabled} value={value} options={items} onChange={(e) => changeValue(e.value)} optionLabel={label} placeholder={"Select " + name} maxSelectedLabels={showItem} /> : <InputText id="inputgroup" type="text" value={`No ${name}s available`} disabled />
                    }
                    {addMoreState && <div onClick={() => setdialogState(true)} className='flex justify-center items-center bg-green-500 hover:bg-green-400 transition-all duration-300 aspect-square w-11 rounded-r-md cursor-pointer'>
                        <GrFormAdd size={20} color="#FFF" />
                    </div>}
                </div>
            </div>
        </div>
    )
}

