import React, { useState, useEffect } from 'react'
import { LockClosedIcon } from '@heroicons/react/solid'
import { useDispatch, useSelector } from 'react-redux'
import { AuthStateAction, LoginAction } from '../../Store/Action/AuthAction'
import { ProgressSpinner } from 'primereact/progressspinner';

export const LoginScreen = () => {
    const [checked, setChecked] = useState(false)
    const [user, setuser] = useState('')
    const [password, setpassword] = useState('')
    const dispatch = useDispatch()
    const [alertState, setalertState] = useState(false)
    const userState = useSelector(state => state.auth)
    const session = useSelector(state => state.session.sessionExpire)

    useEffect(() => {
        if (userState.error) {
            setalertState(true)
            setTimeout(() => {
                setalertState(false)
                dispatch(AuthStateAction({ loading: false, error: false }))
            }, 3000);
        }

        return () => {
        }
    }, [userState.error, dispatch])


    return (<div className="min-h-screen w-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
            <div>
                <img
                    className="mx-auto h-12 w-auto"
                    src="/Assets/Images/logo.png"
                    alt="Logo"
                />
                <h2 className="mt-4 text-center text-3xl font-bold text-gray-900">Sign in to your account</h2>
            </div>



            <form className="mt-1 space-y-6" onSubmit={(e) => {
                e.preventDefault()
                if (user && password) {
                    dispatch(AuthStateAction({ loading: true, error: false }))
                    dispatch(LoginAction({ user, password }))
                } else {
                    alert('INVALID DATA')
                }
            }}>


                <div className={'mt-2 transition-all duration-300 '.concat(alertState || session ? 'h-14' : 'h-0 overflow-hidden')}>
                    <div className="alert bg-red-100 rounded-lg py-3 px-4 text-sm text-red-700 inline-flex items-center justify-between w-full alert-dismissible fade show" role="alert">
                        <span>
                            <strong className="mr-1">Error! </strong> {session ? 'Session Expired': userState.errorMessage}.
                        </span>
                        {/* <button type="button" className="btn-close box-content h-4 p-1 text-red-900 border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-red-900 hover:opacity-75 hover:no-underline" style={{ width: 30 }} data-bs-dismiss="alert" aria-label="Close" onClick={() => setalertState(false)}></button> */}
                    </div>
                </div>

                <input type="hidden" name="remember" defaultValue="true" />
                <div className="rounded-md shadow-sm -space-y-px">
                    <div>
                        <label htmlFor="user-address" className="sr-only">
                            User
                        </label>
                        <input
                            value={user}
                            onChange={(val) => setuser(val.target.value)}
                            id="user-address"
                            name="user"
                            type="user"
                            autoComplete="user"
                            required
                            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                            placeholder="User"
                        />
                    </div>
                    <div>
                        <label htmlFor="password" className="sr-only">
                            Password
                        </label>
                        <input
                            value={password}
                            onChange={(val) => { setpassword(val.target.value) }}
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            required
                            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                            placeholder="Password"
                        />
                    </div>
                </div>

                <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        <input
                            checked={checked}
                            onChange={(val) => setChecked(val.target.checked)}
                            id="remember-me"
                            name="remember-me"
                            type="checkbox"
                            className="h-4 w-4 text-gray-600 focus:ring-gray-500 border-gray-300 rounded"
                        />
                        <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                            Remember me
                        </label>
                    </div>
                </div>

                <div className='flex justify-center'>
                    {userState.loading ? <ProgressSpinner style={{ width: 30, height: 30 }} strokeWidth="5" fill="var(--surface-ground)" animationDuration=".5s" /> :
                        <button
                            type="submit"
                            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                        >
                            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                <LockClosedIcon className="h-5 w-5 text-gray-500 group-hover:text-gray-400" aria-hidden="true" />
                            </span>
                            Sign in
                        </button>}
                </div>
            </form>
        </div>
    </div>
    )
}
