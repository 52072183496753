import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CourseScheduleTable } from '../../../Components/Common/Table/CourseSchedule/CourseScheduleTable';
import { CourseScheduleAction, CourseScheduleStateAction } from '../../../Store/Action/CourseScheduleAction';

export const ScheduleScreen = () => {
    const navigate = useNavigate()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(CourseScheduleStateAction({ loading: true }))
        dispatch(CourseScheduleAction())
    }, [dispatch])

    return (
        <div className='table-padding'>
            <div className='mainHeader'>
                <p className='mainHeading'>Manage Course Schedule</p>
                <button className='custButton' onClick={() => navigate('/course-schedule')}>Add New</button>
            </div>
            <CourseScheduleTable />
        </div>
    );
}
