import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { YoutubeTable } from '../../../Components/Common/Table/Youtube/YoutubeTable';
import { YoutubeVideoDialog } from '../../../Components/Dialog/YoutubeVideoDialog';
import { YouTubeAction, YouTubeStateAction } from '../../../Store/Action/YoutubeAction';

export const YoutubeVideoScreen = () => {
    const [sliderDialogStateState, setsliderDialogStateState] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(YouTubeStateAction({ loading: true }))
        dispatch(YouTubeAction())
    }, [dispatch])


    return (
        <div className='table-padding'>
            <YoutubeVideoDialog state={sliderDialogStateState} changeState={setsliderDialogStateState} status={1} _thumbnail="" />
            <div className='mainHeader'>
                <p className='mainHeading'>Manage Youtube</p>
                <button className='custButton' onClick={() => setsliderDialogStateState(true)}>Add New</button>
            </div>
            <YoutubeTable />
        </div>
    );
}
