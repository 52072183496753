import { token_api_interceptor } from "../Interceptor/Interceptor";
import { InstallmentTrackChangeStatusUrl, InstallmentTrackUrl } from "../Interceptor/Url";

// VIEW
export const INSTALLMENTTRACK = "INSTALLMENTTRACK";
export const INSTALLMENTTRACKSTATE = "INSTALLMENTTRACKSTATE";
export const INSTALLMENTTRACKCHANGESTATUSSTATE = "INSTALLMENTTRACKCHANGESTATUSSTATE";
export const INSTALLMENTTRACKCHANGESTATUS = "INSTALLMENTTRACKCHANGESTATUS";

export const InstallmentTrackStateAction = ({ loading, error }) => {
    return {
        type: INSTALLMENTTRACKSTATE,
        loading, error,
        message: null
    }
}

export const InstallmentTrackAction = () => {
    return async (dispatch) => {
        await token_api_interceptor.post(InstallmentTrackUrl)
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: INSTALLMENTTRACK,
                        data: resp.data.data
                    });
                } else {
                    dispatch({
                        type: INSTALLMENTTRACKSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: INSTALLMENTTRACKSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const InstallmentTrackChangeStatusStateAction = ({ loading, error, id }) => {
    return {
        type: INSTALLMENTTRACKCHANGESTATUSSTATE,
        loading, error, id,
        message: null
    }
}

export const InstallmentTrackChangeStatusAction = ({ id, status }) => {
    return async (dispatch) => {
        await token_api_interceptor.post(InstallmentTrackChangeStatusUrl, { id, status })
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: INSTALLMENTTRACKCHANGESTATUS,
                        data: resp.data.data,
                        id, status
                    });
                } else {
                    dispatch({
                        type: INSTALLMENTTRACKCHANGESTATUSSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message, id
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: INSTALLMENTTRACKCHANGESTATUSSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong', id
                });
            });
    };
};

