import { token_api_interceptor } from "../Interceptor/Interceptor";
import { Role } from "../Interceptor/Url";

// VIEW
export const ROLE = "ROLE";
export const ROLESTATE = "ROLESTATE";

// ADD
export const ADDROLE = "ADDROLE";
export const ADDUPDATEROLESTATE = "ADDUPDATEROLESTATE";

// UPDATE
export const UPDATEROLE = "UPDATEROLE";
export const UPDATEROLESTATE = "UPDATEROLESTATE";

// DELETE
export const DELETEROLE = "DELETEROLE";
export const DELETEROLESTATE = "DELETEROLESTATE";


export const RoleStateAction = ({ loading, error }) => {
    return {
        type: ROLESTATE,
        loading, error,
        message: null
    }
}

export const AddUpdateRoleStateAction = ({ loading, error }) => {
    return {
        type: ADDUPDATEROLESTATE,
        loading, error,
        message: null
    }
}

export const RoleAction = () => {
    return async (dispatch) => {
        await token_api_interceptor.post(Role)
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: ROLE,
                        data: resp.data.data
                    });
                } else {
                    dispatch({
                        type: ROLESTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: ROLESTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const AddRoleAction = ({ name, status, pages }) => {
    return async (dispatch) => {
        await token_api_interceptor.post(Role + 'add', { name, status, pages })
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: ADDROLE,
                        id: resp.data.id,
                        name, status, pages
                    });
                } else {
                    dispatch({
                        type: ADDUPDATEROLESTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: ADDUPDATEROLESTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const UpdateRoleAction = ({ id, name, status, pages }) => {

    return async (dispatch) => {
        await token_api_interceptor.post(Role + 'update', { id, name, status, pages })
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: UPDATEROLE,
                        id, name, status, pages
                    });
                } else {
                    dispatch({
                        type: ADDUPDATEROLESTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: ADDUPDATEROLESTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const DeleteRoleStateAction = ({ loading, error }) => {
    return {
        type: DELETEROLESTATE,
        loading, error
    }
}

export const DeleteRoleAction = ({ id }) => {

    return async (dispatch) => {
        await token_api_interceptor.post(Role + 'delete', { id })
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: DELETEROLE,
                        id
                    });
                } else {
                    dispatch({
                        type: DELETEROLESTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: DELETEROLESTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};