import { storagekey } from "../../Constants/StringValue";
import { auth_api_interceptor, token_api_interceptor } from "../Interceptor/Interceptor";
import { auth_base_url } from "../Interceptor/Url";
export const LOGIN = "login";
export const LOGINERROR = "loginError";
export const LOGOUT = "logout";
export const CLEARLOGIN = "clearlogin";
export const AUTHSTATE = "AUTHSTATE";

export const AuthStateAction = ({ loading, error }) => {
    return {
        type: AUTHSTATE,
        loading, error
    }
}

export const LoginAction = ({ user, password }) => {
    return async (dispatch) => {
        await auth_api_interceptor.post(auth_base_url, { user, password })
            .then((resp) => {
                if (resp.data.status) {


                    const { token, Role, Pages } = resp.data
                    token_api_interceptor.defaults.headers.common['authorization'] = token

                    localStorage.setItem(
                        storagekey,
                        JSON.stringify({
                            user: user,
                            token: token,
                            role: Role,
                            pages: Pages,
                        })
                    );
                    dispatch({
                        type: LOGIN,
                        token: token,
                        user: user,
                        role: Role,
                        pages: Pages,
                        loading: false,
                        error: false,
                        errorMessage: null,
                    });
                } else {
                    dispatch({
                        type: LOGIN,
                        token: null,
                        user: null,
                        role: null,
                        pages: [],
                        loading: false,
                        error: true,
                        errorMessage: resp.data.message,
                    });
                }
            })
            .catch((err) => {

                dispatch({
                    type: LOGIN,
                    token: null,
                    user: null,
                    role: null,
                    loading: false,
                    error: true,
                    errorMessage: 'Something went wrong',
                });
            });
    };
};

export const LogoutAction = () => {
    localStorage.clear(storagekey);
    return {
        type: LOGOUT,
    };
};

export const ClearLoginAction = () => {
    return {
        type: CLEARLOGIN,
    };
};

export const AutoLogin = ({ user, token, role, pages }) => {
    return {
        type: LOGIN,
        token: token,
        user: user,
        role: role,
        pages: pages,
    };
};