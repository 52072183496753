import React from 'react'
import './ImageStyle.css'

export const VideoInputComponent = ({ name, changeValue, value, index = Math.random().toFixed(2) * 100, width = 130, setduration, disabled = false }) => {

    React.useEffect(() => {
        if (value?.name) {
            var vid = document.createElement('video');
            var fileURL = URL.createObjectURL(value);
            vid.src = fileURL;
            // wait for duration to change from NaN to the actual duration
            vid.ondurationchange = function () {
                setduration(this.duration)
            };
        }
    }, [value, setduration])


    return (
        <>
            <div className="input-group mb-2 custom-input-img text-sm">
                <label className="input-group-text p-inputgroup-addon text-sm" htmlFor={`inputGroupFile${index}`} style={{ width: width }}>{name} </label>
                <input disabled={disabled} accept="video/mp4,video/x-m4v,video/*" type="file" className="form-control" id={`inputGroupFile${index}`} style={{ padding: 12 }} onChange={(e) => {
                    if (e.target.files.length) {
                        changeValue(e.target.files[0])
                    }
                }} />

                <label className="form-control text-gray-500 cursor-pointer text-sm flex items-center" htmlFor={`inputGroupFile${index}`} style={{ padding: 12 }}>{value?.name || 'No File is Selected'}</label>
            </div>
        </>
    )
}