import React from "react";
import "./style.css";


// ICONS
import { BsTag, BsFileEarmarkBreak, BsFileEarmark } from "react-icons/bs";
import { BiTimer, BiCarousel, BiMessageSquareError, BiBookAlt } from "react-icons/bi";
import { HiOutlineCollection } from "react-icons/hi";
import { MdKeyboardArrowLeft, MdOutlinePayments, MdOutlinePayment, MdOutlineAssignment, MdAssignment, MdOutlineNotes, MdEvent } from "react-icons/md";
import { TiFlash } from "react-icons/ti";
import { TbReportSearch } from "react-icons/tb";
import { FiUsers } from "react-icons/fi";
import { TbSocial, TbUsers } from "react-icons/tb";
import { FaChalkboardTeacher, FaMoneyBill, FaYoutube, FaUserCog, FaUsers, FaLayerGroup } from "react-icons/fa";
import { AiOutlineAppstore, AiOutlineSchedule } from "react-icons/ai";
import { RiCoupon3Line, RiShieldUserLine, RiFilePaperLine } from "react-icons/ri";
import { SiAirplayvideo } from "react-icons/si";

// CONSTANT
import { PrimaryColor } from "../../Constants/colors";

// COMPONENT
import { SidebarItemComponent } from "./SidebarItemComponent";
export const SideBar = (props) => {

  const sideBarData = [
    {
      url: "/",
      name: "Dashboard",
      icon: AiOutlineAppstore
    },
    {
      url: "/slider",
      name: "Slider",
      icon: BiCarousel
    },
    {
      url: "/seminar",
      name: "Seminar",
      icon: MdEvent
    },
    {
      url: "/seminar-query",
      name: "Seminar Query",
      icon: MdEvent
    },
    {
      url: "/instructor",
      name: "Instructor",
      icon: FaChalkboardTeacher
    },
    {
      url: "/coupon",
      name: "Coupon",
      icon: RiCoupon3Line
    },
    {
      name: 'Course',
      icon: BiBookAlt,
      data: [
        {
          url: "/course-tag",
          name: "Course Tag",
          icon: BsTag
        },
        {
          url: "/batch",
          name: "Batch",
          icon: BiTimer
        },
        {
          url: "/courses",
          name: "Course",
          icon: HiOutlineCollection
        },
        {
          url: "/course-schedules",
          name: "Course Schedule",
          icon: AiOutlineSchedule
        },
        {
          url: "/subject",
          name: "Subject",
          icon: FaLayerGroup
        },
      ]
    },
    {
      url: "/lectures",
      name: "Lectures",
      icon: SiAirplayvideo
    },
    {
      name: 'Assignments',
      icon: MdAssignment,
      data: [
        {
          url: "/assignments",
          name: "Assignments",
          icon: MdOutlineAssignment
        },
        {
          url: "/user-assignments",
          name: "User Assignments",
          icon: MdOutlineAssignment
        },
      ]
    },
    {
      name: 'Study Material',
      icon: MdOutlineNotes,
      data: [

        {
          url: "/current-affairs",
          name: "Current Affairs",
          icon: BsFileEarmarkBreak
        },
        {
          url: "/class-notes",
          name: "Class Notes",
          icon: BsFileEarmark
        },
      ]
    },
    {
      name: 'Quiz',
      icon: RiFilePaperLine,
      data: [

        {
          url: "/quizes",
          name: "Quizzes",
          icon: RiFilePaperLine
        }
      ]
    },
    {
      name: 'Reports',
      icon: TbReportSearch,
      data: [
        {
          url: "/registered-users",
          name: "Register Users",
          icon: FiUsers
        },
        {
          url: "/enrolled-users",
          name: "Enrolled Users",
          icon: FaUsers
        },
        {
          url: "/transaction",
          name: "Transaction",
          icon: FaMoneyBill
        },
        {
          url: "/query",
          name: "ContactUs Report",
          icon: BiMessageSquareError
        },
        {
          url: "/installment-track",
          name: "Installments",
          icon: MdOutlinePayments
        },
      ]
    },
    {
      url: "/social",
      name: "Social",
      icon: TbSocial
    },
    {
      url: "/you-tube",
      name: "YouTube",
      icon: FaYoutube
    },
    {
      url: "/manual-book-seat",
      name: "Manual Book Seat",
      icon: MdOutlinePayment
    },

    {
      name: 'Access',
      icon: RiShieldUserLine,
      data: [

        {
          url: "/role",
          name: "Role",
          icon: FaUserCog
        },
        {
          url: "/access",
          name: "Access",
          icon: TbUsers
        },
      ]
    },
  ];

  return (
    <div
      className={
        props.state
          ? "mainContainer openSideBarWidth"
          : "mainContainer closeSideBarWidth"
      }
    >
      {props.state ? (
        <div style={{ position: "relative" }}>
          <div className="py-4" style={{ backgroundColor: 'rgba(256,256,256,0.2)' }}>
            <img src="./Assets/Images/logo.png" className="w-3/4 m-auto" alt="LOGO" />
          </div>
          {/* <p className="topHead topHead1 pt-5">Naman IAS</p>
          <p className="topHead topHead2 pb-5">Academy</p> */}
          <div className="closeSidebar">
            <MdKeyboardArrowLeft
              onClick={() => {
                props.changeState(false);
              }}
              size={30}
              color="grey"
              className="closeSidebarIcon"
            />
          </div>
        </div>
      ) : (
        <TiFlash
          size={70}
          style={{
            paddingTop: 20,
            paddingBottom: 20,
            backgroundColor: PrimaryColor,
            width: "100%",
          }}
        />
      )}
      <ul className="pt-7">
        {
          sideBarData.map((item, index) => {
            return <SidebarItemComponent item={item} key={index} state={props.state} />
          })

        }
      </ul>
    </div>
  );
};
