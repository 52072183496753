import React from 'react'
export const EnrollmentRowComponent = ({ _i, dt }) => {
    const dataKeys = ['Name', 'Mobile', 'Course', 'Batch','Status']

    return (
        <tr>
            <td className="px-3 py-4 whitespace-nowrap">
                <div className="text-md font-medium text-gray-600 text-center">
                    <span>{_i}</span>
                </div>
            </td>
            {
                dataKeys.map((item, ind) => {
                    if (item === 'Status') {
                        return <td key={ind} className="px-3 py-4 text-center">
                            <div style={{ width: 80 }} className={"px-1 py-1 text-xs font-semibold rounded-full m-auto ".concat(dt.Status ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800')}>
                                {dt.Status ? 'Active' : 'In-Active'}
                            </div>
                        </td>
                    }
                    return <td key={ind} className="px-3 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900 text-center">
                            <span>{dt?.[item]}</span>
                        </div>
                    </td>

                })
            }
        </tr>
    )
}