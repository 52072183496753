import { LOGOUT } from "../Action/AuthAction";
import { USERS, USERSSTATE } from "../Action/UsersAction";

const initialState = {
    data: [],
    loading: true,
    error: false,
    message: null,
};

export const UsersReducer = (state = initialState, action) => {
    switch (action.type) {

        // VIEW USERS
        case USERS: {
            return {
                ...state,
                loading: false,
                error: false,
                data: action.data,
                message: null
            };
        }

        case USERSSTATE: {
            return {
                ...state,
                loading: action.loading,
                error: action.error,
                message: action.message

            };
        }

        // LOGOUT
        case LOGOUT:
            return initialState;

        default:
            return { ...state };
    }
};