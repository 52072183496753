import React, { useState } from 'react'
import { CourseTagDialog } from '../../../Dialog/CourseTagDialog'
import { MdOutlineEdit, MdDelete } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { ProgressSpinner } from 'primereact/progressspinner';
import { DeleteAlertComponent } from '../../Alert/DeleteAlertComponent';
import { DeleteCourseTagAction, DeleteCourseTagStateAction } from '../../../../Store/Action/CourseTagAction';

export const CourseTagRowComponent = ({ _i, dt }) => {
    const dataKeys = ['Title', 'Status', 'Action']
    const [dialogState, setdialogState] = useState(false)
    const [alertState, setalertState] = useState(false)

    const { deleteLoading, deleteError, deleteMessage, deleteDone } = useSelector(state => state.courseTag)

    return (
        <tr style={{ height: 60 }}>
            <CourseTagDialog state={dialogState} changeState={setdialogState} id={dt.ID} status={dt.Status} title={dt.Title} />
            <DeleteAlertComponent state={alertState} changeState={setalertState} id={dt.ID} data={{ deleteLoading, deleteError, deleteMessage, deleteDone }} deleteNow={DeleteCourseTagAction} deleteState={DeleteCourseTagStateAction} />
            <td className="px-3 whitespace-nowrap">
                <div className="text-md font-medium text-gray-600 text-center">
                    <span>{_i}</span>
                </div>
            </td>
            {
                dataKeys.map((item, ind) => {
                    if (item === 'Status') {
                        return <td key={ind} className="px-3 text-center">
                            <div style={{ width: 80 }} className={"px-1 py-1 text-xs font-semibold rounded-full m-auto ".concat(dt.Status ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800')}>
                                {dt.Status ? 'Active' : 'In-Active'}
                            </div>
                        </td>
                    } else if (item === 'Action') {
                        const hw = { height: 35, width: 35 }
                        return deleteLoading ? <td key={ind} className='flex justify-center items-center' style={{ height: 60 }}><ProgressSpinner style={{ width: '25px', height: '25px' }} strokeWidth={4} /> </td>
                            : <td key={ind} className="px-3 whitespace-nowrap">
                                <div className="flex justify-center">
                                    <div onClick={() => setdialogState(true)} className='bg-yellow-500 transition-all duration-300 hover:bg-yellow-800 cursor-pointer flex justify-center items-center rounded-lg' style={hw}>
                                        <MdOutlineEdit color='white' size={18} />
                                    </div>
                                    <div onClick={() => setalertState(true)} className='bg-red-600 transition-all duration-300 hover:bg-red-900 cursor-pointer ml-2 flex justify-center items-center rounded-lg' style={hw}>
                                        <MdDelete color='white' size={18} />
                                    </div>
                                </div>
                            </td>

                    }
                    return <td key={ind} className="px-3 whitespace-nowrap">
                        <div className="text-sm text-gray-900 text-center">
                            <span>{dt[item]}</span>
                        </div>
                    </td>

                })
            }
        </tr>
    )
}