import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { breakpoints } from "../../../../Helpers/breakpoints";
import { token_api_interceptor } from "../../../../Store/Interceptor/Interceptor";
import { QuizResultUrl } from "../../../../Store/Interceptor/Url";
import { UserCompletedQuizRowComponent } from "./UserCompletedQuizRowComponent";

import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";

export const UserCompletedQuizDialog = ({ state, changeState, id }) => {
  const [loading, setloading] = useState(true);
  const [data, setdata] = useState([]);

  const _export = React.useRef(null);

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  useEffect(() => {
    if (state) {
      setloading(true);
      token_api_interceptor
        .get(`${QuizResultUrl}completed/${id}`)
        .then((res) => {
          if (res.data.status) {
            let _data1 = res.data.data;
            _data1.sort((a, b) => a.TimeTaken - b.TimeTaken);
            _data1.sort((a, b) => b.MarksObtained - a.MarksObtained);

            const _data = _data1.map((item, index) => ({
              ...item,
              index: index + 1,
            }));
            setdata(_data);
          } else {
            alert(res.data.message);
          }
        })
        .catch((err) => {
          alert("Something went wrong");
        })
        .finally(() => setloading(false));
    }

    return () => {};
  }, [state, id]);

  const renderFooter = () => {
    return (
      <div>
        <Button
          loading={loading}
          label="Ok"
          onClick={hideDialog}
          className="p-button-text"
        />
      </div>
    );
  };

  const hideDialog = () => {
    changeState(false);
  };

  return (
    <div>
      <Dialog
        draggable={false}
        header="Result"
        visible={state}
        onHide={hideDialog}
        style={{ minWidth: "90vw", margin: 10 }}
        breakpoints={breakpoints}
        footer={renderFooter()}
      >
        <div className="flex flex-col items-center w-full mx-auto p-2">
          {loading ? (
            <p>Loading...</p>
          ) : data.length ? (
            <div className="w-full overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="flex justify-end p-2">
                <ExcelExport data={data} ref={_export}>
                  <ExcelExportColumn field="index" title="Sr. No." />
                  <ExcelExportColumn field="Name" title="Name" />
                  <ExcelExportColumn field="Mobile" title="Mobile" />
                  <ExcelExportColumn
                    field="MarksObtained"
                    title="MarksObtained"
                  />
                  <ExcelExportColumn field="TimeTaken" title="TimeTaken" />
                </ExcelExport>
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-all duration-300"
                  onClick={excelExport}
                >
                  Export
                </button>
              </div>
              <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table className="min-w-full text-left text-sm font-light">
                    <thead className="border-b font-medium dark:border-neutral-500">
                      <tr className="flex-6">
                        <th scope="col" className="px-2 py-4 flex-1">
                          #
                        </th>
                        <th scope="col" className="px-2 py-4 flex-1">
                          Name
                        </th>
                        <th scope="col" className="px-2 py-4 flex-1">
                          Mobile
                        </th>
                        <th scope="col" className="px-2 py-4">
                          Marks Obtained
                        </th>
                        <th scope="col" className="px-2 py-4">
                          Rank
                        </th>
                        <th scope="col" className="px-2 py-4">
                          Time Taken
                        </th>
                        <th scope="col" className="px-2 py-4">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => {
                        return (
                          <tr
                            key={index}
                            className="border-b border-neutral-300"
                          >
                            <UserCompletedQuizRowComponent
                              item={item}
                              data={data}
                              setdata={setdata}
                              idx={index}
                            />
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <p>No Data Found</p>
          )}
        </div>
      </Dialog>
    </div>
  );
};
