import React from 'react'
import { InputText } from 'primereact/inputtext';
import './style.css'

export const FAQInputComponent = ({ allData, setallData, dataId, mandatory = false }) => {
    const { header, content, id } = allData.find(item => item.id === dataId)
    const index = allData.findIndex(item => item.id === dataId)
    return (
        <div className="p-fluid grid mb-2 text-sm">
            <div className="field col-12 md:col-4">
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon" style={{ width: 100 }}>
                        <span>FAQ <span className='text-red-800'>{mandatory && '*'}</span></span>
                    </span>
                    <div className='flex flex-col sm:flex-row w-full'>
                        <div className='flex-1 flex'>
                            <span className="p-inputgroup-addon" style={{ width: 70 }}>
                                <span>Header</span>
                            </span>
                            <InputText id="inputgroup" type={"text"} value={header} onChange={(e) => {
                                const newData = [...allData]
                                newData[index].header = e.target.value
                                setallData(newData)
                            }} placeholder="Header" />
                        </div>

                        <div className='flex-1 flex'>

                            <span className="p-inputgroup-addon" style={{ width: 70 }}>
                                <span>Content</span>
                            </span>
                            <InputText id="inputgroup" type={"text"} value={content} onChange={(e) => {
                                const newData = [...allData]
                                newData[index].content = e.target.value
                                setallData(newData)

                            }} placeholder="Content" />
                            <span onClick={() => {
                                if (allData.length === 1) {
                                    setallData([{ header: '', content: '', id: Date.now() }])
                                } else {
                                    let data = allData.filter(item => item.id !== id)
                                    setallData(data)
                                }
                            }} className="p-inputgroup-addon faq-remove" style={{ width: 50 }}>
                                -
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}