import React, { useEffect } from 'react'
import moment from 'moment'
import { numberWithCommas } from '../../../../Helpers/numberWithCommas'
import { AiFillEye } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import './style.css'
import { InstallmentTrackChangeStatusAction, InstallmentTrackChangeStatusStateAction } from '../../../../Store/Action/InstallmentTrackAction'
import { ProgressSpinner } from 'primereact/progressspinner';

export const InstallmentTrackRowComponent = ({ _i, dt }) => {

    const dispatch = useDispatch()
    const { updateLoading, updateError, updateMessage, id } = useSelector(state => state.installmentTrack)

    useEffect(() => {
        if (updateError && dt.ID === id) {
            alert(updateMessage)
        }

        return () => {

        }
    }, [updateError, updateMessage, id, dt.ID])


    const dataKeys = ['Name', 'Mobile', 'Total', 'AmountPaid', 'Remaining', 'installments', 'StatusN']
    return (
        <tr>
            <td className="px-3 py-4 whitespace-nowrap">
                <div className="text-md font-medium text-gray-600 text-center">
                    <span>{_i}</span>
                </div>
            </td>

            {
                dataKeys.map((item, ind) => {
                    if (item === 'Total' || item === 'AmountPaid' || item === 'Remaining') {
                        return <td key={ind} className="px-3 py-4 whitespace-nowrap flex-1">
                            <div className="text-sm text-gray-900 text-center" style={{ minWidth: 100 }}>
                                <span>{numberWithCommas(dt[item])}</span>
                            </div>
                        </td>
                    }
                    if (item === 'installments') {
                        return <td key={ind} className="px-3 py-4 whitespace-nowrap flex justify-center">
                            <div className="text-sm text-gray-900 text-center flex overflow-auto w-full" id='installment' style={{ maxWidth: '15vw', minWidth: 200 }}>
                                {dt[item].map((dt, ind) => {
                                    return <div key={ind} className="mr-2 rounded-md flex flex-col p-2 border-r-2 border-r-gray-300" style={{ backgroundColor: dt.Paid ? '#d9ffd9' : '#ffaaaa' }}>
                                        <p>{numberWithCommas(dt.InstallmentAmount)}</p>
                                        <p className='text-xs'>{moment(dt.DueDate).format('ll')}</p>
                                    </div>
                                })}
                            </div>
                        </td>
                    } else if (item === 'StatusN') {
                        return <td key={ind} className="px-3 py-4 whitespace-nowrap flex-1">
                            <div className="flex justify-center" style={{ minWidth: 100 }}>
                                {updateLoading ? <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s" /> : <button onClick={() => {
                                    dispatch(InstallmentTrackChangeStatusStateAction({ loading: true, id: dt.ID }))
                                    dispatch(InstallmentTrackChangeStatusAction({ id: dt.ID, status: Number(dt[item]) === 1 ? 2 : 1 }))
                                }} className={'transition-all duration-200 p-2 rounded-md flex justify-center items-center '.concat(dt[item] === 1 ? 'bg-green-500 hover:bg-green-700' : 'bg-red-500 hover:bg-red-700')} style={{ width: 40, height: 40 }}><AiFillEye color='#FFF' /></button>}
                            </div>
                        </td>

                    }
                    return <td key={ind} className="px-3 py-4 whitespace-nowrap flex-1">
                        <div className="text-sm text-gray-900 text-center" style={{ minWidth: 100 }}>
                            <span>{dt[item]}</span>
                        </div>
                    </td>

                })
            }
        </tr>
    )
}