import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CourseAction, CourseStateAction } from '../../../Store/Action/CourseAction';
import { useNavigate } from 'react-router-dom';
import { CourseTable } from '../../../Components/Common/Table/Course/CourseTable';

export const CourseScreen = () => {
    const navigate = useNavigate()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(CourseStateAction({ loading: true }))
        dispatch(CourseAction())
    }, [dispatch])


    return (
        <div className='table-padding'>
            <div className='mainHeader'>
                <p className='mainHeading'>Manage Course</p>
                <button className='custButton' onClick={() => navigate('/course')}>Add New</button>
            </div>
            <CourseTable />
        </div>
    );
}
