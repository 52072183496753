import { LOGOUT } from "../Action/AuthAction";
import { CHANGEQUERYSTATUS, CHANGEQUERYSTATUSSTATE, QUERY, QUERYSTATE } from "../Action/Query";

const initialState = {
    data: [],
    loading: true,
    error: false,
    message: null,

    updateLoading: false,
};

export const queryReducer = (state = initialState, action) => {
    switch (action.type) {

        case QUERY: {
            return {
                ...state.data,
                data: action.data,
                loading: false,
                error: false,
                message: null
            };
        }

        case QUERYSTATE: {
            const { loading, error, message } = action
            return {
                ...state,
                loading, error, message
            };
        }

        case CHANGEQUERYSTATUS: {
            const { id, status } = action

            const data = [...state.data]

            let index = data.findIndex(item =>
                item.ID === id
            )
            data[index].Status = +status

            return {
                ...state,
                data,
                updateLoading: false
            };
        }

        case CHANGEQUERYSTATUSSTATE: {
            return {
                ...state,
                updateLoading: action.loading
            };
        }
        case LOGOUT:
            return initialState;
        default:
            return { ...state };
    }
};