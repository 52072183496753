import React, { useState, useEffect } from 'react'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputComponent } from '../Common/Inputs/InputComponent';
import { DropdownComponent } from '../Common/Inputs/DropdownComponent';
import { breakpoints } from '../../Helpers/breakpoints';
import { token_api_interceptor } from '../../Store/Interceptor/Interceptor';
import { SeminarQuery } from '../../Store/Interceptor/Url';

export const SeminarQueryDialog = ({ state, changeState, id, _name, _email, _mobile, _status, _statuses, _comment }) => {

    const leftInfoWidth = 100

    const [name, setname] = useState(_name)
    const [mobile, setmobile] = useState(_mobile)
    const [email, setemail] = useState(_email)
    const [status, setstatus] = useState({})
    const [comment, setcomment] = useState(_comment)


    const [loading, setloading] = useState(false)
    const [error, seterror] = useState(false)
    const [message, setmessage] = useState('')
    const [added, setadded] = useState(false)


    useEffect(() => {
        if (state) {
            const findStatus = _statuses.find(dt => dt.ID === _status)
            setstatus(findStatus)
        }
        setadded(false)
        return () => {
        }
    }, [state, _status, _statuses])



    const renderFooter = () => {
        return (
            <div>
                <Button loading={loading} disabled={loading} label="No" icon="pi pi-times" onClick={hideDialog} className="p-button-text" />
                <Button loading={loading} disabled={loading} label="Yes" icon="pi pi-check" onClick={onClick} autoFocus />
            </div>
        );
    }

    const hideDialog = () => {
        changeState(false)
    }

    const onClick = () => {
        setloading(true)
        token_api_interceptor.post(SeminarQuery + `update/user/${id}`, {
            comment,
            status: status.ID
        })
            .then((res) => {
                if (res.data.status) {
                    setadded(true)
                    seterror(false)
                } else {
                    seterror(true)
                    setmessage(res.data.message)
                }
                setTimeout(() => {
                    window.location.reload()
                }, 2000);
            }).catch(err => {
                seterror(true)
                setmessage('Something went wrong')

            }).finally(() => setloading(false))
    }

    return (
        <Dialog draggable={false} header="Admin Access" visible={state} onHide={hideDialog} style={{ minWidth: '50vw', margin: 10 }} breakpoints={breakpoints} footer={renderFooter()}>

            {error && <div className="alert alert-danger text-sm" role="alert">
                <span className='font-bold'>Error! </span>
                <span>{message}</span>
            </div>}
            {added && <div className="alert alert-success text-sm" role="alert">
                <span className='font-bold'>Success! </span>
                <span>{"Record Successfully Updated"}</span>
            </div>}
            <div className='py-2'>
                <InputComponent name="Name" value={name} changeValue={setname} width={leftInfoWidth} />
                <InputComponent name="Mobile" value={mobile} changeValue={setmobile} width={leftInfoWidth} />
                <InputComponent name="Email" value={email} changeValue={setemail} width={leftInfoWidth} />

                <DropdownComponent items={_statuses} selectBy="Status" changeSelectedItem={setstatus} selectedItem={status} title="Status" width={leftInfoWidth} />
                <InputComponent name="Comment" value={comment} changeValue={setcomment} width={leftInfoWidth} />
            </div>
        </Dialog>
    )
}