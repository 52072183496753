import { LOGOUT } from "../Action/AuthAction";
import { ADDINSTRUCTOR, UPDATEINSTRUCTOR, ADDUPDATEINSTRUCTORSTATE, INSTRUCTOR, INSTRUCTORSTATE, DELETEINSTRUCTORSTATE, DELETEINSTRUCTOR, } from "../Action/InstructorAction";

const initialState = {
    data: [],
    loading: true,
    error: false,
    message: null,

    // ADD-UPDATE
    addUpdateLoading: false,
    addUpdateError: false,
    addUpdateMessage: null,
    addUpdateDone: false,

    // DELETE
    deleteLoading: false,
    deleteError: false,
    deleteMessage: null,
    deleteDone: false,
};

export const InstructorReducer = (state = initialState, action) => {
    switch (action.type) {

        // VIEW INSTRUCTOR
        case INSTRUCTOR: {
            return {
                ...state,
                loading: false,
                error: false,
                data: action.data,
                message: null
            };
        }

        case INSTRUCTORSTATE: {
            return {
                ...state,
                loading: action.loading,
                error: action.error,
                message: action.message

            };
        }

        // ADD INSTRUCTOR
        case ADDINSTRUCTOR: {
            const { id, name, image, status } = action
            let data = [...state.data];
            data.push({ ID: id, Name: name, Image: image, Status: status })
            return {
                ...state,
                addUpdateLoading: false,
                addUpdateError: false,
                data: data,
                addUpdateMessage: "Instructor Added",
                addUpdateDone: true,
            };
        }

        case ADDUPDATEINSTRUCTORSTATE: {
            return {
                ...state,
                addUpdateLoading: action.loading,
                addUpdateError: action.error,
                addUpdateMessage: action.message,
                addUpdateDone: false,

            };
        }

        // UPDATE INSTRUCTOR
        case UPDATEINSTRUCTOR: {
            let data = [...state.data];
            const { id, name, image, status } = action
            let index = data.findIndex(item =>
                item.ID === id
            )
            data[index] = { ID: id, Name: name, Image: image, Status: status }
            return {
                ...state,
                addUpdateLoading: false,
                addUpdateError: false,
                data: data,
                addUpdateMessage: "Instructor Updated",
                addUpdateDone: true,
            };
        }
        
        // DELETE INSTRUCTOR STATE
        case DELETEINSTRUCTORSTATE: {
            const { loading, error, message } = action
            return {
                ...state,
                deleteLoading: loading,
                deleteError: error,
                deleteMessage: message,
                deleteDone: false,

            };
        }

        // DELETE INSTRUCTOR
        case DELETEINSTRUCTOR: {
            let data = [...state.data];
            const { id } = action
            const filteredData = data.filter(item => item.ID !== id)
            return {
                ...state,
                deleteLoading: false,
                deleteError: false,
                data: filteredData,
                deleteMessage: "Instructor Deleted",
                deleteDone: true,
            };
        }

        // LOGOUT
        case LOGOUT:
            return initialState;

        default:
            return { ...state };
    }
};