import { token_api_interceptor } from "../Interceptor/Interceptor";
import { CourseStatus } from "../Interceptor/Url";

// VIEW
export const COURSESTATUS = "COURSESTATUS";
export const COURSESTATUSSTATE = "COURSESTATUSSTATE";

export const CourseStatusStateAction = ({ loading, error }) => {
    return {
        type: COURSESTATUSSTATE,
        loading, error,
        message: null
    }
}

export const CourseStatusAction = () => {
    return async (dispatch) => {
        await token_api_interceptor.post(CourseStatus)
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: COURSESTATUS,
                        data: resp.data.data
                    });
                } else {
                    dispatch({
                        type: COURSESTATUSSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: COURSESTATUSSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};