import React, { useState } from 'react'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputComponent } from '../Common/Inputs/InputComponent';
import { useDispatch, useSelector } from 'react-redux'
import { DisabledInputComponent } from '../Common/Inputs/DisabledInputComponent';
import { UpdateSocialAction, UpdateSocialStateAction } from '../../Store/Action/SocialAction';
import { breakpoints } from '../../Helpers/breakpoints';

export const SocialDialog = ({ state, changeState, name, count, id }) => {

    const leftInfoWidth = 80

    const {
        updateLoading,
        updateError,
        updateDone,
        updateMessage, } = useSelector(state => state.social)

    const dispatch = useDispatch()
    const [followerCount, setfollowerCount] = useState(count)

    React.useEffect(() => {
        if (state) {
            dispatch(UpdateSocialStateAction({ loading: false, error: false }))
        }
        // eslint-disable-next-line
    }, [state])


    const renderFooter = () => {
        return (
            <div>
                <Button loading={updateLoading} disabled={updateLoading} label="No" icon="pi pi-times" onClick={hideDialog} className="p-button-text" />
                <Button loading={updateLoading} disabled={updateLoading} label="Yes" icon="pi pi-check" onClick={onClick} autoFocus />
            </div>
        );
    }

    const hideDialog = () => {
        changeState(false)
    }

    const onClick = () => {
        if (name.length) {
            dispatch(UpdateSocialStateAction({ loading: true, error: false }))
            dispatch(UpdateSocialAction({ id, count: followerCount }))

        } else {
            alert('Invalid Data')
        }
    }

    return (
        <Dialog draggable={false} header="Update Social" visible={state} onHide={hideDialog} style={{ minWidth: '50vw', margin: 10 }} breakpoints={breakpoints} footer={renderFooter()}>

            {updateError && <div className="alert alert-danger text-sm" role="alert">
                <span className='font-bold'>Error! </span>
                <span>{updateMessage}</span>
            </div>}
            {updateDone && <div className="alert alert-success text-sm" role="alert">
                <span className='font-bold'>Success! </span>
                <span>{updateMessage}</span>
            </div>}
            <div className='py-2'>
                <DisabledInputComponent width={leftInfoWidth} name="Name" value={name} />
                <InputComponent width={leftInfoWidth} name="Followers" changeValue={setfollowerCount} value={followerCount} />
            </div>
        </Dialog>
    )
}