import React from 'react';
import { SeminarQueryTable } from '../../../Components/Common/Table/SeminarQuery/SeminarQueryTable';

export const SeminarQueryScreen = () => {

    return (
        <div className='table-padding'>
            <div className='mainHeader'>
                <p className='mainHeading'>Seminar Query</p>
            </div>
            <SeminarQueryTable />
        </div>
    );
}
