import React from 'react'
import { Accordion, AccordionTab } from 'primereact/accordion';
import { LectureSubjectContainer } from './LectureSubjectContainer';

export const LectureBatchContainer = ({ data, tableIndex }) => {

    const [showData, setshowData] = React.useState([])

    React.useEffect(() => {
        let _ids = []
        let _data = []
        data.forEach((item) => {
            if (!_ids.includes(item.Subject)) {
                const filteredData = data.filter(_i => _i.Subject === item.Subject)
                _ids.push(item.Subject)
                _data.push({
                    id: item.Subject,
                    title: item.SubjectName,
                    data: filteredData
                })
            }
        })
        setshowData(_data)
    }, [data])

    return (
        <div key={showData}>
            {showData.map((item, index) => <Accordion key={index}>
                <AccordionTab header={item.title}>
                    <LectureSubjectContainer data={item.data} tableIndex={tableIndex} />
                </AccordionTab>
            </Accordion>)}

        </div>
    )
}
