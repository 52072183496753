import { token_api_interceptor } from "../Interceptor/Interceptor";
import { transaction } from "../Interceptor/Url";
export const TRANSACTION = "TRANSACTION";
export const TRANSACTIONSTATE = "TRANSACTIONSTATE";

export const TransactionStateAction = ({ loading, error }) => {
    return {
        type: TRANSACTIONSTATE,
        loading, error, message: null
    }
}

export const TransactionAction = () => {
    return async (dispatch) => {
        await token_api_interceptor.post(transaction)
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: TRANSACTION,
                        data: resp.data.data,
                        courses: resp.data.courses,
                        paymentType: resp.data.paymentType,
                        paymentResponse: resp.data.paymentResponse,
                    });
                } else {
                    dispatch({
                        type: TRANSACTIONSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: TRANSACTIONSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};