import React, { useState, useEffect } from 'react'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputComponent } from '../Common/Inputs/InputComponent';
import { DropdownComponent } from '../Common/Inputs/DropdownComponent';
import { useDispatch, useSelector } from 'react-redux'
import { statuses } from '../../Constants/Statuses';
import { breakpoints } from '../../Helpers/breakpoints';
import { AddAdminAccessAction, AddUpdateAdminAccessStateAction, UpdateAdminAccessAction } from '../../Store/Action/AdminAccessAction';
import { RoleAction, RoleStateAction } from '../../Store/Action/RoleAction';

export const AdminAccessDialog = ({ state, changeState, _name, _password, _status, id, _role }) => {

    const {
        addUpdateLoading,
        addUpdateError,
        addUpdateDone,
        addUpdateMessage, } = useSelector(state => state.adminAccess)

    const { data } = useSelector(state => state.role)

    const leftInfoWidth = 100

    const dispatch = useDispatch()
    const [name, setname] = useState('' + _name)
    const currStatus = statuses.find(item => item.key === Number(_status))
    const [status, setstatus] = useState(currStatus)
    const [password, setpassword] = useState('' + _password)
    const [selectedRole, setselectedRole] = useState({})

    useEffect(() => {
        if (state) {
            dispatch(AddUpdateAdminAccessStateAction({ loading: false, error: false }))
        }
    }, [state, dispatch])

    useEffect(() => {
        if (id) {

        } else {
            setname('')
            setpassword('')
            setselectedRole(data[0])
            setstatus(statuses[0])
        }
        // eslint-disable-next-line
    }, [addUpdateDone, id])

    useEffect(() => {
        if (state && data.length === 0) {
            dispatch(RoleAction())
            dispatch(RoleStateAction({ loading: true }))
        }
        return () => {

        }
    }, [dispatch, state, data.length])

    useEffect(() => {
        if (id && state) {

            const _findRole = data.find(item => item.ID === _role)
            setselectedRole(_findRole)
        }

        return () => {

        }
    }, [_role, state, id, data])



    const renderFooter = () => {
        return (
            <div>
                <Button loading={addUpdateLoading} disabled={addUpdateLoading} label="No" icon="pi pi-times" onClick={hideDialog} className="p-button-text" />
                <Button loading={addUpdateLoading} disabled={addUpdateLoading} label="Yes" icon="pi pi-check" onClick={onClick} autoFocus />
            </div>
        );
    }

    const hideDialog = () => {
        changeState(false)
    }

    const onClick = () => {
        if (name.length && password.length && selectedRole.ID) {

            dispatch(AddUpdateAdminAccessStateAction({ loading: true, error: false }))
            if (id) {
                dispatch(UpdateAdminAccessAction({ id, name: name, status: status.key, password, role: selectedRole.ID, roleName: selectedRole.Name }))
            } else {
                dispatch(AddAdminAccessAction({ name: name, status: status.key, password, role: selectedRole.ID, roleName: selectedRole.Name }))
            }
        } else {
            alert('Invalid Data')
        }
    }

    return (
        <Dialog draggable={false} header="Admin Access" visible={state} onHide={hideDialog} style={{ minWidth: '50vw', margin: 10 }} breakpoints={breakpoints} footer={renderFooter()}>

            {addUpdateError && <div className="alert alert-danger text-sm" role="alert">
                <span className='font-bold'>Error! </span>
                <span>{addUpdateMessage}</span>
            </div>}
            {addUpdateDone && <div className="alert alert-success text-sm" role="alert">
                <span className='font-bold'>Success! </span>
                <span>{addUpdateMessage}</span>
            </div>}
            <div className='py-2'>
                <InputComponent name="Name" value={name} changeValue={setname} width={leftInfoWidth} />
                <InputComponent name="Password" value={password} changeValue={setpassword} width={leftInfoWidth} />

                <DropdownComponent title="Role" selectedItem={selectedRole} changeSelectedItem={setselectedRole} items={data} selectBy="Name" width={leftInfoWidth} />
                <DropdownComponent title="Status" selectedItem={status} changeSelectedItem={setstatus} items={statuses} selectBy="title" width={leftInfoWidth} />
            </div>
        </Dialog>
    )
}