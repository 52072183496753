import React, { useState, useEffect } from 'react'
import { DropdownComponent } from '../../../Components/Common/Inputs/DropdownComponent'
import { InputComponent } from '../../../Components/Common/Inputs/InputComponent'
import { useDispatch, useSelector } from 'react-redux'
import { CourseAction, CourseStateAction } from '../../../Store/Action/CourseAction'
import { DateInputComponent } from '../../../Components/Common/Inputs/DateInputComponent'
import { BatchAction, BatchStateAction } from '../../../Store/Action/BatchAction'
import { useLocation } from 'react-router-dom'
import { token_api_interceptor } from '../../../Store/Interceptor/Interceptor'
import { CourseSchedule } from '../../../Store/Interceptor/Url'
import { AddCourseScheduleAction, AddUpdateCourseScheduleStateAction, UpdateCourseScheduleAction } from '../../../Store/Action/CourseScheduleAction'
import { statuses, types } from '../../../Constants/Statuses'
import { ProgressSpinner } from 'primereact/progressspinner';
import moment from 'moment'

export const AddUpdateScheduleScreen = () => {
    const dispatch = useDispatch()
    const location = useLocation()

    const leftInfoWidth = 100

    const batchesData = useSelector(state => state.batch.data.filter(item => item.Status === 1))
    const batchesDataLoading = useSelector(state => state.batch.loading)
    const batchesDataError = useSelector(state => state.batch.error)

    const coursesData = useSelector(state => state.course.data)
    // const coursesData = useSelector(state => state.course.data.filter(item => (item.Status === 1 || item.Status === 2 || item.Status === 3)))
    const courseLoading = useSelector(state => state.course.loading)
    const courseError = useSelector(state => state.course.error)

    const { addUpdateDone, addUpdateLoading, addUpdateError, addUpdateMessage } = useSelector(state => state.courseSchedule)

    // DATA AVAILABLE
    const [availableBatch, setavailableBatch] = useState([])

    // DATA VARIABLES
    const [selectedCourse, setselectedCourse] = useState({})
    const [selectedBatch, setselectedBatch] = useState({})
    const [selectedType, setselectedType] = useState(types[0])
    const [seats, setseats] = useState('')
    const [meetingID, setmeetingID] = useState('')
    // const [meetingPassword, setmeetingPassword] = useState('')
    const [startsOn, setstartsOn] = useState(new Date())
    const [duration, setduration] = useState('')
    const [status, setstatus] = useState(statuses[0])
    const [fetchClassLoading, setfetchClassLoading] = useState(false)

    // UPDATE SCREEN
    const [update, setupdate] = useState(false)
    const [id, setid] = useState(0);
    const [loading, setloading] = useState(false)
    const [error, seterror] = useState(false)

    useEffect(() => {

        if (batchesData.length) {
            const data = batchesData.map(item => {
                return {
                    ...item,
                    title: (item.Batch + ' ' + moment(item.TimeFrom).format('hh:mm A') + ' - ' + moment(item.TimeTo).format('hh:mm A'))
                }
            })
            setavailableBatch(data)
        }
        // eslint-disable-next-line
    }, [batchesData.length])



    useEffect(() => {
        dispatch(CourseStateAction({ loading: true, error: false }))
        dispatch(CourseAction())

        dispatch(BatchStateAction({ loading: true, error: false }))
        dispatch(BatchAction())

    }, [dispatch])

    const emptyData = () => {
        setselectedCourse({})
        setselectedBatch({})
        setseats('')
        setmeetingID('')
        // setmeetingPassword('')
        setstartsOn(new Date())
        setduration('')
    }

    useEffect(() => {
        if (addUpdateDone) {
            if (update) {
                alert('Course Schedule Updated')
            } else {
                alert('Course Schedule added')
                emptyData()
            }
            dispatch(AddUpdateCourseScheduleStateAction({ loading: false, error: false }))
        }
        if (addUpdateError) {
            alert(addUpdateMessage)
        }
    }, [addUpdateDone, addUpdateError, addUpdateMessage, dispatch, update])

    useEffect(() => {
        if (location.search.includes('key')) {
            const key = location.search.slice(5)
            setupdate(true)
            setid(key)

            if (key.length && availableBatch.length && coursesData.length && !loading) {

                setloading(true)
                token_api_interceptor.post(CourseSchedule + 'edit', { id: key }).then(res => {
                    if (res.data.status) {
                        const { CourseID, BatchID, StartOn, Duration, Seats, MeetingID } = res.data.data
                        setselectedCourse(coursesData.find(dt => Number(dt.ID) === Number(CourseID)))
                        setselectedBatch(availableBatch.find(dt => Number(dt.ID) === Number(BatchID)))
                        setstartsOn(new Date(StartOn))
                        setduration(Duration)
                        setseats(Seats)
                        setmeetingID(MeetingID)
                        // setmeetingPassword(MeetingPassword)
                        seterror(false)
                    } else {
                        seterror(true)
                    }
                }).catch(err => {
                    seterror(true)
                }).finally(() => setloading(false))
            }
        } else {
            emptyData()
        }

        return () => {

        }
        // eslint-disable-next-line
    }, [location.search, availableBatch.length, coursesData.length])


    const onSubmit = () => {
        if (selectedCourse && selectedBatch && startsOn && duration && seats && selectedType) {
            dispatch(AddUpdateCourseScheduleStateAction({ loading: true }))
            if (update) {
                dispatch(UpdateCourseScheduleAction({ batch: selectedBatch.ID, course: selectedCourse.ID, duration, seats, startsOn, type: selectedType.title, status: status.key, id, meetingID: meetingID }))
            } else {

                dispatch(AddCourseScheduleAction({ batch: selectedBatch.ID, course: selectedCourse.ID, duration, seats, startsOn, type: selectedType.title, status: status.key }))
            }
        } else {
            alert('Insert Required data')
        }
    }

    const onFetchClass = () => {
        if (String(meetingID).length >= 6) {
            setfetchClassLoading(true)
            token_api_interceptor.post('class', { courseID: selectedCourse.ID, batchID: id, meetingNumber: meetingID })
                .then(res => alert(res.data.message))
                .catch(err => alert('Something went wrong'))
                .finally(() => setfetchClassLoading(false))
        } else {
            alert('Invalid Meeting ID')
        }
    }


    return (
        <>{
            loading || batchesDataLoading || courseLoading ?
                <div className='p-5'>
                    <p>Loading...</p>
                </div> : error || batchesDataError || courseError ? <div className='p-5'>
                    <p>Something went wrong</p>
                </div> :
                    <div className='p-3 pt-5 sm:p-5 main-container'>

                        <p className='mainHeading mb-3'>{update ? 'Update' : 'Add'} Course Schedule</p>
                        <DropdownComponent width={leftInfoWidth} items={coursesData} title="Course" selectBy="Title" selectedItem={selectedCourse} changeSelectedItem={setselectedCourse} />
                        <DropdownComponent width={leftInfoWidth} items={availableBatch} title="Batch" selectBy="title" selectedItem={selectedBatch} changeSelectedItem={setselectedBatch} />
                        <DropdownComponent width={leftInfoWidth} items={types} title="Type" selectBy="title" selectedItem={selectedType} changeSelectedItem={setselectedType} />
                        <DateInputComponent width={leftInfoWidth} name="Starts on" value={startsOn} changeValue={setstartsOn} />
                        <InputComponent width={leftInfoWidth} name="Duration" value={duration} changeValue={setduration} number={true} suffix="Months" suffWidth={70} />
                        <InputComponent width={leftInfoWidth} value={seats} name="Seats" changeValue={setseats} number={true} />

                        <DropdownComponent width={leftInfoWidth} items={statuses} title="Status" selectBy="title" selectedItem={status} changeSelectedItem={setstatus} />
                        {update ? <>
                            <InputComponent width={leftInfoWidth} value={meetingID} name="Mtg. ID" changeValue={setmeetingID} number={true} />
                            {/* <InputComponent width={leftInfoWidth} value={meetingPassword} name="Mtg. Pass." changeValue={setmeetingPassword} /> */}
                        </> : false
                        }



                        {update ? fetchClassLoading ? <div className='flex justify-center'><ProgressSpinner style={{ width: '35px', height: '35px' }} strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s" /> </div> : <button disabled={fetchClassLoading} className='submitButton' onClick={onFetchClass}>Fetch Classes</button> : null}

                        {!fetchClassLoading && <button disabled={addUpdateLoading} className='submitButton my-2' onClick={onSubmit}>{addUpdateLoading ? 'Submitting' : 'Submit'}</button>}


                    </div>}
        </>
    )
}