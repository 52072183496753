import { ADDASSIGNMENTS, ADDUPDATEASSIGNMENTSSTATE, ASSIGNMENTS, ASSIGNMENTSSTATE, DELETEASSIGNMENTS, DELETEASSIGNMENTSSTATE, UPDATEASSIGNMENTS } from "../Action/AssignmentsAction";
import { LOGOUT } from "../Action/AuthAction";

const initialState = {
    data: [],
    loading: true,
    error: false,
    message: null,

    // ADD-UPDATE
    addUpdateLoading: false,
    addUpdateError: false,
    addUpdateMessage: null,
    addUpdateDone: false,

    // DELETE
    deleteLoading: false,
    deleteError: false,
    deleteMessage: null,
    deleteDone: false,
};

export const AssignmentsReducer = (state = initialState, action) => {
    switch (action.type) {

        // VIEW ASSIGNMENTS
        case ASSIGNMENTS: {
            return {
                ...state,
                loading: false,
                error: false,
                data: action.data,
                message: null
            };
        }

        case ASSIGNMENTSSTATE: {
            return {
                ...state,
                loading: action.loading,
                error: action.error,
                message: action.message

            };
        }


        // ADD ASSIGNMENT
        case ADDASSIGNMENTS: {
            // const { id, title, batchID } = action
            // let data = [...state.data];
            // data.push({ ID: id, Title: title, BatchID: batchID })
            return {
                ...state,
                addUpdateLoading: false,
                addUpdateError: false,
                // data: data,
                addUpdateMessage: "Assignment Added",
                addUpdateDone: true,
            };
        }

        case ADDUPDATEASSIGNMENTSSTATE: {
            return {
                ...state,
                addUpdateLoading: action.loading,
                addUpdateError: action.error,
                addUpdateMessage: action.message,
                addUpdateDone: false,

            };
        }

        // UPDATE ASSIGNMENT
        case UPDATEASSIGNMENTS: {

            // let data = [...state.data];
            // const { id, title } = action
            
            // const filteredData = data.map(dt => {
            //     const finalData = [...dt.data]

            //     let index = finalData.findIndex(item =>
            //         Number(item.ID) === Number(id)
            //     )

            //     finalData[index].Title = title

            //     return {
            //         ...dt,
            //         data: finalData
            //     }
            // })

            return {
                ...state,
                addUpdateLoading: false,
                addUpdateError: false,
                addUpdateMessage: "Assignment Updated",
                addUpdateDone: true,
            };
        }

        // DELETE ASSIGNMENT STATE
        case DELETEASSIGNMENTSSTATE: {
            const { loading, error, message } = action
            return {
                ...state,
                deleteLoading: loading,
                deleteError: error,
                deleteMessage: message,
                deleteDone: false,

            };
        }

        // DELETE ASSIGNMENT
        case DELETEASSIGNMENTS: {
            let data = [...state.data];
            const { id } = action
            const filteredData = data.map(dt => {
                const finalData = { ...dt }
                const f_data = dt.data.filter(item => item.ID !== id)
                finalData['data'] = f_data

                return finalData
            })

            return {
                ...state,
                deleteLoading: false,
                deleteError: false,
                data: filteredData,
                deleteMessage: "Assignment Deleted",
                deleteDone: true,
            };
        }

        // LOGOUT
        case LOGOUT:
            return initialState;

        default:
            return { ...state };
    }
};