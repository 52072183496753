import React, { useState } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { MdDelete } from "react-icons/md";
import { AiFillEye } from "react-icons/ai";
import { token_api_interceptor } from "../../../../Store/Interceptor/Interceptor";
import { QuizResultUrl } from "../../../../Store/Interceptor/Url";
import { ViewUserResultPdf } from "./ViewUserResultPdf";

export const UserCompletedQuizRowComponent = ({ data, setdata, item, idx }) => {
  const [removeLoading, setremoveLoading] = useState(false);

  const [dialogState, setdialogState] = useState(false);

  const onRemove = (_id) => {
    setremoveLoading(true);
    token_api_interceptor
      .get(`${QuizResultUrl}delete/${item.QuizID}/${item.UserID}`)
      .then((res) => {
        if (res.data.status) {
          const _data = [...data];
          _data.splice(idx, 1);
          setdata(_data);
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => {
        alert("Something went wrong");
      })
      .finally(() => setremoveLoading(false));
  };

  return (
    <>
      <td className="px-2 py-4 font-medium">{idx + 1}</td>
      {/* max-w-[100px] */}
      <td className="px-2 max-w-[120px] text-ellipsis py-4 font-medium">
        {item.Name}
      </td>

      <td className="px-2 max-w-[120px] text-ellipsis py-4">{item.Mobile}</td>
      <td className="px-2 max-w-[120px] text-ellipsis py-4">
        {item.MarksObtained}
      </td>
      <td className="px-2 max-w-[120px] text-ellipsis py-4">
        <p className="w-10 h-10 border bg-sky-100 border-sky-600 text-sky-600 flex justify-center items-center rounded-full">{idx + 1}</p>
      </td>
      <td className="px-2 max-w-[120px] text-ellipsis py-4">
        {item.TimeTaken}
      </td>
      <td className="px-2 max-w-[120px] text-ellipsis py-4">
        <ViewUserResultPdf
          state={dialogState}
          changeState={setdialogState}
          userId={item.UserID}
          quizId={item.QuizID}
        />
        {removeLoading ? (
          <ProgressSpinner
            style={{ width: 35, height: 35 }}
            strokeWidth="4"
            fill="var(--surface-ground)"
            animationDuration=".5s"
          />
        ) : (
          <div className="flex space-x-2">
            <div
              onClick={() => onRemove(item.ID)}
              className="bg-red-600 transition-all duration-300 hover:bg-red-900 cursor-pointer ml-2 flex justify-center items-center rounded-lg"
              style={{ height: 35, width: 35 }}
            >
              <MdDelete color="#FFF" size={18} />
            </div>
            <div
              onClick={() => setdialogState(true)}
              className="bg-yellow-600 transition-all duration-300 hover:bg-yellow-900 cursor-pointer ml-2 flex justify-center items-center rounded-lg"
              style={{ height: 35, width: 35 }}
            >
              <AiFillEye color="#FFF" size={18} />
            </div>
          </div>
        )}
      </td>
    </>
  );
};
