import React, { useState, useEffect } from 'react'
import { DropdownComponent } from '../../../Components/Common/Inputs/DropdownComponent'
import { InputComponent } from '../../../Components/Common/Inputs/InputComponent'
import { DateInputComponent } from '../../../Components/Common/Inputs/DateInputComponent'
import { PdfInputComponent } from '../../../Components/Common/Inputs/PdfInputContainer'
import { token_api_interceptor } from '../../../Store/Interceptor/Interceptor'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AssignmentsUrl } from '../../../Store/Interceptor/Url'
import { CourseWithBatchAction, CourseWithBatchStateAction } from '../../../Store/Action/CourseWithBatchAction'
import { AddAssignmentsAction, AddUpdateAssignmentsStateAction, UpdateAssignmentsAction } from '../../../Store/Action/AssignmentsAction'
import moment from 'moment'

export const AddUpdateAssignmentScreen = () => {

    const location = useLocation()

    const leftInfoWidth = 100

    const dispatch = useDispatch()

    const { addUpdateLoading, addUpdateError, addUpdateMessage, addUpdateDone } = useSelector(state => state.assignment)

    const courseWithBatchLoading = useSelector(state => state.courseWithBatch.loading)
    const courseWithBatcherror = useSelector(state => state.courseWithBatch.error)
    const courseWithBatchmessage = useSelector(state => state.courseWithBatch.message)
    const courseWithBatchdata = useSelector(state => state.courseWithBatch.data)


    const [title, settitle] = useState('')
    const [selectedCourse, setselectedCourse] = useState({})
    const [selectedBatch, setselectedBatch] = useState({})
    const [file, setfile] = useState('')
    const [assignmentDate, setassignmentDate] = useState(new Date())
    const [assignmentDueDate, setassignmentDueDate] = useState(new Date())
    const [marks, setmarks] = useState(20)
    const [minMarks, setminMarks] = useState(10)

    const [submitLoading, setsubmitLoading] = useState(false)
    // const [status, setstatus] = useState(0)

    const [updateLoading, setupdateLoading] = useState(false)
    const [update, setupdate] = useState(false)
    const [updateError, setupdateError] = useState('')
    const [updateErrorState, setupdateErrorState] = useState(false)
    const [id, setid] = useState('')

    const emptyData = () => {
        settitle('')
        setselectedCourse({})
        setselectedBatch({})
        setsubmitLoading(false)
        setfile('')
        setcurrentChunkIndex(0)
        setmarks(20)
        setminMarks(10)
        setassignmentDate(new Date())
        setassignmentDueDate(new Date())
    }


    useEffect(() => {
        dispatch(CourseWithBatchStateAction({ loading: true, error: false }))
        dispatch(CourseWithBatchAction())

        return () => { }
    }, [dispatch])

    // useEffect(() => {
    //     if (addUpdateDone) {
    //         alert(addUpdateMessage)

    //         if (!update) {
    //             emptyData()
    //         }

    //         dispatch(AddUpdateAssignmentsStateAction({ loading: false, error: false }))
    //     }
    //     if (addUpdateError) {
    //         alert(addUpdateMessage)
    //     }
    // }, [addUpdateDone, addUpdateError, addUpdateMessage, dispatch, update])


    useEffect(() => {
        if (location.search.includes('key')) {
            const key = location.search.slice(5)
            setupdate(true)
            setid(key)

            if (key.length && courseWithBatchdata.length && !courseWithBatchLoading) {

                setupdateLoading(true)
                token_api_interceptor.post(AssignmentsUrl + 'edit', { id: key }).then(res => {
                    if (res.data.status) {
                        const { Title, CourseID, BatchID, Marks, MinMarks, File } = res.data.data
                        settitle(Title)
                        setmarks(Marks)
                        setminMarks(MinMarks)
                        setfile(File)

                        setassignmentDate(new Date(res.data.data.Date))
                        setassignmentDueDate(new Date(res.data.data.DueDate))

                        const currentCourse = courseWithBatchdata.find(dt => Number(dt.ID) === Number(CourseID))
                        setselectedCourse(currentCourse)
                        setselectedBatch(currentCourse?.batch?.find(dt => Number(dt.ID) === Number(BatchID)))

                    } else {
                        setupdateError(res.data.message)
                        setupdateErrorState(true)
                    }
                }).catch(err => {
                    setupdateError('Something went wrong')
                    setupdateErrorState(true)
                }).finally(() => setupdateLoading(false))
            }
        } else {
            emptyData()
        }

        return () => {

        }
        // eslint-disable-next-line
    }, [location.search, courseWithBatchdata.length])


    useEffect(() => {
        if (!update) {
            setselectedBatch({})
        }
        return () => { }
    }, [selectedCourse, update])

    const [currentChunkIndex, setcurrentChunkIndex] = useState(0)

    const chunkSize = 200 * 1024

    const uploadChunk = (readerEvent) => {
        dispatch(AddUpdateAssignmentsStateAction({ loading: true, error: false }))

        const data = readerEvent.target.result
        const params = new URLSearchParams()

        const _date = moment(assignmentDate).format('YYYY-MM-DD HH:mm:ss')
        const _duedate = moment(assignmentDueDate).format('YYYY-MM-DD HH:mm:ss')

        params.set('name', file.name)
        params.set('size', file.size)
        params.set('currentChunkIndex', currentChunkIndex)
        params.set('totalChunks', Math.ceil(file.size / chunkSize))
        params.set('title', title)
        params.set('course', selectedCourse.ID.toString())
        params.set('batch', selectedBatch.ID.toString())
        params.set('date', _date.toString())
        params.set('dueDate', _duedate.toString())
        params.set('marks', marks.toString())
        params.set('minMarks', minMarks.toString())

        const url = AssignmentsUrl + 'add?' + params.toString()

        token_api_interceptor.post(url, data, {
            headers: {
                'content-type': 'application/octet-stream'
            }
        }).then(res => {
            const fileSize = file.size
            const isLastChunk = currentChunkIndex === Math.ceil(fileSize / chunkSize) - 1

            if (isLastChunk) {

                if (res.data.status) {
                    dispatch(AddAssignmentsAction())
                    setTimeout(() => {
                        dispatch(AddUpdateAssignmentsStateAction({ loading: false, error: false }))
                    }, 3000);
                    emptyData()
                } else {
                    dispatch(AddUpdateAssignmentsStateAction({ loading: false, error: true, message: res.data.message }))
                    setcurrentChunkIndex(0)
                }
            } else {
                setcurrentChunkIndex(currentChunkIndex + 1)
            }
        })
            .catch(err => {
                setcurrentChunkIndex(0)
                dispatch(AddUpdateAssignmentsStateAction({ loading: false, error: true, message: 'Something went wrong' }))
                alert('Something went wrong')
            })
    }

    const onSubmit = () => {
        if (title.length === 0) {
            alert('Invalid Title')
        } else if (selectedBatch?.ID) {
            if (id) {
                const _date = moment(assignmentDate).format('YYYY-MM-DD HH:mm:ss')
                const _duedate = moment(assignmentDueDate).format('YYYY-MM-DD HH:mm:ss')

                dispatch(AddUpdateAssignmentsStateAction({ loading: true, error: false }))
                dispatch(UpdateAssignmentsAction({ id, batch: selectedBatch.ID, course: selectedCourse.ID, date: _date, dueDate: _duedate, file, marks, minMarks, title }))

            } else if (file) {
                const reader = new FileReader()
                const from = currentChunkIndex * chunkSize
                const to = from + chunkSize
                const blob = file.slice(from, to)
                reader.onload = (e) => uploadChunk(e)
                reader.readAsDataURL(blob)

            } else {
                alert('File should be selected')
            }
        } else {
            alert('Batch Should be selected')
        }
    }

    useEffect(() => {
        if (currentChunkIndex !== 0) {
            onSubmit()
        }

        // eslint-disable-next-line
    }, [currentChunkIndex])


    React.useEffect(() => {
        if (!id) {
            emptyData()
        } else { }
        if (addUpdateDone) {
            setTimeout(() => {
                dispatch(AddUpdateAssignmentsStateAction({ loading: false, error: false }))
            }, 3000);
        }
        // eslint-disable-next-line
    }, [addUpdateDone, id])


    return (courseWithBatchLoading || updateLoading ? <div className='p-3 pt-5 sm:p-5 main-container'>
        <p>Loading...</p>
    </div > : courseWithBatcherror || updateErrorState ? <div className='p-3 pt-5 sm:p-5 main-container'>
        <p>{updateError || courseWithBatchmessage}</p>
    </div >
        :
        <div className='p-3 pt-5 sm:p-5 main-container'>
            <p className='mainHeading mb-3'>{update ? 'Update' : 'Add'} Assignment</p>
            {addUpdateError && <div className="alert alert-danger text-sm" role="alert">
                <span className='font-bold'>Error! </span>
                <span>{addUpdateMessage}</span>
            </div>}
            {addUpdateDone && <div className="alert alert-success text-sm" role="alert">
                <span className='font-bold'>Success! </span>
                <span>{addUpdateMessage}</span>
            </div>}
            <InputComponent width={leftInfoWidth} name='Title' value={title} changeValue={settitle} />
            <DropdownComponent width={leftInfoWidth} changeSelectedItem={setselectedCourse} items={courseWithBatchdata} selectBy="Title" selectedItem={selectedCourse} title="Course" />
            <DropdownComponent width={leftInfoWidth} changeSelectedItem={setselectedBatch} items={selectedCourse?.batch || []} selectBy="Batch" selectedItem={selectedBatch} title="Batch" />
            <InputComponent width={leftInfoWidth} name='Marks' value={marks} changeValue={setmarks} number={true} />
            <InputComponent width={leftInfoWidth} name='Min Marks' value={minMarks} changeValue={setminMarks} number={true} />
            <DateInputComponent changeValue={setassignmentDate} value={assignmentDate} name="Date" width={leftInfoWidth} />
            <DateInputComponent changeValue={setassignmentDueDate} value={assignmentDueDate} name="Due Date" width={leftInfoWidth} />
            <PdfInputComponent disabled={id} key={3} index={3} name="File" value={file} changeValue={setfile} width={leftInfoWidth} />

            {addUpdateLoading ? <div className='flex justify-center w-full'>
                <div className='bg-slate-300 w-full h-5 rounded-sm overflow-hidden'>
                    <div style={{ width: `${(currentChunkIndex * 100 / (file?.size / chunkSize)).toFixed(2)}%` }} className="h-full transition-all duration-200 bg-green-400 flex justify-end items-center">
                        <p className='text-xs text-white mr-2'>{(currentChunkIndex * 100 / (file?.size / chunkSize)).toFixed(2)}%</p>
                    </div>
                </div>
            </div> : <div className='flex'>
                <button disabled={submitLoading} className='submitButton mt-2 flex justify-center items-center' onClick={onSubmit}>{submitLoading ? 'Submitting' : 'Submit'}</button>
            </div>}

        </div>
    )
}