import { LOGOUT } from "../Action/AuthAction";
import { SOCIAL, SOCIALSTATE, UPDATESOCIAL, UPDATESOCIALSTATE } from "../Action/SocialAction";

const initialState = {
    data: [],
    loading: true,
    error: false,
    message: null,

    // UPDATE
    updateLoading: false,
    updateError: false,
    updateMessage: null,
    updateDone: false
};

export const SocialReducer = (state = initialState, action) => {
    switch (action.type) {

        // VIEW SOCIAL
        case SOCIAL: {
            return {
                ...state,
                loading: false,
                error: false,
                data: action.data,
                message: null
            };
        }

        case SOCIALSTATE: {
            return {
                ...state,
                loading: action.loading,
                error: action.error,
                message: action.message
            };
        }

        case UPDATESOCIALSTATE: {
            return {
                ...state,
                updateLoading: action.loading,
                updateError: action.error,
                updateMessage: action.message,
                updateDone: false
            };
        }

        // UPDATE SOCIAL
        case UPDATESOCIAL: {
            let data = [...state.data];
            const { id, count } = action
            let index = data.findIndex(item =>
                item.ID === id
            )
            data[index]['Count'] = count
            return {
                ...state,
                updateLoading: false,
                updateError: false,
                data: data,
                updateMessage: "Social Updated",
                updateDone: true,
            };
        }

        // LOGOUT
        case LOGOUT:
            return initialState;

        default:
            return { ...state };
    }
};