import React from 'react'
import { MdOutlineEdit } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

export const UserAssignmentsRowComponent = ({ _i, dt }) => {
    const dataKeys = ['Title', 'Contact', 'Marks', 'Date', 'Action']
    const navigate = useNavigate()

    return (
        <tr>
            <td className="px-3 py-4 whitespace-nowrap">
                <div className="text-md font-medium text-gray-600 text-center">
                    <span>{_i}</span>
                </div>
            </td>
            {
                dataKeys.map((item, ind) => {
                    if (item === 'Action') {
                        const hw = { height: 35, width: 35 }
                        return <td key={ind} className="px-3 py-4 whitespace-nowrap">
                            <div className="flex justify-center">
                                <div onClick={() => {
                                    navigate({
                                        pathname: '/user-assignment',
                                        search: 'key=' + dt.ID
                                    })
                                }} className='bg-yellow-500 transition-all duration-300 hover:bg-yellow-800 cursor-pointer flex justify-center items-center rounded-lg' style={hw}>
                                    <MdOutlineEdit color='white' size={18} />
                                </div>
                            </div>
                        </td>

                    }
                    return <td key={ind} className="px-3 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900 text-center">
                            <span>{dt[item]}</span>
                        </div>
                    </td>

                })
            }
        </tr>
    )
}