import React from 'react'
import { TransactionTable } from '../../../Components/Common/Table/Transaction/TransactionTable'

export const TransactionScreen = () => {
    return (
        <div className='table-padding'>
            <TransactionTable />
        </div>
    )
}
