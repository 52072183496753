import { token_api_interceptor } from "../Interceptor/Interceptor";
import { Batch } from "../Interceptor/Url";

// VIEW
export const BATCH = "BATCH";
export const BATCHSTATE = "BATCHSTATE";

// ADD
export const ADDBATCH = "ADDBATCH";
export const ADDUPDATEBATCHSTATE = "ADDUPDATEBATCHSTATE";

// UPDATE
export const UPDATEBATCH = "UPDATEBATCH";
export const UPDATEBATCHSTATE = "UPDATEBATCHSTATE";

// REMOVE
export const DELETEBATCH = "DELETEBATCH";
export const DELETEBATCHSTATE = "DELETEBATCHSTATE";


export const BatchStateAction = ({ loading, error }) => {
    return {
        type: BATCHSTATE,
        loading, error,
        message: null
    }
}

export const AddUpdateBatchStateAction = ({ loading, error }) => {
    return {
        type: ADDUPDATEBATCHSTATE,
        loading, error,
        message: null
    }
}

export const BatchAction = () => {
    return async (dispatch) => {
        await token_api_interceptor.post(Batch)
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: BATCH,
                        data: resp.data.data
                    });
                } else {
                    dispatch({
                        type: BATCHSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: BATCHSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const AddBatchAction = ({ batch, subtitle, from, to, status, batchFrom, batchTo }) => {
    return async (dispatch) => {
        await token_api_interceptor.post(Batch + 'add', { batch, subtitle, from, to, status })
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: ADDBATCH,
                        id: resp.data.id,
                        batch, subtitle, from, to, status, batchFrom, batchTo
                    });
                } else {
                    dispatch({
                        type: ADDUPDATEBATCHSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: ADDUPDATEBATCHSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const UpdateBatchAction = ({ id, batch, subtitle, from, to, status, batchFrom, batchTo }) => {
    return async (dispatch) => {
        await token_api_interceptor.post(Batch + 'update', { id, batch, subtitle, from, to, status })
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: UPDATEBATCH,
                        id, batch, subtitle, from, to, status, batchFrom, batchTo
                    });
                } else {
                    dispatch({
                        type: ADDUPDATEBATCHSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: ADDUPDATEBATCHSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const DeleteBatchStateAction = ({ loading, error }) => {
    return {
        type: DELETEBATCHSTATE,
        loading, error
    }
}

export const DeleteBatchAction = ({ id }) => {
    return async (dispatch) => {
        await token_api_interceptor.post(Batch + 'delete', { id })
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: DELETEBATCH,
                        id
                    });
                } else {
                    dispatch({
                        type: DELETEBATCHSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: DELETEBATCHSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};