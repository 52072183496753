import React, { useState } from 'react'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputComponent } from '../Common/Inputs/InputComponent';
import { DropdownComponent } from '../Common/Inputs/DropdownComponent';
import { useDispatch, useSelector } from 'react-redux'
import { statuses } from '../../Constants/Statuses';
import { AddCouponAction, AddUpdateCouponStateAction, UpdateCouponAction } from '../../Store/Action/CouponAction';
import { breakpoints } from '../../Helpers/breakpoints';

export const CouponDialog = ({ state, changeState, oldcoupon, status, id, disc, type }) => {

    const {
        addUpdateLoading,
        addUpdateError,
        addUpdateDone,
        addUpdateMessage, } = useSelector(state => state.coupon)

    const types = [
        {
            ID: 1,
            Type: 'Percentage'
        }, {
            ID: 2,
            Type: 'INR'
        }
    ]

    const dispatch = useDispatch()
    const [coupon, setcoupon] = useState(oldcoupon)
    const [discount, setdiscount] = useState(disc)
    const currStatus = statuses.find(item => item.key === Number(status))
    const [couponStatus, setcouponStatus] = useState(currStatus)
    const [selectedType, setselectedType] = useState(types[type - 1])


    React.useEffect(() => {
        if (state) {
            dispatch(AddUpdateCouponStateAction({ loading: false, error: false }))
        }
        // eslint-disable-next-line
    }, [state])


    React.useEffect(() => {
        if (id) {
        } else {
            setcoupon('')
            setdiscount(0)
            setcouponStatus(statuses[0])
        }
        // eslint-disable-next-line
    }, [addUpdateDone, id])


    const renderFooter = () => {
        return (
            <div>
                <Button loading={addUpdateLoading} disabled={addUpdateLoading} label="No" icon="pi pi-times" onClick={hideDialog} className="p-button-text" />
                <Button loading={addUpdateLoading} disabled={addUpdateLoading} label="Yes" icon="pi pi-check" onClick={onClick} autoFocus />
            </div>
        );
    }

    const hideDialog = () => {
        changeState(false)
    }

    const onClick = () => {
        if (coupon.length) {
            dispatch(AddUpdateCouponStateAction({ loading: true, error: false }))
            if (id) {
                dispatch(UpdateCouponAction({ id, coupon, discount, status: couponStatus.key, type: selectedType.ID }))
            } else {
                dispatch(AddCouponAction({ coupon, discount, status: couponStatus.key, type: selectedType.ID }))
            }
        } else {
            alert('Invalid Data')
        }
    }

    return (
        <Dialog draggable={false} header="Coupon" visible={state} onHide={hideDialog} style={{ minWidth: '50vw', margin: 10 }} breakpoints={breakpoints} footer={renderFooter()}>

            {addUpdateError && <div className="alert alert-danger text-sm" role="alert">
                <span className='font-bold'>Error! </span>
                <span>{addUpdateMessage}</span>
            </div>}
            {addUpdateDone && <div className="alert alert-success text-sm" role="alert">
                <span className='font-bold'>Success! </span>
                <span>{addUpdateMessage}</span>
            </div>}
            <div className='py-2'>
                <InputComponent name="Coupon" value={coupon} changeValue={setcoupon} width={80} />
                <DropdownComponent title="Type" selectedItem={selectedType} changeSelectedItem={setselectedType} items={types} selectBy="Type" width={80} />
                <InputComponent name="Discount" value={discount} changeValue={setdiscount} number={true} suffix={selectedType.ID === 2 ? 'INR' : '%'} width={80} suffWidth={30} />
                <DropdownComponent title="Status" selectedItem={couponStatus} changeSelectedItem={setcouponStatus} items={statuses} selectBy="title" width={80} />
            </div>
        </Dialog>
    )
}