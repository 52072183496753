import React, { useState } from 'react'
import { MdOutlineEdit, MdDelete } from 'react-icons/md'
import { DeleteSubjectAction, DeleteSubjectStateAction } from '../../../../Store/Action/SubjectAction'
import { SubjectDialog } from '../../../Dialog/SubjectDialog'
import { DeleteAlertComponent } from '../../Alert/DeleteAlertComponent'
import { useSelector } from 'react-redux'

export const SubjectRowComponent = ({ _i, dt }) => {
    const columns = ['Title', 'Action']
    const [dialogState, setdialogState] = useState(false)
    const [alertState, setalertState] = useState(false)

    const { deleteLoading, deleteError, deleteMessage, deleteDone } = useSelector(state => state.subject)

    return (
        <tr>
            <SubjectDialog state={dialogState} changeState={setdialogState} id={dt.ID} oldTitle={dt.Title} />
            <DeleteAlertComponent state={alertState} changeState={setalertState} id={dt.ID} data={{ deleteLoading, deleteError, deleteMessage, deleteDone }} deleteNow={DeleteSubjectAction} deleteState={DeleteSubjectStateAction} />
            <td className="px-3 py-4 whitespace-nowrap">
                <div className="text-md font-medium text-gray-600 text-center">
                    <span>{_i}</span>
                </div>
            </td>
            {
                columns.map((item, ind) => {
                    if (item === 'Action') {
                        const hw = { height: 35, width: 35 }
                        return <td key={ind} className="px-3 py-4 whitespace-nowrap">
                            <div className="flex justify-center">
                                <div onClick={() => setdialogState(true)} className='bg-yellow-500 transition-all duration-300 hover:bg-yellow-800 cursor-pointer flex justify-center items-center rounded-lg' style={hw}>
                                    <MdOutlineEdit color='white' size={18} />
                                </div>
                                <div onClick={() => setalertState(true)} className='bg-red-600 transition-all duration-300 hover:bg-red-900 cursor-pointer ml-2 flex justify-center items-center rounded-lg' style={hw}>
                                    <MdDelete color='white' size={18} />
                                </div>
                            </div>
                        </td>

                    }
                    return <td key={ind} className="px-3 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900 text-center">
                            <span>{dt[item]}</span>
                        </div>
                    </td>

                })
            }
        </tr>
    )
}