import { token_api_interceptor } from "../Interceptor/Interceptor";
import { CourseTag } from "../Interceptor/Url";

// VIEW
export const COURSETAG = "COURSETAG";
export const COURSETAGSTATE = "COURSETAGSTATE";

// ADD
export const ADDCOURSETAG = "ADDCOURSETAG";
export const ADDUPDATECOURSETAGSTATE = "ADDUPDATECOURSETAGSTATE";

// UPDATE
export const UPDATECOURSETAG = "UPDATECOURSETAG";
export const UPDATECOURSETAGSTATE = "UPDATECOURSETAGSTATE";

// DELETE
export const DELETECOURSETAG = "DELETECOURSETAG";
export const DELETECOURSETAGSTATE = "DELETECOURSETAGSTATE";


export const CourseTagStateAction = ({ loading, error }) => {
    return {
        type: COURSETAGSTATE,
        loading, error,
        message: null
    }
}

export const AddUpdateCourseTagStateAction = ({ loading, error }) => {
    return {
        type: ADDUPDATECOURSETAGSTATE,
        loading, error,
        message: null
    }
}

export const CourseTagAction = () => {
    return async (dispatch) => {
        await token_api_interceptor.post(CourseTag)
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: COURSETAG,
                        data: resp.data.data
                    });
                } else {
                    dispatch({
                        type: COURSETAGSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: COURSETAGSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const AddCourseTagAction = ({ title, status }) => {
    return async (dispatch) => {
        await token_api_interceptor.post(CourseTag + 'add', { title, status })
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: ADDCOURSETAG,
                        id: resp.data.id,
                        title, status
                    });
                } else {
                    dispatch({
                        type: ADDUPDATECOURSETAGSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: ADDUPDATECOURSETAGSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const UpdateCourseTagAction = ({ title, id, status }) => {
    return async (dispatch) => {
        await token_api_interceptor.post(CourseTag + 'update', { id, title, status })
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: UPDATECOURSETAG,
                        id, title, status
                    });
                } else {
                    dispatch({
                        type: ADDUPDATECOURSETAGSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: ADDUPDATECOURSETAGSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const DeleteCourseTagStateAction = ({ loading, error }) => {
    return {
        type: DELETECOURSETAGSTATE,
        loading, error
    }
}

export const DeleteCourseTagAction = ({ id }) => {
    return async (dispatch) => {
        await token_api_interceptor.post(CourseTag + 'delete', { id })
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: DELETECOURSETAG,
                        id
                    });
                } else {
                    dispatch({
                        type: DELETECOURSETAGSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: DELETECOURSETAGSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};