import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RoleTable } from '../../../Components/Common/Table/Role/RoleTable';
import { RoleDialog } from '../../../Components/Dialog/RoleDialog';
import { RoleAction, RoleStateAction } from '../../../Store/Action/RoleAction';

export const RoleScreen = () => {
    const [roleDialogState, setroleDialogState] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(RoleStateAction({ loading: true }))
        dispatch(RoleAction())
    }, [dispatch])


    return (
        <div className='table-padding'>
            <RoleDialog state={roleDialogState} changeState={setroleDialogState} status={1} name="" pages={[]} />
            <div className='mainHeader'>
                <p className='mainHeading'>Manage Role</p>
                <button className='custButton' onClick={() => setroleDialogState(true)}>Add New</button>
            </div>
            <RoleTable />
        </div>
    );
}
