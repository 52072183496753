import React from 'react'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { pdf_url } from '../../../Store/Interceptor/Url';

export const ViewPdfComponent = ({ onHideDialog, dialogState, File, faculty }) => {
    const renderFooter = () => {
        return (
            <div>
                <Button label="Ok" icon="pi pi-check" onClick={onHideDialog} autoFocus />
            </div>
        );
    }

    return (
        <div>
            <Dialog draggable={false} breakpoints={{ '960px': '75vw' }} header="Assignment" visible={dialogState} style={{ width: '60vw', minHeight: '80vh' }} footer={renderFooter()} onHide={onHideDialog}>
                <iframe src={pdf_url.concat(faculty ? 'FacultyAssignments/' + File : 'UserAssignments/' + File)} title="File" className='w-full h-full overflow-hidden' style={{ minHeight: '70vh' }}></iframe>
            </Dialog>
        </div>
    )
}
