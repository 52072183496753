import { token_api_interceptor } from "../Interceptor/Interceptor";
import { CourseSchedule } from "../Interceptor/Url";

// VIEW
export const COURSESCHEDULE = "COURSESCHEDULE";
export const COURSESCHEDULESTATE = "COURSESCHEDULESTATE";

// ADD
export const ADDCOURSESCHEDULE = "ADDCOURSESCHEDULE";
export const ADDUPDATECOURSESCHEDULESTATE = "ADDUPDATECOURSESCHEDULESTATE";

// UPDATE
export const UPDATECOURSESCHEDULE = "UPDATECOURSESCHEDULE";
export const UPDATECOURSESCHEDULESTATE = "UPDATECOURSESCHEDULESTATE";

// DELETE
export const DELETECOURSESCHEDULE = "DELETECOURSESCHEDULE";
export const DELETECOURSESCHEDULESTATE = "DELETECOURSESCHEDULESTATE";

export const CourseScheduleStateAction = ({ loading, error }) => {
    return {
        type: COURSESCHEDULESTATE,
        loading, error,
        message: null
    }
}

export const AddUpdateCourseScheduleStateAction = ({ loading, error }) => {
    return {
        type: ADDUPDATECOURSESCHEDULESTATE,
        loading, error,
        message: null
    }
}

export const CourseScheduleAction = () => {
    return async (dispatch) => {
        await token_api_interceptor.post(CourseSchedule)
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: COURSESCHEDULE,
                        data: resp.data.data
                    });
                } else {
                    dispatch({
                        type: COURSESCHEDULESTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: COURSESCHEDULESTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const AddCourseScheduleAction = ({ batch, course, startsOn, duration, type, seats, status }) => {

    return async (dispatch) => {
        await token_api_interceptor.post(CourseSchedule + 'add', { batch, course, startsOn, duration, type, status, seats })
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: ADDCOURSESCHEDULE,
                        id: resp.data.id,
                        batch, course, startsOn, duration, Type: type, status, seats
                    });
                } else {
                    dispatch({
                        type: ADDUPDATECOURSESCHEDULESTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: ADDUPDATECOURSESCHEDULESTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const UpdateCourseScheduleAction = ({ id, batch, course, startsOn, duration, type, seats, meetingID, status }) => {
    return async (dispatch) => {

        await token_api_interceptor.post(CourseSchedule + 'update', { batch, course, startsOn, duration, type, seats, id, meetingID, status })
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: UPDATECOURSESCHEDULE,
                        id, batch, course, startsOn, duration, Type: type, status, seats, meetingID
                    });
                } else {
                    dispatch({
                        type: ADDUPDATECOURSESCHEDULESTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: ADDUPDATECOURSESCHEDULESTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const DeleteCourseScheduleStateAction = ({ loading, error }) => {
    return {
        type: DELETECOURSESCHEDULESTATE,
        loading, error
    }
}

export const DeleteCourseScheduleAction = ({ id }) => {
    return async (dispatch) => {

        await token_api_interceptor.post(CourseSchedule + 'delete', { id })
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: DELETECOURSESCHEDULE,
                        id
                    });
                } else {
                    dispatch({
                        type: DELETECOURSESCHEDULESTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: DELETECOURSESCHEDULESTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};