import { LOGOUT } from "../Action/AuthAction";
import { ADDCOURSESCHEDULE, ADDUPDATECOURSESCHEDULESTATE, COURSESCHEDULE, COURSESCHEDULESTATE, DELETECOURSESCHEDULE, DELETECOURSESCHEDULESTATE, UPDATECOURSESCHEDULE } from "../Action/CourseScheduleAction";

const initialState = {
    data: [],
    loading: true,
    error: false,
    message: null,

    // ADD-UPDATE
    addUpdateLoading: false,
    addUpdateError: false,
    addUpdateMessage: null,
    addUpdateDone: false,


    // DELETE
    deleteLoading: false,
    deleteError: false,
    deleteMessage: null,
    deleteDone: false,
};

export const CourseScheduleReducer = (state = initialState, action) => {
    switch (action.type) {

        // VIEW COURSES
        case COURSESCHEDULE: {
            return {
                ...state,
                loading: false,
                error: false,
                data: action.data,
                message: null
            };
        }

        case COURSESCHEDULESTATE: {
            const { loading, error, message } = action
            return {
                ...state,
                loading, error, message
            };
        }

        // ADD COURSE SCHEDULE
        case ADDCOURSESCHEDULE: {
            let data = [...state.data];
            const { batch, course, startsOn, duration, Type, seats, id, status } = action
            data.push({ ID: id, BatchID: batch, courseID: course, Type, Seats: seats, StartOn: startsOn, Duration: duration, MeetingID: null, MeetingPassword: null, Status: status })
            return {
                ...state,
                addUpdateLoading: false,
                addUpdateError: false,
                data: data,
                addUpdateMessage: "Course Schedule Added",
                addUpdateDone: true,
            };
        }

        case ADDUPDATECOURSESCHEDULESTATE: {
            const { loading, error, message } = action
            return {
                ...state,
                addUpdateLoading: loading,
                addUpdateError: error,
                addUpdateMessage: message,
                addUpdateDone: false,

            };
        }

        // UPDATE COURSE
        case UPDATECOURSESCHEDULE: {
            let data = [...state.data];
            const { batch, course, startsOn, duration, Type, seats, id, meetingId, status } = action
            let index = data.findIndex(item =>
                item.ID === id
            )
            data[index] = { ID: id, BatchID: batch, courseID: course, Type, Seats: seats, StartOn: startsOn, Duration: duration, MeetingID: meetingId, Status: status }

            return {
                ...state,
                addUpdateLoading: false,
                addUpdateError: false,
                data: data,
                addUpdateMessage: "Course Schedule Updated",
                addUpdateDone: true,
            };
        }

        // DELETE COURSE STATE
        case DELETECOURSESCHEDULESTATE: {
            const { loading, error, message } = action
            return {
                ...state,
                deleteLoading: loading,
                deleteError: error,
                deleteMessage: message,
                deleteDone: false,
            };
        }

        // DELETE COURSE
        case DELETECOURSESCHEDULE: {
            let data = [...state.data];
            const { id } = action
            const filteredData = data.filter(item => item.ID !== id)
            return {
                ...state,
                deleteLoading: false,
                deleteError: false,
                data: filteredData,
                deleteMessage: "Course Deleted",
                deleteDone: true,
            };
        }


        // LOGOUT
        case LOGOUT:
            return initialState;

        default:
            return { ...state };
    }
};