import { LOGOUT } from "../Action/AuthAction";
import { UPDATEUSERASSIGNMENTS, UPDATEUSERASSIGNMENTSSTATE, USERASSIGNMENTS, USERASSIGNMENTSSTATE } from "../Action/UserAssignmentsAction";

const initialState = {
    data: [],
    loading: true,
    error: false,
    message: null,

    // UPDATE
    updateLoading: false,
    updateError: false,
    updateMessage: null,
    updateDone: false,
};

export const UserAssignmentsReducer = (state = initialState, action) => {
    switch (action.type) {

        // VIEW USER ASSIGNMENTS
        case USERASSIGNMENTS: {
            return {
                ...state,
                loading: false,
                error: false,
                data: action.data,
                message: null
            };
        }

        case USERASSIGNMENTSSTATE: {
            return {
                ...state,
                loading: action.loading,
                error: action.error,
                message: action.message

            };
        }


        case UPDATEUSERASSIGNMENTSSTATE: {
            return {
                ...state,
                updateLoading: action.loading,
                updateError: action.error,
                updateMessage: action.message,
                updateDone: false,

            };
        }

        // UPDATE ASSIGNMENT
        case UPDATEUSERASSIGNMENTS: {

            return {
                ...state,
                updateLoading: false,
                updateError: false,
                updateMessage: "Assignment Updated",
                updateDone: true,
            };
        }

        // LOGOUT
        case LOGOUT:
            return initialState;

        default:
            return { ...state };
    }
};