import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AdminAccessTable } from '../../../Components/Common/Table/AdminAccess/AdminAccessTable';
import { AdminAccessDialog } from '../../../Components/Dialog/AdminAccessDialog';
import { AdminAccessAction, AdminAccessStateAction } from '../../../Store/Action/AdminAccessAction';

export const AdminAccessScreen = () => {
    const [dialogState, setdialogState] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(AdminAccessStateAction({ loading: true }))
        dispatch(AdminAccessAction())
    }, [dispatch])


    return (
        <div className='table-padding'>
            <AdminAccessDialog state={dialogState} changeState={setdialogState} _name="" _password="" _status={1} />
            <div className='mainHeader'>
                <p className='mainHeading'>Manage Admin Access</p>
                <button className='custButton' onClick={() => setdialogState(true)}>Add New</button>
            </div>
            <AdminAccessTable />
        </div>
    );
}
