import React, { useState, useEffect } from 'react'
import { DropdownComponent } from '../../../Components/Common/Inputs/DropdownComponent'
import { ImageInputComponent } from '../../../Components/Common/Inputs/ImageInputContainer'
import { InputComponent } from '../../../Components/Common/Inputs/InputComponent'
import { MultiSelectDropDown } from '../../../Components/Common/Inputs/MultiSelectDropDown'
import { TextEditorComponent } from '../../../Components/Common/Inputs/TextEditorComponent'
import { useDispatch, useSelector } from 'react-redux'
import { AddCourseAction, AddUpdateCourseStateAction, UpdateCourseAction } from '../../../Store/Action/CourseAction'
import { CourseTagAction, CourseTagStateAction } from '../../../Store/Action/CourseTagAction'
import { CourseStatusAction, CourseStatusStateAction } from '../../../Store/Action/CourseStatusAction'
import { Numericstatuses } from '../../../Constants/Statuses'
import { CouponAction, CouponStateAction } from '../../../Store/Action/CouponAction'
import { FAQInputComponent } from '../../../Components/Common/Inputs/FAQInputComponent'
import { InstructorAction, InstructorStateAction } from '../../../Store/Action/InstructorAction'
import { InstallmentAction, InstallmentStateAction } from '../../../Store/Action/InstallmentAction'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DisabledInputComponent } from '../../../Components/Common/Inputs/DisabledInputComponent'
import { useLocation } from 'react-router-dom'
import { token_api_interceptor } from '../../../Store/Interceptor/Interceptor'
import { Course, imagebase_url } from '../../../Store/Interceptor/Url'

export const AddUpdateCourseScreen = () => {

    const leftInfoWidth = 100

    const dispatch = useDispatch()
    const location = useLocation()

    // REDUX DATA 

    const courseTags = useSelector(state => state.courseTag.data)
    const courseTagsLoading = useSelector(state => state.courseTag.loading)
    const courseTagsError = useSelector(state => state.courseTag.error)

    const courseStatus = useSelector(state => state.courseStatus.data)
    const courseStatusLoading = useSelector(state => state.courseStatus.loading)
    const courseStatusError = useSelector(state => state.courseStatus.error)

    const coupons = useSelector(state => state.coupon.data)
    const couponsLoading = useSelector(state => state.coupon.loading)
    const couponsError = useSelector(state => state.coupon.error)

    const instructors = useSelector(state => state.instructor.data)
    const instructorsLoading = useSelector(state => state.instructor.loading)
    const instructorsError = useSelector(state => state.instructor.error)

    const installments = useSelector(state => state.installment.data)
    const installmentsLoading = useSelector(state => state.installment.loading)
    const installmentsError = useSelector(state => state.installment.error)


    // STATE
    const { addUpdateLoading, addUpdateError, addUpdateMessage, addUpdateDone } = useSelector(state => state.course)


    // DATA AVAILABLE
    const [availableCourseStatus, setavailableCourseStatus] = useState([])
    const [availableCourseTag, setavailableCourseTag] = useState([])
    const [availableCoupons, setavailableCoupons] = useState([])
    const [availableInstructor, setavailableInstructor] = useState([])


    // DATA VARIABLES
    const [title, settitle] = useState('')
    const [subTitle, setsubTitle] = useState('')
    const [image, setimage] = useState('')
    const [description, setdescription] = useState('')
    const [bookSeat, setbookSeat] = useState(Numericstatuses[0])
    const [courseIncludes, setcourseIncludes] = useState('')
    const [status, setstatus] = useState({})
    const [coupon, setcoupon] = useState([])
    const [installment, setinstallment] = useState([]);
    const [price, setprice] = useState('');
    const [finalPrice, setfinalPrice] = useState('')
    const [faq, setfaq] = useState([{ header: '', content: '', id: Date.now() }])
    const [courseTag, setcourseTag] = useState('')
    const [instructor, setinstructor] = useState()
    const [update, setupdate] = useState(false)
    const [id, setid] = useState(0);
    const [imageUrl, setimageUrl] = useState('')
    const [slug, setslug] = useState('')

    const [loading, setloading] = useState(false)
    const [error, seterror] = useState(false)

    const [draftLoading, setdraftLoading] = useState(false)
    const [courseAddUpdateLoading, setcourseAddUpdateLoading] = useState(false)

    const [modalState, setmodalState] = useState(false)

    useEffect(() => {
        if (courseTags.length) {
            const data = courseTags.filter(item => item.Status === 1)
            setavailableCourseTag(data)
        }

        if (coupons.length) {
            const data = coupons.filter(item => item.Status === 1)
            setavailableCoupons(data)
        }

        if (courseStatus.length) {
            const data = courseStatus.filter(item => item.Status === 1)
            setavailableCourseStatus(data)
            if (!update) {
                setstatus(courseStatus[0])
            }
        }

        if (instructors.length) {
            const data = instructors.filter(item => item.Status === 1)
            setavailableInstructor(data)
        }

    }, [courseTags, courseStatus, coupons, instructors, update])

    useEffect(() => {
        dispatch(CourseTagStateAction({ loading: true, error: false }))
        dispatch(CourseTagAction())

        dispatch(CourseStatusStateAction({ loading: true, error: false }))
        dispatch(CourseStatusAction())

        dispatch(CouponStateAction({ loading: true, error: false }))
        dispatch(CouponAction())

        dispatch(InstructorStateAction({ loading: true, error: false }))
        dispatch(InstructorAction())

        dispatch(InstallmentStateAction({ loading: true, error: false }))
        dispatch(InstallmentAction())
    }, [dispatch])

    useEffect(() => {
        if (finalPrice === 0) {
            setbookSeat(Numericstatuses[1])
        }
        return () => {

        };
    }, [finalPrice])

    const emptyData = () => {
        setslug('')
        settitle('')
        setsubTitle('')
        setimage('')
        setdescription('')
        setbookSeat(Numericstatuses[0])
        setcourseIncludes('')
        setinstallment([])
        setprice('')
        setfinalPrice('')
        setcoupon([])
        setimageUrl('')
        setfaq([{ header: '', content: '', id: Date.now() }])
    }

    useEffect(() => {
        if (addUpdateDone) {
            alert(addUpdateMessage)

            if (!update) {
                emptyData()
            }

            dispatch(AddUpdateCourseStateAction({ loading: false, error: false }))
        }
        if (!addUpdateLoading) {
            setdraftLoading(false)
            setcourseAddUpdateLoading(false)
        }
        if (addUpdateError) {
            alert(addUpdateMessage)
        }
    }, [addUpdateDone, addUpdateError, addUpdateMessage, dispatch, update, addUpdateLoading])

    useEffect(() => {
        if (location.search.includes('key')) {
            const key = location.search.slice(5)
            setupdate(true)
            setid(key)

            if (availableCourseTag.length && availableCourseStatus.length && availableCoupons.length && availableInstructor.length && installments.length && !loading) {

                setloading(true)
                token_api_interceptor.post(Course + 'edit', { id: key }).then(res => {
                    if (res.data.status) {
                        const { Title, Slug, SubTitle, Status, BookSeat, Image, Tag, Instructor, Price, FinalPrice, Coupon, Installment, Description, CourseIncludes, FAQ } = res.data.data

                        const stat = availableCourseStatus.find(item => item.ID === Status)
                        const faqData = FAQ ? FAQ?.replace(/\[/, "")?.replace(/\]/, "")?.length ? JSON.parse(FAQ) : [{ header: '', content: '', id: Date.now() }] : [{ header: '', content: '', id: Date.now() }]
                        const inst = Installment ? Installment.length ? JSON.parse(Installment) : [] : []
                        const installmentsData = inst.map(item => {
                            return installments.find(dt => dt.ID === item)
                        })
                        const cpn = JSON.parse(Coupon)
                        const cpnsData = cpn.map(item => {
                            return coupons.find(dt => dt.ID === item)
                        })


                        settitle(Title)
                        setslug(Slug)
                        setsubTitle(SubTitle)
                        setstatus(stat)
                        setbookSeat(Numericstatuses.find(item => item.key === BookSeat))
                        setcourseTag(availableCourseTag.find(item => item.ID === Tag))
                        setinstructor(instructors.find(item => item.ID === Instructor))
                        setprice(Price)
                        setfinalPrice(FinalPrice)
                        setcoupon(cpnsData)
                        setdescription(Description)
                        setinstallment(installmentsData)
                        setcourseIncludes(CourseIncludes)
                        setfaq(faqData)
                        setimage(Image)
                        setimageUrl(imagebase_url + 'Course/' + Image)
                        seterror(false)
                    } else {
                        seterror(true)
                    }
                }).catch(err => {
                    seterror(true)
                }).finally(() => setloading(false))
            }
        }

        return () => {

        }
        // eslint-disable-next-line
    }, [location.search, availableCourseTag, availableInstructor, availableCourseStatus, availableCoupons, installments])


    const onSubmit = ({ isDraft }) => {
        if (!imageUrl && !isDraft) {
            alert('Image not selected')
        } else if ((title && subTitle && status && courseTag && instructor && slug) || isDraft) {
            setmodalState(false)

            const couponId = coupon?.map(item => item?.ID)
            const installmentTime = installment?.map(item => item?.ID)
            const faqData = faq?.filter(item => item?.header?.length)?.map(item => { return { header: item?.header, content: item?.content } })

            if (isDraft) {
                setdraftLoading(true)
            } else {
                setcourseAddUpdateLoading(true)
            }

            dispatch(AddUpdateCourseStateAction({ loading: true, error: false }))
            if (update) {
                dispatch(UpdateCourseAction({ title, slug, subTitle, status: status.ID, tag: courseTag?.ID || 0, instructor: instructor?.ID || 0, price, finalPrice, courseIncludes, description, image, bookSeat: bookSeat?.key, coupon: JSON.stringify(couponId || []), faq: JSON.stringify(faqData || []), installment: JSON.stringify(installmentTime || []), id, draft: isDraft }))
            } else {

                dispatch(AddCourseAction({ title, slug, subTitle, status: status.ID, tag: courseTag?.ID || 0, instructor: instructor?.ID || 0, price, finalPrice, courseIncludes, description, image, bookSeat: bookSeat.key, coupon: JSON.stringify(couponId || []), faq: JSON.stringify(faqData || []), installment: JSON.stringify(installmentTime || []), draft: isDraft }))
            }
        } else {
            alert('Insert Required data')
        }
    }

    const renderFooter = () => {
        return (
            <div>
                <Button label="No" icon="pi pi-times" onClick={() => setmodalState(false)} className="p-button-text" />
                <Button label="Yes" icon="pi pi-check" onClick={() => onSubmit({ isDraft: 0 })} autoFocus />
            </div>
        );
    }


    return (
        <>{
            loading || couponsLoading || courseTagsLoading || instructorsLoading || courseStatusLoading || installmentsLoading ?
                <div className='p-3 sm:p-5'>
                    <p>Loading...</p>
                </div> : error || couponsError || courseTagsError || instructorsError || courseStatusError || installmentsError ? <div className='p-3 sm:p-5'>
                    <p>Something went wrong</p>
                </div> :
                    <div className='p-3 pt-5 sm:p-5 main-container'>
                        <Dialog draggable={false} header="Confirmation" visible={modalState} style={{ width: '85vw' }} footer={renderFooter()} onHide={() => setmodalState(false)}>
                            <div>
                                <DisabledInputComponent name="Title" value={title ? title : 'No title added'} />
                                <DisabledInputComponent name="Slug" value={slug ? slug : 'No slug added'} />
                                <DisabledInputComponent name="SubTitle" value={subTitle ? subTitle : 'No SubTitle added'} />
                                <DisabledInputComponent name="Status" value={status?.Name} />
                                <DisabledInputComponent name="Book Seat" value={bookSeat.title} />
                                <div className='flex'>
                                    <div className='flex-1'>
                                        <DisabledInputComponent name="Image" value={image?.name || image || 'No Image is selected'} />
                                    </div>
                                    {imageUrl && <div className='flex justify-center ml-2' style={{ height: 100, width: 180 }}>
                                        <img src={imageUrl} style={{ height: '100%', width: '100%' }} className="object-cover mb-2" alt='Course' />
                                    </div>}
                                </div>
                                <DisabledInputComponent name="Tag" value={courseTag?.Title || 'No Course Tag is selected'} />
                                <DisabledInputComponent name="Instructor" value={instructor?.Name || 'No instructor is selected'} />
                                <DisabledInputComponent name="Price" value={price || 'Price Not added'} />
                                <DisabledInputComponent name="Final Price" value={finalPrice || 'Final Price not added'} />
                                <DisabledInputComponent name="Coupon" value={coupon?.map(item => item.Coupon)?.join(', ')} />
                                <DisabledInputComponent name="Installment" value={installment?.map(item => item.Duration)} />
                                <DisabledInputComponent name="Description" value={description ? 'Description Added' : 'Description Not available'} />
                                <DisabledInputComponent name="Course Includes" value={courseIncludes?.length ? 'Course Includes Added' : 'Course Includes Not available'} />
                                <DisabledInputComponent name="FAQ" value={faq[0]?.header?.length ? 'FAQ ADDED' : 'FAQ NOT ADDED'} />

                            </div>
                        </Dialog>
                        <p className='mainHeading mb-3'>{update ? 'Update' : 'Add'} Course</p>
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            setmodalState(true)
                        }}
                            encType='multipart/form-data'
                        >

                            <InputComponent width={leftInfoWidth} mandatory={true} minLength={3} value={title} name="Title" changeValue={settitle} />

                            <InputComponent width={leftInfoWidth} mandatory={true} minLength={3} value={slug} name="Slug" changeValue={setslug} />

                            <InputComponent width={leftInfoWidth} mandatory={true} minLength={3} value={subTitle} name="Sub Title" changeValue={setsubTitle} />

                            <DropdownComponent width={leftInfoWidth} mandatory={true} items={availableCourseStatus} title="Status" selectBy="Name" selectedItem={status} changeSelectedItem={setstatus} />

                            <DropdownComponent width={leftInfoWidth} mandatory={true} items={Numericstatuses} title="Book Seat" selectBy="title" selectedItem={bookSeat} changeSelectedItem={setbookSeat} />

                            <ImageInputComponent width={leftInfoWidth} key={2} index={2} mandatory={true} name="Image" value={image} changeValue={setimage} sizeFrom={2000} sizeTo={5000} changeImageUrl={setimageUrl} pixels="5389px * 3593px" />

                            <DropdownComponent width={leftInfoWidth} mandatory={true} items={availableCourseTag} title="Tag" selectBy="Title" selectedItem={courseTag} changeSelectedItem={setcourseTag} />

                            <DropdownComponent width={leftInfoWidth} key={3} mandatory={true} items={availableInstructor} title="Instructor" selectBy="Name" selectedItem={instructor} changeSelectedItem={setinstructor} />

                            <InputComponent width={leftInfoWidth} value={price} name="Price" changeValue={setprice} number={true} />

                            <InputComponent width={leftInfoWidth} mandatory={true} minLength={1} value={finalPrice} name="F. Price" changeValue={setfinalPrice} number={true} />

                            <MultiSelectDropDown width={leftInfoWidth} items={availableCoupons} value={coupon} changeValue={setcoupon} name="Coupon" showItem={3} label="Coupon" />

                            <MultiSelectDropDown width={leftInfoWidth} items={installments} value={installment} changeValue={setinstallment} name="Installment" showItem={3} label="Title" />

                            <TextEditorComponent width={leftInfoWidth} name="Description" value={description} changeValue={setdescription} />

                            <TextEditorComponent width={leftInfoWidth} name="Includes" value={courseIncludes} changeValue={setcourseIncludes} />

                            {faq.map((item, index) => <FAQInputComponent key={index} allData={faq} dataId={item.id} setallData={setfaq} />)}

                            <div className='flex justify-end mb-2'>
                                <button type='button' onClick={() => {
                                    setfaq([...faq, { header: '', content: '', id: Date.now() }])
                                }} className='bg-green-400 hover:bg-green-600 transition-all duration-300 text-white rounded-md px-3 text-xs' style={{ height: 42 }}>Add More FAQ's</button>
                            </div>

                            <div className='flex flex-col pt-2 sm:flex-row sm:pt-0'>
                                <button type='button' disabled={addUpdateLoading} className='submitButton' onClick={() => {
                                    onSubmit({ isDraft: 1 })
                                }}>{draftLoading ? 'Submitting' : 'Save as Draft'}</button>
                                <div style={{ width: 30, height: 15 }}></div>

                                <button type='submit' disabled={addUpdateLoading} className='submitButton'>{courseAddUpdateLoading ? 'Submitting' : 'Preview'}</button>
                            </div>
                        </form>

                    </div>}
        </>
    )
}