import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { AssignmentsTable } from '../../../Components/Common/Table/Assignments/AssignmentsTable';
import { AssignmentsAction, AssignmentsStateAction } from '../../../Store/Action/AssignmentsAction';

export const AssignmentsScreen = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(AssignmentsStateAction({ loading: true }))
        dispatch(AssignmentsAction())
    }, [dispatch])

    const navigate = useNavigate()
    return (
        <div className='table-padding'>
            <div className='mainHeader'>
                <p className='mainHeading'>Manage Assignments</p>
                <button className='custButton' onClick={() => navigate('/assignment')}>Add New</button>
            </div>
            <AssignmentsTable />
        </div>
    );
}
