import React, { useState, useEffect } from 'react'
import { PDFExport } from "@progress/kendo-react-pdf";
import { FiDownload } from 'react-icons/fi'
import { token_api_interceptor } from '../../../../Store/Interceptor/Interceptor';
import { QuizResultUrl } from '../../../../Store/Interceptor/Url';
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { breakpoints } from "../../../../Helpers/breakpoints";

export const ViewUserResultPdf = ({ userId, quizId, state, changeState, }) => {
    const pdfExportComponent = React.useRef(null);

    const [loading, setloading] = useState(true)
    const [error, seterror] = useState(false)
    const [message, setmessage] = useState('')

    const [data, setdata] = useState([])
    const [quizDetails, setquizDetails] = useState({})

    useEffect(() => {
        if (state) {

            setloading(true)
            token_api_interceptor.get(`${QuizResultUrl}user/${quizId}/${userId}`)
                .then(res => {
                    if (res.data.status) {
                        seterror(false)
                        setquizDetails(res.data.quiz)
                        setdata(res.data.data)
                    } else {
                        seterror(true)
                        setmessage(res.data.message)
                    }
                }).catch(() => {
                    seterror(true)
                    setmessage('Something went wrong')
                }).finally(() => setloading(false))
        }
        return () => {

        };
    }, [quizId, state, userId])

    const RenderDetail = ({ title, result }) => {

        return <p className='text-gray-700 bg-white px-3 py-2 rounded-md border-gray-300 mb-2'><p className='inline-block w-32 font-medium'> {title} : </p> {result}</p>

    }

    const RenderAnswer = ({ option, selected, correct, current }) => {

        return <p className={' py-2 px-4 rounded-md border mb-2 '.concat(!correct ? 'bg-yellow-100 border-yellow-300 text-yellow-600' : selected === current && correct === current ? 'bg-green-100 text-green-600 border-green-400' : selected === current ? 'bg-red-100 text-red-600 border-red-400' : 'bg-white border-gray-300')}>{current}). {option}</p>

    }

    const renderFooter = () => {
        return (
            <div>
                <Button
                    label="Ok"
                    icon="pi pi-check"
                    onClick={hideDialog}
                    autoFocus
                />
            </div>
        );
    };

    const hideDialog = () => {
        changeState(false);
    };

    return (
        <div>
            <Dialog
                draggable={false}
                header="Result Pdf"
                visible={state}
                onHide={hideDialog}
                style={{ minWidth: "90vw", margin: 10 }}
                breakpoints={breakpoints}
                footer={renderFooter()}
            >
                {
                    loading ? <p>Loading...</p> : error ? <p>{message}</p> : <PDFExport title='Quiz' fileName={`Quiz Result`} paperSize="A4" margin="2cm" ref={pdfExportComponent} >
                        <div>
                            <div className='flex justify-end items-center'>

                                <button onClick={() => {

                                    if (pdfExportComponent.current) {
                                        pdfExportComponent.current.save();
                                    }
                                }} className='bg-gray-200 h-9 aspect-square rounded-md flex justify-center items-center'> <FiDownload /></button>

                            </div>
                            <p className='font-semibold'>{quizDetails.Title}</p>
                            <div className='bg-gray-100 my-4 p-4 rounded-md'>
                                <RenderDetail title={"Questions"} result={quizDetails.Questions} />
                                <RenderDetail title={"Marks"} result={quizDetails.MarksObtained} />
                                <RenderDetail title={"Attempted"} result={quizDetails.QuestionsAttempted} />
                                <RenderDetail title={"Wrong"} result={quizDetails.WrongAnswers} />
                                <RenderDetail title={"Not Attempted"} result={quizDetails.NotAttempted} />
                                <RenderDetail title={"Correct"} result={quizDetails.RightAnswers} />
                                <RenderDetail title={"TimeTaken"} result={quizDetails.TimeTaken} />
                                <RenderDetail title={"Date"} result={quizDetails.QuizAttemptOn} />
                            </div>
                            {
                                data.map((item, index) => <div key={index} className='p-5 bg-gray-100 rounded-md mb-3 shadow-sm'>
                                    <p className='font-medium mb-4'>{index + 1}). {item.Question}</p>
                                    <RenderAnswer option={item.Option1} correct={item.CorrectAnswer} current={"a"} selected={item.UserAnswer} />
                                    <RenderAnswer option={item.Option2} correct={item.CorrectAnswer} current={"b"} selected={item.UserAnswer} />
                                    <RenderAnswer option={item.Option3} correct={item.CorrectAnswer} current={"c"} selected={item.UserAnswer} />
                                    <RenderAnswer option={item.Option4} correct={item.CorrectAnswer} current={"d"} selected={item.UserAnswer} />
                                    <p className='text-right mt-2'><span className='text-yellow-600'>{!item.CorrectAnswer ? '(Not Attempted)' : ''}</span> Marks : {item.Marks || '0'}</p>
                                </div>)
                            }
                        </div>
                    </PDFExport>
                }
            </Dialog>
        </div>
    )
}