import { DashboardScreen } from "../Screens/Front/DashboardScreen";
import { CourseTagScreen } from "../Screens/Front/CourseTag/CourseTagScreen";
import { InstructorScreen } from "../Screens/Front/Instructor/InstructorScreen";
import { TransactionScreen } from "../Screens/Front/Transaction/TransactionScreen";
import { AddUpdateCourseScreen } from "../Screens/Front/Course/AddUpdateCourseScreen";
import { CourseScreen } from "../Screens/Front/Course/CourseScreen";
import { BatchScreen } from "../Screens/Front/Batch/BatchScreen";
import { CouponScreen } from "../Screens/Front/Coupon/CouponScreen";
import { SocialScreen } from "../Screens/Front/Social/SocialScreen";
import { LecturesScreen } from "../Screens/Front/Lecture/LecturesScreen";
import { AddUpdateLectureScreen } from "../Screens/Front/Lecture/AddUpdateLectureScreen";
import { ScheduleScreen } from "../Screens/Front/CourseSchedule/ScheduleScreen";
import { AddUpdateScheduleScreen } from "../Screens/Front/CourseSchedule/AddUpdateScheduleScreen";
import { SliderScreen } from "../Screens/Front/Slider/SliderScreen";
import { YoutubeVideoScreen } from "../Screens/Front/Youtube/YoutubeVideoScreen";
import { QueryScreen } from "../Screens/Front/Query/QueryScreen";
import { InstallmentTrackScreen } from "../Screens/Front/InstallmentTrack/InstallmentTrackScreen";
import { UsersScreen } from "../Screens/Front/Users/UsersScreen";
import { EnrolledUsersScreen } from "../Screens/Front/EnrolledUsers/EnrolledUsersScreen";
import { RoleScreen } from "../Screens/Front/Role/RoleScreen";
import { AdminAccessScreen } from "../Screens/Front/AdminAccess/AdminAccessScreen";
import { CurrentAffairsScreen } from "../Screens/Front/StudyMaterial/CurrentAffairsScreen";
import { ClassNotesScreen } from "../Screens/Front/StudyMaterial/ClassNotesScreen";
import { ManualBookSeatScreen } from "../Screens/Front/ManualPayment/ManualBookSeatScreen";
import { AssignmentsScreen } from "../Screens/Front/Assignments/AssignmentsScreen";
import { AddUpdateAssignmentScreen } from "../Screens/Front/Assignments/AddUpdateAssignmentsScreen";
import { UserAssignmentsScreen } from "../Screens/Front/UserAssignments/UserAssignmentsScreen";
import { UpdateUserAssignmentScreen } from "../Screens/Front/UserAssignments/UpdateUserAssignmentsScreen";
import { ManualAddLectureScreen } from "../Screens/Front/Lecture/ManualAddLectureScreen";
import { SubjectScreen } from "../Screens/Front/Subject/SubjectScreen";
import { QuizScreen } from "../Screens/Front/Quiz/QuizScreen";
import { AddUpdateQuizScreen } from "../Screens/Front/Quiz/AddUpdateQuizScreen";
import { SeminarScreen } from "../Screens/Front/Seminar/SeminarScreen";
import { SeminarQueryScreen } from "../Screens/Front/SeminarQuery/SeminarQueryScreen";

export const routes = [
  {
    url: "/",
    name: "Dashboard",
    component: <DashboardScreen />,
  },
  {
    url: "/slider",
    name: "Slider",
    component: <SliderScreen />,
  },
  {
    url: "/course-tag",
    name: "Course Tag",
    component: <CourseTagScreen />,
  },
  {
    url: "/batch",
    name: "Batch",
    component: <BatchScreen />,
  },
  {
    url: "/coupon",
    name: "Coupon",
    component: <CouponScreen />,
  },
  {
    url: "/instructor",
    name: "Instructor",
    component: <InstructorScreen />,
  },
  {
    url: "/course",
    name: "Add Update Course",
    component: <AddUpdateCourseScreen />,
  },
  {
    url: "/courses",
    name: "View Course",
    component: <CourseScreen />,
  },
  {
    url: "/course-schedules",
    name: "View Course Schedule",
    component: <ScheduleScreen />,
  },
  {
    url: "/course-schedule",
    name: "Add Course Schedule",
    component: <AddUpdateScheduleScreen />,
  },
  {
    url: "/lectures",
    name: "View Lectures",
    component: <LecturesScreen />,
  },
  {
    url: "/lecture",
    name: "Add Lectures",
    component: <AddUpdateLectureScreen />,
  },
  {
    url: "/manual-lecture",
    name: "Manual Add Lectures",
    component: <ManualAddLectureScreen />,
  },
  {
    url: "/assignments",
    name: "View Assignments",
    component: <AssignmentsScreen />,
  },
  {
    url: "/assignment",
    name: "Add Assignment",
    component: <AddUpdateAssignmentScreen />,
  },
  {
    url: "/user-assignments",
    name: "View Assignments",
    component: <UserAssignmentsScreen />,
  },
  {
    url: "/user-assignment",
    name: "Update User Assignment",
    component: <UpdateUserAssignmentScreen />,
  },
  {
    url: "/transaction",
    name: "Transaction",
    component: <TransactionScreen />,
  },
  {
    url: "/installment-track",
    name: "Installments",
    component: <InstallmentTrackScreen />,
  },
  {
    url: "/role",
    name: "Role",
    component: <RoleScreen />,
  },
  {
    url: "/access",
    name: "Access",
    component: <AdminAccessScreen />,
  },
  {
    url: "/social",
    name: "Social",
    component: <SocialScreen />,
  },
  {
    url: "/you-tube",
    name: "YouTube",
    component: <YoutubeVideoScreen />,
  },
  {
    url: "/query",
    name: "Contact Us Report",
    component: <QueryScreen />,
  },
  {
    url: "/registered-users",
    name: "Registered Users",
    component: <UsersScreen />,
  },
  {
    url: "/enrolled-users",
    name: "Enrolled Users",
    component: <EnrolledUsersScreen />,
  },
  {
    url: "/current-affairs",
    name: "Current Affairs",
    component: <CurrentAffairsScreen />,
  },
  {
    url: "/class-notes",
    name: "Class Notes",
    component: <ClassNotesScreen />,
  },
  {
    url: "/manual-book-seat",
    name: "Manual Book Seat",
    component: <ManualBookSeatScreen />,
  },
  {
    url: "/subject",
    name: "Subject",
    component: <SubjectScreen />,
  },
  {
    url: "/quizes",
    name: "Quiz",
    component: <QuizScreen />,
  },
  {
    url: "/quiz/paid",
    name: "Add Paid Quiz",
    component: <AddUpdateQuizScreen />,
  },
  {
    url: "/quiz/free",
    name: "Add Free Quiz",
    component: <AddUpdateQuizScreen />,
  },
  {
    url: "/seminar",
    name: "Seminar",
    component: <SeminarScreen />,
  },
  {
    url: "/seminar-query",
    name: "Seminar Query",
    component: <SeminarQueryScreen />,
  },
  {
    url: "*",
    name: "Dashboard",
    component: <DashboardScreen />,
  },
];
