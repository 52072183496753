import React, { useState } from 'react'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputComponent } from '../Common/Inputs/InputComponent';
import { DropdownComponent } from '../Common/Inputs/DropdownComponent';
import { useDispatch, useSelector } from 'react-redux'
import { AddYouTubeAction, AddUpdateYouTubeStateAction, UpdateYouTubeAction } from '../../Store/Action/YoutubeAction';
import { statuses } from '../../Constants/Statuses';
import { breakpoints } from '../../Helpers/breakpoints';
import { ImageInputComponent } from '../Common/Inputs/ImageInputContainer';

export const YoutubeVideoDialog = ({ state, changeState, name, status, _thumbnail, id, code }) => {

    const {
        addUpdateLoading,
        addUpdateError,
        addUpdateDone,
        addUpdateMessage, } = useSelector(state => state.youtube)

    const dispatch = useDispatch()
    const [videoTitle, setvideoTitle] = useState(name)
    const [videoCode, setvideoCode] = useState(code)
    const [thumbnail, setthumbnail] = useState(_thumbnail)
    const currStatus = statuses.find(item => item.key === Number(status))
    const [tagStatus, settagStatus] = useState(currStatus)

    const leftInfoWidth = 100

    React.useEffect(() => {
        if (state) {
            dispatch(AddUpdateYouTubeStateAction({ loading: false, error: false }))
        }
        // eslint-disable-next-line
    }, [state])


    React.useEffect(() => {
        if (id) {
        } else {
            setvideoTitle('')
            setvideoCode('')
            settagStatus(statuses[0])
        }
        // eslint-disable-next-line
    }, [addUpdateDone, id])


    const renderFooter = () => {
        return (
            <div>
                <Button loading={addUpdateLoading} disabled={addUpdateLoading} label="No" icon="pi pi-times" onClick={hideDialog} className="p-button-text" />
                <Button loading={addUpdateLoading} disabled={addUpdateLoading} label="Yes" icon="pi pi-check" onClick={onClick} autoFocus />
            </div>
        );
    }

    const hideDialog = () => {
        changeState(false)
    }

    const onClick = () => {
        if (thumbnail) {

            if (videoTitle?.length && videoCode?.length) {
                dispatch(AddUpdateYouTubeStateAction({ loading: true, error: false }))
                if (id) {
                    dispatch(UpdateYouTubeAction({ id, title: videoTitle, code: videoCode, status: tagStatus.key, image: thumbnail }))
                } else {
                    dispatch(AddYouTubeAction({ title: videoTitle, code: videoCode, status: tagStatus.key, image: thumbnail }))
                }
            } else {
                alert('Invalid Data')
            }
        } else {
            alert("Thumbnail can't be null")
        }
    }

    return (
        <Dialog draggable={false} header="Youtube Video" visible={state} onHide={hideDialog} style={{ minWidth: '50vw', margin: 10 }} breakpoints={breakpoints} footer={renderFooter()}>

            {addUpdateError && <div className="alert alert-danger text-sm" role="alert">
                <span className='font-bold'>Error! </span>
                <span>{addUpdateMessage}</span>
            </div>}
            {addUpdateDone && <div className="alert alert-success text-sm" role="alert">
                <span className='font-bold'>Success! </span>
                <span>{addUpdateMessage}</span>
            </div>}
            <div className='py-2'>
                <InputComponent width={leftInfoWidth} name="Title" value={videoTitle} changeValue={setvideoTitle} />
                <InputComponent width={leftInfoWidth} name="Code" value={videoCode} changeValue={setvideoCode} />
                <ImageInputComponent name="Thumbnail" value={thumbnail} changeValue={setthumbnail} sizeFrom={100} sizeTo={2000} pixels="1280px * 720px" width={leftInfoWidth} />
                <DropdownComponent width={leftInfoWidth} title="Status" selectedItem={tagStatus} changeSelectedItem={settagStatus} items={statuses} selectBy="title" />
            </div>
        </Dialog>
    )
}