import React, { useState, useEffect, useRef } from "react";

// ICONS
import { FaUser } from "react-icons/fa";
import { TbFileUpload } from "react-icons/tb";
import { RiFilePaper2Line } from "react-icons/ri";
import { MdOutlineEdit, MdDelete, MdRemoveRedEye } from "react-icons/md";

// DOM
import { useNavigate } from "react-router-dom";

// UTILS
import { shortString } from "../../../../Helpers/ShortString";

// EXTERNAL LIBRARY
import moment from "moment";

// REDUX
import { useSelector } from "react-redux";
import {
  DeleteQuizAction,
  DeleteQuizStateAction,
} from "../../../../Store/Action/Quiz";

// COMPONENTS
import { DeleteAlertComponent } from "../../Alert/DeleteAlertComponent";
import { UploadQuestionDialog } from "./UploadQuestionDialog";
import { ViewQuestionsDialog } from "./ViewQuestionsDialog";
import { UserCompletedQuizDialog } from "./UserCompletedQuizDialog";
import { UserQuizDialog } from "./UserQuizDialog";


export const QuizRowComponent = ({ _i, dt, selectedQuiz, setselectedQuiz, selectedUser }) => {
  const dataKeys = [
    "Title",
    "Course",
    "StartTime",
    // "EndTime",
    "Marks",
    // "Status",
    "Action",
  ];
  const [checked, setchecked] = useState(false);
  const [alertState, setalertState] = useState(false);
  const [dialogState, setdialogState] = useState(false);
  const [viewUserCompletedQuizDialog, setviewUserCompletedQuizDialog] = useState(false)
  const [viewUserQuizDialog, setviewUserQuizDialog] = useState(false)


  const inputRef = useRef(null);

  const [viewQuestionDialog, setviewQuestionDialog] = useState(false);

  const { deleteLoading, deleteError, deleteMessage, deleteDone } = useSelector(
    (state) => state.quiz
  );

  useEffect(() => {
    setchecked(selectedQuiz.find((item) => item === dt.ID));
    return () => { };
  }, [dt, selectedQuiz]);

  const navigate = useNavigate();

  return (
    <tr className={"border-b-1"}>
      <td className="px-3 py-4 whitespace-nowrap flex justify-center">
        <input
          ref={inputRef}
          type="checkbox"
          className="cursor-pointer"
          defaultChecked={checked}
          onChange={() => {
            if (checked) {
              const filteredData = selectedQuiz.filter(
                (item) => item !== dt.ID
              );
              setselectedQuiz(filteredData);
            } else {
              setselectedQuiz((_dt) => [..._dt, dt.ID]);
            }
          }}
        />
      </td>
      <td className="px-3 py-4 whitespace-nowrap">
        <UploadQuestionDialog
          id={[dt.ID]}
          state={dialogState}
          changeState={setdialogState}
          removeId={() => { }}
        />
        <ViewQuestionsDialog
          id={dt.ID}
          state={viewQuestionDialog}
          changeState={setviewQuestionDialog}
        />
        <UserQuizDialog
          id={dt.ID}
          state={viewUserQuizDialog}
          changeState={setviewUserQuizDialog}
        />
        <UserCompletedQuizDialog
          id={dt.ID}
          state={viewUserCompletedQuizDialog}
          changeState={setviewUserCompletedQuizDialog}
        />
        <div className="text-md font-medium text-gray-600 text-center">
          <span>{_i}</span>
        </div>
      </td>

      <DeleteAlertComponent
        state={alertState}
        changeState={setalertState}
        id={dt.ID}
        data={{ deleteLoading, deleteError, deleteMessage, deleteDone }}
        deleteNow={DeleteQuizAction}
        deleteState={DeleteQuizStateAction}
      />
      {dataKeys.map((item, ind) => {
        if (item === "Status") {
          return (
            <td key={ind} className="px-3 py-4 text-center ">
              <div
                style={{ width: 70 }}
                className={"px-1 py-1 text-xs font-semibold rounded-full m-auto ".concat(
                  dt.Status
                    ? "bg-green-100 text-green-700"
                    : "bg-red-100 text-red-700"
                )}
              >
                {dt.Status ? "Active" : "In-Active"}
              </div>
            </td>
          );
        } else if (item === "Marks") {
          return (
            <td
              key={ind}
              className="px-3 py-4 text-center flex justify-center space-x-2"
            >
              <p className="text-sm px-2 py-1 font-semibold rounded-md bg-green-100 text-green-700">
                +{dt.DefaultMarks}
              </p>
              <p className="text-sm px-2 py-1 font-semibold rounded-md bg-red-100 text-red-700">
                {dt.NegativeMarks}
              </p>
            </td>
          );
        } else if (item === "Action") {
          const hw = { height: 35, width: 35 };
          return (
            <td key={ind} className="px-3 py-4 whitespace-nowrap">
              <div className="flex justify-center">
                <div
                  onClick={() => {
                    navigate({
                      pathname: selectedUser === 'All' ? "/quiz/free" : '/quiz/paid',
                      search: "key=" + dt.ID,
                    });
                  }}
                  className="bg-yellow-500 transition-all duration-300 hover:bg-yellow-800 cursor-pointer flex justify-center items-center rounded-lg"
                  style={hw}
                >
                  <MdOutlineEdit color="white" size={18} />
                </div>
                <div
                  onClick={() => setalertState(true)}
                  className="bg-red-600 transition-all duration-300 hover:bg-red-900 cursor-pointer ml-2 flex justify-center items-center rounded-lg"
                  style={hw}
                >
                  <MdDelete color="white" size={18} />
                </div>
                <div
                  onClick={() => setdialogState(true)}
                  className="bg-sky-600 transition-all duration-300 hover:bg-sky-900 cursor-pointer ml-2 flex justify-center items-center rounded-lg"
                  style={hw}
                >
                  <TbFileUpload color="white" size={18} />
                </div>
                <div
                  onClick={() => setviewQuestionDialog(true)}
                  className="bg-pink-600 transition-all duration-300 hover:bg-pink-900 cursor-pointer ml-2 flex justify-center items-center rounded-lg"
                  style={hw}
                >
                  <MdRemoveRedEye color="white" size={18} />
                </div>
                <div
                  onClick={() => setviewUserQuizDialog(true)}
                  className="bg-orange-600 transition-all duration-300 hover:bg-orange-900 cursor-pointer ml-2 flex justify-center items-center rounded-lg"
                  style={hw}
                >
                  <FaUser color="white" size={18} />
                </div>
                <div
                  onClick={() => setviewUserCompletedQuizDialog(true)}
                  className="bg-green-600 transition-all duration-300 hover:bg-green-900 cursor-pointer ml-2 flex justify-center items-center rounded-lg"
                  style={hw}
                >
                  <RiFilePaper2Line color="white" size={18} />
                </div>
              </div>
            </td>
          );
        } else if (item === "StartTime" || item === "EndTime") {
          return (
            <td key={ind} className="px-3 py-4 whitespace-nowrap">
              <div className="text-sm text-gray-900 text-center">
                <span> {moment(new Date(dt[item])).format("DD-MM-YYYY")}</span>
              </div>
            </td>
          );
        } else if (item === "Course") {
          return (
            <td key={ind} className="px-3 py-4 whitespace-nowrap">
              <div className="text-sm text-gray-900 text-center">
                <span> {dt[item] ? shortString({ str: dt[item], len: 30 }) : 'Free Quiz'}</span>
              </div>
            </td>
          );
        }
        return (
          <td key={ind} className="px-3 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 text-center">
              <span> {shortString({ str: dt[item], len: 20 })}</span>
            </div>
          </td>
        );
      })}
    </tr>
  );
};
