import React, { useState } from 'react'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DropdownComponent } from '../Common/Inputs/DropdownComponent';
import { useDispatch, useSelector } from 'react-redux'
import { statuses } from '../../Constants/Statuses';
import { ImageInputComponent } from '../Common/Inputs/ImageInputContainer';
import { AddSliderAction, AddUpdateSliderStateAction, UpdateSliderAction } from '../../Store/Action/SliderAction';
import { breakpoints } from '../../Helpers/breakpoints';

export const SliderDialog = ({ state, changeState, status, id, oldImage }) => {

    const {
        addUpdateLoading,
        addUpdateError,
        addUpdateDone,
        addUpdateMessage, } = useSelector(state => state.slider)

    const dispatch = useDispatch()
    const currStatus = statuses.find(item => item.key === Number(status))
    const [batchStatus, setbatchStatus] = useState(currStatus)
    const [image, setimage] = useState('')

    React.useEffect(() => {
        if (state) {
            dispatch(AddUpdateSliderStateAction({ loading: false, error: false }))
            if (!id) {
                setimage('')
            }
        }
        // eslint-disable-next-line
    }, [state])

    React.useEffect(() => {
        setimage('')

        return () => {

        }
    }, [addUpdateDone])



    React.useEffect(() => {
        if (id) {
        } else {
            setbatchStatus(statuses[0])
        }
        // eslint-disable-next-line
    }, [addUpdateDone, id])


    const renderFooter = () => {
        return (
            <div>
                <Button loading={addUpdateLoading} disabled={addUpdateLoading} label="No" icon="pi pi-times" onClick={hideDialog} className="p-button-text" />
                <Button loading={addUpdateLoading} disabled={addUpdateLoading} label="Yes" icon="pi pi-check" onClick={onClick} autoFocus />
            </div>
        );
    }

    const hideDialog = () => {
        changeState(false)
    }

    const onClick = () => {
        if (id) {
            dispatch(AddUpdateSliderStateAction({ loading: true, error: false }))
            dispatch(UpdateSliderAction({ id, image: image || oldImage, status: batchStatus.key }))
        } else {
            if (image) {
                dispatch(AddUpdateSliderStateAction({ loading: true, error: false }))
                dispatch(AddSliderAction({ image, status: batchStatus.key }))
            } else {
                alert('Image is not selected')
            }
        }
    }

    return (
        <div>

            <Dialog draggable={false} header="Slider" visible={state} onHide={hideDialog} style={{ minWidth: '50vw', margin: 10 }} breakpoints={breakpoints} footer={renderFooter()}>

                {addUpdateError && <div className="alert alert-danger text-sm" role="alert">
                    <span className='font-bold'>Error! </span>
                    <span>{addUpdateMessage}</span>
                </div>}
                {addUpdateDone && <div className="alert alert-success text-sm" role="alert">
                    <span className='font-bold'>Success! </span>
                    <span>{addUpdateMessage}</span>
                </div>}
                <div className='py-2'>
                    <ImageInputComponent name="Image" value={image} changeValue={setimage} sizeFrom={100} sizeTo={2000} pixels="1049px * 416px" width={80} />
                    <DropdownComponent title="Status" selectedItem={batchStatus} changeSelectedItem={setbatchStatus} items={statuses} selectBy="title" width={80} />
                </div>
            </Dialog>
        </div>
    )
}