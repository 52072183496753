import { LOGOUT } from "../Action/AuthAction";
import { MANUALBOOKSEAT, MANUALBOOKSEATSTATE } from "../Action/ManualBookSeat";

const initialState = {
    loading: false,
    error: false,
    message: null,
    addDone: false
};

export const ManualBookSeatReducer = (state = initialState, action) => {
    switch (action.type) {

        // MANUAL BOOK SEAT
        case MANUALBOOKSEATSTATE: {
            const { loading, error, message } = action
            return {
                loading, error, message, addDone: false
            };
        }

        case MANUALBOOKSEAT: {
            return {
                loading: false,
                error: false,
                message: '',
                addDone: true
            };
        }

        // LOGOUT
        case LOGOUT:
            return initialState;

        default:
            return { ...state };
    }
};