import React, { useState, useEffect } from 'react'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputComponent } from '../Common/Inputs/InputComponent';
import { useSelector, useDispatch } from 'react-redux'
import { breakpoints } from '../../Helpers/breakpoints';
import { PdfInputComponent } from '../Common/Inputs/PdfInputContainer';
import { DropdownComponent } from '../Common/Inputs/DropdownComponent';
import { CurrentAffairs } from '../../Store/Interceptor/Url';
import { token_api_interceptor } from '../../Store/Interceptor/Interceptor';
import { AddCurrentAffairsAction, AddUpdateCurrentAffairsStateAction, UpdateCurrentAffairsAction } from '../../Store/Action/CurrentAffairs';

export const CurrentAffairsDialog = ({ state, changeState, _title, id, _file, _folder }) => {

    const leftInfoWidth = 80

    const { folderData, addUpdateLoading,
        addUpdateError,
        addUpdateDone,
        addUpdateMessage, } = useSelector(state => state.currentAffairs)

    const dispatch = useDispatch()
    const [title, settitle] = useState(_title)
    const [file, setfile] = useState(_file)
    const [selectedFolder, setselectedFolder] = useState({})

    const [currentChunkIndex, setcurrentChunkIndex] = useState(0)

    const chunkSize = 200 * 1024

    const emptyData = () => {
        settitle('')
        setfile('')
        setcurrentChunkIndex(0)
    }


    const uploadChunk = (readerEvent) => {
        dispatch(AddUpdateCurrentAffairsStateAction({ loading: true, error: false }))

        const data = readerEvent.target.result
        const params = new URLSearchParams()

        params.set('name', file.name)
        params.set('size', file.size)
        params.set('currentChunkIndex', currentChunkIndex)
        params.set('totalChunks', Math.ceil(file.size / chunkSize))
        params.set('title', title)
        params.set('folder', selectedFolder.ID.toString())

        const url = CurrentAffairs + 'add?' + params.toString()

        token_api_interceptor.post(url, data, {
            headers: {
                'content-type': 'application/octet-stream'
            }
        }).then(res => {
            const fileSize = file.size
            const isLastChunk = currentChunkIndex === Math.ceil(fileSize / chunkSize) - 1

            if (isLastChunk) {

                if (res.data.status) {
                    const { file, id } = res.data
                    dispatch(AddCurrentAffairsAction({ file, id, folder: selectedFolder.ID, title: title }))

                    setTimeout(() => {
                        dispatch(AddUpdateCurrentAffairsStateAction({ loading: false, error: false }))
                    }, 3000);
                    emptyData()
                } else {
                    dispatch(AddUpdateCurrentAffairsStateAction({ loading: false, error: true, message: res.data.message }))
                    setcurrentChunkIndex(0)
                }
            } else {
                setcurrentChunkIndex(currentChunkIndex + 1)
            }
        })
            .catch(err => {
                setcurrentChunkIndex(0)
                dispatch(AddUpdateCurrentAffairsStateAction({ loading: false, error: true, message: 'Something went wrong' }))
                alert('Something went wrong')
            })
    }

    const onSubmit = () => {
        if (title.length === 0) {
            alert('Invalid Title')
        } else if (id) {
            dispatch(AddUpdateCurrentAffairsStateAction({ loading: true, error: false }))
            dispatch(UpdateCurrentAffairsAction({ id, title, oldFolder: _folder, folder: selectedFolder.ID }))

        } else if (file) {
            const reader = new FileReader()
            const from = currentChunkIndex * chunkSize
            const to = from + chunkSize
            const blob = file.slice(from, to)
            reader.onload = (e) => uploadChunk(e)
            reader.readAsDataURL(blob)

        } else {
            alert('File should be selected')
        }
    }

    useEffect(() => {
        if (currentChunkIndex !== 0) {
            onSubmit()
        }

        // eslint-disable-next-line
    }, [currentChunkIndex])


    useEffect(() => {

        if (folderData.length) {
            if (id) {
                const _findData = folderData.find(item => +item.ID === +_folder)
                setselectedFolder(_findData)
            } else {

                setselectedFolder(folderData[0])
            }
        }

        return () => { };
    }, [state, folderData, _folder, id])


    useEffect(() => {
        if (!id) {
            emptyData()
        }
        if (addUpdateDone) {
            setTimeout(() => {
                dispatch(AddUpdateCurrentAffairsStateAction({ loading: false, error: false }))
            }, 3000);
        }
        // eslint-disable-next-line
    }, [addUpdateDone, id, dispatch])

    useEffect(() => {
        dispatch(AddUpdateCurrentAffairsStateAction({ loading: false, error: false }))
    }, [dispatch])

    const renderFooter = () => {
        return (

            <div>{
                addUpdateLoading ? <div className='flex justify-center w-full'>
                    <div className='bg-slate-300 w-full h-5 rounded-sm overflow-hidden'>
                        <div style={{ width: `${(currentChunkIndex * 100 / (file?.size / chunkSize)).toFixed(2)}%` }} className="h-full transition-all duration-200 bg-green-400 flex justify-end items-center">
                            <p className='text-xs text-white mr-2'>{(currentChunkIndex * 100 / (file?.size / chunkSize)).toFixed(2)}%</p>
                        </div>
                    </div>
                </div> :
                    <div>
                        <Button loading={addUpdateLoading} disabled={addUpdateLoading} label="No" icon="pi pi-times" onClick={hideDialog} className="p-button-text" />
                        <Button loading={addUpdateLoading} disabled={addUpdateLoading} label="Yes" icon="pi pi-check" onClick={onSubmit} autoFocus />
                    </div>}
            </div>
        );
    }

    const hideDialog = () => {
        changeState(false)
    }

    return (
        <Dialog draggable={false} header="Current Affairs" visible={state} onHide={hideDialog} style={{ minWidth: '50vw', margin: 10 }} breakpoints={breakpoints} footer={renderFooter()}>

            {addUpdateError && <div className="alert alert-danger text-sm" role="alert">
                <span className='font-bold'>Error! </span>
                <span>{addUpdateMessage}</span>
            </div>}
            {addUpdateDone && <div className="alert alert-success text-sm" role="alert">
                <span className='font-bold'>Success! </span>
                <span>{addUpdateMessage}</span>
            </div>}
            <div className='py-2'>
                <InputComponent value={title} changeValue={settitle} width={leftInfoWidth} name="Title" />
                {/* <DateInputComponent changeValue={setdate} name="Date" value={date} mandatory={true} width={leftInfoWidth} /> */}
                <DropdownComponent items={folderData} selectBy="Name" selectedItem={selectedFolder} changeSelectedItem={setselectedFolder} title="Folder" width={leftInfoWidth} />
                <PdfInputComponent disabled={id} key={1} index={1} name="File" value={file} changeValue={setfile} width={leftInfoWidth} />
            </div>
        </Dialog>
    )
}