import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CurrentAffairsTable } from '../../../Components/Common/Table/CurrentAffairs/CurrentAffairsTable';
import { CurrentAffairFolderDialog } from '../../../Components/Dialog/CurrentAffairFolderDialog';
import { CurrentAffairsDialog } from '../../../Components/Dialog/CurrentAffairsDialog';
import { CurrentAffairsAction, CurrentAffairsStateAction } from '../../../Store/Action/CurrentAffairs';
import { CurrentAffairsFolderAction } from '../../../Store/Action/CurrentAffairsFolder';

export const CurrentAffairsScreen = () => {
    const [dialogState, setdialogState] = useState(false)
    const [folderDialogState, setfolderDialogState] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(CurrentAffairsStateAction({ loading: true }))
        dispatch(CurrentAffairsAction())

        dispatch(CurrentAffairsFolderAction())

    }, [dispatch])


    return (
        <div className='table-padding'>
            <CurrentAffairsDialog state={dialogState} changeState={setdialogState} _title="" />
            <CurrentAffairFolderDialog state={folderDialogState} changeState={setfolderDialogState} />
            <div className='mainHeader'>
                <p className='mainHeading'>Manage Current Affairs</p>
                <div className='flex '>
                    <button className='custButton' onClick={() => setfolderDialogState(true)}>Create New Folder</button>
                    <div style={{ width: 20 }}></div>
                    <button className='custButton' onClick={() => setdialogState(true)}>Add New</button>
                </div>
            </div>
            <CurrentAffairsTable />
        </div>
    );
}
