import React from 'react'
import { Editor } from 'primereact/editor';
import './EditorStyle.css'

export const TextEditorComponent = ({ name, value, changeValue, mandatory = false, width = 150 }) => {

    return (
        <div className="p-fluid grid mb-2 text-sm">
            <div className="field col-12 md:col-4">
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon" style={{ width: width + 20 }}>
                        <span>{name} <span className='text-red-800'>{mandatory && '*'}</span></span>
                    </span>
                    <Editor style={{ height: 100 }} value={value} onTextChange={(e) => changeValue(e.htmlValue)} />
                </div>
            </div>
        </div>
    )
}