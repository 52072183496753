import React, { useState } from 'react'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputComponent } from '../Common/Inputs/InputComponent';
import { useDispatch, useSelector } from 'react-redux'
import { AddSubjectAction, AddUpdateSubjectStateAction, UpdateSubjectAction } from '../../Store/Action/SubjectAction';
import { breakpoints } from '../../Helpers/breakpoints';

export const SubjectDialog = ({ state, changeState, oldTitle, id }) => {

    const {
        addUpdateLoading,
        addUpdateError,
        addUpdateDone,
        addUpdateMessage, } = useSelector(state => state.subject)

    const dispatch = useDispatch()
    const [title, settitle] = useState(oldTitle)


    React.useEffect(() => {
        if (state) {
            dispatch(AddUpdateSubjectStateAction({ loading: false, error: false }))
        }
        // eslint-disable-next-line
    }, [state])


    React.useEffect(() => {
        if (id) {
        } else {
            settitle('')
        }
        // eslint-disable-next-line
    }, [addUpdateDone, id])


    const renderFooter = () => {
        return (
            <div>
                <Button loading={addUpdateLoading} disabled={addUpdateLoading} label="No" icon="pi pi-times" onClick={hideDialog} className="p-button-text" />
                <Button loading={addUpdateLoading} disabled={addUpdateLoading} label="Yes" icon="pi pi-check" onClick={onClick} autoFocus />
            </div>
        );
    }

    const hideDialog = () => {
        changeState(false)
    }

    const onClick = () => {
        if (title.length) {
            dispatch(AddUpdateSubjectStateAction({ loading: true, error: false }))
            if (id) {
                dispatch(UpdateSubjectAction({ id, title }))
            } else {
                dispatch(AddSubjectAction({ title }))
            }
        } else {
            alert('Invalid Data')
        }
    }

    return (
        <Dialog draggable={false} header="Subject" visible={state} onHide={hideDialog} style={{ minWidth: '50vw', margin: 10 }} breakpoints={breakpoints} footer={renderFooter()}>

            {addUpdateError && <div className="alert alert-danger text-sm" role="alert">
                <span className='font-bold'>Error! </span>
                <span>{addUpdateMessage}</span>
            </div>}
            {addUpdateDone && <div className="alert alert-success text-sm" role="alert">
                <span className='font-bold'>Success! </span>
                <span>{addUpdateMessage}</span>
            </div>}
            <div className='py-2'>
                <InputComponent name="Title" value={title} changeValue={settitle} width={80} />
            </div>
        </Dialog>
    )
}