import { LOGOUT } from "../Action/AuthAction";
import { TRANSACTION, TRANSACTIONSTATE } from "../Action/TransactionAction";

const initialState = {
    data: [],
    courses: [],
    paymentType: [],
    paymentResponse: [],
    loading: true,
    error: false,
    errorMessage: null
};

export const transactionReducer = (state = initialState, action) => {
    switch (action.type) {
        case TRANSACTION: {
            return {
                data: action.data,
                courses: action.courses,
                paymentType: action.paymentType,
                paymentResponse: action.paymentResponse,
                loading: false,
                error: false,
                errorMessage: null
            };
        }
        case TRANSACTIONSTATE: {
            return {
                ...state,
                loading: action.loading,
                error: action.error
            };
        }
        case LOGOUT:
            return initialState;
        default:
            return { ...state };
    }
};