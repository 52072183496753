import React, { useState } from 'react'
import { MdOutlineEdit, MdDelete } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { DeleteAlertComponent } from '../../Alert/DeleteAlertComponent'
import { DeleteCurrentAffairsAction, DeleteCurrentAffairsStateAction } from '../../../../Store/Action/CurrentAffairs'
import { CurrentAffairsDialog } from '../../../Dialog/CurrentAffairsDialog'
import { ImDownload3 } from 'react-icons/im'
import { pdf_url } from '../../../../Store/Interceptor/Url'

export const CurrentAffairsRowComponent = React.memo(({ _i, dt }) => {
    const dataKeys = ['Title', 'File', 'Action']
    const [dialogState, setdialogState] = useState(false)
    const [alertState, setalertState] = useState(false)

    const { deleteLoading, deleteError, deleteMessage, deleteDone } = useSelector(state => state.currentAffairs)

    return (
        <tr>
            <CurrentAffairsDialog state={dialogState} changeState={setdialogState} id={dt.ID} status={dt.Status} _title={dt.Title} _file={dt.File} _folder={dt.Folder} />
            <DeleteAlertComponent state={alertState} changeState={setalertState} id={dt.ID} data={{ deleteLoading, deleteError, deleteMessage, deleteDone }} deleteNow={DeleteCurrentAffairsAction} deleteState={DeleteCurrentAffairsStateAction} />
            <td className="px-3 py-4 whitespace-nowrap">
                <div className="text-md font-medium text-gray-600 text-center">
                    <span>{_i}</span>
                </div>
            </td>
            {
                dataKeys.map((item, ind) => {
                    if (item === 'Status') {
                        return <td key={ind} className="px-3 py-4 text-center">
                            <div style={{ width: 80 }} className={"px-1 py-1 text-xs font-semibold rounded-full m-auto ".concat(dt.Status ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800')}>
                                {dt.Status ? 'Active' : 'In-Active'}
                            </div>
                        </td>
                    } else if (item === 'File') {
                        return <td key={ind} className="px-3 whitespace-nowrap flex justify-center items-center">
                            <div className="flex justify-center items-center" style={{ height: 80, width: 80 }}>
                                <a href={pdf_url + 'CurrentAffairs/' + dt[item]} download={dt[item]} target='_blank' rel="noreferrer">
                                    <div className='bg-white p-3 px-4 rounded-md  border-2 border-x-gray-100  border-b-orange-400'>
                                        <div className='py-2'>
                                            <ImDownload3 color='#FFA500' size={20} />
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </td>
                    } else if (item === 'Action') {
                        const hw = { height: 35, width: 35 }
                        return <td key={ind} className="px-3 py-4 whitespace-nowrap">
                            <div className="flex justify-center">
                                <div onClick={() => setdialogState(true)} className='bg-yellow-500 transition-all duration-300 hover:bg-yellow-800 cursor-pointer flex justify-center items-center rounded-lg' style={hw}>
                                    <MdOutlineEdit color='white' size={18} />
                                </div>
                                <div onClick={() => setalertState(true)} className='bg-red-600 transition-all duration-300 hover:bg-red-900 cursor-pointer ml-2 flex justify-center items-center rounded-lg' style={hw}>
                                    <MdDelete color='white' size={18} />
                                </div>
                            </div>
                        </td>

                    }
                    return <td key={ind} className="px-3 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900 text-center">
                            <span>{dt[item]}</span>
                        </div>
                    </td>

                })
            }
        </tr>
    )
})