import React, { useState } from 'react'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputComponent } from '../Common/Inputs/InputComponent';
import { DropdownComponent } from '../Common/Inputs/DropdownComponent';
import { useDispatch, useSelector } from 'react-redux'
import { statuses } from '../../Constants/Statuses';
import { AddBatchAction, AddUpdateBatchStateAction, UpdateBatchAction } from '../../Store/Action/BatchAction';
import { TimeInputComponent } from '../Common/Inputs/TimeInputComponent';
import moment from 'moment/moment';
import { breakpoints } from '../../Helpers/breakpoints';

export const BatchDialog = ({ state, changeState, oldBatch, status, id, oldsubtitle, oldFrom, oldTo }) => {

    const {
        addUpdateLoading,
        addUpdateError,
        addUpdateDone,
        addUpdateMessage, } = useSelector(state => state.batch)

    const dispatch = useDispatch()
    const [batch, setbatch] = useState(oldBatch)
    const [subTitle, setsubTitle] = useState(oldsubtitle)
    const [from, setfrom] = useState(new Date())
    const [to, setto] = useState(new Date())
    const currStatus = statuses.find(item => item.key === Number(status))
    const [batchStatus, setbatchStatus] = useState(currStatus)

    React.useEffect(() => {
        if (state) {
            dispatch(AddUpdateBatchStateAction({ loading: false, error: false }))
        }
        // eslint-disable-next-line
    }, [state])

    React.useEffect(() => {
        if (state) {
            if (oldFrom) {
                setfrom(new Date(oldFrom))
                setto(new Date(oldTo))
            }
        }
    }, [oldFrom, oldTo, state])


    React.useEffect(() => {
        if (id) {
        } else {
            setbatch('')
            setsubTitle('')
            setbatchStatus(statuses[0])
        }
        // eslint-disable-next-line
    }, [addUpdateDone, id])


    const renderFooter = () => {
        return (
            <div>
                <Button loading={addUpdateLoading} disabled={addUpdateLoading} label="No" icon="pi pi-times" onClick={hideDialog} className="p-button-text" />
                <Button loading={addUpdateLoading} disabled={addUpdateLoading} label="Yes" icon="pi pi-check" onClick={onClick} autoFocus />
            </div>
        );
    }

    const hideDialog = React.useCallback(
        () => {
            changeState()
        },
        [changeState],
    )


    React.useEffect(() => {
        if (addUpdateDone) {
            hideDialog()
        }

        return () => {

        }
    }, [addUpdateDone, hideDialog])


    const onClick = () => {
        if (batch.length && subTitle.length) {
            const batchFrom = moment(from).format('HH:mm:ss')
            const batchTo = moment(to).format('HH:mm:ss')
            dispatch(AddUpdateBatchStateAction({ loading: true, error: false }))
            if (id) {
                dispatch(UpdateBatchAction({ id, batch, from: batchFrom, to: batchTo, subtitle: subTitle, status: batchStatus.key, batchFrom: from, batchTo: to }))
            } else {
                dispatch(AddBatchAction({ batch, from: batchFrom, to: batchTo, subtitle: subTitle, status: batchStatus.key, batchFrom: from, batchTo: to }))
            }
        } else {
            alert('Invalid Data')
        }
    }

    return (
        <Dialog draggable={false} header="Batch" visible={state} onHide={hideDialog} style={{ minWidth: '50vw', margin: 10 }} breakpoints={breakpoints} footer={renderFooter()}>

            {addUpdateError && <div className="alert alert-danger text-sm" role="alert">
                <span className='font-bold'>Error! </span>
                <span>{addUpdateMessage}</span>
            </div>}
            <div className='py-2'>
                <InputComponent name="Batch" value={batch} changeValue={setbatch} width={80} />
                <InputComponent name="S. Title" value={subTitle} changeValue={setsubTitle} width={80} />
                <TimeInputComponent name="From" value={from} changeValue={setfrom} width={80} />
                <TimeInputComponent name="To" value={to} changeValue={setto} width={80} />
                <DropdownComponent title="Status" selectedItem={batchStatus} changeSelectedItem={setbatchStatus} items={statuses} selectBy="title" width={80} />
            </div>
        </Dialog>
    )
}