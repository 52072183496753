import { LOGOUT } from "../Action/AuthAction";
import {
  ADDCURRENTAFFAIRS,
  ADDUPDATECURRENTAFFAIRSSTATE,
  CURRENTAFFAIRS,
  CURRENTAFFAIRSSTATE,
  DELETECURRENTAFFAIRS,
  DELETECURRENTAFFAIRSSTATE,
  UPDATECURRENTAFFAIRS,
} from "../Action/CurrentAffairs";
import {
  ADDCURRENTAFFAIRSFOLDER,
  ADDCURRENTAFFAIRSFOLDERSTATE,
  CURRENTAFFAIRSFOLDER,
  CURRENTAFFAIRSFOLDERSTATE,
  DELETECURRENTAFFAIRSFOLDER,
  DELETECURRENTAFFAIRSFOLDERSTATE,
} from "../Action/CurrentAffairsFolder";

const initialState = {
  data: [],
  loading: true,
  error: false,
  message: null,

  // ADD-UPDATE
  addUpdateLoading: false,
  addUpdateError: false,
  addUpdateMessage: null,
  addUpdateDone: false,

  // DELETE
  folderDeleteLoading: false,
  folderDeleteError: false,
  folderDeleteMessage: null,
  folderDeleteDone: false,

  // DELETE
  deleteLoading: false,
  deleteError: false,
  deleteMessage: null,
  deleteDone: false,

  folderData: [],
  folderLoading: true,
  folderError: false,
  folderMessage: null,

  // ADD
  addFolderLoading: false,
  addFolderError: false,
  addFolderMessage: null,
  addFolderDone: false,
};

export const CurrentAffairsReducer = (state = initialState, action) => {
  switch (action.type) {
    // VIEW CURRENT AFFAIRS
    case CURRENTAFFAIRS: {
      return {
        ...state,
        loading: false,
        error: false,
        data: action.data,
        message: null,
      };
    }

    case CURRENTAFFAIRSSTATE: {
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        message: action.message,
      };
    }

    // ADD CURRENT AFFAIRS
    case ADDCURRENTAFFAIRS: {
      const { id, title, file, folder } = action;

      let data = [...state.data];
      const _findIndex = data.findIndex((item) => +item.ID === +folder);

      const newData = {
        ID: id,
        Title: title,
        File: file,
        Folder: folder,
      };

      data[_findIndex].Data.unshift(newData);
      const _sortedData = data.sort(
        (a, b) => new Date(a.Date) - new Date(b.Date)
      );

      return {
        ...state,
        addUpdateLoading: false,
        addUpdateError: false,
        data: _sortedData,
        addUpdateMessage: "Current Affairs Added",
        addUpdateDone: true,
      };
    }

    case ADDUPDATECURRENTAFFAIRSSTATE: {
      return {
        ...state,
        addUpdateLoading: action.loading,
        addUpdateError: action.error,
        addUpdateMessage: action.message,
        addUpdateDone: false,
      };
    }

    // UPDATE CURRENT AFFAIRS
    case UPDATECURRENTAFFAIRS: {
      let data = [...state.data];
      // const { id, title, file, date, _folder } = action
      const { id, title, file, folder, oldFolder } = action;

      const _findIndex = data.findIndex((item) => +item.ID === +oldFolder);

      const newData = {
        ID: id,
        Title: title,
        File: file,
        Folder: folder,
      };

      if (folder === oldFolder) {
        const _f = data[_findIndex].Data;

        const _findData = _f.findIndex((item) => +item.ID === +id);

        data[_findIndex].Data[_findData] = newData;
      } else {
        const _filteredData = data[_findIndex].Data.filter(
          (item) => +item.ID !== +id
        );

        data[_findIndex].Data = _filteredData;

        const _newIndex = data.findIndex((item) => +item.ID === +folder);
        data[_newIndex].Data.unshift(newData);
      }

      const _sortedData = data.sort(
        (a, b) => new Date(a.Date) - new Date(b.Date)
      );

      return {
        ...state,
        addUpdateLoading: false,
        addUpdateError: false,
        data: _sortedData,
        addUpdateMessage: "Current Affairs Updated",
        addUpdateDone: true,
      };
    }

    // DELETE CURRENT AFFAIRS STATE
    case DELETECURRENTAFFAIRSSTATE: {
      const { loading, error, message } = action;
      return {
        ...state,
        deleteLoading: loading,
        deleteError: error,
        deleteMessage: message,
        deleteDone: false,
      };
    }

    // DELETE CURRENT AFFAIRS FOLDER STATE
    case DELETECURRENTAFFAIRSFOLDERSTATE: {
      const { loading, error, message } = action;

      return {
        ...state,
        folderDeleteLoading: loading,
        folderDeleteError: error,
        folderDeleteMessage: message,
        folderDeleteDone: false,
      };
    }

    // DELETE CURRENT AFFAIRS FOLDER
    case DELETECURRENTAFFAIRSFOLDER: {
      let data = [...state.data];
      let folderData = [...state.folderData];
      const { id } = action;
      const filteredData = data.filter((item) => item.ID !== id);
      const filteredFolderData = folderData.filter((item) => item.ID !== id);

      return {
        ...state,
        folderDeleteLoading: false,
        folderDeleteError: false,
        data: filteredData,
        folderData: filteredFolderData,
        folderDeleteMessage: "Current Affairs Folder Deleted",
        folderDeleteDone: true,
      };
    }

    // DELETE CURRENT AFFAIRS
    case DELETECURRENTAFFAIRS: {
      let data = [...state.data];
      const { id } = action;

      const finalData = data.map((item) => {
        const _filteredData = item.Data.filter((dt) => dt.ID !== id);
        item["Data"] = _filteredData;
        return item;
      });

      return {
        ...state,
        deleteLoading: false,
        deleteError: false,
        data: finalData,
        deleteMessage: "Current Affairs Deleted",
        deleteDone: true,
      };
    }

    // VIEW CURRENT AFFAIRS FOLDER
    case CURRENTAFFAIRSFOLDER: {
      return {
        ...state,
        folderLoading: false,
        folderError: false,
        folderData: action.data,
        folderMessage: null,
      };
    }

    case CURRENTAFFAIRSFOLDERSTATE: {
      const { loading, error, message } = action;
      return {
        ...state,
        folderLoading: loading,
        folderError: error,
        folderMessage: message,
      };
    }

    // ADD CURRENT AFFAIRS FOLDER
    case ADDCURRENTAFFAIRSFOLDER: {
      // const { id, name, date, fullDate } = action
      const { id, name, fullDate } = action;

      const date = new Date(fullDate).toISOString();

      let data = [...state.data];
      let folderData = [...state.folderData];

      data.push({ ID: id, Date: date, Folder: name, Data: [] });
      const _sortedData = data.sort(
        (a, b) => new Date(a.Date) - new Date(b.Date)
      );

      folderData.push({ ID: id, Name: name, Date: date });
      const _foldersortedData = folderData.sort(
        (a, b) => new Date(a.Date) - new Date(b.Date)
      );

      return {
        ...state,
        addFolderLoading: false,
        addFolderError: false,
        folderData: _foldersortedData,
        data: _sortedData,
        addFolderMessage: "Current Affairs Folder Added",
        addFolderDone: true,
      };
    }

    case ADDCURRENTAFFAIRSFOLDERSTATE: {
      const { loading, error, message } = action;
      return {
        ...state,
        addFolderLoading: loading,
        addFolderError: error,
        addFolderMessage: message,
        addFolderDone: false,
      };
    }

    // LOGOUT
    case LOGOUT:
      return initialState;

    default:
      return { ...state };
  }
};
