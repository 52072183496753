import React, { useEffect, useState } from 'react';

// REDUX
import { useDispatch } from 'react-redux';
import { SeminarStateAction, SeminarAction } from '../../../Store/Action/SeminarAction';

// COMPONENT
import { SeminarTable } from '../../../Components/Common/Table/Seminar/SeminarTable';
import { SeminarDialog } from '../../../Components/Dialog/SeminarDialog';

export const SeminarScreen = () => {
    const [seminarDialogState, setseminarDialogState] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(SeminarStateAction({ loading: true }))
        dispatch(SeminarAction())

    }, [dispatch])


    return (
        <div className='table-padding'>
            <SeminarDialog state={seminarDialogState} changeState={setseminarDialogState} status={1} oldSlug={""} />
            <div className='mainHeader'>
                <p className='mainHeading'>Manage Seminar</p>
                <button className='custButton' onClick={() => setseminarDialogState(true)}>Add New</button>
            </div>
            <SeminarTable />
        </div>
    );
}
