import { token_api_interceptor } from "../Interceptor/Interceptor";
import { CourseWithBatchUrl } from "../Interceptor/Url";

// VIEW
export const COURSEWITHBATCHSTATE = "COURSEWITHBATCHSTATE";
export const COURSEWITHBATCH = "COURSEWITHBATCH";


export const CourseWithBatchStateAction = ({ loading, error }) => {
    return {
        type: COURSEWITHBATCHSTATE,
        loading, error,
        message: null
    }
}

export const CourseWithBatchAction = () => {
    return async (dispatch) => {
        token_api_interceptor.post(CourseWithBatchUrl)
            .then(res => {
                if (res.data.status) {
                    dispatch({
                        type: COURSEWITHBATCH,
                        data: res.data.data
                    })
                } else {
                    dispatch({
                        type: COURSEWITHBATCHSTATE,
                        loading: false, error: true,
                        message: res.data.message
                    })
                }
            })
            .catch(err => {
                dispatch({
                    type: COURSEWITHBATCHSTATE,
                    loading: false, error: true,
                    message: 'Something went wrong'
                })
            })
    };
};