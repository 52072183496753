import React, { useState, useEffect, useCallback } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { SeminarQueryRowComponent } from './SeminarQueryRowComponent';

import { FaSort } from 'react-icons/fa'

import { DropdownComponent } from '../../Inputs/DropdownComponent';
import { token_api_interceptor } from '../../../../Store/Interceptor/Interceptor';
import { SeminarQuery } from '../../../../Store/Interceptor/Url';

import {
    ExcelExport,
    ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
// import moment from 'moment';

export function SeminarQueryTable() {
    const [showData, setshowData] = useState([]);
    const [numbers, setnumbers] = useState([1]);
    const [indexValue, setindexValue] = useState(1);
    const [tableIndex, settableIndex] = useState(0)


    const [selectedSeminar, setselectedSeminar] = useState({})
    const [selectedSeminarStatus, setselectedSeminarStatus] = useState({})

    const [seminarStatusLoading, setseminarStatusLoading] = useState(true)
    const [seminarStatusError, setseminarStatusError] = useState(false)
    const [seminarStatusMessage, setseminarStatusMessage] = useState('')
    const [seminarStatusData, setseminarStatusData] = useState([])

    const [seminarLoading, setseminarLoading] = useState(true)
    const [seminarError, setseminarError] = useState(false)
    const [seminarMessage, setseminarMessage] = useState('')
    const [seminars, setseminars] = useState([])

    const [filteredSeminarsData, setfilteredSeminarsData] = useState([])

    const [seminarQueryLoading, setseminarQuerysLoading] = useState(true)
    const [seminarQueryError, setseminarQuerysError] = useState(false)
    const [seminarQueryMessage, setseminarQuerysMessage] = useState('')
    const [seminarQuerys, setseminarQuerys] = useState([])

    const [sortedByName, setsortedByName] = useState(false)
    const [sortedByDate, setsortedByDate] = useState(false)


    const getSeminarStatus = useCallback(
        () => {
            setseminarStatusLoading(true)
            token_api_interceptor.get(SeminarQuery + 'seminar-status')
                .then(res => {
                    if (res.data.status) {

                        setseminarStatusData([{ ID: 'All', Status: 'All' }, ...res.data.data])
                        setselectedSeminarStatus({ ID: 'All', Status: 'All' })
                        setseminarStatusError(false)

                    } else {
                        setseminarStatusError(true)
                        setseminarStatusMessage(res.data.message)
                    }
                }).catch(() => {
                    setseminarStatusError(true)
                    setseminarStatusMessage("Something went wrong")
                }).finally(() => setseminarStatusLoading(false))
        },
        [],
    )

    const getSeminars = useCallback(
        () => {
            setseminarLoading(true)
            token_api_interceptor.get(SeminarQuery + 'seminars')
                .then(res => {
                    if (res.data.status) {
                        setseminars(res.data.data)
                        setseminarError(false)

                    } else {
                        setseminarError(true)
                        setseminarMessage(res.data.message)
                    }
                }).catch(() => {
                    setseminarError(true)
                    setseminarMessage("Something went wrong")
                }).finally(() => setseminarLoading(false))
        },
        [],
    )

    useEffect(() => {

        getSeminarStatus()
        getSeminars()

        return () => {

        };
    }, [getSeminarStatus, getSeminars])

    useEffect(
        () => {
            if (selectedSeminar?.ID) {
                setfilteredSeminarsData([])
                setseminarQuerys([])
                setseminarQuerysLoading(true)
                token_api_interceptor.get(SeminarQuery + 'seminar/' + selectedSeminar.ID)
                    .then(res => {
                        if (res.data.status) {
                            setseminarQuerys(res.data.data)
                            setseminarQuerysError(false)

                        } else {
                            setseminarQuerysError(true)
                            setseminarMessage(res.data.message)
                        }
                    }).catch(() => {
                        setseminarQuerysError(true)
                        setseminarQuerysMessage("Something went wrong")
                    }).finally(() => setseminarQuerysLoading(false))
            }
        },
        [selectedSeminar],
    )

    useEffect(() => {
        if (selectedSeminarStatus?.ID && seminarQuerys.length) {
            if (selectedSeminarStatus?.ID === 'All') {
                let _data = seminarQuerys.map((item, index) => ({ ...item, index: index + 1 }))
                setfilteredSeminarsData(_data)
            } else {
                const _filter = seminarQuerys.filter(item => item.Status === selectedSeminarStatus.ID)
                let _data = _filter.map((item, index) => ({ ...item, index: index + 1 }))
                setfilteredSeminarsData(_data)
            }

        }

        return () => {

        };
    }, [selectedSeminarStatus, seminarQuerys])


    function range(start, end) {
        return Array(end - start + 1)
            .fill()
            .map((_, idx) => start + idx);
    }

    useEffect(() => {
        setshowData(filteredSeminarsData.slice((tableIndex - 1) * 10, tableIndex * 10));
    }, [filteredSeminarsData, tableIndex]);

    useEffect(() => {
        if (filteredSeminarsData.length && Math.ceil(filteredSeminarsData.length / 10) > indexValue * 5 - 5) {
            setnumbers(
                range(
                    indexValue * 5 - 4,
                    Math.ceil(
                        filteredSeminarsData.length > indexValue * 5 * 10
                            ? indexValue * 5
                            : filteredSeminarsData.length / 10
                    )
                )
            );
            settableIndex(indexValue * 5 - 4)
        }
    }, [indexValue, filteredSeminarsData.length]);

    useEffect(() => {
        setshowData(filteredSeminarsData.slice(0, 10));
        setnumbers(
            range(
                1,
                Math.ceil(filteredSeminarsData.length > 50 ? 5 : filteredSeminarsData.length / 10)
            )
        );
    }, [filteredSeminarsData]);

    const _export = React.useRef(null);

    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save();
        }
    };

    const sortDataByName = () => {
        if (sortedByName) {
            setfilteredSeminarsData([...filteredSeminarsData].reverse())
        } else {
            setfilteredSeminarsData([...filteredSeminarsData].sort((a, b) => a.Name.substring(0, 1).charCodeAt(0) - b.Name.substring(0, 1).charCodeAt(0)))
        }
        setsortedByName(true)
        setsortedByDate(false)
    }

    const sortDataByDate = () => {
        if (sortedByDate) {
            setfilteredSeminarsData([...filteredSeminarsData].reverse())
        } else {
            setfilteredSeminarsData([...filteredSeminarsData].sort((a, b) => new Date(a.CreatedAt) - new Date(b.CreatedAt)))
        }
        setsortedByDate(true)
        setsortedByName(false)
    }


    const column = [
        { title: 'Sr' },
        { title: 'Name', onClick: sortDataByName },
        { title: 'Mobile' },
        { title: 'Email' },
        { title: 'Date', onClick: sortDataByDate }, { title: 'Action' }]


    return (
        <div
            className="flex flex-col pt-2"
            style={{
                width: "100%",
                overflow: "hidden",
            }}
        >
            {seminarLoading ? <p>Seminar Loading...</p> : seminarError ? <p>{seminarMessage}</p> : <DropdownComponent items={seminars} selectBy="Title" changeSelectedItem={setselectedSeminar} selectedItem={selectedSeminar} title="Seminar" width={100} />}

            {seminarStatusLoading ? <></> : seminarStatusError ? <p>{seminarStatusMessage}</p> : <DropdownComponent items={seminarStatusData} selectBy="Status" changeSelectedItem={setselectedSeminarStatus} selectedItem={selectedSeminarStatus} title="Status" width={100} />}
            {!selectedSeminar?.ID ? <p>Seminar Not Selected</p> : seminarQueryLoading ? (
                <p>Loading</p>
            ) : seminarQueryError ? (
                <p>{seminarQueryMessage}</p>
            ) : (
                <>
                    <div className="flex justify-end mb-3">
                        <ExcelExport data={filteredSeminarsData} ref={_export}>
                            <ExcelExportColumn field="index" title="Sr. No." />
                            <ExcelExportColumn field="Name" title="Name" />
                            <ExcelExportColumn field="Mobile" title="Contact" />
                            <ExcelExportColumn field="Email" title="Email" />
                            <ExcelExportColumn field="CreatedAt" title="Date" />
                            <ExcelExportColumn field="SeminarStatus" title="Status" />
                        </ExcelExport>
                        <button className="btn btn-secondary px-4" onClick={excelExport}>
                            Export
                        </button>
                    </div>

                    <div className="overflow-x-auto sm:-mx-4 lg:-mx-6">
                        <div className="align-middle inline-block min-w-full sm:px-4 lg:px-6">
                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                {showData.length ?
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                {
                                                    column.map((item, index) => <th onClick={item?.onClick} key={index}
                                                        scope="col"
                                                        className={"flex-1  py-3 text-center text-base font-semibold text-gray-500 tracking-wider mx-2 ".concat(item?.onClick ? 'cursor-pointer' : '')}
                                                    >
                                                        <span className="flex items-center justify-center">
                                                            {item.title} <span>{item?.onClick ? <FaSort /> : ""}</span>
                                                        </span>
                                                    </th>
                                                    )
                                                }
                                            </tr>
                                        </thead>

                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {showData.map((dt, _i) => <SeminarQueryRowComponent statuses={seminarStatusData.slice(1)} _i={tableIndex * 10 - (9 - _i)} key={dt.ID} dt={dt} />
                                            )}
                                        </tbody>
                                    </table> : <div className='bg-gray-200 rounded-lg'>
                                        <p className='p-5 text-center text-gray-500 font-medium'>No Record available</p>
                                    </div>}

                            </div>
                        </div>
                    </div>

                    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                        <div className="flex-1 flex justify-between sm:hidden">
                            <p
                                onClick={() => { }}
                                style={{ marginBottom: 0 }}
                                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                            >
                                Previous
                            </p>
                            <p
                                style={{ marginBottom: 0 }}
                                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                            >
                                Next
                            </p>
                        </div>
                        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                            <div>
                                <p className="text-sm text-gray-700">
                                    Showing
                                    <span className="font-medium mx-1">
                                        {showData.length ? tableIndex * 10 - 9 : 0}
                                    </span>
                                    to
                                    <span className="font-medium mx-1">
                                        {tableIndex * 10 > filteredSeminarsData.length
                                            ? filteredSeminarsData.length
                                            : tableIndex * 10}
                                    </span>
                                    of
                                    <span className="font-medium mx-1"> {filteredSeminarsData.length}</span>
                                    results
                                </p>
                            </div>
                            <div>
                                <nav
                                    className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                                    aria-label="Pagination"
                                >
                                    <p
                                        style={{ marginBottom: 0, cursor: "pointer" }}
                                        className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                    >
                                        <span className="sr-only">Previous</span>
                                        <ChevronLeftIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                            onClick={() => {
                                                if (indexValue !== 1) {
                                                    setindexValue(indexValue - 1);
                                                }
                                            }}
                                        />
                                    </p>
                                    {numbers.map((data) => {
                                        return (
                                            <p
                                                onClick={() => {
                                                    settableIndex(data)
                                                }}
                                                key={data}
                                                style={{ marginBottom: 0, cursor: "pointer" }}
                                                className={
                                                    tableIndex === data
                                                        ? "z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                                                        : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                                                }
                                            >
                                                {data}
                                            </p>
                                        );
                                    })}
                                    <p
                                        style={{ marginBottom: 0, cursor: "pointer" }}
                                        className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                    >
                                        <span className="sr-only">Next</span>
                                        <ChevronRightIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                            onClick={() => {
                                                // changeIndex('increment')
                                                setindexValue(indexValue + 1);
                                            }}
                                        />
                                    </p>
                                </nav>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
