import { token_api_interceptor } from "../Interceptor/Interceptor";
import { Instructor } from "../Interceptor/Url";

// VIEW
export const INSTRUCTOR = "INSTRUCTOR";
export const INSTRUCTORSTATE = "INSTRUCTORSTATE";

// ADD
export const ADDINSTRUCTOR = "ADDINSTRUCTOR";
export const ADDUPDATEINSTRUCTORSTATE = "ADDUPDATEINSTRUCTORSTATE";

// UPDATE
export const UPDATEINSTRUCTOR = "UPDATEINSTRUCTOR";
export const UPDATEINSTRUCTORSTATE = "UPDATEINSTRUCTORSTATE";

// DELETE
export const DELETEINSTRUCTOR = "DELETEINSTRUCTOR";
export const DELETEINSTRUCTORSTATE = "DELETEINSTRUCTORSTATE";


export const InstructorStateAction = ({ loading, error }) => {
    return {
        type: INSTRUCTORSTATE,
        loading, error,
        message: null
    }
}

export const AddUpdateInstructorStateAction = ({ loading, error }) => {
    return {
        type: ADDUPDATEINSTRUCTORSTATE,
        loading, error,
        message: null
    }
}

export const InstructorAction = () => {
    return async (dispatch) => {
        await token_api_interceptor.post(Instructor)
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: INSTRUCTOR,
                        data: resp.data.data
                    });
                } else {
                    dispatch({
                        type: INSTRUCTORSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: INSTRUCTORSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const AddInstructorAction = ({ name, image, status }) => {
    const formData = new FormData()
    formData.append('name', name)
    formData.append('image', image)
    formData.append('status', status)
    return async (dispatch) => {
        await token_api_interceptor.post(Instructor + 'add', formData)
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: ADDINSTRUCTOR,
                        id: resp.data.id,
                        name, image: resp.data.image, status
                    });
                } else {
                    dispatch({
                        type: ADDUPDATEINSTRUCTORSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: ADDUPDATEINSTRUCTORSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const UpdateInstructorAction = ({ id, name, image, status }) => {

    const formData = new FormData()
    formData.append('id', id)
    formData.append('name', name)
    formData.append('image', image)
    formData.append('status', status)

    return async (dispatch) => {
        await token_api_interceptor.post(Instructor + 'update', formData)
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: UPDATEINSTRUCTOR,
                        id, name, image: resp.data.image, status
                    });
                } else {
                    dispatch({
                        type: ADDUPDATEINSTRUCTORSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: ADDUPDATEINSTRUCTORSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const DeleteInstructorStateAction = ({ loading, error }) => {
    return {
        type: DELETEINSTRUCTORSTATE,
        loading, error
    }
}

export const DeleteInstructorAction = ({ id }) => {
    return async (dispatch) => {
        await token_api_interceptor.post(Instructor + 'delete', { id })
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: DELETEINSTRUCTOR,
                        id
                    });
                } else {
                    dispatch({
                        type: DELETEINSTRUCTORSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: DELETEINSTRUCTORSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};