import { LOGOUT } from "../Action/AuthAction";
import { ADDCLASSNOTES, ADDUPDATECLASSNOTESSTATE, CLASSNOTES, CLASSNOTESSTATE, DELETECLASSNOTES, DELETECLASSNOTESSTATE, UPDATECLASSNOTES } from "../Action/ClassNotes";

const initialState = {
    data: [],
    loading: true,
    error: false,
    message: null,

    // ADD-UPDATE
    addUpdateLoading: false,
    addUpdateError: false,
    addUpdateMessage: null,
    addUpdateDone: false,

    // DELETE
    deleteLoading: false,
    deleteError: false,
    deleteMessage: null,
    deleteDone: false,
};

export const ClassNotesReducer = (state = initialState, action) => {
    switch (action.type) {

        // VIEW CLASS NOTES
        case CLASSNOTES: {
            return {
                ...state,
                loading: false,
                error: false,
                data: action.data,
                message: null
            };
        }

        case CLASSNOTESSTATE: {
            return {
                ...state,
                loading: action.loading,
                error: action.error,
                message: action.message

            };
        }

        // ADD CLASS NOTES
        case ADDCLASSNOTES: {
            const { id, title, file, course, courseName } = action
            let data = [...state.data];
            data.unshift({ ID: id, Title: title, File: file, CourseID: course, CourseName: courseName })

            return {
                ...state,
                addUpdateLoading: false,
                addUpdateError: false,
                data: data,
                addUpdateMessage: "Class Notes Added",
                addUpdateDone: true,
            };
        }

        case ADDUPDATECLASSNOTESSTATE: {
            return {
                ...state,
                addUpdateLoading: action.loading,
                addUpdateError: action.error,
                addUpdateMessage: action.message,
                addUpdateDone: false,

            };
        }

        // UPDATE CLASS NOTES
        case UPDATECLASSNOTES: {
            let data = [...state.data];
            const { id, title, file, course, courseName } = action
            let index = data.findIndex(item =>
                item.ID === id
            )
            data[index] = { ID: id, Title: title, File: file, CourseID: course, CourseName: courseName }
            return {
                ...state,
                addUpdateLoading: false,
                addUpdateError: false,
                data: data,
                addUpdateMessage: "Class Notes Updated",
                addUpdateDone: true,
            };
        }

        // DELETE CLASS NOTES STATE
        case DELETECLASSNOTESSTATE: {
            const { loading, error, message } = action
            return {
                ...state,
                deleteLoading: loading,
                deleteError: error,
                deleteMessage: message,
                deleteDone: false,

            };
        }

        // DELETE CLASS NOTES
        case DELETECLASSNOTES: {
            let data = [...state.data];
            const { id } = action
            const filteredData = data.filter(item => item.ID !== id)
            return {
                ...state,
                deleteLoading: false,
                deleteError: false,
                data: filteredData,
                deleteMessage: "Class Notes Deleted",
                deleteDone: true,
            };
        }

        // LOGOUT
        case LOGOUT:
            return initialState;

        default:
            return { ...state };
    }
};