import { token_api_interceptor } from "../Interceptor/Interceptor";
import { Query } from "../Interceptor/Url";

export const QUERY = "QUERY";
export const QUERYSTATE = "QUERYSTATE";

export const CHANGEQUERYSTATUS = "CHANGEQUERYSTATUS";
export const CHANGEQUERYSTATUSSTATE = "CHANGEQUERYSTATUSSTATE";

export const QueryStateAction = ({ loading, error }) => {
    return {
        type: QUERYSTATE,
        loading, error, message: null
    }
}

export const QueryAction = () => {
    return async (dispatch) => {
        await token_api_interceptor.post(Query)
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: QUERY,
                        data: resp.data.data
                    });
                } else {
                    dispatch({
                        type: QUERYSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: QUERYSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const ChangeQueryStatusStateAction = ({ loading, error }) => {
    return {
        type: CHANGEQUERYSTATUSSTATE,
        loading, error, message: null
    }
}

export const ChangeQueryStatusAction = ({ id, status }) => {
    return async (dispatch) => {
        await token_api_interceptor.post(Query + 'change-status', { id, status })
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: CHANGEQUERYSTATUS,
                        id, status
                    });
                } else {
                    dispatch({
                        type: CHANGEQUERYSTATUSSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: CHANGEQUERYSTATUSSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};