import { token_api_interceptor } from "../Interceptor/Interceptor";
import { UserAssignmentsUrl } from "../Interceptor/Url";

// VIEW
export const USERASSIGNMENTS = "USERASSIGNMENTS";
export const USERASSIGNMENTSSTATE = "USERASSIGNMENTSSTATE";

// ADD
export const ADDUSERASSIGNMENTS = "ADDUSERASSIGNMENTS";
export const UPDATEUSERASSIGNMENTSSTATE = "UPDATEUSERASSIGNMENTSSTATE";

// UPDATE
export const UPDATEUSERASSIGNMENTS = "UPDATEUSERASSIGNMENTS";

// DELETE
export const DELETEUSERASSIGNMENTS = "DELETEUSERASSIGNMENTS";
export const DELETEUSERASSIGNMENTSSTATE = "DELETEUSERASSIGNMENTSSTATE";

export const UserAssignmentsStateAction = ({ loading, error }) => {
    return {
        type: USERASSIGNMENTSSTATE,
        loading, error,
        message: null
    }
}

export const UserAssignmentsAction = ({ courseID }) => {
    return async (dispatch) => {
        await token_api_interceptor.post(UserAssignmentsUrl, { courseID })
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: USERASSIGNMENTS,
                        data: resp.data.data
                    });
                } else {
                    dispatch({
                        type: USERASSIGNMENTSSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: USERASSIGNMENTSSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const UpdateUserAssignmentsStateAction = ({ loading, error }) => {
    return {
        type: UPDATEUSERASSIGNMENTSSTATE,
        loading, error,
        message: null
    }
}

export const UpdateUserAssignmentsAction = () => {

    return ({
        type: UPDATEUSERASSIGNMENTS
    });
};