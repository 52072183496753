import React, { useState, useEffect } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { useSelector } from 'react-redux';
import { CourseScheduleRowComponent } from './CourseScheduleRowComponent';
import { Accordion, AccordionTab } from 'primereact/accordion';
// import moment from 'moment';

export function CourseScheduleTable() {
    const [showData, setshowData] = useState([]);
    const [numbers, setnumbers] = useState([1]);
    const [indexValue, setindexValue] = useState(1);
    const [tableIndex, settableIndex] = useState(0)
    const [showGroupingData, setshowGroupingData] = useState([])

    const { loading, error, message, data } = useSelector(state => state.courseSchedule)

    const column = ['Sr.', 'Course', 'Batch', 'StartOn', 'Duration', 'Type', 'Status', 'Action']

    function range(start, end) {
        return Array(end - start + 1)
            .fill()
            .map((_, idx) => start + idx);
    }

    useEffect(() => {
        setshowData(showGroupingData.slice((tableIndex - 1) * 10, tableIndex * 10));
    }, [showGroupingData, tableIndex]);

    useEffect(() => {
        if (data.length && Math.ceil(data.length / 10) > indexValue * 5 - 5) {


            const sortedData = data.sort((a, b) => a.CourseID - b.CourseID)
            let groupingData = []
            sortedData.forEach(item => {
                if (groupingData?.[groupingData.length - 1]?.CourseID !== item.CourseID) {
                    const filteredData = sortedData.filter(dt => dt.CourseID === item.CourseID)
                    groupingData.push({ title: item.CourseName, CourseID: item.CourseID, data: filteredData })
                }
            })
            setshowGroupingData(groupingData)


            setnumbers(
                range(
                    indexValue * 5 - 4,
                    Math.ceil(
                        groupingData.length > indexValue * 5 * 10
                            ? indexValue * 5
                            : groupingData.length / 10
                    )
                )
            );
            settableIndex(indexValue * 5 - 4)
        }
    }, [indexValue, data]);

    useEffect(() => {
        setshowData(showGroupingData.slice(0, 10));
        setnumbers(
            range(
                1,
                Math.ceil(showGroupingData.length > 50 ? 5 : showGroupingData.length / 10)
            )
        );
    }, [showGroupingData]);


    return (
        <div
            className="flex flex-col"
            style={{
                width: "100%",
                overflow: "hidden",
            }}
        >
            {loading ? (
                <p>Loading</p>
            ) : error ? (
                <p>{message}</p>
            ) : (
                <>
                    <div className="overflow-x-auto sm:-mx-4 lg:-mx-6">
                        <div className="align-middle inline-block min-w-full sm:px-4 lg:px-6">
                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                {
                                    <Accordion>
                                        {showData.map((dt, index) => {
                                            return <AccordionTab header={dt.title} key={index} >
                                                <table className="min-w-full divide-y divide-gray-200">
                                                    <thead className="bg-gray-50">
                                                        <tr>
                                                            {
                                                                column.map((item, index) => {
                                                                    return <th key={index}
                                                                        scope="col"
                                                                        className="flex-1 py-3 text-center text-base font-semibold text-gray-500 tracking-wider mx-2"
                                                                    >
                                                                        {item}
                                                                    </th>
                                                                })
                                                            }
                                                        </tr>
                                                    </thead>
                                                    <tbody className="bg-white divide-y divide-gray-200">
                                                        {
                                                            dt.data.map((item, ind) =>
                                                                <CourseScheduleRowComponent _i={tableIndex * 10 - (9 - ind)} key={item.ID} dt={item} />
                                                            )
                                                        }

                                                    </tbody>
                                                </table>
                                            </AccordionTab>
                                        })}
                                    </Accordion>
                                }

                            </div>
                        </div>
                    </div>

                    <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                        <div>
                            <p className="text-sm text-gray-700">
                                Showing
                                <span className="font-medium mx-1">
                                    {showGroupingData.length ? tableIndex * 10 - 9 : 0}
                                </span>
                                to
                                <span className="font-medium mx-1">
                                    {tableIndex * 10 > showGroupingData.length
                                        ? showGroupingData.length
                                        : tableIndex * 10}
                                </span>
                                of
                                <span className="font-medium mx-1"> {showGroupingData.length}</span>
                                results
                            </p>
                        </div>
                        <div>
                            <nav
                                className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                                aria-label="Pagination"
                            >
                                <p
                                    style={{ marginBottom: 0, cursor: "pointer" }}
                                    className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                >
                                    <span className="sr-only">Previous</span>
                                    <ChevronLeftIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                        onClick={() => {
                                            if (indexValue !== 1) {
                                                setindexValue(indexValue - 1);
                                            }
                                        }}
                                    />
                                </p>
                                {numbers.map((data) => {
                                    return (
                                        <p
                                            onClick={() => {
                                                settableIndex(data)
                                            }}
                                            key={data}
                                            style={{ marginBottom: 0, cursor: "pointer" }}
                                            className={
                                                tableIndex === data
                                                    ? "z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                                                    : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                                            }
                                        >
                                            {data}
                                        </p>
                                    );
                                })}
                                <p
                                    style={{ marginBottom: 0, cursor: "pointer" }}
                                    className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                >
                                    <span className="sr-only">Next</span>
                                    <ChevronRightIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                        onClick={() => {
                                            // changeIndex('increment')
                                            setindexValue(indexValue + 1);
                                        }}
                                    />
                                </p>
                            </nav>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
