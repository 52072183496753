import React from 'react'
import { InputText } from 'primereact/inputtext';
import './style.css'

export const InputComponent = ({ name, value, changeValue, number, suffix, mandatory = false, minLength = 0, nan = false, width = 130, suffWidth = 90, disabled = false }) => {
    return (
        <div className="p-fluid grid mb-2 text-sm">
            <div className="field col-12 md:col-4">
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon" style={{ width }}>
                        <span>{name} <span className='text-red-800'>{mandatory && '*'}</span></span>
                    </span>
                    {<InputText disabled={disabled} minLength={minLength} required={mandatory} id="inputgroup" value={value} onChange={(e) => {
                        if (e.target.value !== " ") {
                            if (number) {
                                if (!isNaN(e.target.value)) {
                                    changeValue(e.target.value)
                                }
                            } else {
                                nan ? isNaN(e.target.value) ? changeValue(e.target.value) : changeValue('') : changeValue(e.target.value)
                            }
                        }
                    }} placeholder={name} style={{ borderColor: mandatory && String(value).length < minLength ? '#ffa2a2' : '#ced4da' }} />}
                    {suffix && <span className="p-inputgroup-addon" style={{ width: suffWidth }}>
                        <span>{suffix}</span>
                    </span>}
                </div>
            </div>
        </div>
    )
}