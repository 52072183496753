import { LOGIN, LOGOUT, CLEARLOGIN, AUTHSTATE } from "../Action/AuthAction";

const initialState = {
    token: null,
    user: null,
    role: null,
    loading: false,
    pages: [],
    error: false,
    errorMessage: null
};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN: {
            const { token, user, role, pages, loading, error, errorMessage } = action
            
            return {
                ...state,
                token,
                user,
                role,
                pages,
                loading,
                error,
                errorMessage,
            };
        }
        case AUTHSTATE: {
            return {
                ...state,
                loading: action.loading,
                error: action.error
            };
        }
        case CLEARLOGIN: {
            return {
                loading: false,
                error: false,
                errorMessage: null
            };
        }
        case LOGOUT:
            return initialState;
        default:
            return { ...state };
    }
};