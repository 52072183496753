import React, { useRef } from 'react'

export const PdfInputComponent = ({ name, value, changeValue, changeUrl, mandatory = false, index = Math.random().toFixed(2) * 100, width = 130, disabled = false }) => {
    const fileRef = useRef()
    React.useEffect(() => {
        if (!value) {
            fileRef.current.value = ''
        }
    }, [value])

    return (
        <>
            <div onClick={() => {
                if (disabled) {
                    alert("Pdf can't be changed")
                }
            }} className="input-group mb-2 custom-input-img text-sm">
                <label className="input-group-text p-inputgroup-addon text-sm" htmlFor={`inputGroupFile${index}`} style={{ width: width }}>{name} <span className='text-red-800'>{mandatory && '*'}</span></label>
                <input disabled={disabled} accept="application/pdf" ref={fileRef} type="file" className="form-control" id={`inputGroupFile${index}`} style={{ padding: 12 }} onChange={(e) => {
                    if (e.target.files.length) {
                        if (changeUrl) {
                            changeUrl(URL.createObjectURL(e.target.files[0]))
                        }
                        changeValue(e.target.files[0])

                    }
                }} />
                <label className="form-control text-gray-500 cursor-pointer text-sm flex items-center" htmlFor={`inputGroupFile${index}`} style={{ padding: 12, borderColor: mandatory ? value || value?.name ? '#ced4da' : '#ffa2a2' : '#ced4da' }}>{value?.name || 'No File is Selected'}</label>
            </div>
        </>
    )
}