import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { UserAssignmentsTable } from '../../../Components/Common/Table/UserAssignments/UserAssignmentsTable';
import { CourseAction } from '../../../Store/Action/CourseAction';

export const UserAssignmentsScreen = () => {

    const dispatch = useDispatch()
    const { data } = useSelector(state => state.course)

    useEffect(() => {

        if (!data.length) {
            dispatch(CourseAction())
        }
    }, [dispatch, data])

    return (
        <div className='table-padding'>
            <div className='mainHeader'>
                <p className='mainHeading'>User Assignments</p>
            </div>
            <UserAssignmentsTable />
        </div>
    );
}
