import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SliderTable } from '../../../Components/Common/Table/Slider/SliderTable';
import { SliderDialog } from '../../../Components/Dialog/SliderDialog';
import { SliderStateAction, SliderAction } from '../../../Store/Action/SliderAction';

export const SliderScreen = () => {
    const [sliderDialogState, setsliderDialogState] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(SliderStateAction({ loading: true }))
        dispatch(SliderAction())
    }, [dispatch])


    return (
        <div className='table-padding'>
            <SliderDialog state={sliderDialogState} changeState={setsliderDialogState} status={1} />
            <div className='mainHeader'>
                <p className='mainHeading'>Manage Slider</p>
                <button className='custButton' onClick={() => setsliderDialogState(true)}>Add New</button>
            </div>
            <SliderTable />
        </div>
    );
}
