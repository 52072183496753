import React, { useEffect } from 'react'
import { InstallmentTrackTable } from '../../../Components/Common/Table/InstallmentTrack/InstallmentTrackTable'
import { useDispatch } from 'react-redux'
import { InstallmentTrackAction, InstallmentTrackStateAction } from '../../../Store/Action/InstallmentTrackAction'
import { CourseAction, CourseStateAction } from '../../../Store/Action/CourseAction'

export const InstallmentTrackScreen = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(InstallmentTrackStateAction({ loading: true }))
        dispatch(InstallmentTrackAction())
        dispatch(CourseStateAction({ loading: true }))
        dispatch(CourseAction())

        return () => {

        }
    }, [dispatch])


    return (
        <div className='table-padding'>
            <div className='mainHeader'>
                <p className='mainHeading'>Installments</p>
            </div>
            <InstallmentTrackTable />
        </div>
    )
}