import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from 'primereact/button';
import { ChangeQueryStatusAction, ChangeQueryStatusStateAction } from '../../../../Store/Action/Query';

export const QueryRowComponent = ({ _i, dt }) => {
    const dataKeys = ['Name', 'Mobile', 'Message', 'Action']

    const { updateLoading } = useSelector(state => state.query)
    const dispatch = useDispatch()

    const changeStatus = () => {
        dispatch(ChangeQueryStatusStateAction({ loading: true }))
        dispatch(ChangeQueryStatusAction({ id: dt.ID, status: +dt.Status === 1 ? 0 : 1 }))
    }

    return (
        <tr style={{ height: 60 }}>
            <td className="px-3 whitespace-nowrap">
                <div className="text-md font-medium text-gray-600 text-center">
                    <span>{_i}</span>
                </div>
            </td>
            {
                dataKeys.map((item, ind) => {
                    if (item === 'Action') {
                        return <td key={ind} className="p-3">
                            <div className="text-sm text-gray-900 text-center" style={{ minWidth: 150 }}>
                                <Button onClick={changeStatus} label={dt.Status === 1 ? 'Solved' : 'Un-Solved'} iconPos="right" loading={updateLoading} />
                            </div>
                        </td>

                    }
                    return <td key={ind} className="p-3">
                        <div className="text-sm text-gray-900 text-center" style={{ minWidth: 150 }}>
                            <span className={item === 'Message' ? 'text-gray-500 ' : ''}>{dt[item]}</span>
                        </div>
                    </td>
                })
            }
        </tr>
    )
}