import React, { useState, useEffect } from 'react'

// COMPONENTS
import { TextEditorComponent } from '../../../Components/Common/Inputs/TextEditorComponent'
import { InputComponent } from '../../../Components/Common/Inputs/InputComponent'
import { DropdownComponent } from '../../../Components/Common/Inputs/DropdownComponent'

// EXTERNAL LIBRARIES
import { ProgressSpinner } from 'primereact/progressspinner';
import { useLocation } from 'react-router-dom'

// REDUX
import { useDispatch, useSelector } from 'react-redux'

// ACTIONS
import { CourseWithBatchAction, CourseWithBatchStateAction } from '../../../Store/Action/CourseWithBatchAction'
import { AddLectureAction, AddUpdateLectureStateAction, UpdateLectureAction } from '../../../Store/Action/LectureAction'
import { SubjectAction, SubjectStateAction } from '../../../Store/Action/SubjectAction'


// CONSTANT
import { LectureUrl } from '../../../Store/Interceptor/Url'

// INTERCEPTOR
import { token_api_interceptor } from '../../../Store/Interceptor/Interceptor'

export const AddUpdateLectureScreen = () => {

    const location = useLocation()

    const dispatch = useDispatch()

    const { addUpdateLoading, addUpdateError, addUpdateMessage, addUpdateDone } = useSelector(state => state.lecture)

    const courseWithBatchLoading = useSelector(state => state.courseWithBatch.loading)
    const courseWithBatcherror = useSelector(state => state.courseWithBatch.error)
    const courseWithBatchmessage = useSelector(state => state.courseWithBatch.message)
    const courseWithBatchdata = useSelector(state => state.courseWithBatch.data)

    const subjectLoading = useSelector(state => state.subject.loading)
    const subjecterror = useSelector(state => state.subject.error)
    const subjectmessage = useSelector(state => state.subject.message)
    const subjectdata = useSelector(state => state.subject.data)

    const leftInfowidth = 80

    const [title, settitle] = useState('')
    const [selectedCourse, setselectedCourse] = useState({})
    const [selectedBatch, setselectedBatch] = useState({})
    const [description, setdescription] = useState('');

    const [submitLoading, setsubmitLoading] = useState(false)
    // const [status, setstatus] = useState(0)

    const [updateLoading, setupdateLoading] = useState(false)
    const [update, setupdate] = useState(false)
    const [updateError, setupdateError] = useState('')
    const [updateErrorState, setupdateErrorState] = useState(false)
    const [id, setid] = useState('')
    const [subjectId, setsubjectId] = useState({})
    const [deleteVideoLoading, setdeleteVideoLoading] = useState(false)

    const emptyData = () => {
        settitle('')
        setdescription('')
        setselectedCourse({})
        setselectedBatch({})
        setsubmitLoading(false)
    }

    useEffect(() => {
        dispatch(CourseWithBatchStateAction({ loading: true, error: false }))
        dispatch(CourseWithBatchAction())
        dispatch(SubjectStateAction({ loading: true, error: false }))
        dispatch(SubjectAction())

        return () => { }
    }, [dispatch])

    useEffect(() => {
        if (addUpdateDone) {
            alert(addUpdateMessage)

            if (!update) {
                emptyData()
            }

            dispatch(AddUpdateLectureStateAction({ loading: false, error: false }))
        }
        if (addUpdateError) {
            alert(addUpdateMessage)
        }
    }, [addUpdateDone, addUpdateError, addUpdateMessage, dispatch, update])


    useEffect(() => {
        if (location.search.includes('key')) {
            const key = location.search.slice(5)
            setupdate(true)
            setid(key)

            if (key.length && courseWithBatchdata.length && !courseWithBatchLoading && !subjectLoading) {

                setupdateLoading(true)
                token_api_interceptor.post(LectureUrl + 'edit', { id: key }).then(res => {
                    if (res.data.status) {
                        const { Title, CourseID, BatchID, Description, Subject } = res.data.data
                        const _subject = subjectdata.find(item => +item.ID === +Subject)
                        settitle(Title)
                        setdescription(Description)
                        setsubjectId(_subject)

                        const currentCourse = courseWithBatchdata.find(dt => Number(dt.ID) === Number(CourseID))
                        setselectedCourse(currentCourse)
                        setselectedBatch(currentCourse?.batch?.find(dt => Number(dt.ID) === Number(BatchID)))

                    } else {
                        setupdateError(res.data.message)
                        setupdateErrorState(true)
                    }
                }).catch(err => {
                    setupdateError('Something went wrong')
                    setupdateErrorState(true)
                }).finally(() => setupdateLoading(false))
            }
        } else {
            emptyData()
        }

        return () => {

        }
        // eslint-disable-next-line
    }, [location.search, courseWithBatchdata.length, subjectLoading])


    useEffect(() => {
        if (!update) {
            setselectedBatch({})
        }
        return () => { }
    }, [selectedCourse, update])


    const onSubmit = () => {
        if (title.length === 0 || !subjectId?.ID) {
            alert('Invalid Title')
        } else if (selectedBatch?.ID) {
            dispatch(AddUpdateLectureStateAction({ loading: true }))
            if (update) {
                dispatch(UpdateLectureAction({ id, title, description, subject: subjectId.ID }))
            } else {
                dispatch(AddLectureAction({ id: selectedBatch.ID, title, description, subject: subjectId.ID }))
            }

        } else {
            alert('Course and Batch should be selected')
        }
    }

    const DeleteZoomVideo = () => {
        setdeleteVideoLoading(true)
        token_api_interceptor.post(LectureUrl + 'delete-zoom-video', { id })
            .then(res => alert(res.data.message))
            .catch(err => alert('Somthing went wrong'))
            .finally(() => setdeleteVideoLoading(false))
    }

    return (courseWithBatchLoading || updateLoading || subjectLoading ? <div className='p-3 pt-5 sm:p-5 main-container'>
        <p>Loading...</p>
    </div > : courseWithBatcherror || updateErrorState || subjecterror ? <div className='p-3 pt-5 sm:p-5 main-container'>
        <p>{updateError || courseWithBatchmessage || subjectmessage}</p>
    </div >
        :
        <div className='p-3 pt-5 sm:p-5 main-container'>
            <p className='mainHeading mb-3'>{update ? 'Update' : 'Add'} Lecture</p>
            <InputComponent width={leftInfowidth} name='Title' value={title} changeValue={settitle} />
            <DropdownComponent disabled={update} width={leftInfowidth} changeSelectedItem={setselectedCourse} items={courseWithBatchdata} selectBy="Title" selectedItem={selectedCourse} title="Course" />
            <DropdownComponent disabled={update} width={leftInfowidth} changeSelectedItem={setselectedBatch} items={selectedCourse?.batch || []} selectBy="Batch" selectedItem={selectedBatch} title="Batch" />
            <DropdownComponent width={leftInfowidth} changeSelectedItem={setsubjectId} items={subjectdata} selectBy="Title" selectedItem={subjectId} title="Subject" />

            <TextEditorComponent value={description} changeValue={setdescription} name="Description" width={leftInfowidth} />
            {addUpdateLoading || deleteVideoLoading ? <div className='flex justify-center'>
                <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s" />
            </div> : <div className='flex'>
                {update && <button className='submitButton mt-2 flex justify-center items-center' onClick={DeleteZoomVideo}>Delete Zoom Video</button>}
                {update && <div style={{ width: 30 }}></div>}
                <button disabled={submitLoading} className='submitButton mt-2 flex justify-center items-center' onClick={onSubmit}>{submitLoading ? 'Submitting' : 'Submit'}</button>
            </div>}

        </div>
    )
}