import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { breakpoints } from "../../Helpers/breakpoints";
import ReactToPdf from "react-to-pdf";
import moment from "moment";

export const TransactionPdfDialog = ({ state, changeState, data }) => {
  const renderFooter = () => {
    return (
      <div>
        <Button
          label="Ok"
          icon="pi pi-check"
          onClick={hideDialog}
          className="p-button-text"
        />
      </div>
    );
  };

  const hideDialog = () => {
    changeState(false);
  };

  const RowContainer = ({ title, detail }) => {
    return (
      <div
        className="flex justify-between w-full text-sm mb-1"
        style={{ color: "#b7b7b7" }}
      >
        <p>{title} :</p>
        <p>{detail}</p>
      </div>
    );
  };

  const paymentDone = data.Status === "Payment Done";

  return (
    <Dialog
      draggable={false}
      header="Transaction"
      visible={state}
      onHide={hideDialog}
      style={{ minWidth: "50vw", margin: 10 }}
      breakpoints={breakpoints}
      footer={renderFooter()}
    >
      <ReactToPdf filename={data.Name + "_" + data.Course + ".pdf"}>
        {({ toPdf, targetRef }) => (
          <div className=" h-full payment-container">
            <div
              className="w-full m-auto px-3 bg-white flex flex-col items-center rounded-xl self-center"
              id="payments-boxshadow"
              ref={targetRef}
            >
              <p
                className={"text-xl w-full md:text-3xl font-semibold text-center mt-4 ".concat(
                  paymentDone
                    ? "text-green-600 bg-green-100"
                    : "text-red-600 bg-red-100 "
                )}
              >
                {data.Status}
              </p>
              <p className="text-gray-700 mt-2 mb-4">For</p>

              <p
                className={
                  "text-center mt-1 w-full px-4 py-2 text-xs rounded-md text-green-600 bg-green-100"
                }
              >
                {data.Course}
              </p>
              <div
                style={{
                  borderBottom: "1px dashed #b7b7b7",
                  width: "100%",
                  margin: "10px 0px 30px 0px",
                }}
              />

              <RowContainer title="Name" detail={`${data.Name}`} />
              <RowContainer title="Mobile" detail={`${data.Mobile}`} />

              <RowContainer title="Amount" detail={`Rs. ${data.Amount}`} />
              <RowContainer
                title="Date"
                detail={moment(data.CurrDate).format("DD-MM-YYYY LT")}
              />

              <p className="text-center mt-3 text-gray-700 text-lg font-bold">
                Naman Sharma IAS Academy
              </p>
              <p className="text-center text-sm mt-1 text-gray-600">
                SCO. NO- 58 (second floor), Sector 32 C, Chandigarh-160030
                <br />
                Phone no: +918437686541
              </p>
              <p className="text-sm mt-4 text-red-600">
                The fee is non-refundable
              </p>
              <button
                type="button"
                className="bt-cust px-6 py-2.5 mt-4 bg-gray-200 text-gray-700 font-medium text-xs leading-tight rounded shadow-md hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg transition-all duration-150 ease-in-out print-btn"
                id="print-btn"
                onClick={toPdf}
              >
                Download
              </button>
            </div>
          </div>
        )}
      </ReactToPdf>
    </Dialog>
  );
};
