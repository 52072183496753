import { token_api_interceptor } from "../Interceptor/Interceptor";
import { YouTubeVideo } from "../Interceptor/Url";

// VIEW
export const YOUTUBE = "YOUTUBE";
export const YOUTUBESTATE = "YOUTUBESTATE";

// ADD
export const ADDYOUTUBE = "ADDYOUTUBE";
export const ADDUPDATEYOUTUBESTATE = "ADDUPDATEYOUTUBESTATE";

// UPDATE
export const UPDATEYOUTUBE = "UPDATEYOUTUBE";
export const UPDATEYOUTUBESTATE = "UPDATEYOUTUBESTATE";

// DELETE
export const DELETEYOUTUBE = "DELETEYOUTUBE";
export const DELETEYOUTUBESTATE = "DELETEYOUTUBESTATE";


export const YouTubeStateAction = ({ loading, error }) => {
    return {
        type: YOUTUBESTATE,
        loading, error,
        message: null
    }
}

export const AddUpdateYouTubeStateAction = ({ loading, error }) => {
    return {
        type: ADDUPDATEYOUTUBESTATE,
        loading, error,
        message: null
    }
}

export const YouTubeAction = () => {
    return async (dispatch) => {
        await token_api_interceptor.post(YouTubeVideo)
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: YOUTUBE,
                        data: resp.data.data
                    });
                } else {
                    dispatch({
                        type: YOUTUBESTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: YOUTUBESTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const AddYouTubeAction = ({ code, title, status, image }) => {

    const formData = new FormData()
    formData.append('code', code)
    formData.append('title', title)
    formData.append('status', status)
    formData.append('image', image)

    return async (dispatch) => {
        await token_api_interceptor.post(YouTubeVideo + 'add', formData)
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: ADDYOUTUBE,
                        id: resp.data.id,
                        code, title, status, image: resp.data.image
                    });
                } else {
                    dispatch({
                        type: ADDUPDATEYOUTUBESTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: ADDUPDATEYOUTUBESTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const UpdateYouTubeAction = ({ id, code, title, status, image }) => {

    const formData = new FormData()
    formData.append('id', id)
    formData.append('code', code)
    formData.append('title', title)
    formData.append('status', status)
    formData.append('image', image)

    return async (dispatch) => {
        await token_api_interceptor.post(YouTubeVideo + 'update', formData)
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: UPDATEYOUTUBE,
                        id, code, title, status, image: resp.data.image
                    });
                } else {
                    dispatch({
                        type: ADDUPDATEYOUTUBESTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: ADDUPDATEYOUTUBESTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const DeleteYoutubeStateAction = ({ loading, error }) => {
    return {
        type: DELETEYOUTUBESTATE,
        loading, error
    }
}

export const DeleteYouTubeAction = ({ id }) => {
    return async (dispatch) => {
        await token_api_interceptor.post(YouTubeVideo + 'delete', { id })
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: DELETEYOUTUBE,
                        id
                    });
                } else {
                    dispatch({
                        type: DELETEYOUTUBESTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: DELETEYOUTUBESTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};