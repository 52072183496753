import axios from "axios";
import { auth_base_url, base_url } from "./Url";

const auth_api_interceptor = axios.create({
    baseURL: auth_base_url,
})

const token_api_interceptor = axios.create({
    baseURL: base_url,
    timeout: 1200000,
})

export { auth_api_interceptor, token_api_interceptor }