import { SESSIONEXPIRED } from "../Action/SessionExpireAction";

const initialState = {
    sessionExpire: false
};

export const SessionExpireReducer = (state = initialState, action) => {
    switch (action.type) {

        // REMOVE SESSION MESSAGE
        case SESSIONEXPIRED:
            return {
                sessionExpire: action.status
            };

        default:
            return { ...state };
    }
};