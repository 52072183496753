import React, { useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { InstructorDialog } from '../../Dialog/InstructorDialog';
import { GrFormAdd } from 'react-icons/gr'
import { CourseTagDialog } from '../../Dialog/CourseTagDialog';
import { shortString } from '../../../Helpers/ShortString';

export const DropdownComponent = ({ selectedItem, changeSelectedItem, items, selectBy, title, mandatory = false, width = 130, disabled = false }) => {
    const [dialogState, setdialogState] = useState(false)
    const [addMoreState, setaddMoreState] = useState(false)

    useEffect(() => {
        switch (title) {
            case 'Instructor':
                setaddMoreState(true)
                break;
            case 'Course Tag':
                setaddMoreState(true)
                break;

            default:
                setaddMoreState(false)
                break;
        }

        return () => { }
    }, [title])


    return (
        <div className="p-fluid grid mb-2 text-sm">
            <div className="field col-12 md:col-4">
                {title === 'Instructor' ? <InstructorDialog state={dialogState} changeState={setdialogState} status={1} oldname="" /> : title === 'Course Tag' ? <CourseTagDialog state={dialogState} changeState={setdialogState} status={1} title="" /> : <></>}
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon" style={{ width }}>
                        {title} <span className='text-red-800'>{mandatory && '*'}</span>
                    </span>
                    <Dropdown disabled={disabled} required={mandatory} value={selectedItem} options={items} onChange={(item) => {
                        changeSelectedItem(item.target.value)
                    }} optionLabel={selectBy} placeholder={shortString({ str: "Select " + title, len: 12 })} style={{ borderColor: mandatory ? (selectedItem?.ID || selectedItem?.title) ? '#ced4da' : '#ffa2a2' : '#ced4da' }} />
                    {addMoreState && <div onClick={() => setdialogState(true)} className='flex justify-center items-center bg-green-500 hover:bg-green-400 transition-all duration-300 aspect-square w-11 rounded-r-md cursor-pointer'>
                        <GrFormAdd size={20} color="#FFF" />
                    </div>}
                </div>
            </div>
        </div>
    )
}