import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BatchTable } from '../../../Components/Common/Table/Batch/BatchTable';
import { BatchDialog } from '../../../Components/Dialog/BatchDialog';
import { BatchAction, BatchStateAction } from '../../../Store/Action/BatchAction';

export const BatchScreen = () => {

    const [dialogState, setdialogState] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(BatchStateAction({ loading: true }))
        dispatch(BatchAction())
    }, [dispatch])

    return (
        <div className='table-padding'>
            <BatchDialog state={dialogState} changeState={setdialogState} status={1} oldBatch="" oldsubtitle="" />
            <div className='mainHeader'>
                <p className='mainHeading'>Manage Batch</p>
                <button className='custButton' onClick={() => setdialogState(true)}>Add New</button>
            </div>
            <BatchTable />
        </div>
    )
}