import React, { useEffect, useState } from 'react';
import './style.css';
import { useDispatch } from 'react-redux';
import { CourseTagAction, CourseTagStateAction } from '../../../Store/Action/CourseTagAction';
import { CourseTagTable } from '../../../Components/Common/Table/CourseTag/CourseTagTable';
import { CourseTagDialog } from '../../../Components/Dialog/CourseTagDialog';

export const CourseTagScreen = () => {
    const [courseTagDialogState, setcourseTagDialogState] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(CourseTagStateAction({ loading: true }))
        dispatch(CourseTagAction())
    }, [dispatch])


    return (
        <div className='table-padding'>
            <CourseTagDialog state={courseTagDialogState} changeState={setcourseTagDialogState} status={1} title="" />
            <div className='mainHeader'>
                <p className='mainHeading'>Manage Course Tag</p>
                <button className='custButton' onClick={() => setcourseTagDialogState(true)}>Add New</button>
            </div>
            <CourseTagTable />
        </div>
    );
}
