import { token_api_interceptor } from "../Interceptor/Interceptor";
import { Seminar } from "../Interceptor/Url";

// VIEW
export const SEMINAR = "SEMINAR";
export const SEMINARSTATE = "SEMINARSTATE";

// ADD
export const ADDSEMINAR = "ADDSEMINAR";
export const ADDUPDATESEMINARSTATE = "ADDUPDATESEMINARSTATE";

// UPDATE
export const UPDATESEMINAR = "UPDATESEMINAR";
export const UPDATESEMINARSTATE = "UPDATESEMINARSTATE";

// DELETE
export const DELETESEMINAR = "DELETESEMINAR";
export const DELETESEMINARSTATE = "DELETESEMINARSTATE";


export const SeminarStateAction = ({ loading, error }) => {
    return {
        type: SEMINARSTATE,
        loading, error,
        message: null
    }
}

export const AddUpdateSeminarStateAction = ({ loading, error }) => {
    return {
        type: ADDUPDATESEMINARSTATE,
        loading, error,
        message: null
    }
}

export const SeminarAction = () => {
    return async (dispatch) => {
        await token_api_interceptor.post(Seminar)
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: SEMINAR,
                        data: resp.data.data
                    });
                } else {
                    dispatch({
                        type: SEMINARSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: SEMINARSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const AddSeminarAction = ({ image, slug, status, title, eventDate }) => {

    const formData = new FormData()

    formData.append('title', title)
    formData.append('slug', slug)
    formData.append('image', image)
    formData.append('status', status)
    formData.append('eventDate', eventDate)

    return async (dispatch) => {
        await token_api_interceptor.post(Seminar + 'add', formData)
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: ADDSEMINAR,
                        id: resp.data.id,
                        title,
                        slug,
                        image: resp.data.image, status, eventDate
                    });
                } else {
                    dispatch({
                        type: ADDUPDATESEMINARSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: ADDUPDATESEMINARSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const UpdateSeminarAction = ({ id, slug, image, status, title, eventDate }) => {

    const formData = new FormData()
    formData.append('id', id)
    formData.append('image', image)
    formData.append('slug', slug)
    formData.append('title', title)
    formData.append('eventDate', eventDate)
    formData.append('status', status)

    return async (dispatch) => {
        await token_api_interceptor.post(Seminar + 'update', formData)
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: UPDATESEMINAR,
                        id, image: resp.data.image, status, title, eventDate, slug
                    });
                } else {
                    dispatch({
                        type: ADDUPDATESEMINARSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: ADDUPDATESEMINARSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const DeleteSeminarStateAction = ({ loading, error }) => {
    return {
        type: DELETESEMINARSTATE,
        loading, error
    }
}

export const DeleteSeminarAction = ({ id }) => {

    return async (dispatch) => {
        await token_api_interceptor.post(Seminar + 'delete', { id })
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: DELETESEMINAR,
                        id
                    });
                } else {
                    dispatch({
                        type: DELETESEMINARSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: DELETESEMINARSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};