import React from 'react'
import { MdOutlineEdit, MdDelete } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { DeleteAlertComponent } from '../../Alert/DeleteAlertComponent'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { DeleteAssignmentsAction, DeleteAssignmentsStateAction } from '../../../../Store/Action/AssignmentsAction'

export const AssignmentsRowComponent = ({ _i, dt }) => {
    const dataKeys = ['Title', 'CourseName', 'Marks', 'MinMarks', 'Date', 'Action']
    const navigate = useNavigate()
    const [alertState, setalertState] = useState(false)
    const { deleteLoading, deleteError, deleteMessage, deleteDone } = useSelector(state => state.assignment)

    return (
        <tr>
            <td className="px-3 py-4 whitespace-nowrap">
                <div className="text-md font-medium text-gray-600 text-center">
                    <span>{_i}</span>
                </div>
            </td>
            <DeleteAlertComponent state={alertState} changeState={setalertState} id={dt.ID} data={{ deleteLoading, deleteError, deleteMessage, deleteDone }} deleteNow={DeleteAssignmentsAction} deleteState={DeleteAssignmentsStateAction} />
            {
                dataKeys.map((item, ind) => {
                    if (item === 'Date') {
                        return <td key={ind} className="px-3 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900 text-center">
                                <span>{moment(dt[item]).format('MMM, Do YY')}</span>
                            </div>
                        </td>
                    } else if (item === 'Action') {
                        const hw = { height: 35, width: 35 }
                        return <td key={ind} className="px-3 py-4 whitespace-nowrap">
                            <div className="flex justify-center">
                                <div onClick={() => {
                                    navigate({
                                        pathname: '/assignment',
                                        search: 'key=' + dt.ID
                                    })
                                }} className='bg-yellow-500 transition-all duration-300 hover:bg-yellow-800 cursor-pointer flex justify-center items-center rounded-lg' style={hw}>
                                    <MdOutlineEdit color='white' size={18} />
                                </div>
                                <div onClick={() => { setalertState(true) }} className='bg-red-600 transition-all duration-300 hover:bg-red-900 cursor-pointer ml-2 flex justify-center items-center rounded-lg' style={hw}>
                                    <MdDelete color='white' size={18} />
                                </div>
                            </div>
                        </td>

                    }
                    return <td key={ind} className="px-3 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900 text-center">
                            <span>{dt[item]}</span>
                        </div>
                    </td>

                })
            }
        </tr>
    )
}