import { LOGOUT } from "../Action/AuthAction";
import { ADDLECTURE, ADDUPDATELECTURESTATE, DELETELECTURE, DELETELECTURESTATE, LECTURE, LECTURESTATE, UPDATELECTURE } from "../Action/LectureAction";

const initialState = {
    data: [],
    loading: true,
    error: false,
    message: null,

    // ADD-UPDATE
    addUpdateLoading: false,
    addUpdateError: false,
    addUpdateMessage: null,
    addUpdateDone: false,

    // DELETE
    deleteLoading: false,
    deleteError: false,
    deleteMessage: null,
    deleteDone: false,
};

export const LectureReducer = (state = initialState, action) => {
    switch (action.type) {

        // VIEW LECTURES
        case LECTURE: {
            return {
                ...state,
                loading: false,
                error: false,
                data: action.data,
                message: null
            };
        }

        case LECTURESTATE: {
            return {
                ...state,
                loading: action.loading,
                error: action.error,
                message: action.message

            };
        }


        // ADD LECTURE
        case ADDLECTURE: {
            const { id, title, batchID, description } = action
            let data = [...state.data];
            data.push({ ID: id, Title: title, BatchID: batchID, Description: description })
            return {
                ...state,
                addUpdateLoading: false,
                addUpdateError: false,
                data: data,
                addUpdateMessage: "Lecture Added",
                addUpdateDone: true,
            };
        }

        case ADDUPDATELECTURESTATE: {
            return {
                ...state,
                addUpdateLoading: action.loading,
                addUpdateError: action.error,
                addUpdateMessage: action.message,
                addUpdateDone: false,

            };
        }

        // UPDATE LECTURE
        case UPDATELECTURE: {

            let data = [...state.data];
            const { id, title, description } = action

            const filteredData = data.map(dt => {
                const finalData = [...dt.data]

                let index = finalData.findIndex(item =>
                    Number(item.ID) === Number(id)
                )
                finalData[index].Title = title
                finalData[index].Description = description

                return {
                    ...dt,
                    data: finalData
                }
            })

            return {
                ...state,
                addUpdateLoading: false,
                addUpdateError: false,
                data: filteredData,
                addUpdateMessage: "Lecture Updated",
                addUpdateDone: true,
            };
        }

        // DELETE LECTURE STATE
        case DELETELECTURESTATE: {
            const { loading, error, message } = action
            return {
                ...state,
                deleteLoading: loading,
                deleteError: error,
                deleteMessage: message,
                deleteDone: false,

            };
        }

        // DELETE LECTURE
        case DELETELECTURE: {
            let data = [...state.data];
            const { id } = action
            const filteredData = data.map(dt => {
                const finalData = { ...dt }
                const f_data = dt.data.filter(item => item.ID !== id)
                finalData['data'] = f_data

                return finalData
            })

            return {
                ...state,
                deleteLoading: false,
                deleteError: false,
                data: filteredData,
                deleteMessage: "Lecture Deleted",
                deleteDone: true,
            };
        }



        // LOGOUT
        case LOGOUT:
            return initialState;

        default:
            return { ...state };
    }
};