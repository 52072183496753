import React, { useState, useMemo, useEffect } from 'react'
import { DropdownComponent } from '../../../Components/Common/Inputs/DropdownComponent'
import { InputComponent } from '../../../Components/Common/Inputs/InputComponent'
import { useDispatch, useSelector } from 'react-redux'
import { ProgressSpinner } from 'primereact/progressspinner';

import { CourseWithBatchAction, CourseWithBatchStateAction } from '../../../Store/Action/CourseWithBatchAction'
import { ManualBookSeatAction, ManualBookSeatStateAction } from '../../../Store/Action/ManualBookSeat';

export const ManualBookSeatScreen = () => {

    const dispatch = useDispatch()

    const { loading, error, message, addDone } = useSelector(state => state.manualBookSeat)

    const modes = useMemo(() => ['Cash', 'Cheque', 'UPI', 'Bank Transfer'], [])

    const courseWithBatchLoading = useSelector(state => state.courseWithBatch.loading)
    const courseWithBatcherror = useSelector(state => state.courseWithBatch.error)
    const courseWithBatchmessage = useSelector(state => state.courseWithBatch.message)
    const courseWithBatchdata = useSelector(state => state.courseWithBatch.data)

    const leftInfowidth = 80

    const [user, setuser] = useState('')
    const [selectedCourse, setselectedCourse] = useState({})
    const [selectedBatch, setselectedBatch] = useState({})
    const [paymentMode, setpaymentMode] = useState(modes[0])

    const emptyData = () => {
        setuser('')
        setselectedCourse({})
        setselectedBatch({})
    }

    useEffect(() => {
        setselectedBatch({})
    }, [selectedCourse])

    useEffect(() => {
        dispatch(CourseWithBatchStateAction({ loading: true, error: false }))
        dispatch(CourseWithBatchAction())

        return () => { }
    }, [dispatch])

    useEffect(() => {
        if (addDone) {
            alert('Seat Successfully Booked')
            dispatch(ManualBookSeatStateAction({ loading: false, error: false }))
            emptyData()
        }
        if (error) {
            alert(message)
        }
    }, [addDone, error, message, dispatch])


    const onSubmit = () => {
        if (user.length !== 10) {
            alert('Invalid Mobile Number')
        } else if (selectedBatch?.ID) {
            dispatch(ManualBookSeatStateAction({ loading: true }))
            dispatch(ManualBookSeatAction({ user, batch: selectedBatch.ID, course: selectedCourse.ID, mode: paymentMode }))
        } else {
            alert('Course and Batch should be selected')
        }
    }

    return (courseWithBatchLoading ? <div className='p-3 pt-5 sm:p-5 main-container'>
        <p>Loading...</p>
    </div > : courseWithBatcherror ? <div className='p-3 pt-5 sm:p-5 main-container'>
        <p>{courseWithBatchmessage}</p>
    </div >
        :
        <div className='p-3 pt-5 sm:p-5 main-container'>
            <p className='mainHeading mb-3'>Manual Book Seat</p>
            <InputComponent width={leftInfowidth} name='User' value={user} changeValue={setuser} number={true} mandatory={true} />
            <DropdownComponent title="Course" width={leftInfowidth} changeSelectedItem={setselectedCourse} items={courseWithBatchdata} selectBy="Title" selectedItem={selectedCourse} mandatory={true} />
            <DropdownComponent title="Batch" width={leftInfowidth} changeSelectedItem={setselectedBatch} items={selectedCourse?.batch || []} selectBy="Batch" selectedItem={selectedBatch} mandatory={true} />
            <DropdownComponent title="Mode" width={leftInfowidth} changeSelectedItem={setpaymentMode} items={modes} selectBy="" selectedItem={paymentMode} />

            {loading ? <div className='flex justify-center'>
                <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s" />
            </div> :
                <button className='submitButton mt-2 flex justify-center items-center' onClick={onSubmit}>Submit</button>
            }

        </div>
    )
}