import { LOGOUT } from "../Action/AuthAction";
import { ADDROLE, ADDUPDATEROLESTATE, DELETEROLE, DELETEROLESTATE, ROLE, ROLESTATE, UPDATEROLE } from "../Action/RoleAction";

const initialState = {
    data: [],
    loading: true,
    error: false,
    message: null,

    // ADD-UPDATE
    addUpdateLoading: false,
    addUpdateError: false,
    addUpdateMessage: null,
    addUpdateDone: false,

    // DELETE
    deleteLoading: false,
    deleteError: false,
    deleteMessage: null,
    deleteDone: false,
};

export const RoleReducer = (state = initialState, action) => {
    switch (action.type) {

        // VIEW ROLE
        case ROLE: {
            return {
                ...state,
                loading: false,
                error: false,
                data: action.data,
                message: null
            };
        }

        case ROLESTATE: {
            return {
                ...state,
                loading: action.loading,
                error: action.error,
                message: action.message
            };
        }

        // ADD ROLE
        case ADDROLE: {
            const { id, name, status, pages } = action
            let data = [...state.data];
            data.push({ ID: id, Name: name, Status: status, Pages: pages })
            return {
                ...state,
                addUpdateLoading: false,
                addUpdateError: false,
                data: data,
                addUpdateMessage: "Role Added",
                addUpdateDone: true,
            };
        }

        case ADDUPDATEROLESTATE: {
            return {
                ...state,
                addUpdateLoading: action.loading,
                addUpdateError: action.error,
                addUpdateMessage: action.message,
                addUpdateDone: false,
            };
        }

        // UPDATE ROLE
        case UPDATEROLE: {
            let data = [...state.data];
            const { id, name, status, pages } = action
            let index = data.findIndex(item =>
                item.ID === id
            )
            data[index] = { ID: id, Name: name, Pages: pages, Status: status }
            return {
                ...state,
                addUpdateLoading: false,
                addUpdateError: false,
                data: data,
                addUpdateMessage: "Role Updated",
                addUpdateDone: true,
            };
        }

        // DELETE ROLE STATE
        case DELETEROLESTATE: {
            const { loading, error, message } = action
            return {
                ...state,
                deleteLoading: loading,
                deleteError: error,
                deleteMessage: message,
                deleteDone: false,

            };
        }

        // DELETE ROLE
        case DELETEROLE: {
            let data = [...state.data];
            const { id } = action
            const filteredData = data.filter(item => item.ID !== id)
            return {
                ...state,
                deleteLoading: false,
                deleteError: false,
                data: filteredData,
                deleteMessage: "Role Deleted",
                deleteDone: true,
            };
        }

        // LOGOUT
        case LOGOUT:
            return initialState;

        default:
            return { ...state };
    }
};