import React, { useState } from 'react'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputComponent } from '../Common/Inputs/InputComponent';
import { DropdownComponent } from '../Common/Inputs/DropdownComponent';
import { useDispatch, useSelector } from 'react-redux'
import { AddCourseTagAction, AddUpdateCourseTagStateAction, UpdateCourseTagAction } from '../../Store/Action/CourseTagAction';
import { statuses } from '../../Constants/Statuses';
import { breakpoints } from '../../Helpers/breakpoints';

export const CourseTagDialog = ({ state, changeState, title, status, id }) => {

    const {
        addUpdateLoading,
        addUpdateError,
        addUpdateDone,
        addUpdateMessage, } = useSelector(state => state.courseTag)

    const dispatch = useDispatch()
    const [tagTitle, settagTitle] = useState(title)
    const currStatus = statuses.find(item => item.key === Number(status))
    const [tagStatus, settagStatus] = useState(currStatus)

    React.useEffect(() => {
        if (state) {
            dispatch(AddUpdateCourseTagStateAction({ loading: false, error: false }))
        }
        // eslint-disable-next-line
    }, [state])


    React.useEffect(() => {
        if (id) {
        } else {
            settagTitle('')
            settagStatus(statuses[0])
        }
        // eslint-disable-next-line
    }, [addUpdateDone, id])


    const renderFooter = () => {
        return (
            <div>
                <Button loading={addUpdateLoading} disabled={addUpdateLoading} label="No" icon="pi pi-times" onClick={hideDialog} className="p-button-text" />
                <Button loading={addUpdateLoading} disabled={addUpdateLoading} label="Yes" icon="pi pi-check" onClick={onClick} autoFocus />
            </div>
        );
    }

    const hideDialog = () => {
        changeState(false)
    }

    const onClick = () => {
        if (tagTitle.length) {
            dispatch(AddUpdateCourseTagStateAction({ loading: true, error: false }))
            if (id) {
                dispatch(UpdateCourseTagAction({ id, title: tagTitle, status: tagStatus.key }))
            } else {
                dispatch(AddCourseTagAction({ title: tagTitle, status: tagStatus.key }))
            }
        } else {
            alert('Invalid Data')
        }
    }

    return (
        <Dialog draggable={false} header="Course Tag" visible={state} onHide={hideDialog} style={{ minWidth: '50vw', margin: 10 }} breakpoints={breakpoints} footer={renderFooter()}>

            {addUpdateError && <div className="alert alert-danger text-sm" role="alert">
                <span className='font-bold'>Error! </span>
                <span>{addUpdateMessage}</span>
            </div>}
            {addUpdateDone && <div className="alert alert-success text-sm" role="alert">
                <span className='font-bold'>Success! </span>
                <span>{addUpdateMessage}</span>
            </div>}
            <div className='py-2'>
                <InputComponent name="Title" value={tagTitle} changeValue={settagTitle} nan={true} width={80} />
                <DropdownComponent title="Status" selectedItem={tagStatus} changeSelectedItem={settagStatus} items={statuses} selectBy="title" width={80} />
            </div>
        </Dialog>
    )
}