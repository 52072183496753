import { token_api_interceptor } from "../Interceptor/Interceptor";
import { ClassNotes } from "../Interceptor/Url";

// VIEW
export const CLASSNOTES = "CLASSNOTES";
export const CLASSNOTESSTATE = "CLASSNOTESSTATE";

// ADD
export const ADDCLASSNOTES = "ADDCLASSNOTES";
export const ADDUPDATECLASSNOTESSTATE = "ADDUPDATECLASSNOTESSTATE";

// UPDATE
export const UPDATECLASSNOTES = "UPDATECLASSNOTES";
export const UPDATECLASSNOTESSTATE = "UPDATECLASSNOTESSTATE";

// DELETE
export const DELETECLASSNOTES = "DELETECLASSNOTES";
export const DELETECLASSNOTESSTATE = "DELETECLASSNOTESSTATE";


export const ClassNotesStateAction = ({ loading, error }) => {
    return {
        type: CLASSNOTESSTATE,
        loading, error,
        message: null
    }
}

export const AddUpdateClassNotesStateAction = ({ loading, error }) => {
    return {
        type: ADDUPDATECLASSNOTESSTATE,
        loading, error,
        message: null
    }
}

export const ClassNotesAction = () => {
    return async (dispatch) => {
        await token_api_interceptor.post(ClassNotes)
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: CLASSNOTES,
                        data: resp.data.data
                    });
                } else {
                    dispatch({
                        type: CLASSNOTESSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: CLASSNOTESSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const AddClassNotesAction = ({ title, file, course, courseName, id }) => {

    return ({
        type: ADDCLASSNOTES,
        id, title, file, courseName, course
    })
};

export const UpdateClassNotesAction = ({ id, title, file, course, courseName }) => {

    const formData = new FormData()
    formData.append('id', id)
    formData.append('title', title)
    formData.append('file', file)
    formData.append('course', course)

    return async (dispatch) => {
        await token_api_interceptor.post(ClassNotes + 'update', formData)
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: UPDATECLASSNOTES,
                        id, title, file: resp.data.file, course, courseName
                    });
                } else {
                    dispatch({
                        type: ADDUPDATECLASSNOTESSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: ADDUPDATECLASSNOTESSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const DeleteClassNotesStateAction = ({ loading, error }) => {
    return {
        type: DELETECLASSNOTESSTATE,
        loading, error
    }
}

export const DeleteClassNotesAction = ({ id }) => {
    return async (dispatch) => {
        await token_api_interceptor.post(ClassNotes + 'delete', { id })
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: DELETECLASSNOTES,
                        id
                    });
                } else {
                    dispatch({
                        type: DELETECLASSNOTESSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: DELETECLASSNOTESSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};