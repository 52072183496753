import React, { useState, useEffect } from 'react'
import { InputComponent } from '../../../Components/Common/Inputs/InputComponent'
import { PdfInputComponent } from '../../../Components/Common/Inputs/PdfInputContainer'
import { token_api_interceptor } from '../../../Store/Interceptor/Interceptor'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { UserAssignmentsUrl } from '../../../Store/Interceptor/Url'
import { CourseWithBatchAction, CourseWithBatchStateAction } from '../../../Store/Action/CourseWithBatchAction'
import { DisabledInputComponent } from '../../../Components/Common/Inputs/DisabledInputComponent'
import { UpdateUserAssignmentsAction, UpdateUserAssignmentsStateAction } from '../../../Store/Action/UserAssignmentsAction'
import { ViewPdfComponent } from '../../../Components/Common/Inputs/ViewPdfComponent'

export const UpdateUserAssignmentScreen = () => {

    const location = useLocation()

    const leftInfoWidth = 100

    const dispatch = useDispatch()

    const { updateLoading, updateError, updateMessage, updateDone } = useSelector(state => state.userAssignment)

    const [username, setusername] = useState('')
    const [assignmentTitle, setassignmentTitle] = useState('')
    const [file, setfile] = useState('')
    const [userFile, setuserFile] = useState('')
    const [facultyFile, setfacultyFile] = useState('')
    const [marks, setmarks] = useState(20)

    const [editLoading, seteditLoading] = useState(false)
    const [update, setupdate] = useState(false)
    const [editError, seteditError] = useState('')
    const [editErrorState, seteditErrorState] = useState(false)
    const [id, setid] = useState('')
    const [dialogState, setdialogState] = useState(false)
    const [facultyDialogState, setfacultyDialogState] = useState('')


    useEffect(() => {
        dispatch(CourseWithBatchStateAction({ loading: true, error: false }))
        dispatch(CourseWithBatchAction())

        return () => { }
    }, [dispatch])

    useEffect(() => {
        if (updateDone) {
            alert(updateMessage)
            dispatch(UpdateUserAssignmentsStateAction({ loading: false, error: false }))
        }
        if (updateError) {
            alert(updateMessage)
        }
    }, [updateDone, updateError, updateMessage, dispatch, update])


    useEffect(() => {
        if (location.search.includes('key')) {
            const key = location.search.slice(5)
            setupdate(true)
            setid(key)

            if (key.length) {

                seteditLoading(true)
                token_api_interceptor.post(UserAssignmentsUrl + 'edit', { id: key }).then(res => {
                    if (res.data.status) {
                        const { AssignmentTitle, Marks, File, Contact, FacultyFile } = res.data.data
                        setassignmentTitle(AssignmentTitle)
                        setmarks(Marks || 0)
                        setuserFile(File)
                        setusername(Contact)
                        setfacultyFile(FacultyFile)

                    } else {
                        seteditError(res.data.message)
                        seteditErrorState(true)
                    }
                }).catch(err => {
                    seteditError('Something went wrong')
                    seteditErrorState(true)
                }).finally(() => seteditLoading(false))
            }
        }

        // eslint-disable-next-line
    }, [location.search, updateDone])


    const [currentChunkIndex, setcurrentChunkIndex] = useState(0)

    const chunkSize = 200 * 1024

    const uploadChunk = (readerEvent) => {
        dispatch(UpdateUserAssignmentsStateAction({ loading: true, error: false }))

        const data = readerEvent.target.result
        const params = new URLSearchParams()

        params.set('name', file.name)
        params.set('size', file.size)
        params.set('currentChunkIndex', currentChunkIndex)
        params.set('totalChunks', Math.ceil(file.size / chunkSize))
        params.set('id', id.toString())
        params.set('_file', true)
        params.set('marks', marks.toString())

        const url = UserAssignmentsUrl + 'update?' + params.toString()

        token_api_interceptor.post(url, data, {
            headers: {
                'content-type': 'application/octet-stream'
            }
        }).then(res => {
            const fileSize = file.size
            const isLastChunk = currentChunkIndex === Math.ceil(fileSize / chunkSize) - 1

            if (isLastChunk) {

                if (res.data.status) {
                    dispatch(UpdateUserAssignmentsAction())
                    setTimeout(() => {
                        dispatch(UpdateUserAssignmentsStateAction({ loading: false, error: false }))
                    }, 3000);
                } else {
                    dispatch(UpdateUserAssignmentsStateAction({ loading: false, error: true, message: res.data.message }))
                    setcurrentChunkIndex(0)
                }
            } else {
                setcurrentChunkIndex(currentChunkIndex + 1)
            }
        })
            .catch(err => {
                setcurrentChunkIndex(0)
                dispatch(UpdateUserAssignmentsStateAction({ loading: false, error: true, message: 'Something went wrong' }))
                alert('Something went wrong')
            })
    }

    const onSubmit = () => {
        if (file?.name && marks) {
            const reader = new FileReader()
            const from = currentChunkIndex * chunkSize
            const to = from + chunkSize
            const blob = file.slice(from, to)
            reader.onload = (e) => uploadChunk(e)
            reader.readAsDataURL(blob)
        } else if (facultyFile && marks) {

            dispatch(UpdateUserAssignmentsStateAction({ loading: true, error: false }))

            const params = new URLSearchParams()

            params.set('id', id.toString())
            params.set('_file', false)
            params.set('marks', marks.toString())

            const url = UserAssignmentsUrl + 'add?' + params.toString()

            token_api_interceptor.post(url, {
                headers: {
                    'content-type': 'application/octet-stream'
                }
            }).then(res => {

                if (res.data.status) {
                    dispatch(UpdateUserAssignmentsAction())
                    setTimeout(() => {
                        dispatch(UpdateUserAssignmentsStateAction({ loading: false, error: false }))
                    }, 3000);
                } else {
                    dispatch(UpdateUserAssignmentsStateAction({ loading: false, error: true, message: res.data.message }))
                }
            })
                .catch(err => {
                    dispatch(UpdateUserAssignmentsStateAction({ loading: false, error: true, message: 'Something went wrong' }))
                    alert('Something went wrong')
                })
        } else {
            alert('Required data should be available')
        }
    }

    useEffect(() => {
        if (currentChunkIndex !== 0) {
            onSubmit()
        }

        // eslint-disable-next-line
    }, [currentChunkIndex])


    React.useEffect(() => {
        if (updateDone || updateError) {
            setTimeout(() => {
                dispatch(UpdateUserAssignmentsStateAction({ loading: false, error: false }))
            }, 3000);
        }
        // eslint-disable-next-line
    }, [updateDone, updateError, id])

    const onHideDialog = () => {
        setdialogState(false)
    }

    const onHideFacultyDialog = () => {
        setfacultyDialogState(false)
    }


    return (editLoading ? <div className='p-3 pt-5 sm:p-5 main-container'>
        <p>Loading...</p>
    </div > : editErrorState ? <div className='p-3 pt-5 sm:p-5 main-container'>
        <p>{editError}</p>
    </div >
        :
        <div className='p-3 pt-5 sm:p-5 main-container'>
            <p className='mainHeading mb-3'>Check User Assignment</p>
            {updateError && <div className="alert alert-danger text-sm" role="alert">
                <span className='font-bold'>Error! </span>
                <span>{updateMessage}</span>
            </div>}
            {updateDone && <div className="alert alert-success text-sm" role="alert">
                <span className='font-bold'>Success! </span>
                <span>{updateMessage}</span>
            </div>}
            <DisabledInputComponent width={leftInfoWidth} name='User' value={username} />
            <DisabledInputComponent width={leftInfoWidth} name='Assignment' value={assignmentTitle} />
            <InputComponent width={leftInfoWidth} name='Marks' value={marks} changeValue={setmarks} number={true} />

            <PdfInputComponent key={3} index={3} name="File" value={file} changeValue={setfile} width={leftInfoWidth} />
            <ViewPdfComponent File={userFile} dialogState={dialogState} onHideDialog={onHideDialog} />
            <ViewPdfComponent faculty={true} File={facultyFile} dialogState={facultyDialogState} onHideDialog={onHideFacultyDialog} />

            <button className='bg-pink-200 text-sm py-2 px-4 rounded-md hover:bg-pink-400 transition-all duration-200' onClick={() => setdialogState(true)}>Click to view user assignment</button>
            {facultyFile && <button className='bg-sky-200 text-sm py-2 px-4 rounded-md hover:bg-sky-400 transition-all duration-200 ml-2' onClick={() => setfacultyDialogState(true)}>Click to view faculty assignment</button>}

            {updateLoading ? <div className='flex justify-center w-full'>
                <div className='bg-slate-300 w-full h-5 rounded-sm overflow-hidden'>
                    <div style={{ width: `${(currentChunkIndex * 100 / (file?.size / chunkSize)).toFixed(2)}%` }} className="h-full transition-all duration-200 bg-green-400 flex justify-end items-center">
                        <p className='text-xs text-white mr-2'>{(currentChunkIndex * 100 / (file?.size / chunkSize)).toFixed(2)}%</p>
                    </div>
                </div>
            </div> : <div className='flex'>
                <button disabled={updateLoading} className='submitButton mt-2 flex justify-center items-center' onClick={onSubmit}>{updateLoading ? 'Submitting' : 'Submit'}</button>
            </div>}

        </div>
    )
}