import React, { useState, useMemo } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputComponent } from "../Common/Inputs/InputComponent";
import { breakpoints } from "../../Helpers/breakpoints";
import { token_api_interceptor } from "../../Store/Interceptor/Interceptor";
import { QuestionUrl } from "../../Store/Interceptor/Url";
import { DropdownComponent } from "../Common/Inputs/DropdownComponent";

export const QuestionDialog = ({
  state,
  changeState,
  _id,
  _question,
  _option1,
  _option2,
  _option3,
  _option4,
  _answer,
  _quizId,
  _data,
  _setdata,
  _idx,
}) => {
  const width = 100;

  const [loading, setloading] = useState(false);

  const [question, setquestion] = useState(_question || "");
  const [option1, setoption1] = useState(_option1 || "");
  const [option2, setoption2] = useState(_option2 || "");
  const [option3, setoption3] = useState(_option3 || "");
  const [option4, setoption4] = useState(_option4 || "");

  const answerOptions = useMemo(
    () => [
      { key: "(a). " + option1, value: "a" },
      { key: "(b). " + option2, value: "b" },
      { key: "(c). " + option3, value: "c" },
      { key: "(d). " + option4, value: "d" },
    ],
    [option1, option2, option3, option4]
  );

  const [answer, setanswer] = useState(_answer);

  React.useEffect(() => {
    setloading(false);
  }, [state]);

  React.useEffect(() => {
    setanswer("");
    return () => {};
  }, [option1, option2, option3, option4]);

  React.useEffect(() => {
    setanswer(_answer);
    return () => {};
  }, [_answer]);

  const renderFooter = () => {
    return (
      <div>
        <Button
          loading={loading}
          disabled={loading}
          label="No"
          icon="pi pi-times"
          onClick={hideDialog}
          className="p-button-text"
        />
        <Button
          loading={loading}
          disabled={loading}
          label="Yes"
          icon="pi pi-check"
          onClick={onClick}
          autoFocus
        />
      </div>
    );
  };

  const hideDialog = () => {
    changeState(false);
  };

  const onClick = () => {
    if (question && option1 && option2 && option3 && option4 && answer) {
      setloading(true);

      token_api_interceptor
        .post(QuestionUrl.concat(_id ? "update" : "single-add"), {
          question,
          option1,
          option2,
          option3,
          option4,
          answer: answer,
          QuizId: _quizId,
          id: _id || 0,
        })
        .then((res) => {
          if (res.data.status) {
            if (_id) {
              let dt = [..._data];

              dt[_idx].Question = question;
              dt[_idx].Option1 = option1;
              dt[_idx].Option2 = option2;
              dt[_idx].Option3 = option3;
              dt[_idx].Option4 = option4;
              dt[_idx].Answer = answer;

              _setdata(dt);

              alert("Question Updated");
            } else {
              setquestion("");
              setoption1("");
              setoption2("");
              setoption3("");
              setoption4("");
              setanswer("");

              _setdata((dt) => [
                ...dt,
                {
                  Question: question,
                  Option1: option1,
                  Option2: option2,
                  Option3: option3,
                  Option4: option4,
                  Answer: answer,
                  QuizID: _quizId,
                  ID: res.data.id,
                },
              ]);

              alert("Question Added");
            }
          } else {
            alert(res.data.message);
          }
        })
        .catch((err) => alert("Something went wrong"))

        .finally(() => setloading(false));
    } else {
      alert("Invalid Data");
    }
  };

  return (
    <Dialog
      draggable={false}
      header="Question"
      visible={state}
      onHide={hideDialog}
      style={{ minWidth: "70vw", margin: 10 }}
      breakpoints={breakpoints}
      footer={renderFooter()}
    >
      <div className="py-2">
        <InputComponent
          name="Question"
          value={question}
          changeValue={setquestion}
          width={width}
        />
        <InputComponent
          name="(a). "
          value={option1}
          changeValue={setoption1}
          width={width}
        />
        <InputComponent
          name="(b). "
          value={option2}
          changeValue={setoption2}
          width={width}
        />
        <InputComponent
          name="(c). "
          value={option3}
          changeValue={setoption3}
          width={width}
        />
        <InputComponent
          name="(d). "
          value={option4}
          changeValue={setoption4}
          width={width}
        />
        <DropdownComponent
          changeSelectedItem={setanswer}
          items={answerOptions}
          selectBy={"key"}
          selectedItem={answer}
          title={"Answer"}
          width={width}
          disabled={!option1 || !option2 || !option3 || !option4}
        />
      </div>
    </Dialog>
  );
};
