import { token_api_interceptor } from "../Interceptor/Interceptor";
import { QuizUrl } from "../Interceptor/Url";

// VIEW
export const QUIZ = "QUIZ";
export const QUIZSTATE = "QUIZSTATE";

// DELETE
export const DELETEQUIZ = "DELETEQUIZ";
export const DELETEQUIZSTATE = "DELETEQUIZSTATE";

export const QuizStateAction = ({ loading, error }) => {
  return {
    type: QUIZSTATE,
    loading,
    error,
    message: null,
  };
};

export const QuizAction = () => {
  return async (dispatch) => {
    await token_api_interceptor
      .post(QuizUrl)
      .then((resp) => {
        if (resp.data.status) {
          dispatch({
            type: QUIZ,
            data: resp.data.data,
          });
        } else {
          dispatch({
            type: QUIZSTATE,
            loading: false,
            error: true,
            message: resp.data.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: QUIZSTATE,
          loading: false,
          error: true,
          message: "Something went wrong",
        });
      });
  };
};

export const DeleteQuizStateAction = ({ loading, error }) => {
  return {
    type: DELETEQUIZSTATE,
    loading,
    error,
  };
};

export const DeleteQuizAction = ({ id }) => {
  return async (dispatch) => {
    await token_api_interceptor
      .post(QuizUrl + "delete", { id })
      .then((resp) => {
        if (resp.data.status) {
          dispatch({
            type: DELETEQUIZ,
            id,
          });
        } else {
          dispatch({
            type: DELETEQUIZSTATE,
            loading: false,
            error: true,
            message: resp.data.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: DELETEQUIZSTATE,
          loading: false,
          error: true,
          message: "Something went wrong",
        });
      });
  };
};
