import { token_api_interceptor } from "../Interceptor/Interceptor";
import { SubjectUrl } from "../Interceptor/Url";

// VIEW
export const SUBJECT = "SUBJECT";
export const SUBJECTSTATE = "SUBJECTSTATE";

// ADD
export const ADDSUBJECT = "ADDSUBJECT";
export const ADDUPDATESUBJECTSTATE = "ADDUPDATESUBJECTSTATE";

// UPDATE
export const UPDATESUBJECT = "UPDATESUBJECT";
export const UPDATESUBJECTSTATE = "UPDATESUBJECTSTATE";

// DELETE
export const DELETESUBJECT = "DELETESUBJECT";
export const DELETESUBJECTSTATE = "DELETESUBJECTSTATE";


export const SubjectStateAction = ({ loading, error }) => {
    return {
        type: SUBJECTSTATE,
        loading, error,
        message: null
    }
}

export const AddUpdateSubjectStateAction = ({ loading, error }) => {
    return {
        type: ADDUPDATESUBJECTSTATE,
        loading, error,
        message: null
    }
}

export const SubjectAction = () => {
    return async (dispatch) => {
        await token_api_interceptor.post(SubjectUrl)
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: SUBJECT,
                        data: resp.data.data
                    });
                } else {
                    dispatch({
                        type: SUBJECTSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: SUBJECTSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const AddSubjectAction = ({ title }) => {
    return async (dispatch) => {
        await token_api_interceptor.post(SubjectUrl + 'add', { title })
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: ADDSUBJECT,
                        id: resp.data.id,
                        title
                    });
                } else {
                    dispatch({
                        type: ADDUPDATESUBJECTSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: ADDUPDATESUBJECTSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const UpdateSubjectAction = ({ id, title }) => {
    return async (dispatch) => {
        await token_api_interceptor.post(SubjectUrl + 'update', { id, title })
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: UPDATESUBJECT,
                        id, title
                    });
                } else {
                    dispatch({
                        type: ADDUPDATESUBJECTSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: ADDUPDATESUBJECTSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const DeleteSubjectStateAction = ({ loading, error }) => {
    return {
        type: DELETESUBJECTSTATE,
        loading, error
    }
}

export const DeleteSubjectAction = ({ id }) => {
    return async (dispatch) => {
        await token_api_interceptor.post(SubjectUrl + 'delete', { id })
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: DELETESUBJECT,
                        id
                    });
                } else {
                    dispatch({
                        type: DELETESUBJECTSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: DELETESUBJECTSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};