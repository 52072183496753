import React, { useState } from 'react'
import { MdOutlineEdit } from 'react-icons/md'
import { SeminarQueryDialog } from '../../../Dialog/SeminarQueryDialog'

export const SeminarQueryRowComponent = ({ _i, dt, statuses }) => {
    const dataKeys = ['Name', 'Mobile', 'Email', 'CreatedAt', 'Action']
    const [dialogState, setdialogState] = useState(false)

    return (
        <tr style={{ height: 60 }}>
            <SeminarQueryDialog state={dialogState} changeState={setdialogState} id={dt.ID} _name={dt.Name} _comment={dt.Comments} _email={dt.Email} _mobile={dt.Mobile} _status={dt.Status} _statuses={statuses} />
            {/* <AdminAccessDialog state={dialogState} changeState={setdialogState} id={dt.ID} _name={dt.Name} _password={dt.Password} _status={1} _role={dt.Role} /> */}

            <td className="px-3 whitespace-nowrap">
                <div className="text-md font-medium text-gray-600 text-center">
                    <span>{_i}</span>
                </div>
            </td>
            {
                dataKeys.map((item, ind) => {
                    if (item === 'Action') {
                        const hw = { height: 35, width: 35 }
                        return <td key={ind} className="px-3 whitespace-nowrap">
                            <div className="flex justify-center">
                                <div onClick={() => setdialogState(true)} className='bg-yellow-500 transition-all duration-300 hover:bg-yellow-800 cursor-pointer flex justify-center items-center rounded-lg' style={hw}>
                                    <MdOutlineEdit color='white' size={18} />
                                </div>
                            </div>
                        </td>
                    }
                    return <td key={ind} className="px-3 whitespace-nowrap">
                        <div className="text-sm text-gray-900 text-center">
                            <span>{dt[item]}</span>
                        </div>
                    </td>

                })
            }
        </tr>
    )
}