import React, { useState, useEffect } from 'react'

// PRIMEREACT
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

// COMPONENT
import { InputComponent } from '../Common/Inputs/InputComponent';
import { PdfInputComponent } from '../Common/Inputs/PdfInputContainer';

// BREAKPOINT
import { breakpoints } from '../../Helpers/breakpoints';


// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { AddUpdateClassNotesStateAction, UpdateClassNotesAction } from '../../Store/Action/ClassNotes';

// API
import { ClassNotes } from '../../Store/Interceptor/Url';
import { token_api_interceptor } from '../../Store/Interceptor/Interceptor';
import { CourseWithBatchAction, CourseWithBatchStateAction } from '../../Store/Action/CourseWithBatchAction';
import { MultiSelectDropDown } from '../Common/Inputs/MultiSelectDropDown';

export const ClassNotesDialog = ({ state, changeState, _title, id, _file,}) => {

    const leftInfoWidth = 100

    const courseWithBatchdata = useSelector(state => state.courseWithBatch.data)

    const [showBatches, setshowBatches] = useState([]);


    const {
        addUpdateLoading,
        addUpdateError,
        addUpdateDone,
        addUpdateMessage, } = useSelector(state => state.classNotes)


    const dispatch = useDispatch()
    const [title, settitle] = useState(_title)
    const [selectedCourse, setselectedCourse] = useState([])
    const [selectedBatch, setselectedBatch] = useState([])
    const [file, setfile] = useState(_file)

    const [currentChunkIndex, setcurrentChunkIndex] = useState(0)

    const chunkSize = 200 * 1024

    const emptyData = () => {
        settitle('')
        setfile('')
        setcurrentChunkIndex(0)
    }


    useEffect(() => {
        setselectedBatch([])

        return () => { }
    }, [selectedCourse])


    const uploadChunk = (readerEvent) => {
        let batches = selectedBatch.map(item => ({ c: item.CourseID, b: item.ID }))
        dispatch(AddUpdateClassNotesStateAction({ loading: true, error: false }))

        const data = readerEvent.target.result
        const params = new URLSearchParams()

        params.set('name', file.name)
        params.set('size', file.size)
        params.set('currentChunkIndex', currentChunkIndex)
        params.set('totalChunks', Math.ceil(file.size / chunkSize))
        params.set('title', title)
        params.set('batch', JSON.stringify(batches))

        const url = ClassNotes + 'add?' + params.toString()

        token_api_interceptor.post(url, data, {
            headers: {
                'content-type': 'application/octet-stream'
            }
        }).then(res => {
            const fileSize = file.size
            const isLastChunk = currentChunkIndex === Math.ceil(fileSize / chunkSize) - 1

            if (isLastChunk) {

                if (res.data.status) {
                    alert('Class added')
                    window.location.reload()
                } else {
                    dispatch(AddUpdateClassNotesStateAction({ loading: false, error: true, message: res.data.message }))
                    setcurrentChunkIndex(0)
                }
            } else {
                setcurrentChunkIndex(currentChunkIndex + 1)
            }
        })
            .catch(err => {
                setcurrentChunkIndex(0)
                dispatch(AddUpdateClassNotesStateAction({ loading: false, error: true, message: 'Something went wrong' }))
                alert('Something went wrong')
            })
    }

    const onSubmit = () => {

        if (selectedBatch.length === 0) {
            alert('Course and batch should be selected')
            return;
        }

        if (title.length === 0) {
            alert('Invalid Title')
        } else if (id) {
            dispatch(AddUpdateClassNotesStateAction({ loading: true, error: false }))
            dispatch(UpdateClassNotesAction({ id, title, course: selectedCourse.ID, courseName: selectedCourse.Title, file }))

        } else if (file) {
            const reader = new FileReader()
            const from = currentChunkIndex * chunkSize
            const to = from + chunkSize
            const blob = file.slice(from, to)
            reader.onload = (e) => uploadChunk(e)
            reader.readAsDataURL(blob)

        } else {
            alert('File should be selected')
        }
    }

    useEffect(() => {
        if (currentChunkIndex !== 0) {
            onSubmit()
        }

        // eslint-disable-next-line
    }, [currentChunkIndex])


    React.useEffect(() => {
        if (!id) {
            emptyData()
        } else { }
        if (addUpdateDone) {
            setTimeout(() => {
                dispatch(AddUpdateClassNotesStateAction({ loading: false, error: false }))
            }, 3000);
        }
        // eslint-disable-next-line
    }, [addUpdateDone, id])

    React.useEffect(() => {

        dispatch(CourseWithBatchStateAction({ loading: true, error: false }))
        dispatch(CourseWithBatchAction())
    }, [dispatch])

    useEffect(() => {
        dispatch(AddUpdateClassNotesStateAction({ loading: false, error: false }))
    }, [dispatch])


    useEffect(() => {
        if (selectedCourse.length) {
            let _data = [];

            selectedCourse.forEach((item) => {
                const findData = courseWithBatchdata.find(
                    (_item) => _item.ID === item.ID
                );

                if (findData) {
                    let modifiedBatch = findData.batch.map((_item) => {
                        let cloneItem = { ..._item };
                        cloneItem.Batch = item.Title.concat(" (" + cloneItem.Batch + ")");
                        return cloneItem;
                    });
                    _data.push(...modifiedBatch);
                }
            });
            setshowBatches(_data);
        }
        setselectedBatch([]);
    }, [selectedCourse, courseWithBatchdata]);


    const renderFooter = () => {
        return (
            <div>{
                addUpdateLoading ? <div className='flex justify-center w-full'>
                    <div className='bg-slate-300 w-full h-5 rounded-sm overflow-hidden'>
                        <div style={{ width: `${(currentChunkIndex * 100 / (file?.size / chunkSize)).toFixed(2)}%` }} className="h-full transition-all duration-200 bg-green-400 flex justify-end items-center">
                            <p className='text-xs text-white mr-2'>{(currentChunkIndex * 100 / (file?.size / chunkSize)).toFixed(2)}%</p>
                        </div>
                    </div>
                </div> :
                    <div>
                        <Button loading={addUpdateLoading} disabled={addUpdateLoading} label="No" icon="pi pi-times" onClick={hideDialog} className="p-button-text" />
                        <Button loading={addUpdateLoading} disabled={addUpdateLoading} label="Yes" icon="pi pi-check" onClick={onSubmit} autoFocus />
                    </div>}
            </div>
        );
    }

    const hideDialog = () => {
        changeState(false)
    }


    return (
        <Dialog draggable={false} header="Class Notes" visible={state} onHide={hideDialog} style={{ minWidth: '50vw', margin: 10 }} breakpoints={breakpoints} footer={renderFooter()}>

            {addUpdateError && <div className="alert alert-danger text-sm" role="alert">
                <span className='font-bold'>Error! </span>
                <span>{addUpdateMessage}</span>
            </div>}
            {addUpdateDone && <div className="alert alert-success text-sm" role="alert">
                <span className='font-bold'>Success! </span>
                <span>{addUpdateMessage}</span>
            </div>}
            <div className='py-2'>
                <InputComponent value={title} changeValue={settitle} width={leftInfoWidth} name="Title" />

                <MultiSelectDropDown
                    disabled={addUpdateLoading}
                    width={leftInfoWidth}
                    items={courseWithBatchdata}
                    value={selectedCourse}
                    changeValue={setselectedCourse}
                    name="Course"
                    showItem={1}
                    label="Title"
                />
                <MultiSelectDropDown
                    disabled={addUpdateLoading}
                    width={leftInfoWidth}
                    items={showBatches}
                    value={selectedBatch}
                    changeValue={setselectedBatch}
                    name="Batch"
                    showItem={1}
                    label="Batch"
                />
                <PdfInputComponent disabled={id} key={1} index={1} name="File" value={file} changeValue={setfile} width={leftInfoWidth} />
            </div>
        </Dialog>
    )
}