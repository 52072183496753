import React from 'react'
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';

export const MonthInputComponent = ({ name, value, changeValue, mandatory = false, width = 130 }) => {

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    return (
        <div className="p-fluid grid mb-2 text-sm">
            <div className="field col-12 md:col-4">
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon" style={{ width }}>
                        <span>{name} <span className='text-red-800'>{mandatory && '*'}</span></span>
                    </span>
                    <Calendar id="monthpicker" value={value} onChange={(e) => {
                        changeValue(e.value)
                    }} view="month" dateFormat="mm/yy" />
                </div>
            </div>
        </div>
    )
}