import { LOGOUT } from "../Action/AuthAction";
import { ADDCOURSE, UPDATECOURSE, ADDUPDATECOURSESTATE, COURSE, COURSESTATE, DELETECOURSESTATE, DELETECOURSE, } from "../Action/CourseAction";

const initialState = {
    data: [],
    loading: true,
    error: false,
    message: null,

    // ADD-UPDATE
    addUpdateLoading: false,
    addUpdateError: false,
    addUpdateMessage: null,
    addUpdateDone: false,

    // DELETE
    deleteLoading: false,
    deleteError: false,
    deleteMessage: null,
    deleteDone: false,
};

export const CourseReducer = (state = initialState, action) => {
    switch (action.type) {

        // VIEW COURSES
        case COURSE: {
            return {
                ...state,
                loading: false,
                error: false,
                data: action.data,
                message: null
            };
        }

        case COURSESTATE: {
            return {
                ...state,
                loading: action.loading,
                error: action.error,
                message: action.message
            };
        }

        // ADD COURSE
        case ADDCOURSE: {
            let data = [...state.data];
            const { title, subTitle, image, tag, instructor, description, bookSeat, courseIncludes, status, coupon, installment, price, finalPrice, faq, id, draft, slug } = action
            data.unshift({ ID: id, Title: title, SubTitle: subTitle, Image: image, Tag: tag, Instructor: instructor, Description: description, BookSeat: bookSeat, CourseIncludes: courseIncludes, Status: status, Coupon: coupon, Installment: installment, Price: price, FinalPrice: finalPrice, FAQ: faq, Draft: draft, Slug: slug })

            return {
                ...state,
                addUpdateLoading: false,
                addUpdateError: false,
                data: data,
                addUpdateMessage: action.message,
                addUpdateDone: true,
            };
        }

        case ADDUPDATECOURSESTATE: {
            return {
                ...state,
                addUpdateLoading: action.loading,
                addUpdateError: action.error,
                addUpdateMessage: action.message,
                addUpdateDone: false,
            };
        }

        // UPDATE COURSE
        case UPDATECOURSE: {
            let data = [...state.data];
            const { title, subTitle, image, tag, instructor, description, bookSeat, courseIncludes, status, coupon, installment, price, finalPrice, faq, id, draft, slug } = action
            let index = data.findIndex(item =>
                item.ID === id
            )
            data[index] = { ID: id, Title: title, SubTitle: subTitle, Image: image, Tag: tag, Instructor: instructor, Description: description, BookSeat: bookSeat, CourseIncludes: courseIncludes, Status: status, Coupon: coupon, Installment: installment, Price: price, FinalPrice: finalPrice, FAQ: faq, Draft: draft, Slug: slug }
            return {
                ...state,
                addUpdateLoading: false,
                addUpdateError: false,
                data: data,
                addUpdateMessage: "Course Updated",
                addUpdateDone: true,
            };
        }

        // DELETE COURSE STATE
        case DELETECOURSESTATE: {
            const { loading, error, message } = action
            return {
                ...state,
                deleteLoading: loading,
                deleteError: error,
                deleteMessage: message,
                deleteDone: false,
            };
        }

        // DELETE COURSE
        case DELETECOURSE: {
            let data = [...state.data];
            const { id } = action
            const filteredData = data.filter(item => item.ID !== id)
            return {
                ...state,
                deleteLoading: false,
                deleteError: false,
                data: filteredData,
                deleteMessage: "Course Deleted",
                deleteDone: true,
            };
        }

        // LOGOUT
        case LOGOUT:
            return initialState;

        default:
            return { ...state };
    }
};