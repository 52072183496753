import React, { useState, useEffect } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { useSelector } from 'react-redux';
import { InstallmentTrackRowComponent } from './InstallmentTrackRowComponent';
import { MultiSelectDropDown } from '../../Inputs/MultiSelectDropDown';
import { InputComponent } from '../../Inputs/InputComponent';
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
// import moment from 'moment';

export function InstallmentTrackTable() {
    const [showData, setshowData] = useState([]);
    const [numbers, setnumbers] = useState([1]);
    const [indexValue, setindexValue] = useState(1);
    const [tableIndex, settableIndex] = useState(0)
    const [selectedCourses, setselectedCourses] = useState([])
    const [availableCourses, setavailableCourses] = useState([])
    const [filteredData, setfilteredData] = useState([])
    const [mobile, setmobile] = useState('')
    const [name, setname] = useState('')
    const { loading, error, message, data } = useSelector(state => state.installmentTrack)

    const leftInfoWidth = 80

    const courses = useSelector(state => state.course.data)

    const column = ['Sr.', 'Name', 'Mobile', 'Total Fee', 'Amount Paid', 'Remaining', 'Installment', 'Action']

    useEffect(() => {
        setavailableCourses(courses.filter(item => item.Draft === 0))
        return () => {

        }
    }, [courses])


    useEffect(() => {
        setselectedCourses(availableCourses)
        setshowData(data)
        const data_with_id = data.map((item, index) => {
            return {
                ...item,
                id: index + 1
            }
        })
        setfilteredData(data_with_id)
        return () => { }
    }, [availableCourses, data])

    useEffect(() => {

        const selectedData = data.filter(item => selectedCourses.find(dt => dt.ID === item.CourseID) && String(item.Mobile).includes(mobile) && item.Name.toUpperCase().includes(name.toUpperCase()))

        const data_with_id = selectedData.map((item, index) => {
            return {
                ...item,
                id: index + 1
            }
        })

        setfilteredData(data_with_id)
        setnumbers(
            range(
                1,
                Math.ceil(selectedData.length > 50 ? 5 : selectedData.length / 10)
            )
        );

        return () => { }
    }, [selectedCourses, data, mobile, name])



    function range(start, end) {
        return Array(end - start + 1)
            .fill()
            .map((_, idx) => start + idx);
    }

    useEffect(() => {
        setshowData(filteredData.slice((tableIndex - 1) * 10, tableIndex * 10));
    }, [filteredData, tableIndex]);

    useEffect(() => {
        if (filteredData.length && Math.ceil(filteredData.length / 10) > indexValue * 5 - 5) {
            setnumbers(
                range(
                    indexValue * 5 - 4,
                    Math.ceil(
                        filteredData.length > indexValue * 5 * 10
                            ? indexValue * 5
                            : filteredData.length / 10
                    )
                )
            );
            settableIndex(indexValue * 5 - 4)
        }
    }, [indexValue, filteredData.length]);

    useEffect(() => {
        setshowData(filteredData.slice(0, 10));
        setnumbers(
            range(
                1,
                Math.ceil(filteredData.length > 50 ? 5 : filteredData.length / 10)
            )
        );
    }, [filteredData]);

    const _export = React.useRef(null);

    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save();
        }
    };

    return (
        <div
            className="flex flex-col"
            style={{
                width: "100%",
                overflow: "hidden",
            }}
        >
            {loading ? (
                <p>Loading</p>
            ) : error ? (
                <p>{message}</p>
            ) : (
                <>
                    <div className="my-1">
                        <MultiSelectDropDown width={leftInfoWidth} changeValue={setselectedCourses} items={availableCourses} name="Course" showItem={1} value={selectedCourses} label="Title" />
                    </div>
                    <div className='flex flex-col sm:flex-row mb-1'>
                        <div className='w-full'>
                            <InputComponent width={leftInfoWidth} name="Name" value={name} changeValue={setname} />
                        </div>
                        <div style={{ width: 10 }} />
                        <div className='w-full'>
                            <InputComponent width={leftInfoWidth} name="Mobile" value={mobile} changeValue={setmobile} number={true} />
                        </div>
                    </div>
                    <div className='flex justify-end mb-3'>
                        <ExcelExport data={filteredData} ref={_export}>
                            <ExcelExportColumn field="id" title="Sr. No." />
                            <ExcelExportColumn field="Name" title="Name" />
                            <ExcelExportColumn field="Mobile" title="Contact" />
                            <ExcelExportColumn field="Total" title="Total Amount" />
                            <ExcelExportColumn field="AmountPaid" title="Amount Paid" />
                            <ExcelExportColumn field="Remaining" title="Remaining Amount" />
                            <ExcelExportColumn field="EmiMessage" title="Installment Paid" />
                            <ExcelExportColumn field="Status" title="Status" />
                        </ExcelExport>
                        <button
                            className="btn btn-secondary px-4"
                            onClick={excelExport}
                        >
                            Export
                        </button>
                    </div>
                    <div className="overflow-x-auto sm:-mx-4 lg:-mx-6">
                        <div className="align-middle inline-block min-w-full sm:px-4 lg:px-6">
                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            {
                                                column.map((item, index) => {
                                                    return <th key={index}
                                                        scope="col"
                                                        className="flex-1  py-3 text-center text-base font-semibold text-gray-500 tracking-wider mx-2"
                                                    >
                                                        {item}
                                                    </th>
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {showData.map((dt, index) => (
                                            <InstallmentTrackRowComponent _i={tableIndex * 10 - (9 - index)} key={dt.ID} dt={dt} />
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                        <div>
                            <p className="text-sm text-gray-700">
                                Showing
                                <span className="font-medium mx-1">
                                    {showData.length ? tableIndex * 10 - 9 : 0}
                                </span>
                                to
                                <span className="font-medium mx-1">
                                    {tableIndex * 10 > filteredData.length
                                        ? filteredData.length
                                        : tableIndex * 10}
                                </span>
                                of
                                <span className="font-medium mx-1"> {filteredData.length}</span>
                                results
                            </p>
                        </div>
                        <div>
                            <nav
                                className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                                aria-label="Pagination"
                            >
                                <p
                                    style={{ marginBottom: 0, cursor: "pointer" }}
                                    className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                >
                                    <span className="sr-only">Previous</span>
                                    <ChevronLeftIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                        onClick={() => {
                                            if (indexValue !== 1) {
                                                setindexValue(indexValue - 1);
                                            }
                                        }}
                                    />
                                </p>
                                {numbers.map((data) => {
                                    return (
                                        <p
                                            onClick={() => {
                                                settableIndex(data)
                                            }}
                                            key={data}
                                            style={{ marginBottom: 0, cursor: "pointer" }}
                                            className={
                                                tableIndex === data
                                                    ? "z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                                                    : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                                            }
                                        >
                                            {data}
                                        </p>
                                    );
                                })}
                                <p
                                    style={{ marginBottom: 0, cursor: "pointer" }}
                                    className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                >
                                    <span className="sr-only">Next</span>
                                    <ChevronRightIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                        onClick={() => {
                                            // changeIndex('increment')
                                            setindexValue(indexValue + 1);
                                        }}
                                    />
                                </p>
                            </nav>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
