import React, { useState } from 'react'

// PRIME REACT
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

// COMPONENT
import { InputComponent } from '../Common/Inputs/InputComponent';
import { DropdownComponent } from '../Common/Inputs/DropdownComponent';
import { ImageInputComponent } from '../Common/Inputs/ImageInputContainer';

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { AddSeminarAction, AddUpdateSeminarStateAction, UpdateSeminarAction } from '../../Store/Action/SeminarAction';

// STATUS
import { statuses } from '../../Constants/Statuses';
import { breakpoints } from '../../Helpers/breakpoints';
import { DateInputComponent } from '../Common/Inputs/DateInputComponent';
import moment from 'moment';

export const SeminarDialog = ({ state, changeState, status, id, oldImage, oldTitle, oldEventDate, oldSlug }) => {

    const width = 120

    const {
        addUpdateLoading,
        addUpdateError,
        addUpdateDone,
        addUpdateMessage, } = useSelector(state => state.seminar)

    const dispatch = useDispatch()
    const currStatus = statuses.find(item => item.key === Number(status))
    const [SeminarStatus, setSeminarStatus] = useState(currStatus)
    const [image, setimage] = useState('')
    const [title, settitle] = useState(oldTitle)
    const [slug, setslug] = useState(oldSlug)

    const [eventDate, seteventDate] = useState(new Date(oldEventDate))

    React.useEffect(() => {

        if (state) {
            dispatch(AddUpdateSeminarStateAction({ loading: false, error: false }))
            if (!id) {
                setimage('')
            }
        }
        // eslint-disable-next-line
    }, [state])

    React.useEffect(() => {
        setimage('')

        return () => {

        }
    }, [addUpdateDone])



    React.useEffect(() => {
        if (id) {
        } else {
            setSeminarStatus(statuses[0])
            settitle('')
            setslug('')
        }
        // eslint-disable-next-line
    }, [addUpdateDone, id])


    const renderFooter = () => {
        return (
            <div>
                <Button loading={addUpdateLoading} disabled={addUpdateLoading} label="No" icon="pi pi-times" onClick={hideDialog} className="p-button-text" />
                <Button loading={addUpdateLoading} disabled={addUpdateLoading} label="Yes" icon="pi pi-check" onClick={onClick} autoFocus />
            </div>
        );
    }

    const hideDialog = () => {
        changeState(false)
    }

    const onClick = () => {

        const _date = moment(eventDate).format('YYYY-MM-DD')
        if (id) {
            dispatch(AddUpdateSeminarStateAction({ loading: true, error: false }))
            dispatch(UpdateSeminarAction({ id, slug, image: image || oldImage, status: SeminarStatus.key, title, eventDate: _date }))
        } else {
            if (image && title) {
                dispatch(AddUpdateSeminarStateAction({ loading: true, error: false }))
                dispatch(AddSeminarAction({ image, slug, status: SeminarStatus.key, title, eventDate: _date }))
            } else {
                alert('Image and title should be valid')
            }
        }
    }

    return (
        <div>

            <Dialog draggable={false} header="Seminar" visible={state} onHide={hideDialog} style={{ minWidth: '50vw', margin: 10 }} breakpoints={breakpoints} footer={renderFooter()}>

                {addUpdateError && <div className="alert alert-danger text-sm" role="alert">
                    <span className='font-bold'>Error! </span>
                    <span>{addUpdateMessage}</span>
                </div>}
                {addUpdateDone && <div className="alert alert-success text-sm" role="alert">
                    <span className='font-bold'>Success! </span>
                    <span>{addUpdateMessage}</span>
                </div>}
                <div className='py-2'>
                    <InputComponent width={width} name={"Title"} changeValue={settitle} mandatory={true} value={title} />
                    <InputComponent width={width} name={"Slug"} changeValue={setslug} mandatory={true} value={slug} />
                    <DateInputComponent width={width} name={"Event Date"} changeValue={seteventDate} mandatory={true} value={eventDate} />
                    <ImageInputComponent name="Image" value={image} changeValue={setimage} sizeFrom={100} sizeTo={2000} pixels="1049px * 416px" width={width} />
                    <DropdownComponent title="Status" selectedItem={SeminarStatus} changeSelectedItem={setSeminarStatus} items={statuses} selectBy="title" width={width} />
                </div>
            </Dialog>
        </div>
    )
}