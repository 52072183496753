import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ClassNotesTable } from '../../../Components/Common/Table/ClassNotes/ClassNotesTable';
import { ClassNotesDialog } from '../../../Components/Dialog/ClassNotesDialog';
import { ClassNotesAction, ClassNotesStateAction } from '../../../Store/Action/ClassNotes';
import { CourseAction } from '../../../Store/Action/CourseAction';

export const ClassNotesScreen = () => {
    const [dialogState, setdialogState] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(CourseAction())
        dispatch(ClassNotesStateAction({ loading: true }))
        dispatch(ClassNotesAction())
    }, [dispatch])


    return (
        <div className='table-padding'>
            <ClassNotesDialog state={dialogState} changeState={setdialogState} _title="" />
            <div className='mainHeader'>
                <p className='mainHeading'>Manage Class Notes</p>
                <button className='custButton' onClick={() => setdialogState(true)}>Add New</button>
            </div>
            <ClassNotesTable />
        </div>
    );
}
