import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SubjectTable } from '../../../Components/Common/Table/Subject/SubjectTable';
import { SubjectAction, SubjectStateAction } from '../../../Store/Action/SubjectAction';
import { SubjectDialog } from '../../../Components/Dialog/SubjectDialog';

export const SubjectScreen = () => {

    const [dialogState, setdialogState] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(SubjectStateAction({ loading: true }))
        dispatch(SubjectAction())
    }, [dispatch])

    return (
        <div className='table-padding'>
            <SubjectDialog state={dialogState} changeState={setdialogState} oldTitle="" />
            <div className='mainHeader'>
                <p className='mainHeading'>Manage Subjects</p>
                <button className='custButton' onClick={() => setdialogState(true)}>Add New</button>
            </div>
            <SubjectTable />
        </div>
    )
}
