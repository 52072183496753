import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { UsersAction, UsersStateAction } from '../../../Store/Action/UsersAction'
import { UsersTable } from '../../../Components/Common/Table/Users/UsersTable'

export const UsersScreen = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(UsersStateAction({ loading: true }))
        dispatch(UsersAction())

        return () => {

        }
    }, [dispatch])


    return (
        <div className='table-padding'>
            <div className='mainHeader'>
                <p className='mainHeading'>Registered Users</p>
            </div>
            <UsersTable />
        </div>
    )
}