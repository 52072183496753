import { token_api_interceptor } from "../Interceptor/Interceptor";
import { Coupon } from "../Interceptor/Url";

// VIEW
export const COUPON = "COUPON";
export const COUPONSTATE = "COUPONSTATE";

// ADD
export const ADDCOUPON = "ADDCOUPON";
export const ADDUPDATECOUPONSTATE = "ADDUPDATECOUPONSTATE";

// UPDATE
export const UPDATECOUPON = "UPDATECOUPON";
export const UPDATECOUPONSTATE = "UPDATECOUPONSTATE";

// DELETE
export const DELETECOUPON = "DELETECOUPON";
export const DELETECOUPONSTATE = "DELETECOUPONSTATE";


export const CouponStateAction = ({ loading, error }) => {
    return {
        type: COUPONSTATE,
        loading, error,
        message: null
    }
}

export const AddUpdateCouponStateAction = ({ loading, error }) => {
    return {
        type: ADDUPDATECOUPONSTATE,
        loading, error,
        message: null
    }
}

export const CouponAction = () => {
    return async (dispatch) => {
        await token_api_interceptor.post(Coupon)
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: COUPON,
                        data: resp.data.data
                    });
                } else {
                    dispatch({
                        type: COUPONSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: COUPONSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const AddCouponAction = ({ coupon, discount, type, status }) => {
    return async (dispatch) => {
        await token_api_interceptor.post(Coupon + 'add', { coupon, discount, type, status })
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: ADDCOUPON,
                        id: resp.data.id,
                        coupon, discount, couponType: type, status
                    });
                } else {
                    dispatch({
                        type: ADDUPDATECOUPONSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: ADDUPDATECOUPONSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const UpdateCouponAction = ({ id, coupon, discount, type, status }) => {
    return async (dispatch) => {
        await token_api_interceptor.post(Coupon + 'update', { id, coupon, type, discount, status })
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: UPDATECOUPON,
                        id, coupon, discount, couponType: type, status,
                    });
                } else {
                    dispatch({
                        type: ADDUPDATECOUPONSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: ADDUPDATECOUPONSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const DeleteCouponStateAction = ({ loading, error }) => {
    return {
        type: DELETECOUPONSTATE,
        loading, error
    }
}

export const DeleteCouponAction = ({ id }) => {
    return async (dispatch) => {
        await token_api_interceptor.post(Coupon + 'delete', { id })
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: DELETECOUPON,
                        id
                    });
                } else {
                    dispatch({
                        type: DELETECOUPONSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: DELETECOUPONSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};