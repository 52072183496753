import React, { useState } from 'react'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputComponent } from '../Common/Inputs/InputComponent';
import { DropdownComponent } from '../Common/Inputs/DropdownComponent';
import { useDispatch, useSelector } from 'react-redux'
import { statuses } from '../../Constants/Statuses';
import { breakpoints } from '../../Helpers/breakpoints';
import { AddRoleAction, AddUpdateRoleStateAction, UpdateRoleAction } from '../../Store/Action/RoleAction';
import { token_api_interceptor } from '../../Store/Interceptor/Interceptor';
import { MultiSelectDropDown } from '../Common/Inputs/MultiSelectDropDown';

export const RoleDialog = ({ state, changeState, name, pages, status, id }) => {

    const {
        addUpdateLoading,
        addUpdateError,
        addUpdateDone,
        addUpdateMessage, } = useSelector(state => state.role)

    const leftInfoWidth = 100

    const dispatch = useDispatch()
    const [roleName, setroleName] = useState(name)
    const [selectedPages, setselectedPages] = useState([])
    const currStatus = statuses.find(item => item.key === Number(status))
    const [roleStatus, setroleStatus] = useState(currStatus)
    const [availablePages, setavailablePages] = useState([])
    // const [loading, setloading] = useState(false)

    React.useEffect(() => {
        if (state) {
            dispatch(AddUpdateRoleStateAction({ loading: false, error: false }))
        }
        // eslint-disable-next-line
    }, [state])

    React.useEffect(() => {
        // setloading(true)
        token_api_interceptor.post('page')
            .then((res) => {
                if (res.data.status) {
                    setavailablePages(res.data.data)
                    if (id) {
                        const filterData = res.data.data.filter(item => pages.find(dt => dt === item.ID))
                        setselectedPages(filterData)
                    } else {
                        setselectedPages([res.data.data[0]])
                    }
                } else {
                    alert(res.data.message)
                }
            })
            .catch(err => alert('Something went wrong'))
        // .finally(() => setloading(false))

        // eslint-disable-next-line
    }, [id])

    React.useEffect(() => {


        if (availablePages.length) {
            const _findIndex = selectedPages.findIndex(_i => _i?.Name === 'Dashboard')
            if (_findIndex < 0) {
                setselectedPages([...selectedPages, availablePages[0]])
            }
        }
        // eslint-disable-next-line
    }, [selectedPages, availablePages.length])


    React.useEffect(() => {
        if (id) {
        } else {
            setroleName('')
            setroleStatus(statuses[0])
        }
        // eslint-disable-next-line
    }, [addUpdateDone, id])


    const renderFooter = () => {
        return (
            <div>
                <Button loading={addUpdateLoading} disabled={addUpdateLoading} label="No" icon="pi pi-times" onClick={hideDialog} className="p-button-text" />
                <Button loading={addUpdateLoading} disabled={addUpdateLoading} label="Yes" icon="pi pi-check" onClick={onClick} autoFocus />
            </div>
        );
    }

    const hideDialog = () => {
        changeState(false)
    }

    const onClick = () => {
        if (roleName.length) {

            const pagesId = selectedPages.map(item => item.ID)

            dispatch(AddUpdateRoleStateAction({ loading: true, error: false }))
            if (id) {
                dispatch(UpdateRoleAction({ id, name: roleName, status: roleStatus.key, pages: JSON.stringify(pagesId) }))
            } else {
                dispatch(AddRoleAction({ name: roleName, status: roleStatus.key, pages: JSON.stringify(pagesId) }))
            }
        } else {
            alert('Invalid Data')
        }
    }

    return (
        <Dialog draggable={false} header="Role" visible={state} onHide={hideDialog} style={{ minWidth: '50vw', margin: 10 }} breakpoints={breakpoints} footer={renderFooter()}>

            {addUpdateError && <div className="alert alert-danger text-sm" role="alert">
                <span className='font-bold'>Error! </span>
                <span>{addUpdateMessage}</span>
            </div>}
            {addUpdateDone && <div className="alert alert-success text-sm" role="alert">
                <span className='font-bold'>Success! </span>
                <span>{addUpdateMessage}</span>
            </div>}
            <div className='py-2'>
                <InputComponent name="Role Name" value={roleName} changeValue={setroleName} nan={true} width={leftInfoWidth} />
                <MultiSelectDropDown name="Pages" items={availablePages} showItem={2} value={selectedPages} changeValue={setselectedPages} width={leftInfoWidth} label="Name" />
                <DropdownComponent title="Status" selectedItem={roleStatus} changeSelectedItem={setroleStatus} items={statuses} selectBy="title" width={leftInfoWidth} />
            </div>
        </Dialog>
    )
}