import React from 'react'
import { InputText } from 'primereact/inputtext';
import './style.css'

export const DisabledInputComponent = ({ name, value, mandatory = false, width = 130 }) => {
    return (
        <div className="p-fluid grid mb-2 text-sm">
            <div className="field col-12 md:col-4">
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon" style={{ width }}>
                        <span>{name} <span className='text-red-800'>{mandatory && '*'}</span></span>
                    </span>
                    <InputText id="inputgroup" type="text" value={value} disabled />
                </div>
            </div>
        </div>
    )
}