import { LOGOUT } from "../Action/AuthAction";
import { ADDSUBJECT, UPDATESUBJECT, ADDUPDATESUBJECTSTATE, SUBJECT, SUBJECTSTATE, DELETESUBJECTSTATE, DELETESUBJECT, } from "../Action/SubjectAction";

const initialState = {
    data: [],
    loading: true,
    error: false,
    message: null,

    // ADD-UPDATE
    addUpdateLoading: false,
    addUpdateError: false,
    addUpdateMessage: null,
    addUpdateDone: false,

    // DELETE
    deleteLoading: false,
    deleteError: false,
    deleteMessage: null,
    deleteDone: false,
};

export const SubjectReducer = (state = initialState, action) => {
    switch (action.type) {

        // VIEW SUBJECT
        case SUBJECT: {
            return {
                ...state,
                loading: false,
                error: false,
                data: action.data,
                message: null
            };
        }

        case SUBJECTSTATE: {
            return {
                ...state,
                loading: action.loading,
                error: action.error,
                message: action.message

            };
        }

        // ADD SUBJECT
        case ADDSUBJECT: {
            const { id, title } = action
            let data = [...state.data];
            data.push({ ID: id, Title: title })
            return {
                ...state,
                addUpdateLoading: false,
                addUpdateError: false,
                data: data,
                addUpdateMessage: "Subject Added",
                addUpdateDone: true,
            };
        }

        case ADDUPDATESUBJECTSTATE: {
            return {
                ...state,
                addUpdateLoading: action.loading,
                addUpdateError: action.error,
                addUpdateMessage: action.message,
                addUpdateDone: false,

            };
        }

        // UPDATE SUBJECT
        case UPDATESUBJECT: {
            let data = [...state.data];
            const { id, title } = action
            let index = data.findIndex(item =>
                item.ID === id
            )
            data[index].Title = title
            return {
                ...state,
                addUpdateLoading: false,
                addUpdateError: false,
                data: data,
                addUpdateMessage: "Subject Updated",
                addUpdateDone: true,
            };
        }

        // DELETE SUBJECT STATE
        case DELETESUBJECTSTATE: {
            const { loading, error, message } = action
            return {
                ...state,
                deleteLoading: loading,
                deleteError: error,
                deleteMessage: message,
                deleteDone: false,

            };
        }

        // DELETE SUBJECT
        case DELETESUBJECT: {
            let data = [...state.data];
            const { id } = action
            const filteredData = data.filter(item => item.ID !== id)
            return {
                ...state,
                deleteLoading: false,
                deleteError: false,
                data: filteredData,
                deleteMessage: "Subject Deleted",
                deleteDone: true,
            };
        }

        // LOGOUT
        case LOGOUT:
            return initialState;

        default:
            return { ...state };
    }
};