import React, { useState } from 'react'
import { MdOutlineEdit } from 'react-icons/md'
import { SocialDialog } from '../../../Dialog/SocialDialog'
import {
    FaFacebookF,
    FaInstagram,
    FaTelegramPlane,
} from "react-icons/fa";
import {
    BsYoutube
} from "react-icons/bs";
import './style.css'

export const SocialRowComponent = ({ _i, dt }) => {
    const dataKeys = ['Icon', 'Name','Count', 'Action']
    const [dialogState, setdialogState] = useState(false)
    return (
        <tr>
            <SocialDialog state={dialogState} changeState={setdialogState} count={dt.Count} id={dt.ID} name={dt.Name} />
            <td className="px-3 py-4 whitespace-nowrap">
                <div className="text-md font-medium text-gray-600 text-center">
                    <span>{_i}</span>
                </div>
            </td>
            {
                dataKeys.map((item, ind) => {
                    if (item === 'Icon') {
                        return <td key={ind} className="px-3 whitespace-nowrap">
                            <div className="flex justify-center items-center">
                                {
                                    dt.Name === 'Facebook' ?
                                        <div className='social-fb p-2 rounded-md'>
                                            <FaFacebookF color='#FFF' />
                                        </div>
                                        : dt.Name === 'Instagram' ? <div className='social-insta p-2 rounded-md'>
                                            <FaInstagram color='#FFF' />
                                        </div> : dt.Name === 'Telegram' ? <div className='social-telegram p-2 rounded-md'>
                                            <FaTelegramPlane color='#FFF' />
                                        </div> : <div className='social-youtube p-2 rounded-md'>
                                            <BsYoutube color='#FFF' />
                                        </div>
                                }
                            </div>
                        </td>
                    } else if (item === 'Action') {
                        const hw = { height: 35, width: 35 }
                        return <td key={ind} className="px-3 py-4 whitespace-nowrap">
                            <div className="flex justify-center">
                                <div onClick={() => setdialogState(true)} className='bg-yellow-500 transition-all duration-300 hover:bg-yellow-800 cursor-pointer flex justify-center items-center rounded-lg' style={hw}>
                                    <MdOutlineEdit color='white' size={18} />
                                </div>
                            </div>
                        </td>

                    }
                    return <td key={ind} className="px-3 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900 text-center">
                            <span>{dt[item]}</span>
                        </div>
                    </td>

                })
            }
        </tr>
    )
}