import { token_api_interceptor } from "../Interceptor/Interceptor";
import { LectureUrl } from "../Interceptor/Url";

// VIEW
export const LECTURE = "LECTURE";
export const LECTURESTATE = "LECTURESTATE";

// ADD
export const ADDLECTURE = "ADDLECTURE";
export const ADDUPDATELECTURESTATE = "ADDUPDATELECTURESTATE";

// UPDATE
export const UPDATELECTURE = "UPDATELECTURE";

// DELETE
export const DELETELECTURE = "DELETELECTURE";
export const DELETELECTURESTATE = "DELETELECTURESTATE";

export const LectureStateAction = ({ loading, error }) => {
    return {
        type: LECTURESTATE,
        loading, error,
        message: null
    }
}

export const LectureAction = () => {
    return async (dispatch) => {
        await token_api_interceptor.post(LectureUrl)
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: LECTURE,
                        data: resp.data.data
                    });
                } else {
                    dispatch({
                        type: LECTURESTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: LECTURESTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const AddUpdateLectureStateAction = ({ loading, error }) => {
    return {
        type: ADDUPDATELECTURESTATE,
        loading, error,
        message: null
    }
}

export const AddLectureAction = ({ title, id, description, subject }) => {
    return async (dispatch) => {
        await token_api_interceptor.post(LectureUrl + 'add', { title, id, description, subject })
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: ADDLECTURE,
                        title, schId: id, id: resp.data.id, description, subject
                    });
                } else {
                    dispatch({
                        type: ADDUPDATELECTURESTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: ADDUPDATELECTURESTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const UpdateLectureAction = ({ title, id, description, subject }) => {
    return async (dispatch) => {
        await token_api_interceptor.post(LectureUrl + 'update', { title, id, description, subject })
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: UPDATELECTURE,
                        title, id, description, subject
                    });
                } else {
                    dispatch({
                        type: ADDUPDATELECTURESTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: ADDUPDATELECTURESTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const DeleteLectureStateAction = ({ loading, error }) => {
    return {
        type: DELETELECTURESTATE,
        loading, error,
        message: null
    }
}

export const DeleteLectureAction = ({ id }) => {
    return async (dispatch) => {
        await token_api_interceptor.post(LectureUrl + 'delete', { id })
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: DELETELECTURE,
                        id
                    });
                } else {
                    dispatch({
                        type: DELETELECTURESTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: DELETELECTURESTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};
