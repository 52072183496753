import React, { useState } from 'react'
import { MdOutlineEdit, MdDelete } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { shortString } from '../../../../Helpers/ShortString'
import { imagebase_url } from '../../../../Store/Interceptor/Url'
import { useSelector } from 'react-redux'
import { DeleteAlertComponent } from '../../Alert/DeleteAlertComponent'
import { DeleteCourseAction, DeleteCourseStateAction } from '../../../../Store/Action/CourseAction'
import { numberWithCommasNoDecimal } from '../../../../Helpers/numberWithCommas'

export const CourseRowComponent = ({ _i, dt }) => {
    const dataKeys = ['Title', 'Image', 'Price', 'FinalPrice', 'Status', 'Action']
    const [alertState, setalertState] = useState(false)

    const { deleteLoading, deleteError, deleteMessage, deleteDone } = useSelector(state => state.course)

    const navigate = useNavigate()

    const courseStatusColor = (key) => {
        switch (key) {
            case 'Upcoming':
                return 'bg-yellow-100 text-yellow-800'

            case 'Booking':
                return 'bg-blue-100 text-blue-800'

            case 'Ongoing':
                return 'bg-orange-100 text-orange-800'

            case 'Complete':
                return 'bg-green-100 text-green-800'

            case 'In-Active':
            case 'Draft':
                return 'bg-red-100 text-red-800'

            default:
                return 'bg-purple-100 text-purple-800'
        }
    }

    return (
        <tr className={dt.Draft ? 'bg-red-50 border-b-1' : ''} style={{ borderBottom: dt.Draft ? '1px solid #ff6b6d' : '0' }}>
            <td className="px-3 py-4 whitespace-nowrap">
                <div className="text-md font-medium text-gray-600 text-center">
                    <span>{_i}</span>
                </div>
            </td>
            <DeleteAlertComponent state={alertState} changeState={setalertState} id={dt.ID} data={{ deleteLoading, deleteError, deleteMessage, deleteDone }} deleteNow={DeleteCourseAction} deleteState={DeleteCourseStateAction} />
            {
                dataKeys.map((item, ind) => {
                    if (item === 'Status') {
                        return <td key={ind} className="px-3 py-4 text-center">
                            <div style={{ width: 120 }} className={"px-1 py-1 text-xs font-semibold rounded-full m-auto ".concat(courseStatusColor(dt.StatusName))}>
                                {dt.StatusName}
                            </div>
                        </td>
                    } else if (item === 'Action') {
                        const hw = { height: 35, width: 35 }
                        return <td key={ind} className="px-3 py-4 whitespace-nowrap">
                            <div className="flex justify-center">
                                <div onClick={() => {
                                    navigate({
                                        pathname: '/course',
                                        search: 'key=' + dt.ID
                                    })
                                }} className='bg-yellow-500 transition-all duration-300 hover:bg-yellow-800 cursor-pointer flex justify-center items-center rounded-lg' style={hw}>
                                    <MdOutlineEdit color='white' size={18} />
                                </div>
                                <div onClick={() => setalertState(true)} className='bg-red-600 transition-all duration-300 hover:bg-red-900 cursor-pointer ml-2 flex justify-center items-center rounded-lg' style={hw}>
                                    <MdDelete color='white' size={18} />
                                </div>
                            </div>
                        </td>

                    } else if (item === 'Image') {

                        return <td key={ind} className="px-3 whitespace-nowrap flex justify-center items-center">
                            <div style={{ height: 80, width: 80 }}>
                                <img src={imagebase_url + 'Course/' + dt[item]} alt='Course' className='w-full h-full object-cover' />
                            </div>
                        </td>
                    } else if (item.endsWith('Price')) {

                        return <td key={ind} className="px-3 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900 text-center">
                                <span>{numberWithCommasNoDecimal(dt[item])}</span>
                            </div>
                        </td>
                    }
                    return <td key={ind} className="px-3 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900 text-center">
                            <span> {shortString({ str: dt[item], len: 20 })}</span>
                        </div>
                    </td>

                })
            }
        </tr>
    )
}