import React from 'react'
import { LectureRowComponent } from './LectureRowComponent'
import { BsArrowDownUp } from 'react-icons/bs'

export const LectureSubjectContainer = ({ data, tableIndex }) => {

    const [showData, setshowData] = React.useState([])

    const column = ['Sr.', 'Title', 'Course', 'Status', 'Meeting Date', 'Created at', 'Action']

    const onClick = () => {
        const _data = [...showData].reverse()
        setshowData(_data)
    }

    React.useEffect(() => {
        let _ids = []
        let _data = []
        data.forEach((item) => {
            if (!_ids.includes(item.Subject)) {
                const filteredData = data.filter(_i => _i.Subject === item.Subject)
                _ids.push(item.Subject)
                _data.push({
                    id: item.Subject,
                    title: item.SubjectName,
                    data: filteredData
                })
            }
        })
        setshowData(data)
    }, [data])

    return (
        <div key={showData}>
            <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                    <tr>
                        {
                            column.map((item, index) => {
                                if (item === 'Meeting Date') {
                                    return <th key={index}
                                        onClick={onClick}
                                        scope="col"
                                        className="flex-1 py-3 text-center text-base font-semibold text-gray-500 tracking-wider mx-2 cursor-pointer flex items-center justify-center"
                                    >
                                        {item}
                                        <div className='w-1'></div>
                                        <BsArrowDownUp />
                                    </th>
                                }
                                return <th key={index}
                                    scope="col"
                                    className="flex-1 py-3 text-center text-base font-semibold text-gray-500 tracking-wider mx-2 "
                                >
                                    {item}
                                </th>
                            })
                        }
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {showData.map((dt, _i) => <LectureRowComponent _i={tableIndex * 10 - (9 - _i)} key={dt.ID} dt={dt} />
                    )}
                </tbody>
            </table>
        </div>
    )
}
