import { token_api_interceptor } from "../Interceptor/Interceptor";
import { AdminAccess } from "../Interceptor/Url";

// VIEW
export const ADMINACCESS = "ADMINACCESS";
export const ADMINACCESSSTATE = "ADMINACCESSSTATE";

// ADD
export const ADDADMINACCESS = "ADDADMINACCESS";
export const ADDUPDATEADMINACCESSSTATE = "ADDUPDATEADMINACCESSSTATE";

// UPDATE
export const UPDATEADMINACCESS = "UPDATEADMINACCESS";
export const UPDATEADMINACCESSSTATE = "UPDATEADMINACCESSSTATE";

// DELETE
export const DELETEADMINACCESS = "DELETEADMINACCESS";
export const DELETEADMINACCESSSTATE = "DELETEADMINACCESSSTATE";


export const AdminAccessStateAction = ({ loading, error }) => {
    return {
        type: ADMINACCESSSTATE,
        loading, error,
        message: null
    }
}

export const AddUpdateAdminAccessStateAction = ({ loading, error }) => {
    return {
        type: ADDUPDATEADMINACCESSSTATE,
        loading, error,
        message: null
    }
}

export const AdminAccessAction = () => {
    return async (dispatch) => {
        await token_api_interceptor.post(AdminAccess)
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: ADMINACCESS,
                        data: resp.data.data
                    });
                } else {
                    dispatch({
                        type: ADMINACCESSSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: ADMINACCESSSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const AddAdminAccessAction = ({ name, status, password, role, roleName }) => {
    return async (dispatch) => {
        await token_api_interceptor.post(AdminAccess + 'add', { name, status, password, role })
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: ADDADMINACCESS,
                        id: resp.data.id,
                        name, status, password, role, roleName
                    });
                } else {
                    dispatch({
                        type: ADDUPDATEADMINACCESSSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: ADDUPDATEADMINACCESSSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const UpdateAdminAccessAction = ({ id, name, status, password, role, roleName }) => {

    return async (dispatch) => {
        await token_api_interceptor.post(AdminAccess + 'update', { id, name, status, password, role })
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: UPDATEADMINACCESS,
                        id, name, status, password, role, roleName
                    });
                } else {
                    dispatch({
                        type: ADDUPDATEADMINACCESSSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: ADDUPDATEADMINACCESSSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const DeleteAdminAccessStateAction = ({ loading, error }) => {
    return {
        type: DELETEADMINACCESSSTATE,
        loading, error
    }
}

export const DeleteAdminAccessAction = ({ id }) => {

    return async (dispatch) => {
        await token_api_interceptor.post(AdminAccess + 'delete', { id })
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: DELETEADMINACCESS,
                        id
                    });
                } else {
                    dispatch({
                        type: DELETEADMINACCESSSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: DELETEADMINACCESSSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};