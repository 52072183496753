import React, { useState, useEffect } from 'react';
// import { AiFillCheckCircle } from "react-icons/ai";
import { MdAssignmentInd, MdVerifiedUser, MdClass, MdOutlineSupport } from "react-icons/md";
import { ImUser } from "react-icons/im";
import { BiUser, BiUserPin } from "react-icons/bi";
import { RiDraftLine, RiShieldUserLine } from "react-icons/ri";
import { CalendarComponent } from '../../Components/Dashboard/CalendarComponent';
import './style.css'
import { token_api_interceptor } from '../../Store/Interceptor/Interceptor';
import { RenderLottie } from '../../Components/Common/RenderLottie';
import LoadingLottie from '../../Asset/loading.json';
import ErrorLottie from '../../Asset/error.json';
import { useSelector } from 'react-redux';

export function DashboardScreen() {
    const [wish, setwish] = useState();

    const { user, role } = useSelector(state => state.auth)

    let myDate = new Date().getHours();
    const [loading, setloading] = useState(true)
    const [error, seterror] = useState(false)
    const [errorMessage, seterrorMessage] = useState('')
    const [data, setdata] = useState({
        "Courses": 4,
        "PendingQueries": 6,
        "Users": 17,
        "Draft": 0,
        "Instructor": 6,
        "EnrolledUsers": 3
    })
    const [ongoingCourses, setongoingCourses] = useState([])
    const [teamMembers, setteamMembers] = useState([])

    useEffect(() => {
        if (myDate < 12) setwish("Good Morning");
        else if (myDate >= 12 && myDate <= 17) setwish("Good Afternoon");
        else if (myDate >= 17 && myDate <= 24) setwish("Good Evening");
    }, [myDate]);


    const stats = [
        {
            title: 'Courses',
            value: data?.Courses,
            icon: <MdClass size={20} />
        },
        {
            title: 'Courses In Draft',
            value: data?.Draft,
            icon: <RiDraftLine size={20} />
        },
        {
            title: 'Instructor',
            value: data?.Instructor,
            icon: <RiShieldUserLine size={20} />
        },
        {
            title: 'Pending Queries',
            value: data?.PendingQueries,
            icon: <MdOutlineSupport size={20} />
        },
        {
            title: 'Users',
            value: data?.Users,
            icon: <BiUser size={20} />
        },
        {
            title: 'Enrolled Users',
            value: data?.EnrolledUsers,
            icon: <BiUserPin size={20} />
        },
    ]

    useEffect(() => {
        setloading(true)
        token_api_interceptor.post('dashboard')
            .then(res => {
                if (res.data.status) {
                    seterror(false)
                    setdata(res.data.data)
                    setongoingCourses(res.data.ongoingCourses)
                    setteamMembers(res.data.teamMembers)
                } else {
                    seterror(true)
                    seterrorMessage(res.data.message)
                    alert(res.data.message)
                }
            })
            .catch(err => {
                seterror(true)
                seterrorMessage("Something went wrong")
                alert('Something went wrong')
            })
            .finally(() => setloading(false))

        return () => {

        }
    }, [])


    return (
        <div className='w-full'>
            {/* INTRO */}
            <div
                style={{
                    padding: 20,
                    backgroundColor: "#FFF",
                    display: "flex",
                    alignItems: "center",
                    boxShadow:
                        "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
                }}
            >
                <div className="profileIcon">
                    <p className='text-gray-600'>{user.toUpperCase().slice(0, 1)}</p>
                </div>
                <div
                    style={{
                        paddingLeft: 20,
                        color: "rgba(0,0,0,0.75)",
                    }}
                >
                    <h3 className="wish">
                        {wish}, {user.toLowerCase()}
                    </h3>
                    <div className='flex justify-between w-full'>
                        <div className='flex items-center'>
                            {" "}
                            <MdAssignmentInd
                                size={20}
                                color="grey"
                                style={{ cursor: "pointer" }}
                            />
                            <p className="positionContent">{role} </p>{" "}
                        </div>
                        <div className='flex items-center'>
                            <MdVerifiedUser
                                size={20}
                                color="#34D399"
                                style={{ cursor: "pointer" }}
                            />
                            <p className="positionContent">Verified Account</p>{" "}
                        </div>
                    </div>
                </div>
            </div>

            {/* MAIN CONTAINER */}
            {loading ? <div className='mt-2'> <RenderLottie width="70%" maxWidth={300} lottie={LoadingLottie} /></div> : error ? <div className='flex flex-col items-center my-2'>
                <RenderLottie width="70%" maxWidth={500} lottie={ErrorLottie} />
                <p className='font-semibold text-gray-500 text-lg'>{errorMessage}</p>
            </div> : <div id='dashboardMainConatainer' className='p-4'>

                {/* LEFT CONTAINER */}
                <div className='w-full'>

                    <p className='text-2xl text-gray-600 font-semibold mb-2'>Overview</p>
                    <div id='statsContainer' className='mb-4'>
                        {
                            stats.map((item, index) => <div key={index} className={'p-3 flex items-center rounded-md stat'.concat(index + 1)}>
                                <div className={'flex justify-center items-center rounded-lg stat'.concat(index + 1 + '-icon')} style={{ height: 40, width: 40 }}>
                                    {item.icon}
                                </div>
                                <div className='ml-3'>
                                    <p className='font-semibold text-xl'>{item.title}</p>
                                    <p className='text-xs'>{item.value}</p>
                                </div>
                            </div>)
                        }
                    </div>


                    <p className='text-2xl text-gray-600 font-semibold'>Ongoing Courses</p>
                    <div id='ongoingCourses'>
                        {
                            ongoingCourses?.map((item, index) => {

                                return <div key={index} className={'w-full rounded-md flex p-3 tile'.concat((index % 5) + 1)}>

                                    <div className={'flex justify-center items-center rounded-xl tile'.concat((index % 5) + 1 + '-icon')} style={{ aspectRatio : 1/1, height: 40 }}>
                                        <p className='font-semibold'>{item.Title.slice(0, 1).toUpperCase()}</p>
                                    </div>
                                    <div className='pl-3'>
                                        <p className='font-semibold '>{item.Title}</p>
                                        <div className='flex items-center py-1 '>
                                            <ImUser size={15} />
                                            <p className='pl-1' style={{ fontSize: 12 }}>{item.Instructor}</p>
                                        </div>
                                        <div className='flex flex-wrap'>
                                            {
                                                item?.batch?.map((dt, ind) => {
                                                    return <div key={ind} className='bg-white font-bold rounded-md p-2 m-1' style={{ fontSize: 10 }}>{dt.Batch}</div>
                                                })
                                            }
                                        </div>
                                    </div>

                                </div>
                            })
                        }
                    </div>

                </div>

                {/* RIGHT CONTAINER */}
                <div className='w-full flex items-center flex-col'>
                    <CalendarComponent />
                    <div className='flex flex-col items-start w-full my-3 px-3'>
                        <p className='font-bold mb-2 text-xl'>Team Members</p>
                        <div className="overflow-auto w-full team-members">
                            {
                                teamMembers?.map((item, index) => {
                                    return <div key={index} className={'flex rounded-md mb-2 w-full p-2 shadow-md color-'.concat((index % 6) + 1)}>
                                        <div className={'mr-2 rounded-full flex justify-center items-center color-l-'.concat((index % 6) + 1)} style={{ width: 40, height: 40 }}>
                                            <p className='text-sm text-white'>{item.Name.slice(0, 1).toUpperCase()}</p>
                                        </div>
                                        <div>
                                            <p className='font-bold'>{item.Name}</p>
                                            <p className='text-xs'>{item.Role}</p>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    )
}
