import React, { useState } from 'react'
import { MdOutlineEdit, MdDelete } from 'react-icons/md'
import { DeleteCouponAction, DeleteCouponStateAction } from '../../../../Store/Action/CouponAction'
import { CouponDialog } from '../../../Dialog/CouponDialog'
import { DeleteAlertComponent } from '../../Alert/DeleteAlertComponent'
import { useSelector } from 'react-redux'

export const CouponRowComponent = ({ _i, dt }) => {
    const dataKeys = ['Coupon', 'Discount', 'Status', 'Action']
    const [dialogState, setdialogState] = useState(false)
    const [alertState, setalertState] = useState(false)

    const { deleteLoading, deleteError, deleteMessage, deleteDone } = useSelector(state => state.coupon)

    return (
        <tr>
            <CouponDialog state={dialogState} changeState={setdialogState} id={dt.ID} status={dt.Status} oldcoupon={dt.Coupon} disc={dt.Discount} type={dt.Type} />
            <DeleteAlertComponent state={alertState} changeState={setalertState} id={dt.ID} data={{ deleteLoading, deleteError, deleteMessage, deleteDone }} deleteNow={DeleteCouponAction} deleteState={DeleteCouponStateAction} />
            <td className="px-3 py-4 whitespace-nowrap">
                <div className="text-md font-medium text-gray-600 text-center">
                    <span>{_i}</span>
                </div>
            </td>
            {
                dataKeys.map((item, ind) => {
                    if (item === 'Status') {
                        return <td key={ind} className="px-3 py-4 text-center">
                            <div style={{ width: 80 }} className={"px-1 py-1 text-xs font-semibold rounded-full m-auto ".concat(dt.Status ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800')}>
                                {dt.Status ? 'Active' : 'In-Active'}
                            </div>
                        </td>
                    } else if (item === 'Action') {
                        const hw = { height: 35, width: 35 }
                        return <td key={ind} className="px-3 py-4 whitespace-nowrap">
                            <div className="flex justify-center">
                                <div onClick={() => setdialogState(true)} className='bg-yellow-500 transition-all duration-300 hover:bg-yellow-800 cursor-pointer flex justify-center items-center rounded-lg' style={hw}>
                                    <MdOutlineEdit color='white' size={18} />
                                </div>
                                <div onClick={() => setalertState(true)} className='bg-red-600 transition-all duration-300 hover:bg-red-900 cursor-pointer ml-2 flex justify-center items-center rounded-lg' style={hw}>
                                    <MdDelete color='white' size={18} />
                                </div>
                            </div>
                        </td>

                    } else if (item === 'Discount') {
                        return <td key={ind} className="px-3 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900 text-center">
                                {dt.Type === 1 ? <span>{dt.Discount} %</span> :
                                    <span>Rs. {dt.Discount}</span>}
                            </div>
                        </td>
                    }
                    return <td key={ind} className="px-3 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900 text-center">
                            <span>{dt[item]}</span>
                        </div>
                    </td>

                })
            }
        </tr>
    )
}