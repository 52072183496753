import React from 'react'
import { MdOutlineEdit, MdDelete } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { DeleteAlertComponent } from '../../Alert/DeleteAlertComponent'
import { DeleteLectureAction, DeleteLectureStateAction } from '../../../../Store/Action/LectureAction'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

export const LectureRowComponent = ({ _i, dt }) => {
    const dataKeys = ['Title', 'CourseName', 'Status', 'MeetingDate', 'CreatedAt', 'Action']
    const navigate = useNavigate()
    const [alertState, setalertState] = useState(false)
    const { deleteLoading, deleteError, deleteMessage, deleteDone } = useSelector(state => state.lecture)

    return (
        <tr>
            <td className="px-3 py-4 whitespace-nowrap">
                <div className="text-md font-medium text-gray-600 text-center">
                    <span>{_i}</span>
                </div>
            </td>
            <DeleteAlertComponent state={alertState} changeState={setalertState} id={dt.ID} data={{ deleteLoading, deleteError, deleteMessage, deleteDone }} deleteNow={DeleteLectureAction} deleteState={DeleteLectureStateAction} />
            {
                dataKeys.map((item, ind) => {
                    if (item === 'Status') {
                        return <td key={ind} className="px-3 py-4 text-center">
                            <div style={{ width: 80 }} className={"px-1 py-1 text-xs font-semibold rounded-full m-auto ".concat(dt.Status === 1 ? 'bg-orange-100 text-orange-800' : dt.Status === 2 ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800')}>
                                {dt.StatusName}
                            </div>
                        </td>
                    } else if (item === 'CreatedAt' || item === 'MeetingDate') {
                        return <td key={ind} className="px-3 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900 text-center">
                                <span>{moment(dt[item]).format('MMM, Do YY')}</span>
                            </div>
                        </td>
                    } else if (item === 'Action') {
                        const hw = { height: 35, width: 35 }
                        return <td key={ind} className="px-3 py-4 whitespace-nowrap">
                            <div className="flex justify-center">
                                <div onClick={() => {
                                    navigate({
                                        pathname: '/lecture',
                                        search: 'key=' + dt.ID
                                    })
                                }} className='bg-yellow-500 transition-all duration-300 hover:bg-yellow-800 cursor-pointer flex justify-center items-center rounded-lg' style={hw}>
                                    <MdOutlineEdit color='white' size={18} />
                                </div>
                                <div onClick={() => { setalertState(true) }} className='bg-red-600 transition-all duration-300 hover:bg-red-900 cursor-pointer ml-2 flex justify-center items-center rounded-lg' style={hw}>
                                    <MdDelete color='white' size={18} />
                                </div>
                            </div>
                        </td>

                    }
                    return <td key={ind} className="px-3 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900 text-center">
                            <span>{dt[item]}</span>
                        </div>
                    </td>

                })
            }
        </tr>
    )
}