import React, { useState, useEffect } from "react";

// INTERCEPTOR
import { token_api_interceptor } from "../../../Store/Interceptor/Interceptor";

// REDUX
import { useDispatch, useSelector } from "react-redux";

// CONSTANTS
import { ManualLectureUrl } from "../../../Store/Interceptor/Url";

// LIBRARIES
import moment from "moment";

// ACTIONS
import {
  CourseWithBatchAction,
  CourseWithBatchStateAction,
} from "../../../Store/Action/CourseWithBatchAction";

// COMPONENTS
import { DropdownComponent } from "../../../Components/Common/Inputs/DropdownComponent";
import { MultiSelectDropDown } from "../../../Components/Common/Inputs/MultiSelectDropDown";
import { InputComponent } from "../../../Components/Common/Inputs/InputComponent";
import { DisabledInputComponent } from "../../../Components/Common/Inputs/DisabledInputComponent";
import { VideoInputComponent } from "../../../Components/Common/Inputs/VideoInput";
import { DateInputComponent } from "../../../Components/Common/Inputs/DateInputComponent";
import { TextEditorComponent } from "../../../Components/Common/Inputs/TextEditorComponent";
import {
  SubjectAction,
  SubjectStateAction,
} from "../../../Store/Action/SubjectAction";

export const ManualAddLectureScreen = () => {
  const dispatch = useDispatch();
  const courseWithBatchLoading = useSelector(
    (state) => state.courseWithBatch.loading
  );
  const courseWithBatcherror = useSelector(
    (state) => state.courseWithBatch.error
  );
  const courseWithBatchmessage = useSelector(
    (state) => state.courseWithBatch.message
  );
  const courseWithBatchdata = useSelector(
    (state) => state.courseWithBatch.data
  );

  const subjectLoading = useSelector((state) => state.subject.loading);
  const subjecterror = useSelector((state) => state.subject.error);
  const subjectmessage = useSelector((state) => state.subject.message);
  const subjectdata = useSelector((state) => state.subject.data);

  const [title, settitle] = useState("");
  const [selectedCourse, setselectedCourse] = useState([]);
  const [selectedBatch, setselectedBatch] = useState([]);
  const [meetingDate, setmeetingDate] = useState(new Date());
  const [duration, setduration] = useState(0);
  const [description, setdescription] = useState("");
  const [subjectId, setsubjectId] = useState({});

  const [loading, setloading] = useState(false);
  const [file, setfile] = useState();
  const [currentChunkIndex, setcurrentChunkIndex] = useState(0);
  const [showBatches, setshowBatches] = useState([]);
  const [folderName, setfolderName] = useState("");
  const [courseAndBatchId, setcourseAndBatchId] = useState([]);

  // const chunkSize = 5 * 1024 * 1024; // 5 MB
  const chunkSize = 200 * 1024; // 200 KB

  const leftInfowidth = 120;

  // eslint-disable-next-line
  const [fileName, setfileName] = useState(Date.now());

  const emptyData = () => {
    settitle("");
    setduration(0);
    setfile("");
    setselectedCourse([]);
    setselectedBatch([]);
    setloading(false);
    setdescription("");
    setcurrentChunkIndex(0);
    setfileName(Date.now());
  };

  const formatDuration = (durationInMs) => {
    const hours = Math.floor(moment.duration(durationInMs).asHours()) || "00";
    return hours + ":" + moment.utc(durationInMs).format("mm:ss");
  };

  useEffect(() => {
    dispatch(CourseWithBatchStateAction({ loading: true, error: false }));
    dispatch(CourseWithBatchAction());

    dispatch(SubjectStateAction({ loading: true, error: false }));
    dispatch(SubjectAction());

    return () => { };
  }, [dispatch]);

  const uploadChunk = (readerEvent) => {

    const data = readerEvent.target.result
    const blob = new Blob([data], { type: 'video/mp4' });

    const params = new URLSearchParams()
    const formdata = new FormData()

    formdata.append("name", file.name);
    formdata.append("fileName", fileName);
    formdata.append("currentChunkIndex", currentChunkIndex);
    formdata.append('totalChunks', Math.ceil(file.size / chunkSize))
    formdata.append('size', file.size)
    formdata.append("course", folderName);
    formdata.append("duration", duration);
    formdata.append("meetingDate", meetingDate);
    formdata.append("description", description);
    formdata.append("title", title);
    formdata.append("subject", subjectId?.ID);
    formdata.append("courseBatch", JSON.stringify(courseAndBatchId));
    formdata.append("file", blob);
    params.set("course", folderName);

    const url = ManualLectureUrl + "add?" + params.toString();

    token_api_interceptor
      .post(url, formdata, {
        headers: {
          // 'content-type': 'application/octet-stream'
        },
        // onUploadProgress: (p) => {
        //   console.log("upload progress", p);
        // },
      })
      .then((res) => {
        if (res.data.status) {
          const fileSize = file.size
          const isLastChunk = currentChunkIndex === Math.ceil(fileSize / chunkSize) - 1


          if (isLastChunk) {
            emptyData()
            alert('Lecture Uploaded')

          } else {
            setcurrentChunkIndex(currentChunkIndex + 1);
          }
        } else {
          alert("Something went wrong 2");
          setloading(false);
          setcurrentChunkIndex(0);
        }
      })
      .catch((err) => {
        console.log(err)
        setcurrentChunkIndex(0);
        setloading(false);
        alert("Something went wrong 1");
      });

  };


  const onSubmit = () => {
    if (title.length === 0) {
      alert("Invalid Title");
    } else if (selectedBatch?.length && subjectId?.ID) {
      if (file) {
        setloading(true)
        const reader = new FileReader();
        const from = currentChunkIndex * chunkSize;
        const to = from + chunkSize
        const blob = file.slice(from, to);
        reader.onload = uploadChunk;
        reader.readAsArrayBuffer(blob);
      } else {
        alert("File should be selected");
      }
    } else {
      alert("Course and Batch and Subject should be selected");
    }
  };

  useEffect(() => {
    if (currentChunkIndex !== 0) {
      onSubmit();
    }
    // eslint-disable-next-line
  }, [currentChunkIndex]);

  useEffect(() => {
    if (selectedCourse.length) {
      let _data = [];

      selectedCourse.forEach((item) => {
        const findData = courseWithBatchdata.find(
          (_item) => _item.ID === item.ID
        );

        if (findData) {
          let modifiedBatch = findData.batch.map((_item) => {
            let cloneItem = { ..._item };
            cloneItem.Batch = item.Title.concat(" (" + cloneItem.Batch + ")");
            return cloneItem;
          });
          _data.push(...modifiedBatch);
        }
      });
      setshowBatches(_data);
    }
    setselectedBatch([]);
  }, [selectedCourse, courseWithBatchdata]);

  useEffect(() => {
    if (selectedBatch.length) {
      let _ids = [];
      setcourseAndBatchId([]);
      selectedBatch.forEach((item) => {
        setcourseAndBatchId((prev) => [
          ...prev,
          { course: item.CourseID, batch: item.ID },
        ]);
        if (!_ids.includes(item.CourseID)) {
          _ids.push(item.CourseID);
        }
      });

      if (_ids.length === 1) {
        const _c = selectedCourse.find((item) => item.ID === _ids[0]);
        setfolderName(_c.Title?.replace(/\//g, "_")?.replace(/ /g, ""));
      } else {
        setfolderName("Common");
      }
    }
  }, [selectedBatch, selectedCourse]);

  return courseWithBatchLoading || subjectLoading ? (
    <div className="p-3 pt-5 sm:p-5 main-container">
      <p>Loading...</p>
    </div>
  ) : courseWithBatcherror || subjecterror ? (
    <div className="p-3 pt-5 sm:p-5 main-container">
      <p>{courseWithBatchmessage || subjectmessage}</p>
    </div>
  ) : (
    <div className="p-3 pt-5 sm:p-5 main-container">
      <p className="mainHeading mb-3">Add Lecture</p>
      <InputComponent
        disabled={loading}
        width={leftInfowidth}
        name="Title"
        value={title}
        changeValue={settitle}
      />
      <DisabledInputComponent
        width={leftInfowidth}
        name="Duration"
        value={formatDuration(duration * 1000)}
        changeValue={setduration}
        number={true}
      />
      <MultiSelectDropDown
        disabled={loading}
        width={leftInfowidth}
        items={courseWithBatchdata}
        value={selectedCourse}
        changeValue={setselectedCourse}
        name="Course"
        showItem={1}
        label="Title"
      />
      <MultiSelectDropDown
        disabled={loading}
        width={leftInfowidth}
        items={showBatches}
        value={selectedBatch}
        changeValue={setselectedBatch}
        name="Batch"
        showItem={1}
        label="Batch"
      />
      <DropdownComponent
        disabled={loading}
        width={leftInfowidth}
        changeSelectedItem={setsubjectId}
        items={subjectdata}
        selectBy="Title"
        selectedItem={subjectId}
        title="Subject"
      />
      <DateInputComponent
        disabled={loading}
        changeValue={setmeetingDate}
        name="Meeting Date"
        width={leftInfowidth}
        value={meetingDate}
      />
      <VideoInputComponent
        disabled={loading}
        setduration={setduration}
        width={leftInfowidth}
        name="Video"
        changeValue={setfile}
        value={file}
      />
      <TextEditorComponent
        disabled={loading}
        value={description}
        changeValue={setdescription}
        name="Description"
        width={leftInfowidth}
      />

      {loading ? (
        <div className="flex justify-center w-full">
          <div className="bg-slate-300 w-full h-5 rounded-sm overflow-hidden">
            <div
              style={{
                width: `${(
                  ((currentChunkIndex - 1) * 100) /
                  (file?.size / chunkSize)
                ).toFixed(2)}%`,
              }}
              className="h-full transition-all duration-200 bg-green-400 flex justify-end items-center"
            >
              <p className="text-xs text-white mr-2">
                {(
                  ((currentChunkIndex - 1) * 100) /
                  (file?.size / chunkSize)
                ).toFixed(2)}
                %
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex">
          <button
            className="submitButton mt-2 flex justify-center items-center"
            onClick={onSubmit}
          >
            Submit
          </button>
        </div>
      )}
    </div>
  );
};
