import React from 'react'
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';

export const SidebarItemComponent = ({ item, state }) => {
    const navigate = useNavigate();
    const location = useLocation()

    const [dropDown, setdropDown] = React.useState(false)

    const Pages = useSelector(state => state.auth.pages)

    // Pages.find(_dt => _dt.URL === item.url)
    return (
        item?.data?.filter(_d => Pages.find(_dt => _dt.URL === _d?.url)).length || Pages.find(_dt => _dt.URL === item?.url) ?
            <>
                <li onClick={() => {
                    if (item?.data) {
                        setdropDown(!dropDown)
                    } else {
                        navigate(item?.url)
                    }
                }} className={"flex justify-between items-center liRowContainer ".concat(item.url === location.pathname && 'liSelectedRowContainer')}><p className='flex justify-center items-center'><item.icon size={20} /> {state && <span className="pl-3">{item.name}</span>}</p>  <div >
                        {
                            item?.data ? dropDown ? <RiArrowUpSLine size={20} /> : <RiArrowDownSLine size={20} /> : ''
                        }
                    </div></li>
                {dropDown &&
                    item?.data?.map((dt, index) => {
                        return Pages.find(_dt => _dt.URL === dt?.url) ? <li key={index} onClick={() => {
                            navigate(dt?.url)
                        }} className={"ml-5 flex justify-between items-center liRowContainer ".concat(dt.url === location.pathname && 'liSelectedRowContainer')}><p className='flex justify-center items-center'><dt.icon size={20} /> {state && <span className="pl-3">{dt.name}</span>}</p></li> : <li key={index}></li>
                    })
                }
            </> : <></>
    )
}
