import React, { useState } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { MdDelete, MdOutlineEdit } from "react-icons/md";
import { token_api_interceptor } from "../../../../Store/Interceptor/Interceptor";
import { QuestionUrl } from "../../../../Store/Interceptor/Url";
import { QuestionDialog } from "../../../Dialog/QuestionDialog";

export const ViewQuestionRowComponent = ({ data, setdata, item, idx }) => {
  const [modalState, setmodalState] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);

  const onDelete = (_id) => {
    setdeleteLoading(true);
    token_api_interceptor
      .post(QuestionUrl.concat("delete"), { id: _id })
      .then((res) => {
        if (res.data.status) {
          const _data = [...data];
          _data.splice(idx, 1);
          setdata(_data);
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => {
        alert("Something went wrong");
      })
      .finally(() => setdeleteLoading(false));
  };

  return (
    <>
      <td className="px-2 py-4 font-medium">{idx + 1}</td>
      {/* max-w-[100px] */}
      <td className="px-2 max-w-[120px] text-ellipsis py-4 font-medium">
        {item.Question}
      </td>
      <QuestionDialog
        _question={item.Question}
        _option1={item.Option1}
        _option2={item.Option2}
        _option3={item.Option3}
        _option4={item.Option4}
        _answer={item.Answer}
        _id={item.ID}
        _quizId={item.QuizID}
        _data={data}
        _setdata={setdata}
        _idx={idx}
        state={modalState}
        changeState={setmodalState}
      />
      <td className="px-2 max-w-[120px] text-ellipsis py-4">{item.Option1}</td>
      <td className="px-2 max-w-[120px] text-ellipsis py-4">{item.Option2}</td>
      <td className="px-2 max-w-[120px] text-ellipsis py-4">{item.Option3}</td>
      <td className="px-2 max-w-[120px] text-ellipsis py-4">{item.Option4}</td>
      <td className="px-2 max-w-[120px] text-ellipsis py-4">{item.Answer}</td>
      <td className="px-2 max-w-[120px] text-ellipsis py-4">
        {deleteLoading ? (
          <ProgressSpinner
            style={{ width: 35, height: 35 }}
            strokeWidth="4"
            fill="var(--surface-ground)"
            animationDuration=".5s"
          />
        ) : (
          <div className="flex">
            <div
              onClick={() => {
                setmodalState(true);
              }}
              className="bg-yellow-500 transition-all duration-300 hover:bg-yellow-800 cursor-pointer flex justify-center items-center rounded-lg"
              style={{ height: 35, width: 35 }}
            >
              <MdOutlineEdit color="white" size={18} />
            </div>
            <div
              onClick={() => onDelete(item.ID)}
              className="bg-red-600 transition-all duration-300 hover:bg-red-900 cursor-pointer ml-2 flex justify-center items-center rounded-lg"
              style={{ height: 35, width: 35 }}
            >
              <MdDelete color="#FFF" size={18} />
            </div>
          </div>
        )}
      </td>
    </>
  );
};
