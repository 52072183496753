import { token_api_interceptor } from "../Interceptor/Interceptor";
import { AssignmentsUrl } from "../Interceptor/Url";

// VIEW
export const ASSIGNMENTS = "ASSIGNMENTS";
export const ASSIGNMENTSSTATE = "ASSIGNMENTSSTATE";

// ADD
export const ADDASSIGNMENTS = "ADDASSIGNMENTS";
export const ADDUPDATEASSIGNMENTSSTATE = "ADDUPDATEASSIGNMENTSSTATE";

// UPDATE
export const UPDATEASSIGNMENTS = "UPDATEASSIGNMENTS";

// DELETE
export const DELETEASSIGNMENTS = "DELETEASSIGNMENTS";
export const DELETEASSIGNMENTSSTATE = "DELETEASSIGNMENTSSTATE";

export const AssignmentsStateAction = ({ loading, error }) => {
    return {
        type: ASSIGNMENTSSTATE,
        loading, error,
        message: null
    }
}

export const AssignmentsAction = () => {
    return async (dispatch) => {
        await token_api_interceptor.post(AssignmentsUrl)
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: ASSIGNMENTS,
                        data: resp.data.data
                    });
                } else {
                    dispatch({
                        type: ASSIGNMENTSSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: ASSIGNMENTSSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const AddUpdateAssignmentsStateAction = ({ loading, error }) => {
    return {
        type: ADDUPDATEASSIGNMENTSSTATE,
        loading, error,
        message: null
    }
}

export const AddAssignmentsAction = () => {
    return ({
        type: ADDASSIGNMENTS,
    })
};

export const UpdateAssignmentsAction = ({ title, course, batch, file, date, dueDate, marks, minMarks, id }) => {


    const formData = new FormData()
    formData.append('title', title)
    formData.append('course', course)
    formData.append('batch', batch)
    formData.append('file', file)
    formData.append('date', date)
    formData.append('dueDate', dueDate)
    formData.append('marks', marks)
    formData.append('minMarks', minMarks)
    formData.append('id', id)


    return async (dispatch) => {
        await token_api_interceptor.post(AssignmentsUrl + 'update', formData)
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: UPDATEASSIGNMENTS,
                        title, id
                    });
                } else {
                    dispatch({
                        type: ADDUPDATEASSIGNMENTSSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: ADDUPDATEASSIGNMENTSSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const DeleteAssignmentsStateAction = ({ loading, error }) => {
    return {
        type: DELETEASSIGNMENTSSTATE,
        loading, error,
        message: null
    }
}

export const DeleteAssignmentsAction = ({ id }) => {
    return async (dispatch) => {
        await token_api_interceptor.post(AssignmentsUrl + 'delete', { id })
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: DELETEASSIGNMENTS,
                        id
                    });
                } else {
                    dispatch({
                        type: DELETEASSIGNMENTSSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: DELETEASSIGNMENTSSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};
