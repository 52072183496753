import React, { useCallback } from 'react'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { FiAlertTriangle } from 'react-icons/fi'

export const FolderDeleteAlertComponent = ({ state, changeState, changeAlertState }) => {


    const renderFooter = () => {
        return (
            <div>
                <Button label="No" icon="pi pi-times" onClick={hideDialog} className="p-button-text" />
                <Button label="Yes" icon="pi pi-check" onClick={onClick} />
            </div>
        );
    }

    const hideDialog = useCallback(
        () => {
            changeState(false)
        },
        [changeState],
    )


    const onClick = () => {
        changeState(false)
        changeAlertState(true)
    }

    return (
        <Dialog draggable={false} header="Alert" visible={state} onHide={hideDialog} breakpoints={{ '960px': '50vw' }} style={{ width: '30vw' }} footer={renderFooter()}>
            {
                <div className="alert alert-danger text-sm" role="alert">
                    <span className='font-bold'>Error! </span>
                    <span>Folder Data is not empty</span>
                </div>
            }
            <div className='flex items-center py-1'>
                <FiAlertTriangle size={35} /> <p className='ml-3'>You will lost access to items of this folder</p>
            </div>
        </Dialog>
    )
}