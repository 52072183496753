import { token_api_interceptor } from "../Interceptor/Interceptor";
import { Course } from "../Interceptor/Url";

// VIEW
export const COURSE = "COURSE";
export const COURSESTATE = "COURSESTATE";

// ADD
export const ADDCOURSE = "ADDCOURSE";
export const ADDUPDATECOURSESTATE = "ADDUPDATECOURSESTATE";

// UPDATE
export const UPDATECOURSE = "UPDATECOURSE";
export const UPDATECOURSESTATE = "UPDATECOURSESTATE";

// DELETE
export const DELETECOURSE = "DELETECOURSE";
export const DELETECOURSESTATE = "DELETECOURSESTATE";


export const CourseStateAction = ({ loading, error }) => {
    return {
        type: COURSESTATE,
        loading, error,
        message: null
    }
}

export const AddUpdateCourseStateAction = ({ loading, error }) => {
    return {
        type: ADDUPDATECOURSESTATE,
        loading, error,
        message: null
    }
}

export const CourseAction = () => {
    return async (dispatch) => {
        await token_api_interceptor.post(Course)
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: COURSE,
                        data: resp.data.data
                    });
                } else {
                    dispatch({
                        type: COURSESTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: COURSESTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const AddCourseAction = ({ title, subTitle, image, tag, instructor, description, bookSeat, courseIncludes, status, coupon, installment, price, finalPrice, faq, draft, slug }) => {
    const formdata = new FormData();
    formdata.append('title', title)
    formdata.append('slug', slug)
    formdata.append('subTitle', subTitle)
    formdata.append('image', image)
    formdata.append('tag', tag || 0)
    formdata.append('instructor', instructor || 0)
    formdata.append('description', description)
    formdata.append('bookSeat', bookSeat)
    formdata.append('courseIncludes', courseIncludes)
    formdata.append('status', status)
    formdata.append('coupon', coupon)
    formdata.append('installment', installment)
    formdata.append('price', price || 0)
    formdata.append('finalPrice', finalPrice || 0)
    formdata.append('faq', faq)
    formdata.append('draft', draft)
    return async (dispatch) => {
        await token_api_interceptor.post(Course + 'add', formdata)
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: ADDCOURSE,
                        id: resp.data.id,
                        title, subTitle, image, tag, instructor, description, bookSeat, courseIncludes, status, coupon, installment, price, finalPrice, faq, message: resp.data.message, draft, slug
                    });
                } else {
                    dispatch({
                        type: ADDUPDATECOURSESTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: ADDUPDATECOURSESTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const UpdateCourseAction = ({ id, title, subTitle, image, tag, instructor, description, bookSeat, courseIncludes, status, coupon, installment, price, finalPrice, faq, draft, slug }) => {
    return async (dispatch) => {
        const formdata = new FormData();
        formdata.append('id', id)
        formdata.append('title', title)
        formdata.append('slug', slug)
        formdata.append('subTitle', subTitle)
        formdata.append('image', image)
        formdata.append('tag', tag)
        formdata.append('instructor', instructor)
        formdata.append('description', description)
        formdata.append('bookSeat', bookSeat)
        formdata.append('courseIncludes', courseIncludes)
        formdata.append('status', status)
        formdata.append('coupon', coupon)
        formdata.append('installment', installment)
        formdata.append('price', price)
        formdata.append('finalPrice', finalPrice || 0)
        formdata.append('faq', faq)
        formdata.append('draft', draft)

        await token_api_interceptor.post(Course + 'update', formdata)
            .then((resp) => {
                if (resp.data.status) {
                    const s_image = resp.data.image
                    dispatch({
                        type: UPDATECOURSE,
                        id, title, subTitle, image: s_image, tag, instructor, description, bookSeat, courseIncludes, status, coupon, installment, price, finalPrice, faq, draft, slug
                    });
                } else {
                    dispatch({
                        type: ADDUPDATECOURSESTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: ADDUPDATECOURSESTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const DeleteCourseStateAction = ({ loading, error }) => {
    return {
        type: DELETECOURSESTATE,
        loading, error
    }
}

export const DeleteCourseAction = ({ id }) => {
    return async (dispatch) => {

        await token_api_interceptor.post(Course + 'delete', { id })
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: DELETECOURSE,
                        id
                    });
                } else {
                    dispatch({
                        type: DELETECOURSESTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: DELETECOURSESTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};