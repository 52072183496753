import { ADDADMINACCESS, ADDUPDATEADMINACCESSSTATE, ADMINACCESS, ADMINACCESSSTATE, DELETEADMINACCESS, DELETEADMINACCESSSTATE, UPDATEADMINACCESS } from "../Action/AdminAccessAction";
import { LOGOUT } from "../Action/AuthAction";

const initialState = {
    data: [],
    loading: true,
    error: false,
    message: null,

    // ADD-UPDATE
    addUpdateLoading: false,
    addUpdateError: false,
    addUpdateMessage: null,
    addUpdateDone: false,

    // DELETE
    deleteLoading: false,
    deleteError: false,
    deleteMessage: null,
    deleteDone: false,
};

export const AdminAccessReducer = (state = initialState, action) => {
    switch (action.type) {

        // VIEW ROLE
        case ADMINACCESS: {
            return {
                ...state,
                loading: false,
                error: false,
                data: action.data,
                message: null
            };
        }

        case ADMINACCESSSTATE: {
            return {
                ...state,
                loading: action.loading,
                error: action.error,
                message: action.message
            };
        }

        // ADD ADMIN ACCESS
        case ADDADMINACCESS: {
            const { id, name, status, password, role, roleName } = action
            let data = [...state.data];
            data.push({ ID: id, Name: name, Status: status, Password: password, Role: role, RoleName: roleName })
            return {
                ...state,
                addUpdateLoading: false,
                addUpdateError: false,
                data: data,
                addUpdateMessage: "Admin Access Added",
                addUpdateDone: true,
            };
        }

        case ADDUPDATEADMINACCESSSTATE: {
            return {
                ...state,
                addUpdateLoading: action.loading,
                addUpdateError: action.error,
                addUpdateMessage: action.message,
                addUpdateDone: false,
            };
        }

        // UPDATE ADMIN ACCESS
        case UPDATEADMINACCESS: {
            let data = [...state.data];
            const { id, name, status, password, role, roleName } = action
            let index = data.findIndex(item =>
                item.ID === id
            )
            data[index] = { ID: id, Name: name, Password: password, Role: role, RoleName: roleName, Status: status }
            return {
                ...state,
                addUpdateLoading: false,
                addUpdateError: false,
                data: data,
                addUpdateMessage: "Admin Access Updated",
                addUpdateDone: true,
            };
        }

        // DELETE ADMIN ACCESS STATE
        case DELETEADMINACCESSSTATE: {
            const { loading, error, message } = action
            return {
                ...state,
                deleteLoading: loading,
                deleteError: error,
                deleteMessage: message,
                deleteDone: false,

            };
        }

        // DELETE ADMIN ACCESS
        case DELETEADMINACCESS: {
            let data = [...state.data];
            const { id } = action
            const filteredData = data.filter(item => item.ID !== id)
            return {
                ...state,
                deleteLoading: false,
                deleteError: false,
                data: filteredData,
                deleteMessage: "Admin Access Deleted",
                deleteDone: true,
            };
        }

        // LOGOUT
        case LOGOUT:
            return initialState;

        default:
            return { ...state };
    }
};