import React, { useState } from 'react'
import moment from 'moment/moment'
import { MdOutlineEdit, MdDelete } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { DeleteAlertComponent } from '../../Alert/DeleteAlertComponent'
import { DeleteCourseScheduleStateAction, DeleteCourseScheduleAction } from '../../../../Store/Action/CourseScheduleAction'
import { useSelector } from 'react-redux'

export const CourseScheduleRowComponent = ({ _i, dt }) => {
    const dataKeys = ['CourseName', 'BatchName', 'StartOn', 'Duration', 'Type', 'Status', 'Action']

    const [alertState, setalertState] = useState(false)

    const { deleteLoading, deleteError, deleteMessage, deleteDone } = useSelector(state => state.courseSchedule)

    const navigate = useNavigate()
    return (
        <tr>
            <td className="px-3 py-4 whitespace-nowrap">
                <div className="text-md font-medium text-gray-600 text-center">
                    <span>{_i}</span>
                </div>
            </td>

            <DeleteAlertComponent state={alertState} changeState={setalertState} id={dt.ID} data={{ deleteLoading, deleteError, deleteMessage, deleteDone }} deleteNow={DeleteCourseScheduleAction} deleteState={DeleteCourseScheduleStateAction} />
            {
                dataKeys.map((item, ind) => {
                    if (item === 'Status') {
                        return <td key={ind} className="px-3 py-4 text-center">
                            <div style={{ width: 80 }} className={"px-1 py-1 text-xs font-semibold rounded-full m-auto ".concat(dt.Status ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800')}>
                                {dt.Status ? 'Active' : 'In-Active'}
                            </div>
                        </td>
                    } else if (item === 'Action') {
                        const hw = { height: 35, width: 35 }
                        return <td key={ind} className="px-3 py-4 whitespace-nowrap">
                            <div className="flex justify-center">
                                <div onClick={() => {
                                    navigate({
                                        pathname: '/course-schedule',
                                        search: 'key=' + dt.ID
                                    })
                                }} className='bg-yellow-500 transition-all duration-300 hover:bg-yellow-800 cursor-pointer flex justify-center items-center rounded-lg' style={hw}>
                                    <MdOutlineEdit color='white' size={18} />
                                </div>
                                <div onClick={() => setalertState(true)} className='bg-red-600 transition-all duration-300 hover:bg-red-900 cursor-pointer ml-2 flex justify-center items-center rounded-lg' style={hw}>
                                    <MdDelete color='white' size={18} />
                                </div>
                            </div>
                        </td>

                    }
                    return <td key={ind} className="px-3 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900 text-center">
                            <span>{item === 'StartOn' ? moment(dt[item]).format('ll') : dt[item]?.toString()?.length > 20 ? dt[item]?.toString()?.slice(0, 20)?.concat('...') : dt[item]?.toString()} {item === 'Duration' && ' Months'}</span>
                        </div>
                    </td>

                })
            }
        </tr>
    )
}