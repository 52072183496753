import { LOGOUT } from "../Action/AuthAction";
import { QUIZSTATE, QUIZ, DELETEQUIZ, DELETEQUIZSTATE } from "../Action/Quiz";

const initialState = {
  data: [],
  loading: true,
  error: false,
  message: null,

  // DELETE
  deleteLoading: false,
  deleteError: false,
  deleteMessage: null,
  deleteDone: false,
};

export const QuizReducer = (state = initialState, action) => {
  switch (action.type) {
    // VIEW QUIZ
    case QUIZ: {
      return {
        ...state,
        loading: false,
        error: false,
        data: action.data,
        message: null,
      };
    }

    case QUIZSTATE: {
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        message: action.message,
      };
    }

    // DELETE QUIZ STATE
    case DELETEQUIZSTATE: {
      const { loading, error, message } = action;
      return {
        ...state,
        deleteLoading: loading,
        deleteError: error,
        deleteMessage: message,
        deleteDone: false,
      };
    }

    // DELETE QUIZ
    case DELETEQUIZ: {
      let data = [...state.data];
      const { id } = action;
      const filteredData = data.filter((item) => item.ID !== id);
      return {
        ...state,
        deleteLoading: false,
        deleteError: false,
        data: filteredData,
        deleteMessage: "QUIZ Deleted",
        deleteDone: true,
      };
    }

    // LOGOUT
    case LOGOUT:
      return initialState;

    default:
      return { ...state };
  }
};
