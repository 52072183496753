import { LOGOUT } from "../Action/AuthAction";
import { INSTALLMENTTRACK, INSTALLMENTTRACKCHANGESTATUS, INSTALLMENTTRACKCHANGESTATUSSTATE, INSTALLMENTTRACKSTATE } from "../Action/InstallmentTrackAction";

const initialState = {
    data: [],
    loading: true,
    error: false,
    message: null,

    id: null,
    updateLoading: false,
    updateError: false,
    updateMessage: null
};

export const InstallmentTrackReducer = (state = initialState, action) => {
    switch (action.type) {

        // VIEW INSTALLMENT
        case INSTALLMENTTRACK: {
            return {
                ...state,
                loading: false,
                error: false,
                data: action.data,
                message: null
            };
        }

        case INSTALLMENTTRACKSTATE: {
            return {
                ...state,
                loading: action.loading,
                error: action.error,
                message: action.message
            };
        }

        case INSTALLMENTTRACKCHANGESTATUSSTATE: {
            const { loading, error, message, id } = action
            return {
                ...state,
                updateLoading: loading, updateError: error, updateMessage: message, id
            };
        }

        case INSTALLMENTTRACKCHANGESTATUS: {
            const { id, status } = action

            let data = [...state.data];
            let index = data.findIndex(item =>
                item.ID === id
            )
            data[index].Status = status === 1 ? 'Active' : 'In-Active'
            data[index].StatusN = status

            return {
                ...state,
                updateLoading: false, updateError: false, updateMessage: null, data, id: null
            };
        }

        // LOGOUT
        case LOGOUT:
            return initialState;

        default:
            return { ...state };
    }
};