import React, { useState, useEffect } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { useSelector } from "react-redux";
import { QuizRowComponent } from "./QuizRowComponent";
import { FaSort } from 'react-icons/fa'
// import moment from 'moment';

export function QuizTable({ selectedUser, selectedQuizes, setselectedQuizes }) {
  const [showData, setshowData] = useState([]);
  const [availableData, setavailableData] = useState([]);
  const [numbers, setnumbers] = useState([1]);
  const [indexValue, setindexValue] = useState(1);
  const [tableIndex, settableIndex] = useState(0);

  const [sortByTitle, setsortByTitle] = useState('none')
  const [sortByStartTime, setsortByStartTime] = useState('none')

  const { loading, error, message, data } = useSelector((state) => state.quiz);

  const column = [
    "",
    "Sr.",
    "Title",
    "Course",
    "Start",
    // "End",
    "Marks",
    // "Status",
    "Action",
  ];

  function range(start, end) {
    return Array(end - start + 1)
      .fill()
      .map((_, idx) => start + idx);
  }

  useEffect(() => {
    setshowData(availableData.slice((tableIndex - 1) * 10, tableIndex * 10));
  }, [availableData, tableIndex]);

  useEffect(() => {
    if (
      availableData.length &&
      Math.ceil(availableData.length / 10) > indexValue * 5 - 5
    ) {
      setnumbers(
        range(
          indexValue * 5 - 4,
          Math.ceil(
            availableData.length > indexValue * 5 * 10
              ? indexValue * 5
              : availableData.length / 10
          )
        )
      );
      settableIndex(indexValue * 5 - 4);
    }
  }, [indexValue, availableData.length]);

  useEffect(() => {
    setshowData(availableData.slice(0, 10));
    setnumbers(
      range(
        1,
        Math.ceil(availableData.length > 50 ? 5 : availableData.length / 10)
      )
    );
  }, [availableData]);

  useEffect(() => {
    if (data.length) {
      setavailableData(data.filter((dt) => dt.UserType === selectedUser));
    }
    return () => { };
  }, [selectedUser, data]);

  const sortDataByTitle = () => {

    let data = []

    if (sortByTitle === 'none') {
      data = [...availableData].sort((a, b) => a.Title.substring(0, 1).charCodeAt(0) - b.Title.substring(0, 1).charCodeAt(0))
      setsortByTitle('sort')
    } else {
      data = [...availableData].reverse()
    }

    setavailableData(data)
  }

  const sortDataByStartTime = () => {

    let data = []

    if (sortByStartTime === 'none') {
      data = [...availableData].sort((a, b) => new Date(a.StartTime) - new Date(b.StartTime))
      setsortByStartTime('sort')
    } else {
      data = [...availableData].reverse()
    }

    setavailableData(data)
  }

  return (
    <div
      className="flex flex-col"
      style={{
        width: "100%",
        overflow: "hidden",
      }}
    >
      {loading ? (
        <p>Loading</p>
      ) : error ? (
        <p>{message}</p>
      ) : (
        <>
          <div className="overflow-x-auto sm:-mx-4 lg:-mx-6">
            <div className="align-middle inline-block min-w-full sm:px-4 lg:px-6">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      {column.map((item, index) => {
                        return (
                          <th
                            onClick={() => {
                              if (item === 'Title') {
                                sortDataByTitle()
                              } else if (item === 'Start') {
                                sortDataByStartTime()
                              }
                            }}
                            key={index}
                            scope="col"
                            className="flex-1 cursor-pointer hover:bg-gray-200 duration-300 transition-all py-3 text-center text-base font-semibold text-gray-500 tracking-wider mx-2"
                          >
                            <span>{item}</span>
                            {(item === 'Title' || item === 'Start') && <span className="ml-2"> <FaSort className="inline" /></span>}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {showData.map((dt, index) => (
                      <QuizRowComponent
                        _i={tableIndex * 10 - (9 - index)}
                        key={dt.ID}
                        dt={dt}
                        selectedQuiz={selectedQuizes}
                        setselectedQuiz={setselectedQuizes}
                        selectedUser={selectedUser}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700">
                Showing
                <span className="font-medium mx-1">
                  {availableData.length ? tableIndex * 10 - 9 : 0}
                </span>
                to
                <span className="font-medium mx-1">
                  {tableIndex * 10 > availableData.length
                    ? availableData.length
                    : tableIndex * 10}
                </span>
                of
                <span className="font-medium mx-1">
                  {" "}
                  {availableData.length}
                </span>
                results
              </p>
            </div>
            <div>
              <nav
                className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                aria-label="Pagination"
              >
                <p
                  style={{ marginBottom: 0, cursor: "pointer" }}
                  className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <span className="sr-only">Previous</span>
                  <ChevronLeftIcon
                    className="h-5 w-5"
                    aria-hidden="true"
                    onClick={() => {
                      if (indexValue !== 1) {
                        setindexValue(indexValue - 1);
                      }
                    }}
                  />
                </p>
                {numbers.map((data) => {
                  return (
                    <p
                      onClick={() => {
                        settableIndex(data);
                      }}
                      key={data}
                      style={{ marginBottom: 0, cursor: "pointer" }}
                      className={
                        tableIndex === data
                          ? "z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                          : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                      }
                    >
                      {data}
                    </p>
                  );
                })}
                <p
                  style={{ marginBottom: 0, cursor: "pointer" }}
                  className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <span className="sr-only">Next</span>
                  <ChevronRightIcon
                    className="h-5 w-5"
                    aria-hidden="true"
                    onClick={() => {
                      // changeIndex('increment')
                      setindexValue(indexValue + 1);
                    }}
                  />
                </p>
              </nav>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
