export function numberWithCommas(x) {
    // let finalNumber = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return x ? Number(x).toLocaleString('en-IN', {
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'INR',
    }) : '';
}

export function numberWithCommasNoDecimal(x) {
    // let finalNumber = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return x ? Number(Number(x).toFixed(0)).toLocaleString('en-IN', {
        maximumFractionDigits: 0,
        style: 'currency',
        currency: 'INR',
    }) : '';
}