import React, { useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { breakpoints } from "../../../../Helpers/breakpoints";
import { read, utils } from "xlsx";
import { token_api_interceptor } from "../../../../Store/Interceptor/Interceptor";
import { CgClose } from "react-icons/cg";
import { QuestionUrl } from "../../../../Store/Interceptor/Url";

export const UploadQuestionDialog = ({ state, changeState, id, removeId }) => {
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState([]);

  const ref = useRef();

  const renderFooter = () => {
    return (
      <div>
        <Button
          loading={loading}
          disabled={loading}
          label="Cancel"
          icon="pi pi-times"
          onClick={hideDialog}
          className="p-button-text"
        />
        <Button
          loading={loading}
          disabled={loading}
          label="Upload"
          icon="pi pi-check"
          onClick={onClick}
          autoFocus
        />
      </div>
    );
  };

  const hideDialog = () => {
    changeState(false);
  };

  const onClick = () => {
    if (data.every((item) => item.valid)) {
      setloading(true);
      token_api_interceptor
        .post(QuestionUrl.concat("add"), {
          data: JSON.stringify(data),
          quizId: JSON.stringify(id),
        })
        .then((res) => {
          if (res.data.status) {
            ref.current.value = "";
            setdata([]);
            removeId();
            alert("Questions Submitted");
          } else {
            alert(res.data.message);
          }
        })
        .catch((err) => {
          alert("Something went wrong");
        })
        .finally(() => setloading(false));
    } else {
      alert("Wrong answers found");
    }
  };

  return (
    <div>
      <Dialog
        draggable={false}
        header="Questions"
        visible={state}
        onHide={hideDialog}
        style={{ minWidth: "90vw", margin: 10 }}
        breakpoints={breakpoints}
        footer={renderFooter()}
      >
        <div className="flex flex-col items-center w-full mx-auto p-2">
          <div className="mb-3 w-full">
            <input
              ref={ref}
              className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding py-[0.32rem] px-3 text-base font-normal text-neutral-700  transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100  file:px-3 file:py-[0.32rem] file:text-neutral-700  file:transition file:duration-150 file:ease-in-out file:[margin-inline-end:0.75rem] file:[border-inline-end-width:1px] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none"
              type="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              id="formFile"
              onChange={(e) => {
                const file = e.target.files[0];
                const reader = new FileReader();
                reader.onload = (event) => {
                  const data = new Uint8Array(event.target.result);
                  const workbook = read(data, { type: "array" });
                  const sheetName = workbook.SheetNames[0];
                  const worksheet = workbook.Sheets[sheetName];
                  const sheetData = utils
                    .sheet_to_json(worksheet, {
                      header: 1,
                    })
                    .map((item) => ({
                      Question: item[0],
                      Option1: item[1],
                      Option2: item[2],
                      Option3: item[3],
                      Option4: item[4],
                      Answer: item[5],
                      valid: ["a", "b", "c", "d"].includes(item[5]),
                    }));
                  setdata(sheetData);
                  //   console.log("_DATA IS : ", _data);
                };
                reader.readAsArrayBuffer(file);
              }}
            />
          </div>

          {data.length ? (
            <div className="w-full overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table className="min-w-full text-left text-sm font-light">
                    <thead className="border-b font-medium dark:border-neutral-500">
                      <tr className="flex-6">
                        <th scope="col" className="px-2 py-4 flex-1">
                          #
                        </th>
                        <th scope="col" className="px-2 py-4 flex-1">
                          Question
                        </th>
                        <th scope="col" className="px-2 py-4 flex-1">
                          (a).
                        </th>
                        <th scope="col" className="px-2 py-4">
                          (b).
                        </th>
                        <th scope="col" className="px-2 py-4">
                          (c).
                        </th>
                        <th scope="col" className="px-2 py-4">
                          (d).
                        </th>
                        <th scope="col" className="px-2 py-4">
                          Answer
                        </th>
                        <th scope="col" className="px-2 py-4">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => {
                        return (
                          <tr
                            key={index}
                            className={"border-b border-neutral-300 ".concat(
                              item.valid ? "" : "bg-red-100"
                            )}
                          >
                            <td className="px-2 py-4 font-medium">
                              {index + 1}
                            </td>
                            {/* max-w-[100px] */}
                            <td className="px-2 max-w-[120px] text-ellipsis py-4 font-medium">
                              {item.Question}
                            </td>
                            <td className="px-2 max-w-[120px] text-ellipsis py-4">
                              {item.Option1}
                            </td>
                            <td className="px-2 max-w-[120px] text-ellipsis py-4">
                              {item.Option2}
                            </td>
                            <td className="px-2 max-w-[120px] text-ellipsis py-4">
                              {item.Option3}
                            </td>
                            <td className="px-2 max-w-[120px] text-ellipsis py-4">
                              {item.Option4}
                            </td>
                            <td className="px-2 max-w-[120px] text-ellipsis py-4">
                              {item.Answer}
                            </td>
                            <td className="px-2 max-w-[120px] text-ellipsis py-4">
                              <div
                                onClick={() => {
                                  const dt = [...data];
                                  dt.splice(index, 1);

                                  setdata(dt);
                                }}
                                className="bg-red-600 transition-all duration-300 hover:bg-red-900 cursor-pointer ml-2 flex justify-center items-center rounded-lg"
                                style={{ height: 35, width: 35 }}
                              >
                                <CgClose color="#FFF" size={18} />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </Dialog>
    </div>
  );
};
