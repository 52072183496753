import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CouponTable } from '../../../Components/Common/Table/Coupon/CouponTable';
import { CouponDialog } from '../../../Components/Dialog/CouponDialog';
import { CouponAction, CouponStateAction } from '../../../Store/Action/CouponAction';

export const CouponScreen = () => {

    const [dialogState, setdialogState] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(CouponStateAction({ loading: true }))
        dispatch(CouponAction())
    }, [dispatch])

    return (
        <div className='table-padding'>
            <CouponDialog state={dialogState} changeState={setdialogState} amt={0} oldcoupon="" status={1} disc={0} type={1} />
            <div className='mainHeader'>
                <p className='mainHeading'>Manage Coupons</p>
                <button className='custButton' onClick={() => setdialogState(true)}>Add New</button>
            </div>
            <CouponTable />
        </div>
    )
}
