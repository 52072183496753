import React, { useRef } from 'react'
import './ImageStyle.css'

export const ImageInputComponent = ({ name, value, changeValue, sizeTo, changeImageUrl, mandatory = false, pixels, index = Math.random().toFixed(2) * 100, width = 130 }) => {
    const imageRef = useRef()
    let typeTo = sizeTo < 1000 ? sizeTo + ' KB' : ((sizeTo / 1000) + ' MB')

    React.useEffect(() => {
        if (!value) {
            imageRef.current.value = ''
        }
    }, [value])

    return (
        <>
            <div className="input-group mb-2 custom-input-img text-sm">
                <label className="input-group-text p-inputgroup-addon text-sm" htmlFor={`inputGroupFile${index}`} style={{ width: width }}>{name} <span className='text-red-800'>{mandatory && '*'}</span></label>
                <input accept="image/png,image/jpg,image/jpeg" ref={imageRef} type="file" className="form-control" id={`inputGroupFile${index}`} style={{ padding: 12 }} onChange={(e) => {
                    if (e.target.files.length) {
                        let size = e.target.files[0].size / 1000
                        if (size <= sizeTo) {
                            if (changeImageUrl) {
                                changeImageUrl(URL.createObjectURL(e.target.files[0]))
                            }
                            changeValue(e.target.files[0])
                        } else {

                            let message = `Image size should be smaller than  ${typeTo}`
                            alert(message)
                        }
                    }
                }} />
                <label className="form-control text-gray-500 cursor-pointer text-sm flex items-center" htmlFor={`inputGroupFile${index}`} style={{ padding: 12, borderColor: mandatory ? value || value?.name ? '#ced4da' : '#ffa2a2' : '#ced4da' }}>{value?.name || 'No Image is Selected'}</label>
            </div>
            <div style={{ marginLeft: width + 10, top: -5, fontSize: 11 }} className="text-gray-400 relative">
                <span style={{ fontSize: 11 }}>
                    NOTE : Image size should be smaller than {typeTo} (Dimensions : {pixels})
                </span>
            </div>
        </>
    )
}