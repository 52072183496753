import React, { useState } from "react";

// REDUX
import ReduxThunk from "redux-thunk";
import { createStore, combineReducers, applyMiddleware } from "redux";
import { Provider, useDispatch, useSelector } from "react-redux";

// KEYS
import { storagekey } from "./Constants/StringValue";

// ACTIONS
import { AutoLogin, LogoutAction } from "./Store/Action/AuthAction";
import { SessionExpiredMessageAction } from "./Store/Action/SessionExpireAction";

// COMPONENTS
import { SideBar } from "./Components/Common/SideBar";
import { Navbar } from "./Components/Common/Navbar";

// INTERCEPTOR
import { token_api_interceptor } from "./Store/Interceptor/Interceptor";

// ROUTER-DOM
import { BrowserRouter } from "react-router-dom";

// REDUCERS
import { authReducer } from "./Store/Reducer/Auth";
import { AssignmentsReducer } from "./Store/Reducer/Assignments";
import { UserAssignmentsReducer } from "./Store/Reducer/UserAssignments";
import { SubjectReducer } from "./Store/Reducer/SubjectReducer";
import { CourseWithBatchReducer } from "./Store/Reducer/CourseWithBatch";
import { RoleReducer } from "./Store/Reducer/Role";
import { AdminAccessReducer } from "./Store/Reducer/AdminAccess";
import { CurrentAffairsReducer } from "./Store/Reducer/CurrentAffairs";
import { ClassNotesReducer } from "./Store/Reducer/ClassNotes";
import { ManualBookSeatReducer } from "./Store/Reducer/ManualBookSeat";
import { transactionReducer } from "./Store/Reducer/Transaction";
import { CourseTagReducer } from "./Store/Reducer/CourseTag";
import { CourseReducer } from "./Store/Reducer/Course";
import { batchReducer } from "./Store/Reducer/Batch";
import { CouponReducer } from "./Store/Reducer/Coupon";
import { InstructorReducer } from "./Store/Reducer/Instructor";
import { CourseStatusReducer } from "./Store/Reducer/CourseStatus";
import { InstallmentReducer } from "./Store/Reducer/Installment";
import { SocialReducer } from "./Store/Reducer/SocialReducer";
import { CourseScheduleReducer } from "./Store/Reducer/CourseSchedule";
import { SeminarReducer } from "./Store/Reducer/Seminar";
import { SliderReducer } from "./Store/Reducer/Slider";
import { YoutubeReducer } from "./Store/Reducer/YouTube";
import { queryReducer } from "./Store/Reducer/query";
import { InstallmentTrackReducer } from "./Store/Reducer/InstallmentTrack";
import { LectureReducer } from "./Store/Reducer/Lecture";
import { UsersReducer } from "./Store/Reducer/User";
import { EnrolledUsersReducer } from "./Store/Reducer/EnrolledUser";
import { SessionExpireReducer } from "./Store/Reducer/SessionExpire";

// ROUTES
import { AuthRoutes, MainRoutes } from "./routes";
import { QuizReducer } from "./Store/Reducer/Quiz";

// COMBINE REDUCERS
const rootReducer = combineReducers({
  auth: authReducer,
  batch: batchReducer,
  coupon: CouponReducer,
  instructor: InstructorReducer,
  courseTag: CourseTagReducer,
  courseStatus: CourseStatusReducer,
  course: CourseReducer,
  courseWithBatch: CourseWithBatchReducer,
  courseSchedule: CourseScheduleReducer,
  transaction: transactionReducer,
  installment: InstallmentReducer,
  installmentTrack: InstallmentTrackReducer,
  social: SocialReducer,
  slider: SliderReducer,
  youtube: YoutubeReducer,
  query: queryReducer,
  lecture: LectureReducer,
  users: UsersReducer,
  enrolledUsers: EnrolledUsersReducer,
  session: SessionExpireReducer,
  role: RoleReducer,
  adminAccess: AdminAccessReducer,
  currentAffairs: CurrentAffairsReducer,
  classNotes: ClassNotesReducer,
  manualBookSeat: ManualBookSeatReducer,
  assignment: AssignmentsReducer,
  userAssignment: UserAssignmentsReducer,
  subject: SubjectReducer,
  quiz: QuizReducer,
  seminar: SeminarReducer
});

const store = createStore(rootReducer, applyMiddleware(ReduxThunk));

const App = () => {
  const [sideBarWithTitle, setsideBarWithTitle] = useState(true);
  const [loading, setloading] = useState(true);
  const dispatch = useDispatch();

  const token = useSelector((state) => state.auth.token);

  React.useEffect(() => {
    if (token) {
      token_api_interceptor.defaults.headers.common["authorization"] = token;
    }
    token_api_interceptor.interceptors.response.use(
      (res) => res,
      (err) => {
        if (err.response.status === 511) {
          // alert('Session expired')
          dispatch(LogoutAction());
          dispatch(SessionExpiredMessageAction({ status: true }));
          setTimeout(() => {
            dispatch(SessionExpiredMessageAction({ status: false }));
          }, 3000);
        }
        return Promise.reject(err);
      }
    );
  }, [token, dispatch]);

  React.useEffect(() => {
    if (typeof Storage !== "undefined") {
      let previous_login = localStorage.getItem(storagekey);
      if (previous_login !== null) {
        let credentials = JSON.parse(previous_login);

        const { user, role, token, pages } = credentials;
        token_api_interceptor.defaults.headers.common["authorization"] = token;

        if (pages) {
          dispatch(AutoLogin({ user, role, token, pages }));
        } else {
          dispatch(LogoutAction());
        }
      }
      setloading(false);
    }
  }, [dispatch]);

  return (
    <div
      className="h-full min-h-screen flex"
      style={{ backgroundColor: "rgb(243, 244, 246)" }}
    >
      <BrowserRouter>
        {token && (
          <SideBar state={sideBarWithTitle} changeState={setsideBarWithTitle} />
        )}
        <div className="w-full flex-1">
          {token && (
            <Navbar
              state={sideBarWithTitle}
              changeState={setsideBarWithTitle}
            />
          )}
          {loading ? null : !token ? <AuthRoutes /> : <MainRoutes />}
        </div>
      </BrowserRouter>
    </div>
  );
};

export default function RootApp() {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
}
