import { token_api_interceptor } from "../Interceptor/Interceptor";
import { CurrentAffairs } from "../Interceptor/Url";

// VIEW
export const CURRENTAFFAIRS = "CURRENTAFFAIRS";
export const CURRENTAFFAIRSSTATE = "CURRENTAFFAIRSSTATE";

// ADD
export const ADDCURRENTAFFAIRS = "ADDCURRENTAFFAIRS";
export const ADDUPDATECURRENTAFFAIRSSTATE = "ADDUPDATECURRENTAFFAIRSSTATE";

// UPDATE
export const UPDATECURRENTAFFAIRS = "UPDATECURRENTAFFAIRS";
export const UPDATECURRENTAFFAIRSSTATE = "UPDATECURRENTAFFAIRSSTATE";

// DELETE
export const DELETECURRENTAFFAIRS = "DELETECURRENTAFFAIRS";
export const DELETECURRENTAFFAIRSSTATE = "DELETECURRENTAFFAIRSSTATE";


export const CurrentAffairsStateAction = ({ loading, error, message = null }) => {
    return {
        type: CURRENTAFFAIRSSTATE,
        loading, error,
        message
    }
}

export const AddUpdateCurrentAffairsStateAction = ({ loading, error }) => {
    return {
        type: ADDUPDATECURRENTAFFAIRSSTATE,
        loading, error,
        message: null
    }
}

export const CurrentAffairsAction = () => {
    return async (dispatch) => {
        await token_api_interceptor.post(CurrentAffairs)
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: CURRENTAFFAIRS,
                        data: resp.data.data
                    });
                } else {
                    dispatch({
                        type: CURRENTAFFAIRSSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: CURRENTAFFAIRSSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const AddCurrentAffairsAction = ({ id, title, file, folder }) => {
    return ({
        type: ADDCURRENTAFFAIRS,
        id, title, file, folder
    })
};

export const UpdateCurrentAffairsAction = ({ id, title, folder, oldFolder }) => {

    const formData = new FormData()
    formData.append('id', id)
    formData.append('title', title)
    formData.append('folder', folder)

    return async (dispatch) => {
        await token_api_interceptor.post(CurrentAffairs + 'update', formData)
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: UPDATECURRENTAFFAIRS,
                        id, title, file: resp.data.file, folder, oldFolder
                    });
                } else {
                    dispatch({
                        type: ADDUPDATECURRENTAFFAIRSSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: ADDUPDATECURRENTAFFAIRSSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};

export const DeleteCurrentAffairsStateAction = ({ loading, error }) => {
    return {
        type: DELETECURRENTAFFAIRSSTATE,
        loading, error
    }
}

export const DeleteCurrentAffairsAction = ({ id }) => {
    return async (dispatch) => {
        await token_api_interceptor.post(CurrentAffairs + 'delete', { id })
            .then((resp) => {
                if (resp.data.status) {
                    dispatch({
                        type: DELETECURRENTAFFAIRS,
                        id
                    });
                } else {
                    dispatch({
                        type: DELETECURRENTAFFAIRSSTATE,
                        loading: false,
                        error: true,
                        message: resp.data.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: DELETECURRENTAFFAIRSSTATE,
                    loading: false,
                    error: true,
                    message: 'Something went wrong',
                });
            });
    };
};